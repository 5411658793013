import React from "react";
class DefaultScreen extends React.Component<any, any> {
  render() {
    return (
      <div className="right_Sec default_screen" id="right_Sec_main">
        {/* <div className="chat_or_info">hi</div> */}
      </div>
    );
  }
}
export default DefaultScreen;
