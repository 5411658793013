import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as actions from "../../redux/actions/actions";
import * as commonMethod from "../../commonMethods";
import { convertListsFromRefsIntoObjects, avatarColorMap, filterContactListWithRef } from "../../commonMethods";
import { ReactComponent as GroupSvg } from "../../images/grp_icon.svg";
import { ReactComponent as PlusOutlineSvg } from "../../images/plus-icon-outline.svg";
import { ReactComponent as MinusOutlineSvg } from "../../images/minus-icon-ouline-orange.svg";
import _ from "lodash";
import { store } from "../../..";
class YakchatGroupComponent extends React.Component<any, any> {
    constructor(props: any, state: any) {
        super(props);
        this.state = {
            Calledfrom: this.props.calledFrom !== undefined ? this.props.calledFrom : "",
            status: this.props.Status,
            userslist: this.props.UsersList !== undefined ? this.props.UsersList : [],
            contactlist: [],
            ContactFoldersWithconatactlist: this.props.ContactFoldersWithConatactList !== undefined ? this.props.ContactFoldersWithConatactList : [],
            contactsListToShow: [],
            page: 1,
            messageSelectedContacts: 0,
            activeCollapseKey: "",
            isGroupOpen: false,
        };
    }
    componentDidMount() {
        var GroupIds = this.state.yakChatListId.map((y: any, z: number) => {
            return (y.Id)
        })
        this.props.showGroupContacts(GroupIds)
    }

    addToSelected_newMessage = (x: any, y: any = undefined, e: any) => {
        let newContact;
        if (this.props.newCount !== this.state.messageSelectedContacts) {
            this.setState({ messageSelectedContacts: this.props.newCount });
        }
        this.setState({ messageSelectedContacts: this.state.messageSelectedContacts + 1 });
        this.props.messageSelectedContactsCount(this.props.newCount + 1);

        if (y) {
            if (this.props.calledFrom === "YakChat")
                newContact = { ...x, groupId: y.Id, type: "YakChat Group", };
            // The contact is of SharePoint and is situated inside a folder
            else if (this.props.calledFrom === "Share Point")
                newContact = {
                    ConatctFolderId: y.ConatctFolderId, idInFolder: x.id, type: "Share Point", displayName: x.displayName, mobilePhone: x.mobilePhone,
                };
            else if (this.props.calledFrom === "Users")
                newContact = { ...x, type: "Users Group", };
            else {
                newContact = { ...x, type: x.type ? x.type : this.props.calledFrom };
            }
        } else {
            newContact = { ...x, type: x.type ? x.type : this.props.calledFrom };
        }
        const newSelectedContacts = [
            ...this.props.selectedContacts_newMessage,
            newContact,
        ];
        this.props.setSelectedContacts(newSelectedContacts);
        e.stopPropagation();
    }
    removeFromSelected_newMessage = (x: any, y: any = undefined, e: any) => {
        this.props.messageSelectedContactsCount(this.props.newCount - 1);
        const newSelectedContacts = _.cloneDeep(this.props.selectedContactsNewMessage);
        let index = -1;
        if (y) {
            // The contact is of SharePoint and is situated inside a folder
            index = newSelectedContacts.findIndex((z: any) => z.Id === x.Id && z.ContactId === x.ContactId);
            index = newSelectedContacts.findIndex((z: any) => z.groupId === x.groupId && z.id === x.id);
        } else {
            index = newSelectedContacts.findIndex((z: any) => {
                if (z.type === "YakChat") return z.ContactId === x.ContactId;
                else if (z.type === "Users") return z.id === x.id;
                else if (z.type === "Outlook") return z.id === x.id;
                else if (z.type === "NewContact")
                    return z.mobilePhone === x.mobilePhone;
            });
        }
        newSelectedContacts.splice(index, 1);
        this.props.setSelectedContacts(newSelectedContacts);
        e.stopPropagation();
    };
    handelGroupClick = (y: any, z: any, callFrom: any) => {
        debugger
        if (y?.localContacts !== undefined) {
            y.localContacts.$values = [];
        } else if (y.ListData !== undefined) {
            y.ListData.localContacts.$values = [];
        }
        if (this.state.openNewGroup !== undefined && callFrom !== "group") {
            this.props.setGroupCurrentlyEditing(this.props.openNewGroup, this.state.yakChatListId, "ww", []);
            this.setState({ isGroupOpen: true });

        } else {
            if (this.state.yakChatListId) {
                this.props.setGroupCurrentlyEditing(y, this.state.yakChatListId, "ww", []);
            }
        }
        setTimeout(() => { this.props.setTabOpened("viewgroup"); }, 100)
        setTimeout(() => { commonMethod.openLeftSection(); }, 800);
        this.state.yakChatListId.forEach((localContact: any, index: any) => {
            if (index === z) {
                var element = document.getElementById("collapeYakChatLists" + index);
                var element1 = document.getElementById("setting_cion_button");
                setTimeout(() => { element1?.classList?.add("newContactshow"); }, 100);
            }
        })
        this.state.yakChatListId.forEach((localContact: any, index: any) => {
            if (index !== z) {
                var element = document.getElementById("collapeYakChatLists" + index);
                setTimeout(() => {
                    element?.classList?.remove("show");
                }, 100);
            }
            else if (index === z) {
                var element = document.getElementById("collapeYakChatLists" + index);
                element?.classList?.remove("newShow");
            }
        })
    }
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.ContactFoldersWithConatactList !== prevState.ContactFoldersWithconatactlist ||
            nextProps.Status !== prevState.status ||
            nextProps.ContactList !== prevState.contactlist ||
            nextProps.UsersList !== prevState.userslist ||
            nextProps.yakchatLists !== prevState.yakChatListId ||
            nextProps.newCount !== prevState.newCount ||
            nextProps.storeActualContactsInList !== prevState.storeActualContactsInList ||
            nextProps.openNewGroup !== prevState.openNewGroup
        ) {
            return {
                ContactFoldersWithconatactlist: nextProps.ContactFoldersWithConatactList,
                status: nextProps.Status,
                contactlist: nextProps.ContactList,
                userslist: nextProps.UsersList,
                yakChatListId: nextProps.yakchatLists,
                newCount: nextProps.newCount,
                storeActualContactsInList: nextProps.storeActualContactsInList,
                openNewGroup: nextProps.openNewGroup
            };
        } else {
            return null;
        }
    }
    public render() {
        return (
            < >
                <div id="accordion">
                    {this.state.yakChatListId.map((y: any, z: number) => {
                      if(y.ListData!=undefined)
                      {y.InboxId=y.ListData?.InboxId!=undefined?y.ListData?.InboxId:0}
                      else{
                        y.InboxId=y.inboxPermissions?.$values.length<=0?0:y.inboxPermissions?.$values[0].InboxId
                      }
                       
                        let x=false;
                        let companyVersion=store.getState().reduxReducer.companyVersion;
                        if (companyVersion == 2) {
                          debugger;
                          if (y.InboxId == 0) {
                            x = false;
                          } else {
                            x =
                              y.InboxId !== undefined
                                ? store
                                    .getState()
                                    .reduxReducer.loggedInUser.inboxes.$values.filter(
                                      (item: any) => item.InboxId == y.InboxId
                                    ) != undefined
                                  ? store
                                      .getState()
                                      .reduxReducer.loggedInUser.inboxes.$values.filter(
                                        (item: any) => item.InboxId == y.InboxId
                                      )[0]?.InboxType === 1
                                    ? true
                                    : false
                                  : false
                                : store
                                    .getState()
                                    .reduxReducer.loggedInUser.inboxes.$values.filter(
                                      (item: any) =>
                                        item.InboxId == y.ListData.InboxId
                                    ) != undefined
                                ? store
                                    .getState()
                                    .reduxReducer.loggedInUser.inboxes.$values.filter(
                                      (item: any) =>
                                        item.InboxId == y.ListData.InboxId
                                    )[0]?.InboxType === 1
                                  ? true
                                  : false
                                : false;
                          }
                        }
                        return (
                          <div
                            className="card h"
                            key={z}
                            //  onClick={() => { this.loadContactsForList(y.ListData !== undefined ? y?.ListData : y); }}
                            onClick={() => {
                              this.props.getYakChatLists();
                            }}
                          >
                            <div className="card-header" id="heading10">
                              <h2 className="mb-0 cardInnerHeader">
                                <span
                                  id="groupClickIcon"
                                  style={{
                                    display: "none",
                                    visibility: "hidden",
                                  }}
                                  onClick={() => {
                                    this.handelGroupClick(y, z, "Notgroup");
                                  }}
                                ></span>
                                <button
                                  className="btn btn-block text-left collapsed showContacts"
                                  type="button"
                                  id="setting_cion_button"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  data-target={"#collapeYakChatLists" + z}
                                  aria-expanded="false"
                                  onClick={() => {
                                    this.handelGroupClick(y, z, "group");
                                  }}
                                >
                                  <>
                                    <span className="cl_lft">
                                      <span className="left_Bx">
                                        <span className="grp_bx">
                                          {" "}
                                          {commonMethod.generateFirstLastNameIntials(
                                            y.ListName !== undefined
                                              ? y.ListName
                                              : y.ListData.ListName
                                          )}
                                        </span>
                                        <span
                                          className="grp_bx_txt"
                                          title={
                                            y.ListName !== undefined
                                              ? y.ListName
                                              : y.ListData.ListName
                                          }
                                        >
                                          {y.ListName !== undefined
                                            ? y.ListName
                                            : y.ListData.ListName}
                                          {y.GroupEmail || x ? (
                                            <GroupSvg
                                              width="25"
                                              height="25"
                                              style={{ marginLeft: 5 }}
                                            />
                                          ) : null}
                                          <span className="grry_txt">
                                            {y.ListName !== undefined
                                              ? y.contactCount !== 0 &&
                                                y.contactCount !== undefined
                                                ? y.contactCount + " Contacts"
                                                : y.localContacts?.length !==
                                                  undefined
                                                ? y.localContacts?.length +
                                                  " Contacts"
                                                : ""
                                              : ""}
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                    <span className="cl_rit">
                                      <img
                                        src="images/down_arrow.svg"
                                        className="arrw_dwn_img"
                                        id="down_arrow_img"
                                        alt="usr_icn"
                                      />
                                    </span>
                                  </>
                                </button>
                              </h2>
                            </div>
                            <div
                              id={"collapeYakChatLists" + z}
                              className="collapse"
                            >
                              <div className="card-body">
                                <ul className="usr_list ">
                                  {/* Listing the contacts in the group here */}
                                  {this.props.selectedContactsNewMessage?.map(
                                    (a: any, b: any) => {
                                      return (
                                        <li
                                          className={
                                            (this.props
                                              .contactSelected_OpenContact &&
                                              this.props
                                                .contactSelected_OpenContact
                                                .ContactId === a?.ContactId &&
                                              (this.props.tabOpened ===
                                                "contactopen" ||
                                                this.props.tabOpened ===
                                                  "contactedit")) ||
                                            (this.props
                                              .selectedContactsWithCtrl &&
                                              this.props.selectedContactsWithCtrl.find(
                                                (k: any) =>
                                                  k.ContactId === a?.ContactId
                                              ))
                                              ? "active"
                                              : ""
                                          }
                                          key={"contactlist" + b}
                                          style={{
                                            cursor:
                                              this.props.tabOpened !==
                                                "newmessage" &&
                                              this.props.tabOpened !==
                                                "newgroup" &&
                                              this.props.tabOpened !==
                                                "editgroup"
                                                ? "pointer"
                                                : "auto",
                                          }}
                                          onClick={() => {
                                            if (
                                              this.props.tabOpened ===
                                                "newmessage" ||
                                              this.props.tabOpened ===
                                                "newgroup" ||
                                              this.props.tabOpened ===
                                                "editgroup"
                                            ) {
                                              return;
                                            } else {
                                              this.props.setContactSelected_OpenContact(
                                                a,
                                                "YakChat"
                                              );
                                              this.props.setTabOpened(
                                                "contactopen"
                                              );
                                            }
                                          }}
                                        >
                                          <div className="usr_imgbx">
                                            <div
                                              className="usr_img2"
                                              style={{
                                                backgroundColor: a?.avatarColor,
                                              }}
                                            >
                                              {commonMethod.generateFirstLastNameIntials(
                                                a?.ForeName + " " + a?.SureName
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            className="usr_txt"
                                            style={{ display: "flex" }}
                                          >
                                            <div>
                                              <h3 style={{ textAlign: "left" }}>
                                                {a?.ForeName} {a?.SureName}
                                              </h3>
                                              <div className="txt_div">
                                                <span className="sub_txt">
                                                  {a?.PhoneNumber}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                flex: 1,
                                                alignSelf: "center",
                                                float: "right",
                                                textAlign: "right",
                                              }}
                                            >
                                              {this.props.tabOpened ===
                                                "newmessage" ||
                                              this.props.tabOpened ===
                                                "newgroup" ||
                                              this.props.tabOpened ===
                                                "editgroup" ? (
                                                this.props.selectedContactsNewMessage.filter(
                                                  (m: any) => {
                                                    return (
                                                      a?.ContactId ===
                                                        m?.ContactId &&
                                                      a?.Id === m?.groupId
                                                    );
                                                  }
                                                ).length > 0 ? (
                                                  <MinusOutlineSvg
                                                    className="ctThem"
                                                    onClick={(e: any) =>
                                                      this.removeFromSelected_newMessage(
                                                        y,
                                                        a,
                                                        e
                                                      )
                                                    }
                                                  ></MinusOutlineSvg>
                                                ) : (
                                                  <PlusOutlineSvg
                                                    fill={
                                                      this.props
                                                        .currentTheme === "dark"
                                                        ? "var(--text)"
                                                        : "black"
                                                    }
                                                    onClick={(e: any) => {
                                                      this.addToSelected_newMessage(
                                                        y,
                                                        a,
                                                        e
                                                      );
                                                    }}
                                                  ></PlusOutlineSvg>
                                                )
                                              ) : null}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                    })}
                </div>
            </>
        );
    }


}
const mapStateToProps = (props: any) => {
    return {
        selectedContactsNewMessage: props.reduxReducer.selectedContactsNewMessage || [],
        tabOpened: props.reduxReducer.tabOpened,
        contactSelected_OpenContact: props.reduxReducer.contactSelected_OpenContact,
        currentTheme: props.reduxReducer.currentTheme,
        selectedContactsWithCtrl: props.reduxReducer.selectedContactsWithCtrl,
        newCount: props.reduxReducer.newCount,
        yakchatLists: props.reduxReducer.yakchatLists,
        storeActualContactsInList: props.reduxReducer.storeActualContactsInListReducer,
        opencollapse: props.reduxReducer.opencollapse,
        yakChatGroupContacts: props.reduxReducer.yakChatGroupContacts,
        openNewGroup: props.reduxReducer.openNewGroup,
    };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
    return {
        setSelectedContacts: (contact: any) => {
            dispatch<any>(actions.setSelectedContacts(contact));
        },
        setTabOpened: (tab: string) => {
            dispatch<any>(actions.setTabOpened(tab));
        },
        setContactSelected_OpenContact: (contact: any, calledFrom: any) => {
            dispatch<any>(actions.setContactSelected_OpenContact({ ...contact, type: calledFrom }));
        },
        setGroupCurrentlyEditing: async (group: any, lists: any, calledFrom: any, contacts: any) => {
            let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
            dispatch<any>(actions.getContactsByListId_V2(group, lists, "", []));
        }
        else
        {
            dispatch<any>(actions.getContactsByListId(group, lists, "", []));
        }
        },
        // openNewGroup: (group: any,) => {
        //     dispatch<any>(actions.setNewCreatedGroup(group));
        // },
        selectContactWithCtrl: (contact: any) => {
            dispatch<any>(actions.selectContactWithCtrl(contact));
        },
        messageSelectedContactsCount: (count: any) => {
            dispatch<any>(actions.messageSelectedContactsCount(count));
        },
        getYakChatLists: () => {
            let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
      {
            dispatch<any>(actions.getYakChatLists_V2());
      }
      else
      {
        dispatch<any>(actions.getYakChatLists());
      }
        },
        showGroupContacts: (GroupIds: any) => {
            dispatch<any>(actions.showGroupContacts(GroupIds));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(YakchatGroupComponent);
