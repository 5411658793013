import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as actions from "../../redux/actions/actions";
import * as commonMethod from "../../commonMethods";
import { ReactComponent as PlusOutlineSvg } from "../../images/plus-icon-outline.svg";
import { ReactComponent as MinusOutlineSvg } from "../../images/minus-icon-ouline-orange.svg";
import { ReactComponent as SettingIcon } from "../../images/setting.svg";
import { Affix, Button, Collapse } from "antd";
import profileImage from "../../images/profile.png";
import _ from "lodash";
import YakchatGroups from "./yakchatGroups";
import DisplayNewContactAddOption from "./displayNewContact";
import { store } from "../../..";
const { Panel } = Collapse;
class contactsComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      Calledfrom:
        this.props.calledFrom !== undefined ? this.props.calledFrom : "",
      status: this.props.Status,
      userslist: this.props.UsersList !== undefined ? this.props.UsersList : [],
      contactlist: [],
      ContactFoldersWithconatactlist:
        this.props.ContactFoldersWithConatactList !== undefined
          ? this.props.ContactFoldersWithConatactList
          : [],
      contactsListToShow: [],
      page: 1,
      messageSelectedContacts: 0,
      activeCollapseKey: "",
    };
  }
  callback = (key: any) => { };
  onScrollPagination = (e: any) => {
    if (e.target.scrollTop / e.target.scrollHeight > 1.1) {
      this.setState({ page: this.state.page + 1 });
    }
  };
  componentDidMount() {
    document
      .getElementById("contacts_list_div")
      ?.addEventListener("scroll", this.onScrollPagination);
  }
  componentWillUnmount() {
    document
      .getElementById("contacts_list_div")
      ?.removeEventListener("scroll", this.onScrollPagination);
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    
    if (
    
      nextProps.ContactFoldersWithConatactList !==
      prevState.ContactFoldersWithconatactlist ||
      nextProps.Status !== prevState.status ||
      nextProps.ContactList !== prevState.contactlist ||
      nextProps.UsersList !== prevState.userslist ||
      nextProps.yakchatLists !== prevState.yakChatListId ||
      nextProps.newCount !== prevState.newCount||
      nextProps.azureGroups !== prevState.azureGroups
    ) {
      return {
        ContactFoldersWithconatactlist:
          nextProps.ContactFoldersWithConatactList,
        status: nextProps.Status,
        contactlist: nextProps.ContactList,
        userslist: nextProps.UsersList,
        yakChatListId: nextProps.yakchatLists,
        newCount: nextProps.newCount,
        azureGroups : nextProps.azureGroups,
      };
    } else {
      return null;
    }
  }
  public render() {
    
    return (
      <React.Fragment>
        <div className="accordion" id="accordion">
          {(this.state.Calledfrom === "Outlook" ||
            this.state.Calledfrom === "YakChat") &&
            this.state.status === true &&
            this.state.contactlist !== undefined
            ? this.renderContactsListhtml()
            : this.state.Calledfrom === "Users" && this.state.status === true
              ? this.renderUsersListHtml()
              : null}

          {(this.state.Calledfrom === "Outlook" ||
            this.state.Calledfrom === "Share Point") &&
            this.state.status === true &&
            this.state.ContactFoldersWithconatactlist !== undefined ? (
            this.state.ContactFoldersWithconatactlist.filter(
              (x: any) => x.ConatctFolderId !== "" && x.ContactList?.length > 0
            )?.length === 0 ? (
              <DisplayNewContactAddOption />
            ) : (
              this.state.ContactFoldersWithconatactlist.filter(
                (x: any) => x.ConatctFolderId !== "" && x.ContactList.length > 0
              ).map((x: any, i: number) => (
                <Collapse
                  onChange={this.callback}
                  className="ant-collapseheader  cardInnerHeader"
                  defaultActiveKey={
                    this.props.opencollapse ? ["1", "2", "0"] : ""
                  }
                  expandIconPosition={"right"}
                >
                  {/* <li className="card cardInnerHeader"> */}
                  <Panel
                    forceRender={this.props.opencollapse ? true : false}
                    showArrow={false}
                    header={
                      <span className="cl_lft" style={{ overflow: "hidden" }}>
                        <span className="left_Bx">
                          <span className="grp_bx">
                            {" "}
                            {commonMethod.generateFirstLastNameIntials(
                              x.ContactName
                            )}
                          </span>
                          <span
                            className="grp_bx_txt"
                            style={{ inlineSize: "max-content" }}
                          >
                            {x.ContactName}
                            <span className="grry_txt">
                              {" "}
                              {x.ContactList.length !== 0
                                ? x.ContactList.length + " " + "Contacts"
                                : ""}{" "}
                            </span>
                          </span>
                        </span>
                      </span>
                    }
                    extra={
                      <span className="cl_rit">
                        <a href="javascript:;void(0);" className="setting_cion">
                          <SettingIcon className=""></SettingIcon>
                        </a>
                        <img
                          src="images/down_arrow.svg"
                          className="arrw_dwn_img"
                          alt="usr_icn"
                        />
                      </span>
                      // <img
                      //   src="images/down_arrow.svg"
                      //   className="arrw_dwn_img"
                      //   alt="usr_icn"
                      // />
                    }
                    key={i}
                  >
                    <div className="card-body">
                      <ul className="usr_list ">
                        {x.ContactList.length !== 0
                          ? x.ContactList.filter((h: any) => h.id !== "").map(
                            (y: any, z: number) => {
                              y.type = "Share Point";
                              return (
                                <li
                                  className={
                                    (this.props.contactSelected_OpenContact &&
                                      this.props.contactSelected_OpenContact
                                        .id === y.id &&
                                      (this.props.tabOpened ===
                                        "contactopen" ||
                                        this.props.tabOpened ===
                                        "contactedit")) ||
                                      (this.props.selectedContactsWithCtrl &&
                                        this.props.selectedContactsWithCtrl.find(
                                          (k: any) => k.id === y.id
                                        ))
                                      ? "active"
                                      : ""
                                  }
                                  key={"contactlist" + z}
                                  style={{
                                    cursor:
                                      this.props.tabOpened !== "newmessage" &&
                                        this.props.tabOpened !== "newgroup" &&
                                        this.props.tabOpened !== "editgroup"
                                        ? "pointer"
                                        : "auto",
                                  }}
                                  onClick={(e) => {
                                    if (
                                      this.props.tabOpened === "newmessage" ||
                                      this.props.tabOpened === "newgroup" ||
                                      this.props.tabOpened === "editgroup"
                                    )
                                      
                                    return;
                                    if (e.ctrlKey) {
                                      if (
                                        this.props.selectedContactsWithCtrl.find(
                                          (n: any) => n.id === y.id
                                        )
                                      )
                                        return;
                                      this.props.selectContactWithCtrl({ ...y, type: this.state.Calledfrom, });
                                    } else {
                                      this.props.selectContactWithCtrl(null);
                                      if (this.state.yakChatListId) {
                                        this.props.setGroupCurrentlyEditing(x, this.state.yakChatListId, "ww", []);
                                      }
                                      this.props.setContactSelected_OpenContact(y, this.props.calledFrom);
                                      this.props.setTabOpened("contactopen");
                                      setTimeout(() => {
                                        commonMethod.openLeftSection();
                                      }, 200);
                                    }
                                  }}
                                >
                                  <div className="usr_imgbx">
                                    <div
                                      className="usr_img2"
                                      style={{
                                        backgroundColor: y.avatarColor,
                                      }}
                                    >
                                      {commonMethod.generateFirstLastNameIntials(
                                        y.displayName
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="usr_txt"
                                    style={{ display: "flex" }}
                                  >
                                    <div>
                                      <h3 style={{ textAlign: "left" }}>
                                        {y.displayName.includes(y.title)
                                          ? y.displayName
                                          : y.displayName +
                                          " " +
                                          y.title}{" "}
                                      </h3>
                                      <div className="txt_div">
                                        <span className="sub_txt">
                                          {y.mobilePhone}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        flex: 1,
                                        alignSelf: "center",
                                        float: "right",
                                        textAlign: "right",
                                      }}
                                    >
                                      {this.props.tabOpened ===
                                        "newmessage" ||
                                        this.props.tabOpened === "newgroup" ||
                                        this.props.tabOpened === "editgroup" ? (
                                        this.props.selectedContacts_newMessage.find(
                                          (m: any) => {
                                            if (m.type === "Share Point")
                                              return (
                                                y.id === m.idInFolder &&
                                                x.ConatctFolderId ===
                                                m.ConatctFolderId
                                              );
                                          }
                                        ) ? (
                                          <MinusOutlineSvg
                                            className="ctThem"
                                            onClick={(e: any) =>
                                              this.removeFromSelected_newMessage(
                                                y,
                                                x,
                                                e
                                              )
                                            }
                                          ></MinusOutlineSvg>
                                        ) : (
                                          <PlusOutlineSvg
                                            fill={
                                              this.props.currentTheme ===
                                                "dark"
                                                ? "var(--text)"
                                                : "black"
                                            }
                                            onClick={(e: any) =>
                                              this.addToSelected_newMessage(
                                                y,
                                                x,
                                                e
                                              )
                                            }
                                          ></PlusOutlineSvg>
                                        )
                                      ) : null}
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          )
                          : null}
                      </ul>
                    </div>
                    {/* </div> */}
                  </Panel>
                  {/* </li> */}
                </Collapse>
              ))
            )
          ) : null}
        </div>
      </React.Fragment>
    );
  }
  loadContactsForList = (x: any) => {
    setTimeout(() => {
      commonMethod.openLeftSection();
    }, 100);
  };

  addToSelected_newMessage = (x: any, y: any = undefined, e: any) => {
    let newContact;
    if (this.props.newCount !== this.state.messageSelectedContacts) {
      this.setState({ messageSelectedContacts: this.props.newCount });
    }
    this.setState({
      messageSelectedContacts: this.state.messageSelectedContacts + 1,
    });
    this.props.messageSelectedContactsCount(this.props.newCount + 1);

    if (y) {
      if (this.props.calledFrom === "YakChat")
        newContact = {
          ...x,
          groupId: y.Id,
          type: "YakChat Group",
        };
      // The contact is of SharePoint and is situated inside a folder
      else if (this.props.calledFrom === "Share Point")
        newContact = {
          ConatctFolderId: y.ConatctFolderId,
          idInFolder: x.id,
          type: "Share Point",
          displayName: x.displayName,
          mobilePhone: x.mobilePhone,
        };
      else if (this.props.calledFrom === "Users")
        newContact = {
          ...x,
          type: "Users Group",
        };
      else {
        newContact = { ...x, type: x.type ? x.type : this.props.calledFrom };
      }
    } else {
      newContact = { ...x, type: x.type ? x.type : this.props.calledFrom };
    }
    const newSelectedContacts = [
      ...this.props.selectedContacts_newMessage,
      newContact,
    ];
    this.props.setSelectedContacts(newSelectedContacts);
    e.stopPropagation();
  };
  removeFromSelected_newMessage = (x: any, y: any = undefined, e: any) => {
    this.props.messageSelectedContactsCount(this.props.newCount - 1);
    const newSelectedContacts = _.cloneDeep(
      this.props.selectedContacts_newMessage
    );
    let index = -1;
    if (y) {
      // The contact is of SharePoint and is situated inside a folder
      if (x.type === "Share Point")
        index = newSelectedContacts.findIndex(
          (z: any) =>
            z.ConatctFolderId === y.ConatctFolderId && z.idInFolder === x.id
        );
      else if (x.type === "YakChat Group")
        index = newSelectedContacts.findIndex(
          (z: any) => z.groupId === x.groupId && z.ContactId === x.ContactId
        );
      else if (x.type === "Users Group")
        index = newSelectedContacts.findIndex(
          (z: any) => z.groupId === x.groupId && z.id === x.id
        );
    } else {
      index = newSelectedContacts.findIndex((z: any) => {
        if (z.type === "YakChat") return z.ContactId === x.ContactId;
        else if (z.type === "Users") return z.id === x.id;
        else if (z.type === "Outlook") return z.id === x.id;
        else if (z.type === "NewContact")
          return z.mobilePhone === x.mobilePhone;
      });
    }
    newSelectedContacts.splice(index, 1);
    this.props.setSelectedContacts(newSelectedContacts);
    e.stopPropagation();
  };
  liYakChatList = () => {

    return this.state.contactlist
      .slice(0, this.state.page * 5000)
      .map((x: any, i: number) => (
        <>
          <li
            className={
              (this.props.contactSelected_OpenContact &&
                this.props.contactSelected_OpenContact[
                this.state.Calledfrom === "Outlook"
                  ? "id"
                  : this.state.Calledfrom === "YakChat"
                    ? "ContactId"
                    : ""
                ] ===
                x[
                this.state.Calledfrom === "Outlook"
                  ? "id"
                  : this.state.Calledfrom === "YakChat"
                    ? "ContactId"
                    : ""
                ] &&
                (this.props.tabOpened === "contactopen" ||
                  this.props.tabOpened === "contactedit")) ||
                (this.props.selectedContactsWithCtrl &&
                  this.props.selectedContactsWithCtrl.find(
                    (k: any) =>
                      k[
                      this.state.Calledfrom === "Outlook"
                        ? "id"
                        : this.state.Calledfrom === "YakChat"
                          ? "ContactId"
                          : ""
                      ] ===
                      x[
                      this.state.Calledfrom === "Outlook"
                        ? "id"
                        : this.state.Calledfrom === "YakChat"
                          ? "ContactId"
                          : ""
                      ]
                  ))
                ? "active"
                : ""
            }
            key={i}
            style={{
              cursor:
                this.props.tabOpened !== "newmessage" &&
                  this.props.tabOpened !== "newgroup" &&
                  this.props.tabOpened !== "editgroup"
                  ? "pointer"
                  : "auto",
            }}
            onClick={(e) => {
              if (
                this.props.tabOpened === "newmessage" ||
                this.props.tabOpened === "newgroup" ||
                this.props.tabOpened === "editgroup"
              )
                return;
              if (e.ctrlKey) {
                if (
                  this.props.selectedContactsWithCtrl &&
                  this.props.selectedContactsWithCtrl.find(
                    (n: any) =>
                      n[
                      this.state.Calledfrom === "Outlook"
                        ? "id"
                        : this.state.Calledfrom === "YakChat"
                          ? "ContactId"
                          : ""
                      ] ===
                      x[
                      this.state.Calledfrom === "Outlook"
                        ? "id"
                        : this.state.Calledfrom === "YakChat"
                          ? "ContactId"
                          : ""
                      ]
                  )
                )
                  return;
                this.props.selectContactWithCtrl({
                  ...x,
                  type: this.state.Calledfrom,
                });
              } else {
                this.props.selectContactWithCtrl(null);
                this.props.setContactSelected_OpenContact(
                  x,
                  this.props.calledFrom
                );
                this.props.setTabOpened("contactopen");
                //   this.props.getYakChatList();
                setTimeout(() => {
                  commonMethod.openLeftSection();
                }, 200);
              }
            }}
          >
            <div className="usr_imgbx">
              <div
                className="usr_img2"
                style={{ backgroundColor: x.avatarColor }}
              >
                {commonMethod.generateFirstLastNameIntials(
                  x.displayName !== undefined
                    ? x.displayName
                    : x.SureName !== undefined
                      ? x.ForeName + " " + (x?.SureName !== null ? x?.SureName : "")
                      : x.ForeName
                )}
              </div>
            </div>
            <div
              className="usr_txt"
              style={{ display: "flex", maxWidth: "80%" }}
            >
              <div style={{ overflow: "hidden" }}>
                <h3
                  style={{
                    textAlign: "left",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {x.displayName !== undefined
                    ? x.displayName
                    : x.SureName !== undefined
                      ? (x.ForeName !== null ? x.ForeName : "") + " " + (x.SureName !== null ? x.SureName : "")
                      : x.ForeName}{" "}
                </h3>
                <div className="txt_div">
                  <span className="sub_txt">
                    {x.mobilePhone !== undefined
                      ? x.mobilePhone
                      : x.PhoneNumber}{" "}
                  </span>
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  alignSelf: "center",
                  float: "right",
                  textAlign: "right",
                }}
              >
                {this.props.tabOpened === "newmessage" ||
                  this.props.tabOpened === "newgroup" ||
                  this.props.tabOpened === "editgroup" ? (
                  this.props.selectedContacts_newMessage.find((m: any) => {
                    if (this.props.calledFrom === "YakChat")
                      return m.ContactId === x.ContactId;
                    else if (this.props.calledFrom === "Outlook")
                      return m.id === x.id;
                  }) ? (
                    <MinusOutlineSvg
                      className="ctThem"
                      onClick={(e: any) =>
                        this.removeFromSelected_newMessage(x, "", e)
                      }
                    ></MinusOutlineSvg>
                  ) : (
                    <PlusOutlineSvg
                      fill={
                        this.props.currentTheme === "dark"
                          ? "var(--text)"
                          : "black"
                      }
                      onClick={(e: any) =>
                        this.addToSelected_newMessage(x, "", e)
                      }
                    ></PlusOutlineSvg>
                  )
                ) : null}
              </div>
            </div>
          </li>
        </>
      ));
  };
  renderYakChatHtml = () => {
    
    return this.props.searchFilter.length !== 0 &&
      this.props.tabOpened === "newmessage" ? (
      <ul className="usr_list ">
        {this.liYakChatList()}
        {<DisplayNewContactAddOption />}
      </ul>
    ) : (
      <div className="card">
        <div className="card-header" id="heading4">
          <h2 className="mb-0">
            <button
              className="btn  btn-block text-left collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#collapse5"
              aria-expanded="true"
              aria-controls="collapse5"
            >
              {(this.state.Calledfrom === "Outlook" ||
                this.state.Calledfrom === "Share Point" ||
                this.state.Calledfrom === "YakChat") &&
                this.state.status === true ? (
                <span className="cl_lft">
                  {this.state.Calledfrom === "YakChat"
                    ? "All"
                    : this.state.Calledfrom}{" "}
                  Contacts
                  <span className="sub_txt2">
                    {" "}
                    {this.state.contactlist !== undefined
                      ? this.state.contactlist.length
                      : null}{" "}
                    Contacts{" "}
                  </span>
                </span>
              ) : (
                <span className="cl_lft">
                  All YakChat Contacts
                  <span className="sub_txt2"> 40 Contacts </span>
                </span>
              )}
              <span className="cl_rit">
                <img
                  src="images/down_arrow.svg"
                  className="arrw_dwn_img"
                  alt="usr_icn"
                />
              </span>
            </button>
          </h2>
        </div>
        <div
          id="collapse5"
          className="collapse show"
          aria-labelledby="heading4"
        >
          <div className="card-body">
            <ul className="usr_list ">{this.liYakChatList()}</ul>
            {this.props.newCount > 0 ? (
              <Affix offsetBottom={25} className="newFABMessageButton">
                <Button
                  onClick={() => {
                    commonMethod.openLeftSection();
                    setTimeout(() => {
                      this.props.setTabOpened("newmessage");
                    }, 100);
                  }}
                  className="messageFAB"
                >
                  {this.props.newCount + " "}Contacts Selected
                </Button>
              </Affix>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          id="collapse4"
          className="collapse show"
          aria-labelledby="heading4"
        ></div>
        <div
          id="collapse10"
          className="collapse show"
          aria-labelledby="heading4"
        >
          <div className="card-body" id=" accordion">
            <ul className="usr_list" id="heading10">
              {this.props.searchFilter?.length === 0 ? (
                this.state.yakChatListId?.length === 0 ? null : (
                  this.state.Calledfrom === "YakChat" ? <YakchatGroups /> : ""
                )
              ) : this.state.yakChatListId?.length === 0 ? null : (
                this.state.yakChatListId.map((j: any, i: number) => {
                  const s = this.props.searchFilter.toLowerCase();
                  if (!j.Id) return null;
                  if(j.ListName==null||j.ListName==undefined)
                  {
                    j.ListName="";
                  }
                  if (j.ListName.toLowerCase().includes(s))
                    return this.renderYakChatListAndMembersFromList(j, i);
                  
                  let newMembers = [];
                  j = _.cloneDeep(j);
                  if (j?.localContacts !== undefined) {
                    for (let member of j?.localContacts.$values) {
                      if (
                        member?.ContactId &&
                        (member.ContactEmail?.toLowerCase().includes(s) ||
                          member.ForeName?.toLowerCase().includes(s) ||
                          member.SureName?.toLowerCase().includes(s) ||
                          member.PhoneNumber?.toLowerCase().includes(s) ||
                          member.GroupEmail?.toLowerCase().includes(s) ||
                          (
                            member.ForeName.toLowerCase() +
                            " " +
                            member.SureName.toLowerCase()
                          )?.includes(s))
                      )
                        newMembers.push(member);
                    }
                  }

                  j.members = newMembers;

                  return j.members.length > 0
                    ? this.renderYakChatListAndMembersFromList(j, i)
                    : null;
                })
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  renderContactsListhtml = () => {
    
    return this.state.contactlist !== undefined
      ? this.state.contactlist.length === 0
        ? this.renderYakChatHtml()
        : this.renderYakChatHtml()
      : null;
  };
  renderUsersListHtml = () => {
    return (
      <div className="card">
        <div className="card-header" id="heading4">
          <h2 className="mb-0">
            <button
              className="btn  btn-block text-left collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#collapse4"
              aria-expanded="true"
              aria-controls="collapse4"
            >
              <span className="cl_lft">
                <span className="left_Bx">
                  <span className="grp_bx_txt">
                    All Directory Contacts
                    <span className="sub_txt2">
                      {" "}
                      {this.state.userslist !== undefined
                        ? this.state.userslist.length
                        : null}{" "}
                      users{" "}
                    </span>
                  </span>
                </span>
              </span>
              <span className="cl_rit">
                <img
                  src="images/down_arrow.svg"
                  className="arrw_dwn_img"
                  alt="usr_icn"
                />
              </span>
            </button>
          </h2>
        </div>

        <div
          id="collapse4"
          className="collapse show"
          aria-labelledby="heading4"
          data-parent="#accordion"
        >
          <div className="card-body">
            <ul className="usr_list ">
              {this.state.userslist.length === 0 ? (
                <DisplayNewContactAddOption />
              ) : (
                this.state.userslist
                  .slice(0, this.state.page * 100)
                  .map((x: any, i: number) => (
                    <li
                      className={
                        (this.props.contactSelected_OpenContact &&
                          this.props.contactSelected_OpenContact.id === x.id &&
                          (this.props.tabOpened === "contactopen" ||
                            this.props.tabOpened === "contactedit")) ||
                          (this.props.selectedContactsWithCtrl &&
                            this.props.selectedContactsWithCtrl.find(
                              (k: any) => k.id === x.id
                            ))
                          ? "active"
                          : ""
                      }
                      key={i}
                      style={{
                        cursor:
                          this.props.tabOpened !== "newmessage" &&
                            this.props.tabOpened !== "newgroup" &&
                            this.props.tabOpened !== "editgroup"
                            ? "pointer"
                            : "auto",
                      }}
                      onClick={(e) => {
                        if (
                          this.props.tabOpened === "newmessage" ||
                          this.props.tabOpened === "newgroup" ||
                          this.props.tabOpened === "editgroup"
                        )
                          return;
                        if (e.ctrlKey) {
                          if (
                            this.props.selectedContactsWithCtrl.find(
                              (n: any) => n.id === x.id
                            )
                          )
                            return;
                          this.props.selectContactWithCtrl({
                            ...x,
                            type: this.state.Calledfrom,
                          });
                        } else {
                          this.props.selectContactWithCtrl(null);
                          this.props.setContactSelected_OpenContact(
                            x,
                            this.props.calledFrom
                          );
                          this.props.setTabOpened("contactopen");
                          setTimeout(() => {
                            commonMethod.openLeftSection();
                          }, 200);
                        }
                      }}
                    >
                      <div className="usr_imgbx">
                        <div
                          className="usr_img2"
                          style={{ backgroundColor: x.avatarColor }}
                        >
                          {" "}
                          {commonMethod.generateFirstLastNameIntials(
                            x.displayName
                          )}{" "}
                        </div>
                      </div>
                      <div className="usr_txt" style={{ display: "flex" }}>
                        <div>
                          <h3 style={{ textAlign: "left" }}>{x.displayName}</h3>
                          <div className="txt_div">
                            <span className="sub_txt">{x.mobilePhone}</span>
                          </div>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            alignSelf: "center",
                            float: "right",
                            textAlign: "right",
                          }}
                        >
                          {this.props.tabOpened === "newmessage" ||
                            this.props.tabOpened === "newgroup" ||
                            this.props.tabOpened === "editgroup" ? (
                            this.props.selectedContacts_newMessage.find(
                              (m: any) => m.id === x.id
                            ) ? (
                              <MinusOutlineSvg
                                className="ctThem"
                                onClick={(e: any) =>
                                  this.removeFromSelected_newMessage(x, "", e)
                                }
                              ></MinusOutlineSvg>
                            ) : (
                              <PlusOutlineSvg
                                fill={
                                  this.props.currentTheme === "dark"
                                    ? "var(--text)"
                                    : "black"
                                }
                                onClick={(e: any) =>
                                  this.addToSelected_newMessage(x, "", e)
                                }
                              ></PlusOutlineSvg>
                            )
                          ) : null}
                        </div>
                      </div>
                    </li>
                  ))
              )}
            </ul>
          </div>
        </div>
        <div
          id="collapse5"
          className="collapse show"
          aria-labelledby="heading4"
          data-parent="#accordion1"
        >
          <div className="card-body">
            <ul className="usr_list ">
              {

                this.props.searchFilter.length === 0
                  ? this.props.azureGroupData &&
                  this.props.azureGroupData.map((x: any, i: number) =>
                    this.renderAzureGroupAndMembersFromGroup(x, i)
                  )
                  : this.props.azureGroupData &&
                  this.props.azureGroupData.map((j: any, i: number) => {
                    const s = this.props.searchFilter.toLowerCase();
                    if (j.displayName.toLowerCase().includes(s))
                      return this.renderAzureGroupAndMembersFromGroup(j, i);
                    let newMembers = [];
                    j = JSON.parse(JSON.stringify(j));

                    for (let member of j.members) {
                      if (
                        member.displayName?.toLowerCase().includes(s) ||
                        member.mail?.toLowerCase().includes(s) ||
                        member.mobilePhone?.toLowerCase().includes(s)
                      )
                        newMembers.push(member);
                    }
                    j.members = newMembers;

                    return j.members.length > 0
                      ? this.renderAzureGroupAndMembersFromGroup(j, i)
                      : null;
                  })}
            </ul>
          </div>
        </div>
      </div>
    );
  };

 
  handelGroupClick = (y: any, z: any, callFrom: any) => {
    if (y?.localContacts !== undefined) {
        y.localContacts.$values = [];
    } else if (y.ListData !== undefined) {
        y.ListData.localContacts.$values = [];
    }
    if (this.state.openNewGroup !== undefined && callFrom !== "group") {
        this.props.setAzureGroupCurrentlyEditing(this.props.openNewGroup, this.state.azureGroups, "ww", []);
        this.setState({ isGroupOpen: true });

    } else {
        if (this.state.azureGroups) {
            this.props.setAzureGroupCurrentlyEditing(y, this.state.azureGroups, "ww", []);
        }
    }
    setTimeout(() => { this.props.setTabOpened("viewgroup"); }, 100)
    setTimeout(() => { commonMethod.openLeftSection(); }, 800);
    this.state.yakChatListId.forEach((localContact: any, index: any) => {
        if (index === z) {
            var element = document.getElementById("collapeYakChatGroups" + index);
            var element1 = document.getElementById("setting_cion_button");
            setTimeout(() => { element1?.classList?.add("newContactshow"); }, 100);
        }
    })
    this.state.yakChatListId.forEach((localContact: any, index: any) => {
        if (index !== z) {
            var element = document.getElementById("collapeYakChatGroups" + index);
            setTimeout(() => {
                element?.classList?.remove("show");
            }, 100);
        }
        else if (index === z) {
            var element = document.getElementById("collapeYakChatGroups" + index);
            element?.classList?.remove("newShow");
        }
    })
}
  renderYakChatListAndMembersFromList = (x: any, i: number) => <></>;

  renderAzureGroupAndMembersFromGroup = (x: any, i: number) => (
    <div className="card" key={i}>
      <div className="card-header" id="heading10">
        <h2 className="mb-0">
          <button
            className="btn btn-block text-left collapsed"
            type="button"
            data-toggle="collapse"
            data-target={"#collapeYakChatGroups" + i}
            aria-expanded="false"
            onClick={() => {
              this.handelGroupClick(x, i, "group")
          }}
          >
            <span className="cl_lft">
              <span className="left_Bx">
                <span className="grp_bx">
                  {" "}
                  {commonMethod.generateFirstLastNameIntials(x.displayName)}
                </span>

                <span className="grp_bx_txt" title={x.displayName}>
                  {x.displayName}
                  <span className="grry_txt">
                    {x.members.length !== 0
                      ? x.members.length + " " + "Contacts"
                      : ""}
                  </span>
                </span>
              </span>
            </span>
            <span className="cl_rit">
              <a href="javascript:;void(0);" className="setting_cion">
                <SettingIcon className=""></SettingIcon>
              </a>
              <img
                src="images/down_arrow.svg"
                className="arrw_dwn_img"
                alt="usr_icn"
              />
            </span>
          </button>
        </h2>
      </div>
      <div id={"collapeYakChatGroups" + i} className="collapse">
        <div className="card-body">
          <ul className="usr_list ">
            {/* Listing the contacts in the group here */}
            {x.members && x.members.length !== 0
              ? x.members.map((y: any, z: number) => {
                y.groupId = x.id;
                y.type = "Users Group";
                return (
                  <li
                    className={
                      this.props.contactSelected_OpenContact &&
                        this.props.contactSelected_OpenContact.id === y.id
                        ? "active"
                        : ""
                    }
                    key={"contactlist" + z}
                    style={{
                      cursor:
                        this.props.tabOpened !== "newmessage" &&
                          this.props.tabOpened !== "newgroup" &&
                          this.props.tabOpened !== "editgroup"
                          ? "pointer"
                          : "auto",
                    }}
                    onClick={() => {
                      if (
                        this.props.tabOpened === "newmessage" ||
                        this.props.tabOpened === "newgroup" ||
                        this.props.tabOpened === "editgroup"
                      ) {
                        return;
                      }
                      else {
                        this.props.setContactSelected_OpenContact(
                          y,
                          this.props.calledFrom
                        );
                        this.props.setTabOpened("contactopen");
                      }
                    }}
                  >
                    <div className="usr_imgbx">
                      <div className="usr_img2">
                        {commonMethod.generateFirstLastNameIntials(
                          y.displayName
                        )}
                      </div>
                    </div>
                    <div className="usr_txt" style={{ display: "flex" }}>
                      <div>
                        <h3 style={{ textAlign: "left" }}>{y.displayName}</h3>
                        <div className="txt_div">
                          <span className="sub_txt">{y.mobilePhone}</span>
                        </div>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          alignSelf: "center",
                          float: "right",
                          textAlign: "right",
                        }}
                      >
                        {this.props.tabOpened === "newmessage" ||
                          this.props.tabOpened === "newgroup" ||
                          this.props.tabOpened === "editgroup" ? (
                          this.props.selectedContacts_newMessage.find(
                            (m: any) => {
                              if (m.type === "Users Group")
                                return (
                                  y.id === m.id && y.groupId === m.groupId
                                );
                            }
                          ) ? (
                            <img
                              src={require("../../images/remove.svg")}
                              color="orange"
                              style={{ width: "25px" }}
                              onClick={(e: any) =>
                                this.removeFromSelected_newMessage(y, x, e)
                              }
                            />
                          ) : (
                            <img
                              src={
                                this.props.currentTheme === "dark"
                                  ? require("../../images/plus.svg")
                                  : require("../../images/plusBlack.svg")
                              }
                              color="black"
                              style={{ width: "22px" }}
                              onClick={(e: any) =>
                                this.addToSelected_newMessage(y, x, e)
                              }
                            />
                          )
                        ) : null}
                      </div>
                    </div>
                  </li>
                );
              })
              : null}
          </ul>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (props: any) => {
  return {
    selectedContacts_newMessage:
      props.reduxReducer.selectedContactsNewMessage || [],
    tabOpened: props.reduxReducer.tabOpened,
    contactSelected_OpenContact: props.reduxReducer.contactSelected_OpenContact,
    currentTheme: props.reduxReducer.currentTheme,
    selectedContactsWithCtrl: props.reduxReducer.selectedContactsWithCtrl,
    newCount: props.reduxReducer.newCount,
    yakchatLists: props.reduxReducer.yakchatLists,
    opencollapse: props.reduxReducer.opencollapse,
    azureGroups:props.reduxReducer.azureGroups,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    setSelectedContacts: (contact: any) => {
      dispatch<any>(actions.setSelectedContacts(contact));
    },
    setTabOpened: (tab: string) => {
      dispatch<any>(actions.setTabOpened(tab));
    },
    setContactSelected_OpenContact: (contact: any, calledFrom: any) => {
      dispatch<any>(
        actions.setContactSelected_OpenContact({ ...contact, type: calledFrom })
      );
    },
    setGroupCurrentlyEditing: async (group: any, lists: any, calledFrom: any, contacts: any
    ) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
      dispatch<any>(actions.getContactsByListId_V2(group, lists, "", []));
        }
        else{
          dispatch<any>(actions.getContactsByListId(group, lists, "", []));
        }
    },
    setAzureGroupCurrentlyEditing: (group: any, lists: any, calledFrom: any, contacts: any
      ) => {
        dispatch<any>(actions.getAzureContactsByListId(group, lists, "", []));
      },
    selectContactWithCtrl: (contact: any) => {
      dispatch<any>(actions.selectContactWithCtrl(contact));
    },
    messageSelectedContactsCount: (count: any) => {
      dispatch<any>(actions.messageSelectedContactsCount(count));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(contactsComponent);
