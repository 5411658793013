import * as React from "react";

import ContactComponent from "../../sharedComponent/contactsComponent";

export default class YakChatListComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      Calledfrom:  this.props.calledFrom !== undefined ? this.props.calledFrom : "",
      status: this.props.Status,
      YakchatContactsList: this.props.YakchatContacts !== undefined ? this.props.YakchatContacts     : [],
      yakChatList:[]
    };
  }
  componentDidMount() {}
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.YakchatContacts !== prevState.YakchatContacts ||
      nextProps.calledFrom !== prevState.Calledfrom ||
      nextProps.Status !== prevState.status ||
      nextProps.yakchatLists !== prevState.yakChatList
    ) {
      return {
        YakchatContactsList: nextProps.YakchatContacts,
        status: nextProps.Status,
        Calledfrom: nextProps.calledFrom,
        yakChatList:nextProps.yakchatLists
      };
    } else {
      return null;
    }
  }

  public render() {
    return (
      <React.Fragment>
        {this.state.YakchatContactsList !== undefined && this.state.yakChatList !== undefined ? (
          <ContactComponent
            calledFrom={this.state.Calledfrom}
            Status={this.state.status}
            ContactList={this.state.YakchatContactsList}
            searchFilter={this.props.searchFilter}
            yakChatGroupData={this.props.yakChatGroupData}
            yakchatLists={this.state.yakChatList}
          />
        ) : null}
      </React.Fragment>
    );
  }
}
