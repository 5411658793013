import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { store } from "../../../..";
import * as actions from "../../../redux/actions/actions";
import YakChatListComponent from "../YakChatContacts/YakChatListComponent";
class YakChatParentComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      calledfrom: "",
      YakchatContactList: [],
      yakchatselected: this.props.yakchatSelected,
      pageindex: this.props.PageIndex !== undefined ? this.props.PageIndex : 1,
      yakchatContacListId:[]
    };
  }
  componentDidMount() {
  
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.YakChatContactList !== prevState.YakchatContactList ||
      nextProps.yakchatSelected !== prevState.yakchatselected ||
      nextProps.PageIndex !== prevState.pageindex ||
      nextProps.yakchatLists !== prevState.yakchatContacListId
    ) {
      return {
        YakchatContactList: nextProps.YakChatContactList,
        yakchatselected: nextProps.yakchatSelected,
        pageindex: nextProps.PageIndex,
        yakchatContacListId:nextProps.yakchatLists 
      };
    } else {
      return null;
    }
  }

  public render() {
    return (
      <React.Fragment>
        {this.state.YakchatContactList !== undefined  && this.state.yakchatContacListId !== undefined ? (
          <YakChatListComponent
            YakchatContacts={this.state.YakchatContactList}
            calledFrom={"YakChat"}
            Status={this.state.yakchatselected}
            searchFilter={this.props.searchFilter}
            yakChatGroupData={this.props.yakChatGroupData}
            yakchatLists={this.state.yakchatContacListId}
          />
        ) : null}
      </React.Fragment>
    );
  }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
    return {
    YakChatContactList: props.reduxReducer.YakChatContactList,
    yakChatGroupData: props.reduxReducer.yakChatGroupData,
    yakchatLists: props.reduxReducer.yakchatLists,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    getcontactlist: async (PageIndex: any) => {
      debugger;
      let companyVersion=store.getState().reduxReducer.companyVersion;
        if(companyVersion===2)
          {
      dispatch<any>(actions.getyakchatContacts_V2(PageIndex));
          }
          else
          {
            dispatch<any>(actions.getyakchatContacts(PageIndex));
          }
    },
    getYakChatGroupData: () => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
      {
      dispatch<any>(actions.getYakChatGroupData_V2(1));
      }
      else{
        dispatch<any>(actions.getYakChatGroupData(1));
      }
    },
    getYakChatLists: () => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
      {
      dispatch<any>(actions.getYakChatLists_V2());
      }
      else{
        dispatch<any>(actions.getYakChatLists());
      }
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YakChatParentComponent);
