import React, { useState } from "react";
import AuthHelper from "../../../Config/msalConfig";
import logo from "../../../TeamsApp/images/CT Text Logo-2.png";
import sponser from "../../../TeamsApp/images/Powered_by_YakChat_White-01.png"
import { ReactComponent as LockIcon } from "../../images/greenLock.svg";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import * as actions from "../../redux/actions/actions";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

const antIcon = <Loading3QuartersOutlined style={{ fontSize: 36, color: "#fff", backgroundColor: "none", display: "flex", marginRight: "10%" }} spin />;
// export  function CallTowerLogin(props: any) {

class CallTowerLogin extends React.Component<any, any> {
  state = {
    SpinnerValueState: false
  };
  handleLogin = () => {
    AuthHelper.signIn();
    // AuthHelper.initSignin();
    this.setState({
      SpinnerValueState: true
    })
    // setSpinnerValueState(true)
  }
  openCalltowerRegister = () => {
    setTimeout(() => {
      window.location.reload()
    }, 200);
  }
  // const [spinnerValueState,setSpinnerValueState]=useState(false);
  render() {
    return (
      <section className="userlog_sec error_sec calltowerLogin">
        <div className="container">
          <span className="login_box inner_loginBox" style={{ textAlign: "center" }}>
            <span className="text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {this.props.loginStatus === "loggingIn" ? (
                <img className="app_logo call-tower-logo"
                  src={logo}
                  alt="logo"
                  style={{
                    width: "300px",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "auto",
                    marginTop: "-10px"
                  }}
                />
              ) : (
                <img src={logo} alt="logo" className="app_logo call-tower-logo" style={{ height: "auto", width: "300px", }} />
              )}
            </span>
            {this.props.loginStatus === "loggingIn" ? (
              <>
                <Spin spinning={true} indicator={antIcon}
                  className="login_spinner " style={{ zIndex: 9999, }} />
                <div className=" calltower_txt" >
                  <img src={sponser} alt="#" style={{
                    height: "auto"
                  }} />
                  {/* Microsoft Secure Authentication */}
                </div>
                <div className="authen_txt" style={{ backgroundColor: "#ffff" }}>
                  <LockIcon fill="#6bbc15" className="calltower-lock-svg" /> Microsoft Secure Authentication
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <button
                  id="loginButtonMainInTeams"
                  onClick={this.handleLogin}
                  className="btn cstm_btn gry_btn"
                  style={{
                    marginTop: "2%",
                    // marginLeft: "1%",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                    color: "#000"
                  }}
                >
                  Sign in
                </button>
                <Link to="#/registercalltower" style={{ marginTop: "2%" }}>
                  <button
                    id="loginButtonMainInTeams"
                    className="btn cstm_btn gry_btn calltowerRegister"
                    onClick={this.openCalltowerRegister}
                  >
                    Register
                  </button>
                </Link>
                <div className=" calltower_txt signin_calltower_txt" >
                  <img src={sponser} alt="#" style={{
                    height: "auto"
                  }} />

                </div>
                <span className="signin_spinner">
                  <Spin spinning={this.state.SpinnerValueState} indicator={antIcon}
                    className="login_spinner " style={{ zIndex: 9999, }} />
                </span>
              </div>
            )}
          </span>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (props: any) => {
  return {
    whiteLabelBrand: props.reduxReducer.whiteLabelBrand,
    registerTheme: props.reduxReducer.registerTheme
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    setRegisterTheme: (registerTheme: any) => {
      dispatch<any>(actions.setRegisterTheme(registerTheme));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallTowerLogin);
// export default connect(mapStateToProps,mapDispatchToProps)(CallTowerLogin);
