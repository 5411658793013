import React from "react";
import "../TeamsApp/globalTheme.css";
import configData from "../appsettings.json"
export default class WelcomeCommsChoice extends React.Component<any, any>  {
 
    copyLink =()=> 
            {     
              navigator.clipboard.writeText("https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=id_token%20token&nonce=value&scope=directory.read.all%20directory.accessasuser.all%20openid%20profile%20offline_access%20user.read%20user.readbasic.all%20user.read.all%20contacts.read%20sites.read.all%20https%3A%2F%2Fchatabox365.onmicrosoft.com%2FChataAPI%2Fuser_impersonation&client_id="+configData.client_id+"&prompt=consent&state=null&response_mode=fragment&redirect_uri="+configData.BaseUrlTeams+"/thankyou.html");   }
 
              render() {
    return (
      <section className="consent_container">
           <div className="consent_popup ">
      
          <div className="addcontact_box" 
           style={{backgroundColor:"#000045"}}
           >
            <div className="sure_detail" style={{backgroundColor:"#fff"}}>
              <div className="addcont_txt"  >
                <h3>
               Welcome to Yakchat
                </h3>
                <p className="save_press">
                <span style={{color:"#999999"}}>Yakchat requires administrator consent to connect with your Microsoft 365 system.Click Continue if you are an administrator
                 or send the permissions link to your system administrator for approval</span>
                </p>
              </div>
              <div className="addgr_btnbox ">
                <button
                  className="btn cstm_btn blk_btn comms_color_black" onClick={this.copyLink} style={{backgroundColor:"#000", color:"#fff"}}
                >
                  {" "}
                  Copy Link{" "}  
                </button>
                <button
                  className="btn  cstm_btn comms_color"
                  style={{backgroundColor:"#000045", color:"#fff"}}
                  onClick={()=>{
                    window.open("https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=id_token%20token&nonce=value&scope=directory.read.all%20directory.accessasuser.all%20openid%20profile%20offline_access%20user.read%20user.readbasic.all%20user.read.all%20contacts.read%20sites.read.all%20https%3A%2F%2Fchatabox365.onmicrosoft.com%2FChataAPI%2Fuser_impersonation&client_id="+configData.client_id+"&prompt=consent&state=null&response_mode=fragment&redirect_uri="+configData.BaseUrlTeams+"/thankyou.html?brand=commsChoice");}
                }
                >
              Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}