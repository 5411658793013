import React from "react";
import "../TeamsApp/globalTheme.css";
import AuthHelper from "../Config/msalConfig";
import { setTimeout } from "timers";
import CallTowerLogin from "../TeamsApp/Login/callTower/callTowerLogin";
import configData from "../appsettings.json"
export default class CallTowerConsent extends React.Component<any, any>  {
  state={
    showLink:false,
    url:"",
    showLoginCalltower:false,
  }
  componentDidMount() {
    // window.location.origin
  }
  checkLoginHandel =()=>{
    window.open("https://login.microsoftonline.com/common/adminConsent?client_id="+configData.client_id+"&redirect_uri="+configData.BaseUrlTeams+"/thankyou.html?brand=callTower");
      localStorage.clear();
      sessionStorage.clear();
      this.setState({showLoginCalltower:true});
      setTimeout(() => {
        window.location.assign(configData.publishurl+"/#/callTowerConsent")
      }, 1000); 
  }
  handelRefresh =()=>{
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => {
      AuthHelper.initSignin();
    }, 50);
   
  }
  render() {
    return (
      <>
         { this.state.showLoginCalltower ?   <CallTowerLogin loginStatus={this.props.loggedInUser}></CallTowerLogin> :
      <section className="consent_container">
           <div className="consent_popup ">
          <div className="addcontact_box"   style={{backgroundColor:"#6bbc15" }}>
            <div className="sure_detail" style={{backgroundColor:"#fff"}}>
              <div className="addcont_txt">
                <h3>
                Connect to Microsoft Teams
                </h3>
                <p className="save_press">
                <span style={{color:"#999999"}}>Yakchat requires administrator consent to connect with your Microsoft 365 system.</span>
                </p>
              </div>
              <div className="addgr_btnbox " >
                <button
                onClick={this.handelRefresh}
                  className="btn cstm_btn blk_btn comms_color_black" style={{backgroundColor:"#000", color:"#fff"}}
                >
                  {" "}
                  Refresh{" "}
                </button>
                <button
                  className="btn  cstm_btn call_color"
                  style={{backgroundColor:"#6bbc15", color:"#fff"}}
                  onClick={this.checkLoginHandel}
                >
                Continue
                </button>
              {this.state.showLink ? <a target="_blank" href={this.state.url}></a> :""}
              </div>
            </div>
          </div>
        </div>
      </section>}
      </>
    );
  }
}