
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";
import React from "react";
import { connect, } from "react-redux";
import "../../TeamsApp/globalTheme.css";
import "../../TeamsApp/antd-minified.css";
import Login from "./login";
import AuthService from "../../Config/msalConfig";
import HeaderComponent from "../components/layout/headerComponent/headerComponent";
import SystemUnavailable from "../loginError/SystemUnavailable";
import Authentication from "../loginError/authentication";



class MainApp extends React.Component<any, any> {
  state = {
    openPage: ""
  };
  componentDidMount() {
    AuthService.initSignin()
  }
  render() {
    return this.props.openPage ? (
      this.props.checkUser ? <Authentication /> :
        <Login />
    ) : this.props.trailExpier ? (<SystemUnavailable />) : this.props.sharedError ? (<SystemUnavailable />) :
      this.props.loggedInUser &&
        typeof this.props.loggedInUser !== "string" ? (<HeaderComponent></HeaderComponent>) : (
        <Router>
          <div>
            <Link to="/#">
              <Login loginStatus={this.props.loggedInUser}></Login>
            </Link>
          </div>
        </Router>
      );
  }
}

const mapStateToProps = (props: any) => {
  return {
    loggedInUser: props.reduxReducer.loggedInUser,
    openPage: props.reduxReducer.openPage,
    trailExpier: props.reduxReducer.trailExpier,
    sharedError: props.reduxReducer.sharedError,
    checkUser: props.reduxReducer.checkUser,
  };

};
export default connect(mapStateToProps, null)(MainApp);
