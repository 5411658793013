import React, { Component,useState }  from "react";
import ReactCodeInput from 'react-verification-code-input';
import { connect, } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
// import Logo from "../../images/login_logo.png";
import sponser2 from "../../../TeamsApp/images/commsgroup-alt-logo 2.png"
import logo from "../../../TeamsApp/images/CT Text Logo-2.png";
import { Link } from "react-router-dom";
import * as actions from "../../redux/actions/actions";
import * as msTeams from '@microsoft/teams-js';
import jwt_decode from "jwt-decode";
import Logo from "../logo";
import { Console } from "console";
import { store } from "../../..";
import * as action from '../../redux/actions';
import {GoVerified } from 'react-icons/go'
// import  "./style.css";
import configData from "../../../appsettings.json";
import { colors } from "@material-ui/core";
import { Dispatch } from "redux";
class verifyUsers extends React.Component<any, any> {
  state = {
    PhoneNumber: "",
    fullname: "",
    email: "",
    accessTokenApi: "",
    registerTheme: "",
    disableButton: false,
    showing: false,
    code:"",
    check:false,
    CodeMaxAttemp: false,
    InvalidCode:false,
    CodeSent:false,
  };
  handelUser = () => {
    
         
  
  }
  
 checkverification=async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
  e.preventDefault();
  this.setState({CodeSent:false});
  debugger;
  const API_KEY = process.env.TKey;
  const API_Sec = process.env.TSec;
  const API_Verify = process.env.TVerify;
    var headers = new Headers();
    const data = new URLSearchParams();
    if(this.props.userData)
    {
    var PhoneNumber = this.props.userData.PhoneNumber.includes('+')? this.props.userData.PhoneNumber:"+"+ this.props.userData.PhoneNumber;
    data.append("To", PhoneNumber);
    data.append("Code",this.state.code);
    PhoneNumber=PhoneNumber?.replace("+", "%2B");
    fetch(configData.APiUrl+`/v2/Authorize/VerifyCode?to=${PhoneNumber}&code=${this.state.code}`, {
      method: 'GET',
      headers: {
          'Content-type': 'application/json'
      },
  }).then((response: any) => response.json()).then(data => {
    
        if(data.status=="approved"&& data.valid==true)
        {
          this.setState({check:true});
          this.setState({showing:true});
        }
        else if(data.status=="Error"&&data.message=="Max check attempts reached")
        {
          this.setState({CodeMaxAttemp:true});
          // this.props.history.push('/');
          // return;
          
        }
        else{
          this.setState({check:false});
          this.setState({showing:false});
          this.setState({InvalidCode:true});
        }
      });
    }
   return false;
  
}
  verifyUser=(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>)=>{
    debugger;
    this.setState({CodeSent:false});
    const API_KEY = process.env.TKey;
  const API_Sec = process.env.TSec;
  const API_Verify = process.env.TVerify;
    e.preventDefault();
    var headers = new Headers();
    const data = new URLSearchParams();
    if(this.props.userData)
    {
    var PhoneNumber = this.props.userData.PhoneNumber.includes('+')? this.props.userData.PhoneNumber:"+"+ this.props.userData.PhoneNumber;
    PhoneNumber=PhoneNumber?.replace("+", "%2B");
    fetch(configData.APiUrl+`/v2/Authorize/GetVerifyCode?to=${PhoneNumber}&channel=${"sms"}`, {
      method: 'GET',
      headers: {
          'Content-type': 'application/json'
      },
  }).then((response: any) => response.json()).then(data => {
    
        if(data.status=="pending")
        {
          this.setState({CodeSent:true});
          // this.setState({showing:true});
        }
      });
    }
      return false;
  }
    

  componentDidMount() {
    let fullName;
    let email;
    let PhoneNumber;
    let msEmail: any;
    if (this.props.userData !== undefined) {
      fullName = this.props.userData.fullname;
      email = this.props.userData.email;
      PhoneNumber = this.props.userData?this.props.userData.PhoneNumber.includes('+')? this.props.userData.PhoneNumber:"+"+ this.props.userData.PhoneNumber:"";
    }
    this.setState({ PhoneNumber: PhoneNumber, fullname: fullName, email: email })
    
    msTeams.initialize(() => {
      var isInTeams = true;
      msTeams.getContext(async (c) => { 
        msTeams.authentication.getAuthToken({
          successCallback: async function (result) {
            const tokenDecoded: any = jwt_decode(result);
            msEmail = tokenDecoded.upn;
          },
          failureCallback: function (error) {
            console.log("Failure: " + error);
          }
        })
        setTimeout(() => {
          this.setState({ email: msEmail })
        }, 100);

      })
    })
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.accessTokenApi !== prevState.accessTokenApi ||
      nextProps.registerTheme !== prevState.registerTheme)
      return {
        accessTokenApi: nextProps.accessTokenApi,
        registerTheme: nextProps.registerTheme,
      }
    else return null;
  }
  render() {
    //debugger;
    return (
      <div className={this.state.registerTheme === "calltower" ? "userlog_sec calltowerSection_register" : this.props.registerTheme === "commsChoice" ? "userlog_sec commsSection_register" : this.props.registerTheme === "onenet" ? "userlog_sec OneNet_register" 
      : this.props.registerTheme === "mobex" ? "userlog_sec OneNet_register Mobex_register" 
      : this.props.registerTheme === "berkshire" ? "userlog_sec OneNet_register Berkshire_register" 
      :  this.props.registerTheme === "setup4" ? "userlog_sec setupRegister" : 
      this.props.registerTheme === "oneuc" ? "userlog_sec Oneuc_register"  :
       "userlog_sec register_section"}>
        <div className="container">
          <div className="login_box">
            <Logo/>
            <p className="comp_reg text-center">Please complete your details to <br />register for YakChat</p>
            <div className="step_box text-center">
            <a href="#/register" className="step_btn ">Step 1</a>
              <a href="javascript:;void(0);" className="step_btn step_active">Step 2</a>
              <a href="javascript:;void(0);" className="step_btn">Step 3</a>
              <a href="javascript:;void(0);" className="step_btn ">Step 4</a>
              <a href="javascript:;void(0);" className="step_btn ">Step 5</a>
            </div>
            <div className="user_detail" style={{ backgroundColor: "#fff" }}>
              <div className="register_scroll">
                <h3 className="user_head">Enter the Verification Code</h3>
                <form>
                <div className="mb-3" style={{  justifyContent:"center",  alignItems: "center" , display: (this.state.showing ?'flex':  'none') }}  >
                <GoVerified color="green" fontSize={45} vertical-align={'center'}></GoVerified>
                </div>
                  <div className="mb-3"  style={{ display: (this.state.showing ?  'none':'block') }}>
                  <ReactCodeInput   fields={6} type='number'  onChange={(e) => this.setState({ code: e })}/>
                  {this.state.CodeMaxAttemp ? (
                      <span className="error-div" style={{color:"red"}}>
                        {/* <ErrorIcon className="error-icon" style={{color:"red"}} />{" "} */}
                        <span className="error-span">
                        You have made too many verification attempts please contact info@yakchat.com
                        </span>
                      </span>
                    ) : ("")}
                     {this.state.InvalidCode ? (
                      <span className="error-div" style={{color:"red"}}>
                        {/* <ErrorIcon className="error-icon" style={{color:"red"}} />{" "} */}
                        <span className="error-span">
                        This validation code is invalid. 
                        </span>
                      </span>
                    ) : ("")}
                  </div>
                
                  <div className="mb-3" style={{ display: (this.state.showing ?  'none':'block') }}>
                    <label className="form-label">didn't receive the code ? <a href='#' style={{color:"black",textDecorationLine:'underline'}} onClick={e=>this.verifyUser(e)}> Try Again.</a></label>
                    {this.state.CodeSent ? (
                      <span className="error-div" style={{color:"red"}}>
                        {/* <ErrorIcon className="error-icon" style={{color:"red"}} />{" "} */}
                        <span className="error-span">
                        The validation code has been re-sent to your mobile number.
                        </span>
                      </span>
                    ) : ("")}
                  </div>
                  <div className="mb-3" >
  
  <div  className="mb-3"  style={{ display: (this.state.showing ?  'none':'block') }}> 
  <button onClick={e=>this.checkverification(e)} type="button" className="form-control" style={{backgroundColor:"#fcbf00",color:"white"}} >Verify</button>
  </div>
</div>
                  {/* <div className="mb-3" style={{ display: (this.state.showing ? 'block' : 'none') }}>
                    <label className="form-label">VerificationCode</label>
                    <input type="text" className="form-control" value={this.state.code}  onChange={(e) => this.setState({ code: e.target.value })}
                      placeholder="Enter the code sent to your phone number" />
                       <button onClick={this.checkverification} className="form-control" >Submit</button>
                  </div> */}
                </form>
              </div>
              <div className="next_backbtn">
                <Link to="/register">
                  <button className="nxtBtn back_btn">Back</button>
                </Link>
              {this.state.check?this.state.showing?
                <Link to="/registerUser">
                  <button onClick={this.handelUser}
                    className={"nxtBtn"} >Next</button>
                </Link> : <button className={"disable_btn_nxt registerNext"} >Next</button>: <button className={"disable_btn_nxt registerNext"} >Next</button>
                }
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (props: any) => {
  return {
    userData: props.reduxReducer.userDetails_registeration,
    user: props.reduxReducer.user,
    accessTokenApi: props.reduxReducer.accessTokenApi,
    registerTheme: props.reduxReducer.registerTheme,
  };
};
export default connect(mapStateToProps)(verifyUsers);

