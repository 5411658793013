import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as actions from "../../redux/actions/actions";
import { ReactComponent as PlusOutlineSvg } from "../../images/plus-icon-outline.svg";
import { ReactComponent as MinusOutlineSvg } from "../../images/minus-icon-ouline-orange.svg";
import profileImage from "../../images/profile.png";
import _ from "lodash";
import { store } from "../../..";
class DisplayNewContactAddOption extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      Calledfrom:
        this.props.calledFrom !== undefined ? this.props.calledFrom : "",
      status: this.props.Status,
      userslist: this.props.UsersList !== undefined ? this.props.UsersList : [],
      contactlist: [],
      ContactFoldersWithconatactlist:
        this.props.ContactFoldersWithConatactList !== undefined
          ? this.props.ContactFoldersWithConatactList
          : [],
      contactsListToShow: [],
      page: 1,
      messageSelectedContacts: 0,
      activeCollapseKey: "",
    };
  }
  callback = (key: any) => {};
  addToSelected_newMessage = (x: any, y: any = undefined, e: any) => {
    let newContact;
    if (this.props.newCount !== this.state.messageSelectedContacts) {
      this.setState({ messageSelectedContacts: this.props.newCount });
    }
    this.setState({
      messageSelectedContacts: this.state.messageSelectedContacts + 1,
    });
    this.props.messageSelectedContactsCount(this.props.newCount + 1);

    if (y) {
      if (this.props.calledFrom === "YakChat")
        newContact = {
          ...x,
          groupId: y.Id,
          type: "YakChat Group",
        };
      // The contact is of SharePoint and is situated inside a folder
      else if (this.props.calledFrom === "Share Point")
        newContact = {
          ConatctFolderId: y.ConatctFolderId,
          idInFolder: x.id,
          type: "Share Point",
          displayName: x.displayName,
          mobilePhone: x.mobilePhone,
        };
      else if (this.props.calledFrom === "Users")
        newContact = {
          ...x,
          type: "Users Group",
        };
      else {
        newContact = { ...x, type: x.type ? x.type : this.props.calledFrom };
      }
    } else {
      newContact = { ...x, type: x.type ? x.type : this.props.calledFrom };
    }
    
    const newSelectedContacts = [
      ...this.props.selectedContacts_newMessage,
      newContact,
    ];
    this.props.setSelectedContacts(newSelectedContacts);
    e.stopPropagation();
  };
  removeFromSelected_newMessage = (x: any, y: any = undefined, e: any) => {
    ;
    this.props.messageSelectedContactsCount(this.props.newCount - 1);
    const newSelectedContacts = _.cloneDeep(
      this.props.selectedContactsNewMessage
    );
    let index = -1;
    if (y) {
      // The contact is of SharePoint and is situated inside a folder
      index = newSelectedContacts.findIndex(
        (z: any) => z.Id === x.Id && z.ContactId === x.ContactId
      );
      index = newSelectedContacts.findIndex(
        (z: any) => z.groupId === x.groupId && z.id === x.id
      );
    } else {
      index = newSelectedContacts.findIndex((z: any) => {
        if (z.type === "YakChat") return z.ContactId === x.ContactId;
        else if (z.type === "Users") return z.id === x.id;
        else if (z.type === "Outlook") return z.id === x.id;
        else if (z.type === "NewContact")
          return z.mobilePhone === x.mobilePhone;
      });
    }
    newSelectedContacts.splice(index, 1);
    this.props.setSelectedContacts(newSelectedContacts);
    e.stopPropagation();
  };
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.ContactFoldersWithConatactList !==
        prevState.ContactFoldersWithconatactlist ||
      nextProps.Status !== prevState.status ||
      nextProps.ContactList !== prevState.contactlist ||
      nextProps.UsersList !== prevState.userslist ||
      nextProps.yakchatLists !== prevState.yakChatListId ||
      nextProps.newCount !== prevState.newCount
    ) {
      return {
        ContactFoldersWithconatactlist:
        nextProps.ContactFoldersWithConatactList,
        status: nextProps.Status,
        contactlist: nextProps.ContactList,
        userslist: nextProps.UsersList,
        yakChatListId: nextProps.yakchatLists,
        newCount: nextProps.newCount,
      };
    } else {
      return null;
    }
  }
  public render() {
    return (
      <React.Fragment>
        <div className="card-body">
          <ul className="usr_list ">
            <li
              className="number_list"
              key={"contactlist1"}
              style={{
                cursor:
                  this.props.tabOpened !== "newmessage" ? "pointer" : "auto",
              }}
            >
              <div className="usr_imgbx">
                <div className="usr_img2">
                  <img src={profileImage} />
                </div>
              </div>
              <div
                className="usr_txt"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <div className="txt_div">
                    <span className="sub_txt">{this.props?.searchFilter}</span>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    alignSelf: "center",
                    float: "right",
                    textAlign: "right",
                  }}
                >
                  {this.props.tabOpened === "newmessage"  ? (
                    this.props.selectedContacts_newMessage?.find((m: any) => {
                      if (m.type === "New Contact")
                        return this.props.searchFilter === m.mobilePhone;
                    }) ? (
                      <MinusOutlineSvg
                        className="ctThem"
                        onClick={(e: any) =>
                          this.removeFromSelected_newMessage(
                            {
                              mobilePhone: this.props.searchFilter,
                              type: "New Contact",
                            },
                            "",
                            e
                          )
                        }
                      ></MinusOutlineSvg>
                    ) : (
                      <PlusOutlineSvg
                        fill={
                          this.props.currentTheme === "dark"
                            ? "var(--text)"
                            : "black"
                        }
                        onClick={(e: any) =>
                          this.addToSelected_newMessage(
                            {
                              mobilePhone: this.props.searchFilter,
                              type: "New Contact",
                            },
                            "",
                            e
                          )
                        }
                      ></PlusOutlineSvg>
                    )
                  ) : null}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (props: any) => {
  return {
    selectedContactsNewMessage:
      props.reduxReducer.selectedContactsNewMessage || [],
    tabOpened: props.reduxReducer.tabOpened,
    contactSelected_OpenContact: props.reduxReducer.contactSelected_OpenContact,
    currentTheme: props.reduxReducer.currentTheme,
    selectedContactsWithCtrl: props.reduxReducer.selectedContactsWithCtrl,
    newCount: props.reduxReducer.newCount,
    yakchatLists: props.reduxReducer.yakchatLists,
    opencollapse: props.reduxReducer.opencollapse,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    setSelectedContacts: (contact: any) => {
      dispatch<any>(actions.setSelectedContacts(contact));
    },
    setTabOpened: (tab: string) => {
      dispatch<any>(actions.setTabOpened(tab));
    },
    setContactSelected_OpenContact: (contact: any, calledFrom: any) => {
      dispatch<any>(
        actions.setContactSelected_OpenContact({ ...contact, type: calledFrom })
      );
    },
    setGroupCurrentlyEditing: async (
      group: any,
      lists: any,
      calledFrom: any,
      contacts: any
    ) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
      dispatch<any>(actions.getContactsByListId_V2(group, lists, "", []));
        }
        else{
          dispatch<any>(actions.getContactsByListId(group, lists, "", []));
        }
    },
    selectContactWithCtrl: (contact: any) => {
      dispatch<any>(actions.selectContactWithCtrl(contact));
    },
    messageSelectedContactsCount: (count: any) => {
      dispatch<any>(actions.messageSelectedContactsCount(count));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayNewContactAddOption);
