import * as constants from "./constants";
import * as Msal from "msal";
import { store } from "../index";
import { setLoggedInUser,setLoggedInUser_V2 ,getCompanyVersion_V2, companyVersion, accessTokenExpireOn , accessTokenRegister} from "../TeamsApp/redux/actions/actions";//setActivityConfigurations
import { openLoginConsent } from "../TeamsApp/redux/actions/actions";
import * as msTeams from "@microsoft/teams-js";
import SignalRHelper from "./signalRConfig"
import jwt_decode from "jwt-decode";
import configData from "../appsettings.json";
const Querystring = require('querystring')
const loginScopes = ["openid", "profile", "offline_access"];
const YakChatAPITokenScopes = ["https://chatabox365.onmicrosoft.com/ChataAPI/user_impersonation"]
const YakChatAPIClient: Msal.Configuration = {
    auth: {
        authority: 'https://login.microsoftonline.com/common',
        clientId: configData.client_id,
        redirectUri: window.location.origin,
        validateAuthority: false,
        knownAuthorities: ['https://login.microsoftonline.com/common'],
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
};
// let webexCode:any;
// let codewebex=window.location.search;

// if(codewebex!=""&&codewebex.includes('code'))
// {
//     debugger;
//     codewebex = codewebex.replace(/\?/g, '');
//         const parsedcode= Querystring.parse(codewebex);
     
//         codewebex=parsedcode['code']
//         webexCode=codewebex;
//         localStorage.setItem(
//             "webexC",
//             codewebex
//           );
//           [
//             'code'
//           ].forEach((key) => Reflect.deleteProperty(parsedcode, key));
//           window.location.search = Querystring.stringify(parsedcode);
//           let userdomian=localStorage.getItem("user");
//           if(userdomian?.includes("calltower"))
//           {
//           window.location.replace("https://"+window.location.host+"/#/cttext-webex")
//           }
//           else
//           { window.location.replace("https://"+window.location.host+"/#/webex")}
          
// }
const msalInstance = new Msal.UserAgentApplication(YakChatAPIClient);
msalInstance.handleRedirectCallback((error, response) => {
    window.close();
    console.log(error)
    console.log(response)
});
export default class AuthService {
    static YakChatAPIClient: Msal.UserAgentApplication = new Msal.UserAgentApplication({
        auth: {
            authority: 'https://login.microsoftonline.com/common',
            clientId: configData.client_id,
            validateAuthority: false,
            knownAuthorities: ['https://login.microsoftonline.com/common'],
            navigateToLoginRequestUrl: true
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    });
    static GraphAPIClient: Msal.UserAgentApplication = new Msal.UserAgentApplication({
        auth: {
            authority: 'https://login.microsoftonline.com/common',
            clientId: configData.client_id,
            navigateToLoginRequestUrl: true,
            validateAuthority: false,
            knownAuthorities: ['https://login.microsoftonline.com/common'],
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true
        }
    });
    static isInTeams: boolean;
    static async saveToken(loginHint: string = "") {
        
        let response: any = null;
        let response2;
        try {
            while (!(response && response2)) {
                try {
                    
                debugger;
                    response = response || await AuthService.GraphAPIClient.acquireTokenSilent({ scopes: ["openid", "profile", "offline_access"], loginHint });
                    console.log("1", response);
                    response2 = response2 || await AuthService.YakChatAPIClient.acquireTokenSilent({ scopes: YakChatAPITokenScopes, loginHint, prompt: "consent" });
                    console.log("2", response2)
                    response.accessTokenApi = response2.accessToken;
                    let version:any=await getCompanyVersion_V2(response.accessTokenApi );
                    if(version.Data===2)
                    {
                        store.dispatch<any>(setLoggedInUser_V2(response)); 
                       store.dispatch<any>(companyVersion(version.Data ));
                       //store.dispatch<any>(webexC(localStorage.getItem("webexC")));
                       store.dispatch<any>(accessTokenExpireOn(response.expiresOn));
                       store.dispatch<any>({type:"Retry_Send",retrysend:0});
                    }
                    else
                    {
                    store.dispatch<any>(setLoggedInUser(response));
                    store.dispatch<any>(companyVersion(version.Data ));
                    //store.dispatch<any>(webexC(localStorage.getItem("webexC")));
                    store.dispatch<any>(accessTokenExpireOn(response.expiresOn));
                    store.dispatch<any>({type:"Retry_Send",retrysend:0});
                    }
                    
                    SignalRHelper.createConnection(response.accessTokenApi);
                    
                    return
                } catch (err) {
                    let error:any = err
                    if(error.errorMessage.indexOf("Token renewal operation failed due to timeout.") !== -1){
                        localStorage.clear();
                        document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                        // alert("clear Storage");
                        window.location.reload();
                    }
                    if (error.errorMessage.indexOf("interactive authorization request") !== -1) {
                        await AuthService.GraphAPIClient.acquireTokenPopup({
                            scopes: ["directory.read.all", "directory.accessasuser.all", "openid", "profile", "offline_access", "user.read", "user.readbasic.all", "user.read.all", "contacts.read", "sites.read.all", "https://chatabox365.onmicrosoft.com/ChataAPI/user_impersonation"]
                            , loginHint, prompt: "consent"
                        })
                            .then(async (accessTokenResponse: any) => {
                                response = accessTokenResponse;
                                response2 = await AuthService.YakChatAPIClient.acquireTokenSilent({ scopes: YakChatAPITokenScopes, loginHint });
                                response.accessTokenApi = response2.accessToken;
                                let version:any=await getCompanyVersion_V2(response.accessTokenApi);
                                if(version.Data===2)
                                {
                                store.dispatch<any>(setLoggedInUser_V2(response));
                                store.dispatch<any>(companyVersion(version.Data ));
                                //store.dispatch<any>(webexC(localStorage.getItem("webexC")));
                                store.dispatch<any>(accessTokenExpireOn(response.expiresOn));
                                store.dispatch<any>({type:"Retry_Send",retrysend:0});
                                }
                                else
                                {
                                    store.dispatch<any>(setLoggedInUser(response));
                                    store.dispatch<any>(companyVersion(version.Data ));
                                    //store.dispatch<any>(webexC(localStorage.getItem("webexC")));
                                    store.dispatch<any>(accessTokenExpireOn(response.expiresOn));
                                    store.dispatch<any>({type:"Retry_Send",retrysend:0});
                            }
                                SignalRHelper.createConnection(response.accessTokenApi)
                                return
                            })
                            .catch((error: any) => {
                                console.log(error)
                                return
                            })
                    } else {
                       ///// test
                      try{
                       let email: any = localStorage.getItem("email");
                       if(email!==undefined)
                       {
                      
                      // loginHint="admin@yakchat.com";
                      const account = msalInstance.getAccount();
        
                      debugger;
                      loginHint=email;
                       response = await AuthService.YakChatAPIClient.acquireTokenSilent({ scopes: YakChatAPITokenScopes, loginHint });
                       response.accessTokenApi = response.accessToken;
                       let version:any=await getCompanyVersion_V2(response.accessTokenApi);
                       if(version.Data===2)
                       {
                       store.dispatch<any>(setLoggedInUser_V2(response));
                       store.dispatch<any>(companyVersion(version.Data ));
                       //store.dispatch<any>(webexC(localStorage.getItem("webexC")));
                       store.dispatch<any>(accessTokenExpireOn(response.expiresOn));
                       store.dispatch<any>({type:"Retry_Send",retrysend:0});
                       }
                       else
                       {
                           store.dispatch<any>(setLoggedInUser(response));
                           store.dispatch<any>(companyVersion(version.Data ));
                          // store.dispatch<any>(webexC(localStorage.getItem("webexC")));
                           store.dispatch<any>(accessTokenExpireOn(response.expiresOn));
                           store.dispatch<any>({type:"Retry_Send",retrysend:0});
                   }
                       SignalRHelper.createConnection(response.accessTokenApi);
                }
                       return
            
        } catch (err) {
            let error:any = err
            console.log(error.errorMessage);
                    }
                
                }
            }
            }
        }
        catch (error) {
            // console.log("ERERERERERr")
            console.log(error)
        }
    }
    static async signIn() {
        let response: any = null;
        try {
            
            if (AuthService.isInTeams) {
                AuthService.initSignin()
            }
            else {
                response = await AuthService.GraphAPIClient.loginPopup({ scopes: loginScopes });
                store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: "loggingIn" })
                await AuthService.saveToken();
            }
        } catch (err) {
            console.log(err)
            alert(err)
        }
    }
        // let response: any = null
        // try {
        //     response = await AuthService.GraphAPIClient.loginPopup({ scopes: loginScopes });
        //     store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: "loggingIn" })
        //     await AuthService.saveToken()
        // } catch (err) {
        //     console.log(err)
        //     alert(err)
        // }
    //}
    static signOut() {
        if (!this.isInTeams) {
            AuthService.GraphAPIClient.logout();
            store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: null })
            return;
        }
        localStorage.clear();
        sessionStorage.clear();
        var cookies = document.cookie;
        if (cookies.length > 0)
            for (var i = 0; i < cookies.split(";").length; ++i) {
                var myCookie = cookies[i];
                var pos = myCookie.indexOf("=");
                var name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
        store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: null })
    }

    static async initSignin() {
        msTeams.initialize(() => {
            this.isInTeams = true;
            store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: "loggingIn" })
            msTeams.getContext(async (c) => {
                debugger;
            //     let siteurl=c.subEntityId;
            //    let chatId="";
            //    let teamId=c.teamId;
            //    let channelId=c.channelId;
            //    let userId=c.userObjectId;
            //    let userEmail=c.loginHint;
            //    let tid=c.tid;
                // if (siteurl!=""&& siteurl!=undefined&&siteurl!=null)
                // {
                //     store.dispatch<any>({type:"deepLinkRoom",deepLinkRoom:siteurl});
                // }
                // else{
                //     store.dispatch<any>({type:"deepLinkRoom",deepLinkRoom:0});
                // }
                msTeams.authentication.getAuthToken({
                    successCallback: async function (result) {
                        const tokenDecoded: any = jwt_decode(result);
                        let response: any;
                        let response2: any;
                        try {
                            // var body = 'client_id='+configData.client_id+'&grant_type=urn:ietf:params:oauth:grant-type:jwt-bearer&'+ 
                            // 'client_secret='+configData.Clint_Secret+'&'+
                            // 'requested_token_use=on_behalf_of&'+
                            // 'assertion='+result+'&'+
                            // 'scope=https://chatabox365.onmicrosoft.com/ChataAPI/user_impersonation'; 
                            // fetch(`https://login.microsoftonline.com/${tid}/oauth2/v2.0/token`, {
                            //     method: "POST",
                            //     headers: {
                            //       // accept: "application/json;",
                            //       "Content-Type": "application/x-www-form-urlencoded"
                            //     }, body:body
                            // }).then((response: any) => response.json()).then(async data => {
                            //     debugger;
                            //     let x=data
                            // });
                            //https://api.yakchat.com Live
                            //https://dev-api.yakchat.com Dev
                            //https://api.yakchat.com" Live core
                            fetch(configData.APiUrl+`/v2/Authorize/GetToken?assertToken=${result}`, {
                                method: 'GET',
                                headers: {
                                    'Content-type': 'application/json'
                                },
                            }).then((response: any) => response.json()).then(async data => {
                                
                        //Please add url for new Them or Mainfest
                                
                                if (data.result !== "ok") {
                                    AuthService.getPopUp(response, response2, "");
                                    store.dispatch<any>(openLoginConsent(true));
                                } else {
                                    if(window.location.href.toLowerCase().includes('calltower') === true ){
                                        window.location.assign(configData.publishurl+"/#/calltower")
                                    }else if(window.location.href.toLowerCase().includes('cttext-webex') === true){
                                        window.location.assign(configData.publishurl+"/#/cttext-webex")
                                    }else if(window.location.href.toLowerCase().includes('webex') === true){
                                        window.location.assign(configData.publishurl+"/#/webex")
                                    }else if(window.location.href.toLowerCase().includes('commschoice') === true){
                                        window.location.assign(configData.publishurl+"/#/commschoice")
                                    }
                                        else if(window.location.href.toLowerCase().includes('onenet') === true){
                                            window.location.assign(configData.publishurl+"/#/oneNet")
                                    }   else if(window.location.href.toLowerCase().includes('mobex') === true){
                                        window.location.assign(configData.publishurl+"/#/mobex")
                                        
                                }else if(window.location.href.toLowerCase().includes('berkshire') === true){
                                    window.location.assign(configData.publishurl+"/#/berkshire")
                                }
                                else if(window.location.href.toLowerCase().includes('teligentip') === true){
                                    window.location.assign(configData.publishurl+"/#/teligentip")
                        }
                                else if(window.location.href.toLowerCase().includes('oneuc') === true){
                                    window.location.assign(configData.publishurl+"/#/oneuc")
                                }
                                else if(window.location.href.toLowerCase().includes('setup4') === true){
                                 
                                    window.location.assign(configData.publishurl+"/#/setup4")
                            }else{
                                        window.location.assign(configData.publishurl+"/#/")
                                    }
                                    const user: any = {
                                        account: {
                                            name: tokenDecoded.name, userName: tokenDecoded.unique_name
                                        },
                                        accessToken: data.GraphToken,
                                        accessTokenApi: data.ApiToken,
                                        expireOn:data.ExpireOn
                                    }
                                    store.dispatch<any>({type:"ACCESS_TOKEN_REGISTER",accessTokenApi:data.ApiToken});
                                    store.dispatch<any>({type:"Expire_On",expireOn:data.ExpireOn});
                                    store.dispatch<any>({type:"Retry_Send",retrysend:0});
                                   SignalRHelper.createConnection(data.ApiToken);
                                    let version:any=await getCompanyVersion_V2(data.ApiToken);
                                if(version.Data===2)
                                {
                                store.dispatch<any>(setLoggedInUser_V2(user));
                                store.dispatch<any>(companyVersion(version.Data ))
                                }
                                else
                                {
                                store.dispatch<any>(setLoggedInUser(user));
                                store.dispatch<any>(companyVersion(version.Data ))
                                }
                                //get chatId and save it 
                               // store.dispatch<any>(setActivityConfigurations(data,userId,channelId,chatId,teamId,tid,userEmail));

                                }
                            })
                        }
                        catch (error) {
                            alert("Test" + error);
                        }
                    },
                    failureCallback: function (error) {
                        console.log("Failure: " + error);
                    }
                })
            })
        })
        const account = msalInstance.getAccount();
        
        if (account) {
            store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: "loggingIn" })
            try {
                await AuthService.saveToken();
            } catch (error) {
                alert(error)
                store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: null })
                throw error;
            }
        }
    }
    static async RefreshTokens() {
        debugger;
        store.dispatch<any>({type:"Is_Paused",isPaused:true});
         msTeams.initialize(() => {
             this.isInTeams = true;
             let response2: any;
             msTeams.authentication.getAuthToken({
                silent: true,
                successCallback: async function (result) {
                    const tokenDecoded: any = jwt_decode(result);
                    let response: any;
                    let response2: any;
                    try {
                        
                        //https://api.yakchat.com Live
                        //https://dev-api.yakchat.com Dev
                        //https://api.yakchat.com" Live core
                        fetch(configData.APiUrl+`/v2/Authorize/GetToken?assertToken=${result}`, {
                            method: 'GET',
                            headers: {
                                'Content-type': 'application/json'
                            },
                        }).then((response: any) => response.json()).then(data => {
                            
                    //Please add url for new Them or Mainfest
                            
                            if (data.result !== "ok") {
                                AuthService.getPopUp(response, response2, "");
                                store.dispatch<any>(openLoginConsent(true));
                            } else {
                                if(window.location.href.toLowerCase().includes('calltower') === true ){
                                    window.location.assign(configData.publishurl+"/#/calltower")
                                }else if(window.location.href.toLowerCase().includes('cttext-webex') === true){
                                    window.location.assign(configData.publishurl+"/#/cttext-webex")
                                }else if(window.location.href.toLowerCase().includes('webex') === true){
                                    window.location.assign(configData.publishurl+"/#/webex")
                                }else if(window.location.href.toLowerCase().includes('commschoice') === true){
                                    window.location.assign(configData.publishurl+"/#/commschoice")
                                }
                                    else if(window.location.href.toLowerCase().includes('onenet') === true){
                                        window.location.assign(configData.publishurl+"/#/oneNet")
                                }   else if(window.location.href.toLowerCase().includes('mobex') === true){
                                    window.location.assign(configData.publishurl+"/#/mobex")
                            }else if(window.location.href.toLowerCase().includes('berkshire') === true){
                                window.location.assign(configData.publishurl+"/#/berkshire")
                            }
                            else if(window.location.href.toLowerCase().includes('teligentip') === true){
                                window.location.assign(configData.publishurl+"/#/teligentip")
                    }
                            else if(window.location.href.toLowerCase().includes('oneuc') === true){
                                window.location.assign(configData.publishurl+"/#/oneuc")
                            }
                            else if(window.location.href.toLowerCase().includes('setup4') === true){
                             
                                window.location.assign(configData.publishurl+"/#/setup4")
                        }else{
                                    window.location.assign(configData.publishurl+"/#/")
                                }
                                const user: any = {
                                    account: {
                                        name: tokenDecoded.name, userName: tokenDecoded.unique_name
                                    },
                                    accessToken: data.GraphToken,
                                    accessTokenApi: data.ApiToken,
                                    expireOn:data.ExpireOn
                                }
                                console.log("Signal R")
                                store.dispatch<any>({type:"ACCESS_TOKEN_REGISTER",accessTokenApi:data.ApiToken});
                                 store.dispatch<any>({type:"Expire_On",expireOn:data.ExpireOn});
                                 store.dispatch<any>({type:"Retry_Send",retrysend:0});

                                SignalRHelper.createConnection(data.ApiToken);
                                // store.dispatch<any>(setLoggedInUser(user));
                            }
                        })
                    }
                    catch (error) {
                        alert("Test" + error);
                    }
                },
                failureCallback: function (error) {
                    console.log("Failure: " + error);
                }
            })
           
         })
         const account = msalInstance.getAccount();
         if(!this.isInTeams)
         {
         if (account) {
            store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: "loggingIn" })
             try {
                 await AuthService.saveRefreshToken();
             } catch (error) {
                 alert(error)
               store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: null })
                 throw error;
             }
         }
        }
        store.dispatch<any>({type:"Is_Paused",isPaused:false});
     }
    static async getLoggedInUSerName() {
        const account = msalInstance.getAccount();
        if (account) {
            return account
        } else return null
    }
    static async saveRefreshToken(loginHint: string = "") {
        debugger;
        let response: any = null;
        let response2;
        try {
            while (!(response && response2)) {
                try {
                    
                    response = response || await AuthService.GraphAPIClient.acquireTokenSilent({ scopes: ["openid", "profile", "offline_access"], loginHint });
                    console.log("1", response);
                    response2 = response2 || await AuthService.YakChatAPIClient.acquireTokenSilent({ scopes: YakChatAPITokenScopes, loginHint, prompt: "consent" });
                    console.log("2", response2)
                    response.accessTokenApi = response2.accessToken;
                    SignalRHelper.createConnection(response.accessTokenApi);
                    store.dispatch<any>({type:"ACCESS_TOKEN_REGISTER",accessTokenApi:response.accessTokenApi});
                    store.dispatch<any>({type:"Expire_On",expireOn:response2.expiresOn});
                    store.dispatch<any>({type:"Retry_Send",retrysend:0});
                    return
                } catch (err) {
                    let error:any = err
                    if(error.errorMessage.indexOf("Token renewal operation failed due to timeout.") !== -1){
                        localStorage.clear();
                        document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                        // alert("clear Storage");
                        window.location.reload();
                    }
                    if (error.errorMessage.indexOf("interactive authorization request") !== -1) {
                        await AuthService.GraphAPIClient.acquireTokenPopup({
                            scopes: ["directory.read.all", "directory.accessasuser.all", "openid", "profile", "offline_access", "user.read", "user.readbasic.all", "user.read.all", "contacts.read", "sites.read.all", "https://chatabox365.onmicrosoft.com/ChataAPI/user_impersonation"]
                            , loginHint, prompt: "consent"
                        })
                            .then(async (accessTokenResponse: any) => {
                                response = accessTokenResponse;
                                response2 = await AuthService.YakChatAPIClient.acquireTokenSilent({ scopes: YakChatAPITokenScopes, loginHint });
                                response.accessTokenApi = response2.accessToken;
                                store.dispatch<any>(accessTokenRegister(response.accessTokenApi));
                                store.dispatch<any>(accessTokenExpireOn(response.expiresOn));
                                SignalRHelper.createConnection(response.accessTokenApi)
                                return
                            })
                            .catch((error: any) => {
                                console.log(error)
                                return
                            })
                    } else {
                       ////// test return  
                       let email: any = localStorage.getItem("email");
                       if(email!==undefined)
                       {
                     
                      debugger;
                      const account = msalInstance.getAccount();
        
                      loginHint=email;
                       response = await AuthService.YakChatAPIClient.acquireTokenSilent({ scopes: YakChatAPITokenScopes, loginHint });
                      response.accessTokenApi = response.accessToken;
                       store.dispatch<any>(accessTokenRegister(response.accessTokenApi));
                       store.dispatch<any>(accessTokenExpireOn(response.expiresOn));
                       SignalRHelper.createConnection(response.accessTokenApi);
                       }
                       return
                    }
                }
            }
        }
        catch (error) {
            // console.log("ERERERERERr")
            console.log(error)
        }
    }
    static async getPopUp(response: any, response2: any, loginHint: string = "") {
        
        console.log("call-tower--href",window.location.href.toLowerCase());
         if(window.location.href.toLowerCase().includes('calltower') === true ){
            window.location.assign(window.location.origin+'/#/callTowerConsent')
            console.log("/callTowerConsent")
         }else if(window.location.href.toLowerCase().includes('commschoice') === true){
            window.location.assign(window.location.origin+'/#/commsChoiceConsent')
         }else{
            window.location.assign(window.location.origin+'/#/adminConsent')
         }
         localStorage.clear();
         sessionStorage.clear();
         //webexCode=webexCode!=undefined?webexCode:localStorage.getItem("webexC");
         //localStorage.setItem(
        //     "webexC",
        //     webexCode
        //   );
        // msTeams.initialize(() => {
        //     msTeams.getContext(async (c) => {
        //         msTeams.authentication.authenticate({
        //             url: window.location.origin + "/#/adminConsent",
        //             width: 600,
        //             height: 535,
        //             successCallback: function (result) {
        //                 debugger
        //                 alert("Scucces")
        //                 console.log(result);
        //                 debugger
        //                 msTeams.authentication.notifySuccess();
        //             },
        //             failureCallback: function (reason) {
        //                 debugger
        //                 alert("Error 123456789")
        //                 console.log(response);
        //                 window.close();
        //                 msTeams.authentication.notifyFailure();
        //             }
        //         });
        //     });
        // });
        //  await AuthService.GraphAPIClient.loginRedirect({ scopes: ["directory.read.all", "directory.accessasuser.all", "openid", "profile", "offline_access", "user.read", "user.readbasic.all", "user.read.all", "contacts.read", "sites.read.all", "https://chatabox365.onmicrosoft.com/ChataAPI/user_impersonation"], loginHint: loginHint, prompt: "consent" })
        // await AuthService.GraphAPIClient.acquireTokenPopup({ scopes: ["directory.read.all", "directory.accessasuser.all", "openid", "profile", "offline_access", "user.read", "user.readbasic.all", "user.read.all", "contacts.read", "sites.read.all","https://chatabox365.onmicrosoft.com/ChataAPI/user_impersonation"], loginHint: loginHint, prompt: "consent" })
        //     .then(async (accessTokenResponse: any) => {
        //         debugger
        //         response = accessTokenResponse;
        //         response2 = await AuthService.YakChatAPIClient.acquireTokenSilent({ scopes: YakChatAPITokenScopes, loginHint });
        //         response.accessTokenApi = response2.accessToken;
        //         store.dispatch<any>(setLoggedInUser(response));
        //         SignalRHelper.createConnection(response.accessTokenApi);
        //         return
        //     })
        //     .catch(async (error: any) => {
        //         console.log(error);
        //         if (error.errorMessage.indexOf("User login is required") !== -1) {
        //             response = await AuthService.GraphAPIClient.loginPopup({ scopes: ["directory.read.all", "directory.accessasuser.all", "openid", "profile", "offline_access", "user.read", "user.readbasic.all", "user.read.all", "contacts.read", "sites.read.all","https://chatabox365.onmicrosoft.com/ChataAPI/user_impersonation"] });
        //             console.log("response",response)
        //         store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: "loggingIn" })
        //         await AuthService.saveToken();
        //         }
        //         return
        //         store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: null })

        //     })
    }
}
// const YakChatAPIClient: Msal.UserAgentApplication = new Msal.UserAgentApplication({
//     auth: {
//         authority: 'https://login.microsoftonline.com/common',
//         clientId: configData.client_id,
//         redirectUri: window.location.origin,
//         validateAuthority: false,
//         knownAuthorities: ['https://login.microsoftonline.com/common'],
//         navigateToLoginRequestUrl: true,
//     },
//     cache: {
//         cacheLocation: "localStorage",
//         storeAuthStateInCookie: false
//     }
// });
// const GraphAPIClient: Msal.UserAgentApplication = new Msal.UserAgentApplication({
//     auth: {
//         authority: 'https://login.microsoftonline.com/common',
//         clientId: configData.client_id,
//         redirectUri: window.location.origin,
//     },
//     cache: {
//         cacheLocation: 'localStorage',
//         storeAuthStateInCookie: true
//     }
// });
// const msalInstance = YakChatAPIClient;
// msalInstance.handleRedirectCallback((error, response) => {
//     alert('error')
// });
// export default class AuthService {
//     static isInTeams: boolean;
//     static async saveToken(loginHint: string = "") {
//         let response: any = null;
//         let response2: any;
//         try {
//             while (!(response && response2)) {
//                 try {
//                     response = response || await GraphAPIClient.acquireTokenSilent({ scopes: ["directory.read.all", "directory.accessasuser.all", "openid", "profile", "offline_access", "user.read", "user.readbasic.all", "user.read.all", "contacts.read", "sites.read.all","https://chatabox365.onmicrosoft.com/ChataAPI/user_impersonation"], loginHint });
//                     response2 = response2 || await YakChatAPIClient.acquireTokenSilent({ scopes: YakChatAPITokenScopes, loginHint, prompt: "consent" });
//                     response.accessTokenApi = response2.accessToken;
//                     store.dispatch<any>(setLoggedInUser(response));
//                     SignalRHelper.createConnection(response.accessTokenApi);
//                     return
//                 } catch (err) {
//                     console.log(err);
//                     if (err.errorMessage.indexOf("interactive authorization request") !== -1 || err.errorMessage.indexOf(" The user or administrator has not consented to use the application") !== -1) {
//                         await GraphAPIClient.acquireTokenPopup({
//                             scopes: ["directory.read.all", "directory.accessasuser.all", "openid", "profile", "offline_access", "user.read", "user.readbasic.all", "user.read.all", "contacts.read", "sites.read.all","https://chatabox365.onmicrosoft.com/ChataAPI/user_impersonation"], loginHint: loginHint, prompt: "consent"
//                         })
//                             .then(async (accessTokenResponse: any) => {
//                                 response = accessTokenResponse;

//                                 response2 = await YakChatAPIClient.acquireTokenSilent({ scopes: YakChatAPITokenScopes, loginHint });
//                                 response.accessTokenApi = response2.accessToken;
//                                 store.dispatch<any>(setLoggedInUser(response));
//                                 SignalRHelper.createConnection(response.accessTokenApi);
//                                 return
//                             })
//                             .catch((error: any) => {
//                                 console.log(error);
//                                 return
//                                 store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: null })
//                             })
//                     } else {
//                         console.log(err);
//                         store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: null })
//                         return
//                     }
//                 }
//             }

//         }

//         catch (error) {
//             store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: null });
//             console.log(error)
//             return
//         }
//     }
//     static async signIn() {
//         let response: any = null;
//         try {
//             debugger
//             if (AuthService.isInTeams) {
//                 AuthService.initSignin()
//             }
//             else {
//                 response = await GraphAPIClient.loginPopup({ scopes: loginScopes });
//                 store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: "loggingIn" })
//                 await AuthService.saveToken();
//             }
//         } catch (err) {
//             console.log(err)
//             alert(err)
//         }
//     }
//     static signOut() {
//         if (!this.isInTeams) {
//             GraphAPIClient.logout();
//             store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: null })
//             return;
//         }
//         localStorage.clear();
//         sessionStorage.clear();
//         var cookies = document.cookie;
//         if (cookies.length > 0)
//             for (var i = 0; i < cookies.split(";").length; ++i) {
//                 var myCookie = cookies[i];
//                 var pos = myCookie.indexOf("=");
//                 var name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
//                 document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
//             }
//         store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: null })
//     }
//     static async initSignin() {
//         localStorage.clear();
//         sessionStorage.clear();
//         msTeams.initialize(() => {
//             this.isInTeams = true;
//             store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: "loggingIn" })
//             msTeams.getContext(async (c) => {
//                 msTeams.authentication.getAuthToken({
//                     successCallback: async function (result) {
//                         AuthService.fetchToken(result, "");
//                     },
//                     failureCallback: function (error) {
//                         alert(error);
//                         console.log("Failure: " + error);
//                     }
//                 })
//             })
//         })
//     }
//     static async fetchToken(assertToken: any, loginHint: string = "") {
//         try {
//             const tokenDecoded: any = jwt_decode(assertToken);
//             let response: any;
//             let response2: any;
//             //https://api.yakchat.com Live
//             //https://dev-api.yakchat.com Dev
//             fetch(`https://dev-api.yakchat.com/AuthReact/GetReactTeamToken?assertToken=${assertToken}`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-type': 'application/json'
//                 },
//             }).then((response: any) => response.json()).then(async (data) => {
//                 if (data.result !== "ok") {
//                     AuthService.getPopUp(response, response2, "");
//                 } else {
//                     const user: any = {
//                         account: {
//                             name: tokenDecoded.name, userName: tokenDecoded.unique_name
//                         },
//                         accessToken: data.GraphToken,
//                         accessTokenApi: data.ApiToken
//                     }
//                     console.log("Signal R")
//                     SignalRHelper.createConnection(data.ApiToken);
//                     store.dispatch<any>(setLoggedInUser(user));
//                 }

//             })
//         }
//         catch (error) {
//             console.log(error)
//             alert("Test" + error);
//         }
//     }
//     static async getPopUp(response: any, response2: any, loginHint: string = "") {
//         debugger
//         await GraphAPIClient.acquireTokenPopup({ scopes: ["directory.read.all", "directory.accessasuser.all", "openid", "profile", "offline_access", "user.read", "user.readbasic.all", "user.read.all", "contacts.read", "sites.read.all","https://chatabox365.onmicrosoft.com/ChataAPI/user_impersonation"], loginHint: loginHint, prompt: "consent" })
//             .then(async (accessTokenResponse: any) => {
//                 debugger
//                 response = accessTokenResponse;
//                 response2 = await YakChatAPIClient.acquireTokenSilent({ scopes: YakChatAPITokenScopes, loginHint });
//                 response.accessTokenApi = response2.accessToken;
//                 store.dispatch<any>(setLoggedInUser(response));
//                 SignalRHelper.createConnection(response.accessTokenApi);
//                 return
//             })
//             .catch(async (error: any) => {
//                 console.log(error);
//                 if (error.errorMessage.indexOf("User login is required") !== -1) {
//                     response = await GraphAPIClient.loginPopup({ scopes: ["directory.read.all", "directory.accessasuser.all", "openid", "profile", "offline_access", "user.read", "user.readbasic.all", "user.read.all", "contacts.read", "sites.read.all","https://chatabox365.onmicrosoft.com/ChataAPI/user_impersonation"] });
//                     console.log("response",response)
//                 store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: "loggingIn" })
//                 await AuthService.saveToken();
//                 }
//                 return
//                 store.dispatch<any>({ type: "setLoggedInUser", loggedInUser: null })

//             })
//     }
//     static async getLoggedInUSerName() {
//         const account = msalInstance.getAccount();
//         if (account) {
//             return account
//         } else return null
//     }
// }




