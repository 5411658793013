import React from 'react'

function ContactFolders() {
    return (
        <>
           <div className="usr_img blk_bg">
                          <svg
                            className="wht_icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="29"
                            viewBox="0 0 26 29"
                          >
                            <g>
                              <g>
                                <path d="M20.394 19.534c-.072.528-.428 1.711-.661 2.456a.93.93 0 0 1-.886.653H9.758a.93.93 0 0 1-.886-.653c-.233-.745-.588-1.928-.66-2.456-.275-1.994 1.524-3.689 3.478-3.885h5.226c1.953.196 3.753 1.89 3.478 3.885zM14.303 6.907a3.496 3.496 0 1 1 0 6.993 3.496 3.496 0 0 1 0-6.993zm9.32-6.294H4.983c-.936 0-1.695.704-1.695 1.573v1.967h.786a2.364 2.364 0 0 1 2.361 2.36V7.3c0 1.302-1.06 2.36-2.36 2.36h-.787v2.362h.786a2.364 2.364 0 0 1 2.361 2.36v.786c0 1.302-1.06 2.36-2.36 2.36h-.787v2.36h.786a2.364 2.364 0 0 1 2.361 2.36v.788a2.364 2.364 0 0 1-2.36 2.36h-.787v1.967c0 .87.759 1.573 1.695 1.573h18.64c.936 0 1.695-.704 1.695-1.573V2.186c0-.869-.759-1.573-1.695-1.573z" />
                              </g>
                              <g>
                                <path d="M1 8.087a.86.86 0 0 1-.86-.859v-.642A.86.86 0 0 1 1 5.727h3.002a.86.86 0 0 1 .86.859v.642a.86.86 0 0 1-.86.86z" />
                              </g>
                              <g>
                                <path d="M1 15.955a.86.86 0 0 1-.86-.859v-.642a.86.86 0 0 1 .86-.86h3.002a.86.86 0 0 1 .86.86v.642a.86.86 0 0 1-.86.859z" />
                              </g>
                              <g>
                                <path d="M1 23.823a.86.86 0 0 1-.86-.86v-.641a.86.86 0 0 1 .86-.86h3.002a.86.86 0 0 1 .86.86v.642a.86.86 0 0 1-.86.859z" />
                              </g>
                            </g>
                          </svg>
                        </div>
        </>
    )
}

export default ContactFolders
