import * as React from "react";

import ContactComponent from "../../sharedComponent/contactsComponent";

export default class SharePointListComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      Calledfrom:
        this.props.calledFrom !== undefined ? this.props.calledFrom : "",
      status: this.props.Status,
      SharePointContactsLists:
        this.props.SharePointContacts !== undefined
          ? this.props.SharePointContacts
          : [],
    };
  }
  componentDidMount() {
   
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.SharePointContacts !== prevState.SharePointContactsLists ||
      nextProps.calledFrom !== prevState.Calledfrom ||
      nextProps.Status !== prevState.status
    ) {
      return {
        SharePointContactsLists: nextProps.SharePointContacts,
        status: nextProps.Status,
        Calledfrom: nextProps.calledFrom,
      };
    } else {
      return null;
    }
  }

  public render() {
    return (
      <React.Fragment>
        {this.state.SharePointContactsLists !== undefined ? (
          <ContactComponent
            searchFilter={this.props.searchFilter}
            tabOpened={this.props.tabOpened}
            calledFrom={this.state.Calledfrom}
            Status={this.state.status}
            ContactFoldersWithConatactList={this.state.SharePointContactsLists}
          />
        ) : null}
      </React.Fragment>
    );
  }
}
