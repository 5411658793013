import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import * as action from "../../../redux/actions/actions";
import { ReactComponent as SendIcon } from "../../../images/send_blk.svg";
import { ReactComponent as AddToGroupIcon } from "../../../images/group-add-icon.svg";
import { store } from "../../../..";

class SelectedContacts extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    const c: any = [];
    this.state = { contacts: c };
  }

  componentDidMount() {
    let contacts = this.props.selectedContactsWithCtrl;
  

    contacts.map((contact: any) => {
      let phoneNumber;
      let firstName;
      let lastName;
      let email;
      let sharedWith: any;
      let contactId = "";
      if (contact.type === "YakChat") {
        phoneNumber = contact.PhoneNumber;
        firstName = contact.ForeName;
        lastName = contact.SureName;
        email = contact.ContactEmail;
        sharedWith = contact.GroupEmail;
        contactId = contact.ContactId;
      } else if (contact.type === "Share Point") {
        phoneNumber = contact.mobilePhone;
        firstName = contact.displayName;
        lastName = contact.title;
        email = contact.mail ? contact.mail : "";
        contactId = contact.id;
      } else if (contact.type === "Users") {
        phoneNumber = contact.mobilePhone;
        firstName = contact.givenName;
        lastName = contact.surname;
        email = contact.mail;
        contactId = contact.id;
      } else if (contact.type === "Outlook") {
        phoneNumber = contact.mobilePhone;
        firstName = contact.givenName;
        lastName = contact.surname;
        email = contact.emailAddresses[0]?.address;
        contactId = contact.id;
      }
      return { phoneNumber, firstName, lastName, email, sharedWith, contactId };
    });
    this.setState({ contacts });
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let toReturn: any = {};
    if (nextProps.contacts !== prevState.contacts) {
      const c =
        nextProps.selectedContactsWithCtrl &&
        nextProps.selectedContactsWithCtrl.map((contact: any) => {
          let phoneNumber;
          let firstName;
          let lastName;
          let email;
          let sharedWith: any;
          let contactId = "";
          if (contact.type === "YakChat") {
            phoneNumber = contact.PhoneNumber;
            firstName = contact.ForeName;
            lastName = contact.SureName;
            email = contact.ContactEmail;
            sharedWith = contact.GroupEmail;
            contactId = contact.ContactId;
          } else if (contact.type === "Share Point") {
            phoneNumber = contact.mobilePhone;
            firstName = contact.displayName;
            lastName = contact.title;
            email = contact.mail ? contact.mail : "";
            contactId = contact.id;
          } else if (contact.type === "Users") {
            phoneNumber = contact.mobilePhone;
            firstName = contact.givenName;
            lastName = contact.surname;
            email = contact.mail;
            contactId = contact.id;
          } else if (contact.type === "Outlook") {
            phoneNumber = contact.mobilePhone;
            firstName = contact.givenName;
            lastName = contact.surname;
            email = contact.emailAddresses[0]?.address;
            contactId = contact.id;
          }
          return {
            phoneNumber,
            firstName,
            lastName,
            email,
            sharedWith,
            contactId,
          };
        });
      toReturn = {
        ...toReturn,
        contacts: c,
      };
    }
    return toReturn;
  }

  render() {
    return (
      <div className="right_Sec" id="right_Sec_main">
        <div className="top_row">
          <div className="top_left"></div>
        </div>
        <div className="chat_or_info">
          <div className="chat_sec">
            <div className="chat_bx">
              <div className="cntct_frm cntct_view contct_upl">
                <div className="row">
                  <div className="col-md-6">
                    <div className="cntcts_grp">
                      <div className="cntct_hedr">
                        <h2>Selected Contacts</h2>
                        <span className="sub_txt">
                          {" "}
                          {this.state.contacts?.length} contacts
                        </span>
                      </div>
                      <ul className="usr_list import_contactbox">
                        {this.state.contacts?.map((x: any, i: number) => {
                          return (
                            <li>
                              <div className="usr_imgbx">
                                <div className="usr_img2"> LC </div>
                              </div>
                              <div className="usr_txt">
                                <h3>{x.firstName + " " + x.lastName !== null ? x.lastName : ""}</h3>
                                <div className="txt_div">
                                  <span className="sub_txt">
                                    {x.phoneNumber}
                                  </span>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="drag_btnbox"
              style={{ display: "flex", float: "right" }}
            >
              <a
                style={{ width: 150 }}
                href="javascript:;void(0);"
                className="drag_btn cancel_btn"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.setSelectedContacts(this.props.selectedContactsWithCtrl);
                  this.props.setTabOpened("newgroup");
                  this.props.setSelectedContactsWithCtrl([]);
                }}
              >
                <AddToGroupIcon
                  className="blk_icon"
                  style={{ fill: "var(--surface)" }}
                  width={18}
                  height={18}
                />
                {"   "}Add to group
              </a>
              <a
                href="javascript:;void(0);"
                className="drag_btn next_btn next_yellow"
                onClick={(e) => { e.preventDefault();
                  this.props.createRoomGroup(this.state.groupName,this.props.selectedContactsNewMessage,this.state.groupEmail);
                }}
              >
                <SendIcon
                  style={{ fill: "var(--surface)" }}
                  width={18}
                  height={18}
                />
                {"       "}Send message in contacts
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (props: any) => {
  return {
    testData: props.reduxReducer.testName,
    tabOpened: props.reduxReducer.tabOpened,
    alertData: props.reduxReducer.alertData,
    currentTheme: props.reduxReducer.currentTheme,
    selectedContactsWithCtrl: props.reduxReducer.selectedContactsWithCtrl,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    testReduxPRops: (value: any) => {
      dispatch<any>(action.testRedux(value));
    },
    setTabOpened: (tab: string) => {
      dispatch<any>(action.setTabOpened(tab));
    },
    getUsableCreditForUser: () => {
      dispatch<any>(action.getUsableCreditForUser());
    },
    setCurrentTheme: (theme: string) => {
      dispatch<any>(action.setCurrentTheme(theme));
    },
    setLoggedInUser: (res: any) => {
     let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
      {
        dispatch<any>(action.setLoggedInUser_V2(res));
      }
      else
      dispatch<any>(action.setLoggedInUser(res));
    },
    setAlertData: (alertData: any) => {
      dispatch<any>(action.setAlertData(alertData));
    },
    setSelectedContacts: (contact: any) => {
      dispatch<any>(action.setSelectedContacts(contact));
    },
    setSelectedContactsWithCtrl: (contacts: any) => {
      dispatch<any>({ type: "setSelectedContactsWithCtrl", payload: contacts });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectedContacts);
