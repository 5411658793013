import React from "react";
import AuthHelper from "../../../Config/msalConfig";
import { connect, } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Link } from "react-router-dom";
import * as actions from "../../redux/actions/actions";
import Logo from "../logo";
import Login from "../../Login/login";
import { store } from "../../..";

class Bandwidth extends React.Component<any, any> {
    state = {
        terms: false,
        policy: false,
        accessToken: "",
        loginPage: false
    }
    redirectToHome = () => {
		const { history } = this.props;
		if (history) {
		 history.push('/')
		}
	}
    
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.accessToken !== prevState.accessTokenApi)
            return {
                accessToken: nextProps.accessToken,
            }
        else return null;
    }
    reloadPage = () => {
		setTimeout(() => {
			window.location.reload();
		}, 500)
	}
   setLoginUser =()=>{
        AuthHelper.signIn()
        setTimeout(() => {
            this.redirectToHome();
            this.reloadPage();
        }, 300);
      
       
       }
    render() {
        const { history } = this.props;
        return (
            <>
                {this.state.loginPage ? <Login loginStatus={this.props.loggedInUser}></Login> :
                     <div className={this.props.registerTheme === "calltower" ? "userlog_sec calltowerSection_register" : this.props.registerTheme === "commsChoice" ? "userlog_sec commsSection_register" : this.props.registerTheme === "onenet" ? "userlog_sec OneNet_register" 
                     :  this.props.registerTheme === "mobex" ? "userlog_sec OneNet_register Mobex_register" 
                     :  this.props.registerTheme === "berkshire" ? "userlog_sec OneNet_register Berkshire_register" 
                     :  this.props.registerTheme === "teligentip" ? "userlog_sec OneNet_register Teligentip_register" 
                     :  this.props.registerTheme === "oneuc" ? "userlog_sec OneNet_register Oneuc_register"  
                     : this.props.registerTheme === "setup4" ? "userlog_sec setupRegister" : "userlog_sec register_section"}>
                         <div className="container">
                            <div className="login_box">
                            <Logo/> 
                                <p className="comp_reg text-center">Please complete your details to <br />register for YakChat</p>
                                <div className="step_box text-center">
                                    <a href="#/register" className="step_btn">Step 1</a>
                                    <a href="javascript:;void(0);" className="step_btn ">Step 2</a>
                                    <a href="javascript:;void(0);l" className="step_btn ">Step 3</a>
                                    <a href="javascript:;void(0);" className="step_btn step_active">Step 4</a>
                                </div>
                                <div className="user_detail mfa_box">
                                    <div className="register_scroll">
                                        <h3 className="user_head">MFA Number Unavailable</h3>
                                        <div className="terms_login">
                                            <div>
                                                We have not been able to automatically assign a number that is capable of receiving MFA codes.
                                                Please contact us at <a href="mailto: info@yakchat.com">info@yakchat.com</a> and we will assign a number
                                                to your trial.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="next_backbtn justify-content-center">
                                        <div className="register_cancel_btn">
                                            <Link to="/">
                                            <button
                                            onClick={this.setLoginUser} className="nxtBtn">Continue</button>
                                            </Link>
                                        </div>
                                    </div>
                                    {/* <Link to={this.props.registerTheme === "calltower" ? "/registercalltower" : this.props.registerTheme === "commsChoice" ? "/registercomms" : "/"}>
										<button id="redirectLogin" style={{ display: "none" }}>Finish</button>
									</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}
const mapStateToProps = (props: any) => {
 
    return {
        accessToken: props.reduxReducer.accessTokenApi,
        subscriptionDetails_registeration: props.reduxReducer.subscriptionDetails_registeration,
        userDetails_registeration: props.reduxReducer.userDetails_registeration,
        companyDetails_registeration: props.reduxReducer.companyDetails_registeration,
        registerTheme: props.reduxReducer.registerTheme,
        isNumber: props.reduxReducer.isNumber,
    };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
    return {
        registerCompanyTrail: (company: any, subscription: any, user: any, accessToken: any) => {
            let companyVersion=store.getState().reduxReducer.companyVersion;
            if(companyVersion===2)
              {
            dispatch<any>(actions.registerCompanyTrail_V2(company, subscription, user, accessToken));
              }
              else
              {
                dispatch<any>(actions.registerCompanyTrail(company, subscription, user, accessToken));
              }
        },
        cancelBtnMailChimp: (company: any, subscription: any, user: any, accessToken: any) => {

            dispatch<any>(actions.cancelBtnMailChimp(company, subscription, user, accessToken));
        },
        cancelBtnZoho: (company: any, subscription: any, user: any, accessToken: any) => {

            dispatch<any>(actions.cancelBtnZoho(company, subscription, user, accessToken));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Bandwidth);