// import { hubConnection } from 'signalr-asp-net';
import { store } from '..';
import * as action from '../TeamsApp/redux/actions/actions';
import * as signalR from "@microsoft/signalr";
import configData from "../appsettings.json";
export default class SignalRHelper {
    public static connection: any
    public static createConnection(token: string) {
        if (!token) return;
        debugger;
        console.log("companyVersion "+store.getState().reduxReducer.companyVersion);
        let companyVersion=store.getState().reduxReducer.companyVersion;
        //Dev
        // 'https://dev-ws.yakchat.com';
        //Live
        // 'https://ws.yakchat.com'; 
     
        //this.connection.accessToken = token;
    
        //console.log(this.connection.accessToken)
       const connection = new signalR.HubConnectionBuilder()
            .withUrl(configData.signalrUrl+"/clienthub", { accessTokenFactory: () => token, skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
            .withAutomaticReconnect([0, 0, 5000])
            .build();
            connection.onreconnecting( async error => {
                //use same way of getting and bind the rooms by using this api Rooms/GetPagingUnArchivedRooms_New
                let companyVersion=store.getState().reduxReducer.companyVersion;
                if(companyVersion===2){
                    store.dispatch<any>(action.getPersonalInboxNew_V2(1,store.getState().reduxReducer.loggedInUser.inboxes.$values[0].InboxId, false, "''", "SignalR", ""));
                   // store.dispatch<any>(action.newMessageNotificationAlert_V2())
                }
                else
                {
                    store.dispatch<any>(action.getPersonalInboxNew(1, store.getState().reduxReducer.loggedInUser.Email, false, "''", "SignalR", ""));
                //store.dispatch<any>(action.newMessageNotificationAlert())
                }
                });
           // connection.serverTimeoutInMilliseconds = 1000 * 60 * 10;
            connection.on('NotifyClient', (notification: any, message: any) => {
                debugger;
                let companyVersion=store.getState().reduxReducer.companyVersion;
                console.log(companyVersion);
                if(companyVersion===2){
                    store.dispatch<any>(action.UpdateThisMessageStatusInThisRoom(notification.RoomId,1))  
                    if (notification.Direction == "Out"||notification.Direction == 10) {
                        store.dispatch<any>(action.UpdateThisMessageStatusInThisRoom(notification.RoomId,1))  
                    } else if(notification.Direction == "In"||notification.Direction == -10) {
                        if (!notification.Room) {
                            var newMessage = {
                                Id: notification.Id,
                                Direction: -10,
                                Text: notification.Text,
                                TimeStamp: notification.TimeStamp,
                            };
                            //store.dispatch<any>(action.insertMessageIntoRoom(newMessage, notification.RoomId, ""))
                            // store.dispatch<any>(action.getInbox_V2(1,store.getState().reduxReducer.loggedInUser.inboxes.$values[0].InboxId, false, "''", "", ""));
                            // store.dispatch<any>(action.newMessageNotificationAlert_V2())
                        }
                        if(notification.Direction == "In"||notification.Direction == -10){
                            var data = [];
                            data.push(notification); 
                            if( data !== undefined)
                           {
                            // store.dispatch<any>(action.updateRoomsFromWebSocket(data));
                           } 
                            //store.dispatch<any>(action.messageFromSignal(true));
                            //store.dispatch<any>(action.newMessageNotificationAlert_V2())
                        }
                    } 
                    var email= store.getState().reduxReducer.sharedInboxData?.currentSelected.email=="Closed"? store.getState().reduxReducer.openMessageRoom?.InboxId:store.getState().reduxReducer.sharedInboxData?.currentSelected.email;
                    var archivedFlag =store.getState().reduxReducer.sharedInboxData?.currentSelected.email ==="Closed" ? true:false;
                    store.dispatch<any>(action.getInbox_V2(1,email, archivedFlag, email, "", ""));
                   // store.dispatch<any>(action.newMessageNotificationAlert_V2())
                }
                else
                {
                    store.dispatch<any>(action.UpdateThisMessageStatusInThisRoom(notification.RoomId,1))  
                    if (notification.MsgDirection == "Out"||notification.MsgDirection == 10) {
                        store.dispatch<any>(action.UpdateThisMessageStatusInThisRoom(notification.RoomId,1))  
                    } else if(notification.MsgDirection == "In"||notification.MsgDirection == -10) {
                        if (!notification.Room) {
                            var newMessage = {
                                Id: notification.RoomMessageId,
                                Direction: -10,
                                Text: notification.Text,
                                TimeStamp: notification.TimeStamp,
                            };
                            store.dispatch<any>(action.insertMessageIntoRoom(newMessage, notification.RoomId, ""))
                        }
                        if(notification.MsgDirection == "In"){
                            var data = [];
                            data.push(notification); 
                            if( data !== undefined)
                           {
                            // store.dispatch<any>(action.updateRoomsFromWebSocket(data));
                           } 
                            store.dispatch<any>(action.messageFromSignal(true));
                            store.dispatch<any>(action.newMessageNotificationAlert())
                        }
                    } 
                // store.dispatch<any>(action.getPersonalInbox(1, store.getState().reduxReducer.sharedInboxData?.currentSelected.email, false, "''", "", ""));
                // store.dispatch<any>(action.newMessageNotificationAlert())
                }
        });
      
        connection.onclose( async error => {
            start();
            //use same way of getting and bind the rooms by using this api Rooms/GetPagingUnArchivedRooms_New
            let companyVersion=store.getState().reduxReducer.companyVersion;
            if(companyVersion===2){
                store.dispatch<any>(action.getPersonalInboxNew_V2(1,store.getState().reduxReducer.loggedInUser.inboxes.$values[0].InboxId, false, "''", "SignalR", ""));
               // store.dispatch<any>(action.newMessageNotificationAlert_V2())
            }
            else
            store.dispatch<any>(action.getPersonalInboxNew(1, store.getState().reduxReducer.sharedInboxData?.currentSelected.email, false, "''", "SignalR", ""));
            //store.dispatch<any>(action.newMessageNotificationAlert())
            });
        // Start the connection.
        start();
        async function start() {
            console.log("start")
            try {
                await connection.start();
                console.log("SignalR Connected.");

            } catch (err) {
                let companyVersion=store.getState().reduxReducer.companyVersion;
                if(companyVersion===2){
                    const connection = new signalR.HubConnectionBuilder()
                    .withUrl(configData.signalrUrl+"/clienthub", { accessTokenFactory: () => token, skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
                    .withAutomaticReconnect([0, 0, 5000])
                    .build();
                    connection.onreconnecting( error => {
                       //use same way of getting and bind the rooms by using this api Rooms/GetPagingUnArchivedRooms_New
                let companyVersion=store.getState().reduxReducer.companyVersion;
                if(companyVersion===2){
                    store.dispatch<any>(action.getPersonalInbox_V2(1,store.getState().reduxReducer.loggedInUser.inboxes.$values[0].InboxId, false, "''", "SignalR", ""));
                   // store.dispatch<any>(action.newMessageNotificationAlert_V2())
                }
                else
                {
                    store.dispatch<any>(action.getPersonalInboxNew(1, store.getState().reduxReducer.loggedInUser.Email, false, "''", "SignalR", ""));
                //store.dispatch<any>(action.newMessageNotificationAlert())
                }
                });
                }
            }

        };
    }
}
