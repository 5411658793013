import React from "react";
import AuthService from "../../Config/msalConfig";
import logo from "../../TeamsApp/images/CT Text Logo-2.png";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import sponser from "../../TeamsApp/images/commsgroup-alt-logo 2.svg"
import Errorlogo from "../components/errorBoundary/errorlogo";

class SystemUnavailable extends React.Component<any, any> {
  state = {
    errorType: "Your 14-day free trial has expired",
    errorMessage: "Click the button below to continue using the service or contact info@yakchat.com.",
  };

  render() {
    return (
      <section className="userlog_sec error_sec" style={{
        backgroundColor: this.props.whiteLabelBrand === "calltower" || window.location.href.toLowerCase().includes("calltower")|| window.location.href.toLowerCase().includes("cttext-webex") ? "#6bbc15" :
          this.props.whiteLabelBrand === "commschoice" || window.location.href.toLowerCase().includes("commschoice") ? "#000045" :
            this.props.whiteLabelBrand === "onenet" || window.location.href.toLowerCase().includes("onenet") ? "#E9E9ED" :
              this.props.whiteLabelBrand === "mobex" || window.location.href.toLowerCase().includes("mobex") ? "#E5E5E5" :
                this.props.whiteLabelBrand === "berkshire" || window.location.href.toLowerCase().includes("berkshire") ? "#0082CA" :
                window.location.href.toLowerCase().includes("webex")?"#E5E5E5":
                  this.props.whiteLabelBrand === "teligentip" || window.location.href.toLowerCase().includes("teligentip") ? "#F37430" :
                    this.props.whiteLabelBrand === "oneuc" || window.location.href.toLowerCase().includes("oneuc") ? "#492575"
                      : this.props.whiteLabelBrand === "setup4" || window.location.href.toLowerCase().includes("setup4") ? "#ededed" : "#fcbf00"
      }}>
        <div className="container" style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
          <div className="login_box" style={{ textAlign: "center" }}>
            <div className="error_logo text-center">
              <Errorlogo />
            </div>
            <div className="timeOutBox" style={{ backgroundColor: "#fff", }}>
              <div className="inner_box">
                <h2>{this.props.trailExpier === "SystemError" ? "Oops! We’re unable to connect to the service" : this.props.sharedError ? "Oops! No inbox assigned " : this.props.trailExpier === "DisabledError" ? "Your user account has been disabled" : this.props.trailExpier === "DomainValidation" ? "Unable to validate account" : this.props.trailExpier === "maxAttempt" ? "Unable to verify account" : this.state.errorType}</h2>
                <p className="subheading" style={{ marginTop: "4px" }}>
                  {this.props.trailExpier === "SystemError" ? "Please refresh the application. If this problem persists, please contact info@yakchat.com" : this.props.sharedError ? "Please contact your Administrator to be assigned to an inbox." : this.props.trailExpier === "DisabledError" ? "Please contact your administrator or info@yakchat.com to re-enable your account" : this.props.trailExpier === "DomainValidation" ? "Please contact info@yakchat.com to approve registering a trial using this domain." : this.props.trailExpier === "maxAttempt" ? "Please contact info@yakchat.com to approve registering a trial using this domain." : this.state.errorMessage}
                </p>
                <div style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                  {this.props.trailExpier === "DisabledError" ? "" : this.props.trailExpier === "DomainValidation" || this.props.trailExpier === "maxAttempt" ? <button className="btn" onClick={() => window.open("https://www.yakchat.com/contact-us/")} style={{
                    backgroundColor: this.props.whiteLabelBrand === "calltower" || window.location.href.toLowerCase().includes("calltower")|| window.location.href.toLowerCase().includes("cttext-webex") ? "#6bbc15" : this.props.whiteLabelBrand === "commschoice" || window.location.href.toLowerCase().includes("commschoice") ? "#000045" : this.props.whiteLabelBrand === "onenet" || window.location.href.toLowerCase().includes("Onenet") ? "#BECE31"
                      : this.props.whiteLabelBrand === "mobex" || window.location.href.toLowerCase().includes("mobex") ? "#4555AC"
                        : this.props.whiteLabelBrand === "berkshire" || window.location.href.toLowerCase().includes("berkshire") ? "#0082CA"
                          : this.props.whiteLabelBrand === "teligentip" || window.location.href.toLowerCase().includes("teligentip") ? "#F3F2F1"
                          :window.location.href.toLowerCase().includes("webex")?"#E5E5E5"
                            : this.props.whiteLabelBrand === "oneuc" || window.location.href.toLowerCase().includes("oneuc") ? "#482474" : this.props.whiteLabelBrand === "setup4" || window.location.href.toLowerCase().includes("setup4") ? "#000000" : "#fcbf00", borderRadius: "11px", color: "#fff", marginTop: "10px"
                  }}>Contact us</button>
                    : this.props.trailExpier !== "SystemError" ? <button className="btn" onClick={() => window.open("https://yakchat.com/no-shared-inbox-assigned")} style={{
                      backgroundColor: this.props.whiteLabelBrand === "calltower" || window.location.href.toLowerCase().includes("calltower")||window.location.href.toLowerCase().includes("cttext-webex")  ? "#6bbc15" : this.props.whiteLabelBrand === "commschoice" || window.location.href.toLowerCase().includes("commschoice") ? "#000045" : this.props.whiteLabelBrand === "onenet" || window.location.href.toLowerCase().includes("Onenet") ? "#BECE31"
                        : this.props.whiteLabelBrand === "mobex" || window.location.href.toLowerCase().includes("mobex") ? "#4555AC"
                          : this.props.whiteLabelBrand === "berkshire" || window.location.href.toLowerCase().includes("berkshire") ? "#0082CA"
                          :window.location.href.toLowerCase().includes("webex")?"#E5E5E5"
                            : this.props.whiteLabelBrand === "teligentip" || window.location.href.toLowerCase().includes("teligentip") ? "#F3F2F1"
                              : this.props.whiteLabelBrand === "oneuc" || window.location.href.toLowerCase().includes("oneuc") ? "#482474" : this.props.whiteLabelBrand === "setup4" || window.location.href.toLowerCase().includes("setup4") ? "#000000" : "#fcbf00", borderRadius: "11px", color: "#fff", marginTop: "10px"
                    }}>More info</button>

                      : this.props.trailExpier === "SystemError" ? "" : this.props.sharedError ? "" :
                        <Link to="/register">
                          <button className="btn" style={{
                            backgroundColor: this.props.whiteLabelBrand === "calltower" || window.location.href.toLowerCase().includes("calltower") ||window.location.href.toLowerCase().includes("cttext-webex") ? "#6bbc15" : this.props.whiteLabelBrand === "commschoice" || window.location.href.toLowerCase().includes("commschoice") ? "#000045" : this.props.whiteLabelBrand === "onenet" || window.location.href.toLowerCase().includes("Onenet") ? "#BECE31"
                              : this.props.whiteLabelBrand === "mobex" || window.location.href.toLowerCase().includes("setup4") ? "#4555AC"
                                : this.props.whiteLabelBrand === "teligentip" || window.location.href.toLowerCase().includes("teligentip") ? "#F37430"
                                  : this.props.whiteLabelBrand === "berkshire" || window.location.href.toLowerCase().includes("berkshire") ? "#0082CA"
                                  :window.location.href.toLowerCase().includes("webex")?"#E5E5E5"
                                    : this.props.whiteLabelBrand === "oneuc" || window.location.href.toLowerCase().includes("oneuc") ? "#492575" : this.props.whiteLabelBrand === "setup4" || window.location.href.toLowerCase().includes("setup4") ? "#000000" : "#fcbf00", borderRadius: "11px", color: "#fff", marginTop: "10px"
                          }}> Register</button>
                        </Link>
                  }
                  {this.props.trailExpier ? "" : this.props.trailExpier === "SystemError" ? "" : this.props.sharedError ? "" :
                    <button
                      className="btn-sends"
                      onClick={() => {
                        AuthService.signOut();
                      }}
                      style={{
                        marginTop: "20px",
                        marginLeft: "25px",
                        backgroundColor: "var(--primary)",
                        border: "none",
                      }}
                    >
                      Logout
                    </button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (props: any) => {
  return {
    whiteLabelBrand: props.reduxReducer.whiteLabelBrand,
    openPage: props.reduxReducer.openPage,
    trailExpier: props.reduxReducer.trailExpier,
    traiMessage: props.reduxReducer.traiMessage,
    sharedError: props.reduxReducer.sharedError
  };
};
export default connect(mapStateToProps, null)(SystemUnavailable);

