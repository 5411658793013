import moment from "moment";
export const generateFirstLastNameIntials = (name: any) => {
  var firstName = "";
  var lastName = "";
  var inital = "";
  if (name !== undefined && name !== "" && name !==null) {
    if (name.indexOf(" ") !== -1) {
      var splitName = name.split(" ");
      firstName = splitName[0].toString();
      lastName = splitName[1].toString();
      var firstIntiial = firstName !== "" ? firstName.charAt(0) : "";
      var lastIntial = lastName !== "" ? lastName.charAt(0) : "";
      inital = firstIntiial + "" + lastIntial;
    } else {
      inital = name.charAt(0);
    }
  } else {
    inital = "";
  }

  return inital;
};
export const filterContactListWithRef = async (lists: any) => {
  var obj: any = {};
  debugger;
  var filterContactListWithOutRef = lists?.LocalContactinLists?.$values.filter((x: any) => x.$ref === undefined);
  var data = lists.LocalContactinLists?.$values.map((element: any) => {
    if (element.$ref === undefined) {
      return element;
    }
    else {
      var data = filterContactListWithOutRef.map((elementRef: any) => {
        if (elementRef?.contactLists?.$values?.length !== 0) {
          var checkIfItContainsRef = elementRef.contactLists.$values.filter((x: any) => x.$id === element.$ref);
          if (checkIfItContainsRef?.length !== 0) {
            return checkIfItContainsRef[0];
          }
          else {
            var y = elementRef.contactLists.$values.map((value: any) => {
              if (value.localContacts?.$values?.length !== 0) {
                var checkIfItContainsRef = value.localContacts.$values.filter((x: any) => x.$id === element.$ref);
                if (checkIfItContainsRef?.length !== 0) {
                  return checkIfItContainsRef[0];
                }
              }
            });
            return y[0];
          }
        }
        return data;
      });
      element = data[0];
      return element;
    }
  });
  return data;
}

export const filterAzureContactListWithRef = async (lists: any) => {
  debugger;
  var obj: any = {};
  var filterContactListWithOutRef = lists?.value.filter((x: any) => x.$ref === undefined);
  var data = lists?.value.map((element: any) => {
    if (element.$ref === undefined) {
      return element;
    }
    else {
      var data = filterContactListWithOutRef.map((elementRef: any) => {
        if (elementRef?.$values?.length !== 0) {
          var checkIfItContainsRef = elementRef.$values.filter((x: any) => x.$id === element.$ref);
          if (checkIfItContainsRef?.length !== 0) {
            return checkIfItContainsRef[0];
          }
          else {
            var y = elementRef.$values.map((value: any) => {
              if (value.localContacts?.$values?.length !== 0) {
                var checkIfItContainsRef = value.$values.filter((x: any) => x.$id === element.$ref);
                if (checkIfItContainsRef?.length !== 0) {
                  return checkIfItContainsRef[0];
                }
              }
            });
            return y[0];
          }
        }
        return data;
      });
      element = data[0];
      return element;
    }
  });
  return data;
}

export const convertListsFromRefsIntoObjects = async (lists: any) => {
  const obj: any = {};
  
  // populate the obj with all objects in the lists with their $id as the key
  for (let i = 0; i < lists?.length; i++) {
    const l = lists[i];
    if (l.$id) {
      obj[l.$id] = l;
      if (l.localContacts) {
        for (let j = 0; j < l.localContacts?.$values?.length; j++) {
          const c = l.localContacts.$values[j];
          if (c.$id) obj[c.$id] = c;
          if (c.contactLists) {
            for (let k = 0; k < c.contactLists?.$values?.length; k++) {
              let cl = c.contactLists.$values[k];
              if (cl.$id) obj[cl.$id] = cl;
            }
          }
        }
      }
    }
  }

  // use the obj to replace refs with original objects
  for (let i = 0; i < lists?.length; i++) {
    while (lists[i].$ref && obj[lists[i].$ref]) {
      lists[i] = obj[lists[i].$ref];
    }
    if (lists[i].localContacts) {
      for (let j = 0; j < lists[i]?.localContacts?.$values?.length; j++) {
        while (
          lists[i].localContacts.$values[j].$ref &&
          obj[lists[i].localContacts.$values[j].$ref]
        ) {
          lists[i].localContacts.$values[j] =
            obj[lists[i].localContacts.$values[j].$ref];
        }
      }
    }
  }
  return lists;
};

const REFERENCE = moment();
const TODAY = REFERENCE.clone().startOf("day");
const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
const A_WEEK_OLD = REFERENCE.clone().subtract(6, "days").startOf("day");
export const isToday = (momentDate: any) =>
  moment(momentDate).isSame(TODAY, "d");
export const isYesterday = (momentDate: any) =>
  moment(momentDate).isSame(YESTERDAY, "d");
export const isWithinAWeek = (momentDate: any) =>
  moment(momentDate).isAfter(A_WEEK_OLD);

export const openBootstrapCollapseWithId = (id: string) => {
  if (!document.getElementById(id)?.classList.contains("show"))
    document.getElementById(id)?.classList.add("show");
  document.getElementById(id + "Button")?.setAttribute("aria-expanded", "true");
};

export const openLeftSection = () => {
  if (!document.getElementById("left_sec_main")?.classList.contains("hide_left_sec"))
  document.getElementById("left_sec_main")?.classList.add("hide_left_sec");
  if (!document.getElementById("right_Sec_main")?.classList.contains("show_right_sec"))
   document.getElementById("right_Sec_main")?.classList.add("show_right_sec");
};

export const hideLeftSection = () => {
  if (document.getElementById("left_sec_main")?.classList.contains("hide_left_sec"))
    document.getElementById("left_sec_main")?.classList.remove("hide_left_sec");
  if (document.getElementById("right_Sec_main")?.classList.contains("show_right_sec"))
    document.getElementById("right_Sec_main")?.classList.remove("show_right_sec");
};

// export const showRightSection =() =>{
//   if (document.getElementById("right_Sec_main")?.classList.contains("show_right_sec"))
//   document.getElementById("right_Sec_main")?.classList.remove("show_right_sec");
//   if (document.getElementById("left_sec_main")?.classList.contains("hide_left_sec"))
//   document.getElementById("left_sec_main")?.classList.remove("hide_left_sec");
// };
export const showRightSection =() =>{
  debugger
  if (!document.getElementById("right_Sec_main")?.classList.contains("show_right_sec"))
  {
    document.getElementById("right_Sec_main")?.classList.add("show_right_sec");
  }
  if (document.getElementById("left_sec_main")?.classList.contains("hide_left_sec"))
  {
    document.getElementById("left_sec_main")?.classList.remove("hide_left_sec");
  }
};
export const avatarColorMap = [
  "var(--avatars-red)",
  "var(--avatars-red-light)",
  "var(--avatars-purple-light)",
  "var(--avatars-purple)",
  "var(--avatars-navy-blue)",
  "var(--avatars-blue)",
  "var(--avatars-caribbean-green)",
  "var(--avatars-light-green)",
  "var(--avatars-orange)",
  "var(--avatars-light-orange)",
];
