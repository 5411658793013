import React, { ReactElement } from "react";
import "./chatPanel.css";

interface Props {
  direction: number;
}
const Cowtail = (props: Props) => {
  let svg: ReactElement = <div></div>;
  if (props.direction === 10) {
    svg = (
      <svg
        width="24"
        height="20"
        viewBox="0 0 24 20"
        id="cowtail-10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.98066 14.9549C4.72121 14.1942 2.61178 13.0452 0.747212 11.5595L4.32605 0.545013C5.42757 2.68246 6.80819 4.66408 8.43165 6.43788C10.4401 8.67714 12.9894 10.3638 15.8358 11.3365C17.4783 11.8659 19.1974 12.1182 20.9227 12.0831C21 12.0813 21.0771 12.0924 21.1507 12.1161C21.2669 12.1544 21.3699 12.2249 21.4477 12.3194C21.5255 12.4139 21.5749 12.5286 21.5902 12.65C21.6059 12.7655 21.5862 12.8831 21.5336 12.9871C21.481 13.0911 21.3981 13.1767 21.2958 13.2325C19.4796 14.282 17.5057 15.031 15.4505 15.4506C12.6319 16.0204 9.7136 15.8496 6.98066 14.9549V14.9549Z" />
      </svg>
    );
  } else {
    svg = (
      <svg
        width="25"
        height="20"
        viewBox="0 0 25 20"
        id="cowtail--10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.3679 14.9562C19.6275 14.1965 21.7373 13.0484 23.6023 11.5637L20.0226 0.546359C18.921 2.6838 17.5404 4.66543 15.9169 6.43923C13.9085 8.67849 11.3592 10.3651 8.51283 11.3379C6.87034 11.8672 5.15119 12.1195 3.42586 12.0844C3.34857 12.0826 3.27152 12.0938 3.19793 12.1175C3.08167 12.1558 2.97866 12.2263 2.90086 12.3208C2.82307 12.4153 2.77368 12.5299 2.75844 12.6514C2.74269 12.7669 2.76241 12.8844 2.81498 12.9885C2.86756 13.0925 2.95051 13.1781 3.05285 13.2339C4.86901 14.2833 6.84287 15.0324 8.89806 15.4519C11.7167 16.0218 14.635 15.851 17.3679 14.9562V14.9562Z" />
      </svg>
    );
  }
  return svg;
};

export default Cowtail;
