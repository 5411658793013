import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as actions from "../../../redux/actions/actions";
import SharePointListComponent from "../SharePointContacts/SharePointListComponent";
class SharePointParentComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      calledfrom: "",
      sharepointcontacts: [],
      SharepointSelected: this.props.SharePointSelected,
      pageindex: this.props.PageIndex !== undefined ? this.props.PageIndex : 1,
    };
  }
  componentDidMount() {
    // this.props.getsharepointcontacts(1);
    // console.log("pageIndex in share point",this.state.pageIndex,this.props.sharepointcontactsdata)
    
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.sharepointcontactsdata !== prevState.sharepointcontacts ||
      nextProps.SharePointSelected !== prevState.SharepointSelected ||
      nextProps.PageIndex !== prevState.pageindex 
    ) {
      return {
        sharepointcontacts: nextProps.sharepointcontactsdata,
        SharepointSelected: nextProps.SharePointSelected,
        pageindex: nextProps.PageIndex,
      };
    } else {
      return null;
    }
  }

  public render() {
  { if (this.state.sharepointcontacts){ 
    if(this.state.sharepointcontacts.length === 1){
      this.props.setSpinnerInSideBar(true);
    } else{
      this.props.setSpinnerInSideBar(false);
    }
    this.state.sharepointcontacts?.sort((a: any, b: any) => {
      var nameA = a?.ContactName?.toUpperCase() !== undefined ? a?.ContactName?.toUpperCase() : a?.title?.toUpperCase;
      var nameB = b?.ContactName?.toUpperCase() !== undefined ? b?.ContactName?.toUpperCase() : b?.title?.toUpperCase;
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    })
  } }
    const filteredContacts = [];
    if (this.state.sharepointcontacts)
      for (let contact of this.state.sharepointcontacts) {
        const filteredContactList = contact.ContactList.filter((y: any) => {
          return y.displayName?.toLowerCase().includes(this.props.searchFilter.toLowerCase());
        });
        const newObj = {
          ConatctFolderId: contact.ConatctFolderId,
          ContactList: filteredContactList,
          ContactName: contact.ContactName,
        };
        filteredContacts.push(newObj);
      }
    return (
      <React.Fragment>
        {this.state.sharepointcontacts !== undefined ?  (
          <SharePointListComponent
            searchFilter={this.props.searchFilter}
            tabOpened={this.props.tabOpened}
            SharePointContacts={filteredContacts}
            calledFrom={"Share Point"}
            Status={this.state.SharepointSelected}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {
    sharepointcontactsdata: props.reduxReducer.SharePointContactlist,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    getsharepointcontacts: (pageIndex:number) => {
      dispatch<any>(actions.getSharePointContacts(pageIndex));
    },
    setSpinnerInSideBar: (value: any) => {
      dispatch<any>(actions.setSpinnerInSideBar(value))
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SharePointParentComponent);
