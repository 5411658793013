export enum ActionTypes {
  GET_ALL = "GET_ALL",
  SHOW_HIDE_Info = "SHOW_HIDE_Info",
  Outlook_Contact_List = "OUTLOOK_CONTACT_LIST",
  Outlook_Contact_Folders_List = "OUTLOOK_CONTACT_FOLDERS_LIST",
  Share_Point_Contact_List = "SHARE_POINT_CONTACT_List",
  Yak_Chat_Contact_List = "YAK_CHAT_CONTACT_LIST",
  USER_LIST = "USER_LIST",
  setSelectedContacts_NewMessage = "setSelectedContacts_NewMessage",
  setTabOpened_Main = "setTabOpened_Main",
  SetPrevious_Tab = "SetPrevious_Tab",
  setContactSelected_OpenContact = "setContactSelected_OpenContact",
  UpdateOneOutlook_Contact_List = "UpdateOneOutlook_Contact_List",
  UpdateOne_USER_LIST = "UpdateOne_USER_LIST",
  UpdateOne_Share_Point_Contact = "UpdateOne_Share_Point_Contact",
  UpdateOne_Yak_Chat_Contact_List = "UpdateOne_Yak_Chat_Contact_List",
  AddOne_Yak_Chat_Contact_List = "AddOne_Yak_Chat_Contact_List",
  setAlertData = "setAlertData",
  Yak_Chat_GroupEmail_List = "Yak_Chat_GroupEmail_List",
  AddOne_Yak_Chat_GroupEmail_List = "AddOne_Yak_Chat_GroupEmail_List",
  usableCredit = "usableCredit",
  setRooms = "setRooms",
  setOpenMessageRoom = "setOpenMessageRoom",
  setAzureGroups = "setAzureGroups",
  setCurrentTheme = "setCurrentTheme",
  setMFA="setMFA",
  setLoggedInUser = "setLoggedInUser",
  insertMessageIntoRoom = "insertMessageIntoRoom",
  setSharedInboxData = "setSharedInboxData",
  setSharedEmails ="setSharedEmails",
  setCurrentSelectedSharedEmailInboxData = "setCurrentSelectedSharedEmailInboxData",
  updateThisMessageStatusInThisRoom = "updateThisMessageStatusInThisRoom",
  clearOpenMessageRoom = "clearOpenMessageRoom",
  setYakchatLists = "setYakchatLists",
  setYakChatUsers = "setYakChatUsers",
  SetNewContactData = "SetNewContactData",
  NewUnreadMessageCount = "NewUnreadMessageCount",
  NewUnreadGroupMessage = "GroupMessagecount",
  OwnerCircleIndicator = "OwnerCircleIndicator",
  SetServicePlanId = "ServicePlanId",
  SetAplhaBetGroupEmail = "SetGroupemail",
  SetRoomsForAlphabetGroupemail = "SetRoomsForAlphabetGroupemail",
  setCurrentSelectedSharedEmailInboxDataByMessage = "setCurrentSelectedSharedEmailInboxDataByMessage",
  SetGroupContactList = "SetGroupContactList",
  CURRENT_PAGE_INDEX_FOR_ROOM_MESSAGES = 'PAGE_INDEX_FOR_ROOM_MESSAGES',
  PAGE_COUNT_PAGE_INDEX_FOR_ROOM_MESSAGES = 'PAGE_COUNT_PAGE_INDEX_FOR_ROOM_MESSAGES',
  CURRENT_PAGE_INDEX_FOR_INBOX = 'CURRENT_PAGE_INDEX_FOR_INBOX',
  PAGE_COUNT_PAGE_INDEX_FOR_INBOX = 'PAGE_COUNT_PAGE_INDEX_FOR_INBOX',
  SHARED_INBOX_MESSAGE_COUNT = 'SHARED_INBOX_MESSAGE_COUNT',
  UPDATE_ROOM_FROM_WEB_SOCKET = 'UPDATE_ROOM_FROM_WEB_SOCKET',
  SET_SPINNER = 'SET_SPINNER',
  ACTUAL_CONTACTS_IN_LIST = 'ACTUAL_CONTACTS_IN_LIST',
  SET_SPINNER_SIDE_BAR = 'SET_SPINNER_SIDE_BAR',
  SET_DEFAULT_CONTACT_LIST = 'SET_DEFAULT_CONTACT_LIST',
  CURRENT_PAGE_INDEX_FOR_YAKCHAT = 'CURRENT_PAGE_INDEX_FOR_YAKCHAT',
  PAGE_COUNT_PAGE_INDEX_FOR_YAKCHAT = 'PAGE_COUNT_PAGE_INDEX_FOR_YAKCHAT',
  SET_CONTACT_SOURCESHAREPOINT = 'SET_CONTACT_SOURCESHAREPOINT',
  SET_CONTACT_SOURCEOUTLOOK = "SET_CONTACT_SOURCEOUTLOOK",
  SET_CONTACT_SOURCEACTIVEDIRECTORY = 'SET_CONTACT_SOURCEACTIVEDIRECTORY',
  MESSAGE_SELECTED_CONTACTS_COUNT = "MESSAGE_SELECTED_CONTACTS_COUNT",
  MOBILE_MESSAGE_SECTION = "MOBILE_MESSAGE_SECTION",
  SAS_TOKEN = "SAS_TOKEN",
  WHITE_LABLE_BRAND_THEM = "WHITE_LABLE_BRAND_THEM",
  setWhiteLabelBrandThem = "setWhiteLabelBrandThem",
  REGISTER_USER_TRAIL = "REGISTER_USER_TRAIL",
  OPEN_REGISTRATION_PAGE = "OPEN_REGISTRATION_PAGE",
  SAVE_YAKCHAT_SETTINGS = "SAVE_YAKCHAT_SETTINGS",
  GET_USER_BY_EMAIL = "GET_USER_BY_EMAIL",
  COUNT_CONTACT_MESSAGE = "COUNT_CONTACT_MESSAGE",
  ACCESS_TOKEN_REGISTER = "ACCESS_TOKEN_REGISTER",
  Expire_On="Expire_On",
  Is_Paused="Is_Paused",
  setTextToEmpty="setTextToEmpty",
  Retry_Send="Retry_Send",
  // webexToken="webexToken",
  // webexRToken="webexRToken",
  // webexC="webexC",
  deepLinkRoom="deepLinkRoom",
  companyVersion = "companyVersion",
  TranslationLanguages="TranslationLanguages",
  UserTransLang="UserTransLang",
  selectedroom="selectedroom",
  USER_DETAILS = 'USER_DETAILS',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  SUBSCRIPTION_DETAILS = 'SUBSCRIPTION_DETAILS',
  REMOVE_CONTACT_FROM_LIST = "REMOVE_CONTACT_FROM_LIST",
  SAVED_CONTACT_WITHSHARED_INBOX = "SAVED_CONTACT_WITHSHARED_INBOX",
  SET_DISPLAY_MODE = "SET_DISPLAY_MODE",
  emptyChatInput = "emptyChatInput",
  MESSAGE_FROMSIGNALR = "MESSAGE_FROMSIGNALR",
  From_AddContact = "From_AddContact",
  UPLOAD_CONTACT_INDICATOR = "UPLOAD_CONTACT_INDICATOR",
  From_NewContact = "From_NewContact",
  SET_SPINNER_SIDE = "SET_SPINNER_SIDE",
  OPEN_TRIAL_PAGE = "OPEN_TRIAL_PAGE",
  NEW_BANDWIDTH_USER="NEW_BANDWIDTH_USER",
  openTrialExpiredMessage = "openTrialExpiredMessage",
  OPEN_LOGIN_CONSENT = "OPEN_LOGIN_CONSENT",
  OPEN_SHARED_ERROR = "OPEN_SHARED_ERROR",
  OPEN_COLLAPSE = "OPEN_COLLAPSE",
  SET_REGISTER_THEME = "SET_REGISTER_THEME",
  CHECK_REGISTER_USER = "CHECK_REGISTER_USER",
  CHECK_BANDWIDTH_NUMBER="CHECK_BANDWIDTH_NUMBER",
  SHOW_GROUP_CONTACTS="SHOW_GROUP_CONTACTS",
  SET_NEW_GROUP="SET_NEW_GROUP",
}