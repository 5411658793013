import { Dispatch } from "redux";
import _, { result, zip } from "lodash";
import * as index from "./index";
import * as contactservice from "../../services/Contacts.Service";
import * as usersService from "../../services/Users.Service";
import { store } from "../../../index";
import AuthService from "../../../Config/msalConfig";
import {
  convertListsFromRefsIntoObjects,
  avatarColorMap,
  filterContactListWithRef,
  filterAzureContactListWithRef,
} from "../../commonMethods";
import * as commonMethod from "../../commonMethods";
import { setTimeout } from "timers";
import Moment from "moment";
import { responsiveMap } from "antd/lib/_util/responsiveObserve";
import NewMessage from "../../components/layout/NewMessage/NewMessage";
import { stringify } from "querystring";
import { StringDecoder } from "string_decoder";
import { Empty } from "antd";
import yakchatGroups from "../../components/sharedComponent/yakchatGroups";
import { useHistory, useParams } from "react-router-dom";
import configData from "../../../appsettings.json";
let NewGroupMessageCount = 0;

const likeAudio = new Audio(require(`../../images/notification.mp3`));
const likeAudio1 = new Audio(require(`../../images/like.mp3`));
export const testRedux = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      testName: value,
      type: index.ActionTypes.GET_ALL,
    });
  };
};
var lastTime = new Date().getTime();

// setInterval(function() {
//   ////////debugger;
//   var currentTime = (new Date()).getTime();
//   if (currentTime > (lastTime + 2000*2)) {  // ignore small delays
//     // Probably just woke up!
//     let companyVersion=store.getState().reduxReducer.companyVersion;
//       if(companyVersion===2)
//         {
//           store.dispatch<any>(getInbox_V2(1, store.getState().reduxReducer.sharedInboxData?.currentSelected.email, false, store.getState().reduxReducer.sharedInboxData?.currentSelected.email, "", ""));
//         }
//         else
//         {
//     store.dispatch<any>(getPersonalInbox(1, store.getState().reduxReducer.sharedInboxData?.currentSelected.email, false, "''", "", ""));
//         }
//   }
//   lastTime = currentTime;
// }, 2000);
function waitForIt() {
  setTimeout(() => {}, 100);
}
export const searchConversation_V2 = (
  archiveFlag: any,
  searchText: any,
  groupEmail: any,
  userEmail: any,
  inboxId: any
) => {
  searchText = encodeURIComponent(searchText);
  return async (dispatch: Dispatch) => {
    try {
      var ExpirOnDate =
        store.getState().reduxReducer.expireOn == undefined
          ? store.getState().reduxReducer.loggedInUser.expiresOn
          : store.getState().reduxReducer.expireOn;
      var CurrentDate = new Date().toISOString();
      if (CurrentDate >= ExpirOnDate) {
        store.dispatch<any>({ type: "Is_Paused", isPaused: true });
        await AuthService.RefreshTokens();
      }

      if (store.getState().reduxReducer.isPaused === true) {
        setTimeout(function () {
          waitForIt();
        }, 100);
      } else {
        if (searchText !== "") {
          contactservice
            .searchConversation_V2(archiveFlag, searchText, inboxId)
            .then(async (result: any) => {
              result = await result.json();
              ////////debugger;
              result = result.Data;
              for (let room of result.Rooms.$values) {
                room.avatarColor = avatarColorMap[room.Id % 10];
                for (let participant of room.Participants?.$values)
                  participant.avatarColor = avatarColorMap[participant.Id % 10];
              }
              dispatch({
                roomData: result.Rooms.$values,
                type: index.ActionTypes.setRooms,
              });
              // dispatch<any>(setOpenMessageRoom(result.Rooms.$values));
            });
        } else {
          //////debugger;
          dispatch<any>(
            getPersonalInbox_V2(
              1,
              userEmail,
              archiveFlag,
              groupEmail,
              "searchConversation",
              ""
            )
          );
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
};
export const searchConversation = (
  archiveFlag: any,
  searchText: any,
  groupEmail: any,
  userEmail: any
) => {
  searchText = encodeURIComponent(searchText);
  return async (dispatch: Dispatch) => {
    try {
      var ExpirOnDate =
        store.getState().reduxReducer.expireOn == undefined
          ? store.getState().reduxReducer.loggedInUser.expiresOn
          : store.getState().reduxReducer.expireOn;
      var CurrentDate = new Date().toISOString();
      if (CurrentDate >= ExpirOnDate) {
        store.dispatch<any>({ type: "Is_Paused", isPaused: true });
        await AuthService.RefreshTokens();
      }

      if (store.getState().reduxReducer.isPaused === true) {
        setTimeout(function () {
          waitForIt();
        }, 100);
      } else {
        if (searchText !== "") {
          contactservice
            .searchConversation(archiveFlag, searchText, groupEmail, userEmail)
            .then(async (result: any) => {
              result = await result.json();
              for (let room of result.Rooms.$values) {
                room.avatarColor = avatarColorMap[room.Id % 10];
                for (let participant of room.Participants?.$values)
                  participant.avatarColor = avatarColorMap[participant.Id % 10];
              }
              dispatch({
                roomData: result.Rooms.$values,
                type: index.ActionTypes.setRooms,
              });
              // dispatch<any>(setOpenMessageRoom(result.Rooms.$values));
            });
        } else {
          dispatch<any>(
            getPersonalInbox(
              1,
              userEmail,
              archiveFlag,
              groupEmail,
              "searchConversation",
              ""
            )
          );
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
};
export const newMessageNotificationAlert_V2 = () => {
  return async (dispatch: Dispatch) => {
    ////////debugger;
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      var selectedEmail =
        store.getState().reduxReducer.sharedInboxData.currentSelected.email;
      var userEmail =
        store.getState().reduxReducer.sharedInboxData.currentSelected.email;
      try {
        let type = selectedEmail === userEmail ? "LOGGEDINUSER" : "SHAREDEMAIL";
        switch (type) {
          case "LOGGEDINUSER":
            likeAudio.play();
            break;
          case "SHAREDEMAIL":
            likeAudio1.play();
            break;
          default:
            likeAudio.play();
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
  return;
};
export const newMessageNotificationAlert = () => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      var selectedEmail =
        store.getState().reduxReducer.sharedInboxData.currentSelected.email;
      var userEmail = store.getState().reduxReducer.loggedInUser.Email;
      try {
        let type = selectedEmail === userEmail ? "LOGGEDINUSER" : "SHAREDEMAIL";
        switch (type) {
          case "LOGGEDINUSER":
            likeAudio.play();
            break;
          case "SHAREDEMAIL":
            likeAudio1.play();
            break;
          default:
            likeAudio.play();
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
  return;
};
export const getSasTokenBlob = () => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice.getSasTokenBlobs().then(async (result: any) => {
          result = await result.json();
          if (result) {
            dispatch({ sasToken: result, type: index.ActionTypes.SAS_TOKEN });
            var fileNewDetail = result;
          } else {
            //console.log("no res.accessTokenApi");
          }
        });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const getSasTokenBlob_V2 = () => {
  ////////debugger;
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice.getSasTokenBlobs_V2().then(async (result: any) => {
          result = await result.json();
          result = result.Data;
          if (result) {
            dispatch({ sasToken: result, type: index.ActionTypes.SAS_TOKEN });
            var fileNewDetail = result;
          } else {
            //console.log("no res.accessTokenApi");
          }
        });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const uploadToAzureBlob = (
  roomId: any,
  text: any,
  groupEmail: any,
  userEmail: any,
  file: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var fileBlobDetails: any = {};
        var ArrayfileBlobDetails: any = [];
        file.map((imgfile: any) => {
          fileBlobDetails = {
            fileBasestring: imgfile.blob,
            imageName: imgfile.name,
          };
          ArrayfileBlobDetails.push(fileBlobDetails);
        });
        contactservice
          .uploadImageToBlob(ArrayfileBlobDetails)
          .then(async (result: any) => {
            ////////debugger;
            result = await result.json();
            if (result.Code == 200 && result.Data.$values.length > 0) {
              var fileUploaded: any = {};
              var ArrayfileUploaded: any = [];
              result.Data.$values.map((fileurl: any) => {
                fileUploaded = {
                  uploadedFileUrl: fileurl,
                  fileName: fileurl.split("/").pop(),
                };
                ArrayfileUploaded.push(fileUploaded);
              });
              dispatch<any>(
                queueMessageMMSTwillio(
                  roomId,
                  text,
                  groupEmail,
                  ArrayfileUploaded,
                  userEmail
                )
              );
            } else {
              dispatch<any>(
                setAlertData({
                  className: "danger",
                  text: "Image not uploaded to blob.",
                })
              );
              setTimeout(() => {
                dispatch<any>(setAlertData(null));
              }, 2000);
            }
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const uploadToAzureBlob_V2 = (
  data: any,
  roomId: any,
  text: any,
  groupEmail: any,
  userEmail: any,
  file: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var fileBlobDetails: any = {};
        var ArrayfileBlobDetails: any = [];
        file.map((imgfile: any) => {
          fileBlobDetails = {
            fileBasestring: imgfile.blob,
            imageName: imgfile.name,
          };
          ArrayfileBlobDetails.push(fileBlobDetails);
        });
        contactservice
          .uploadImageToBlob_V2(ArrayfileBlobDetails)
          .then(async (result: any) => {
            ////////debugger;
            result = await result.json();
            if (result.Code == 200 && result.Data.$values.length > 0) {
              var fileUploaded: any = {};
              var ArrayfileUploaded: any = [];
              result.Data.$values.map((fileurl: any) => {
                fileUploaded = {
                  uploadedFileUrl: fileurl,
                  fileName: fileurl.split("/").pop(),
                };
                ArrayfileUploaded.push(fileUploaded);
              });
              dispatch<any>(
                queueMessageMMSTwillio_V2(
                  data,
                  roomId,
                  text,
                  groupEmail,
                  ArrayfileUploaded
                )
              );
            } else {
              dispatch<any>(
                setAlertData({
                  className: "danger",
                  text: "Image not uploaded to blob.",
                })
              );
              setTimeout(() => {
                dispatch<any>(setAlertData(null));
              }, 2000);
            }
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const getOutLookFolderstlist = () => {
  ////////debugger;
  return async (dispatch: Dispatch) => {
    try {
      var ExpirOnDate =
        store.getState().reduxReducer.expireOn == undefined
          ? store.getState().reduxReducer.loggedInUser.expiresOn
          : store.getState().reduxReducer.expireOn;
      var CurrentDate = new Date().toISOString();
      if (CurrentDate >= ExpirOnDate) {
        store.dispatch<any>({ type: "Is_Paused", isPaused: true });
        await AuthService.RefreshTokens();
      }

      if (store.getState().reduxReducer.isPaused === true) {
        setTimeout(function () {
          waitForIt();
        }, 100);
      } else {
        contactservice
          .getOutlookcontactfoldersWithContacts()
          .then(async (result: any) => {
            result = await result.json();
            if (result.error) {
            } else {
              if (result?.value?.length !== 0) {
                if (result?.value?.length > 0) {
                  var FoldersContactList = [
                    { ContactName: "", ConatctFolderId: "", ContactList: [] },
                  ];
                  result.value.map((x: any) => {
                    FoldersContactList.push({
                      ContactName: x.displayName,
                      ConatctFolderId: x.id,
                      ContactList: x.contacts,
                    });
                  });
                  dispatch({
                    OutLookContactListByFolders: FoldersContactList,
                    type: index.ActionTypes.Outlook_Contact_Folders_List,
                  });
                }
              }
            }
          });
      }
    } catch (error) {}
  };
};

export const getOutLookContactlist = (pageIndex: number) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice
          .getoutlookContactList(pageIndex)
          .then(async (result: any) => {
            result = await result?.json();
            // //console.log("resultOutlook",result)
            if (result.value !== undefined) {
              for (let contact of result?.value) {
                var data =
                  contact?.mobilePhone !== undefined &&
                  contact?.mobilePhone !== null &&
                  contact?.mobilePhone !== ""
                    ? contact.mobilePhone.charCodeAt(11) % 10
                    : contact.id.charCodeAt(20) % 10;
                contact.avatarColor =
                  avatarColorMap[Number.isNaN(data) ? 5 : data];
              }
            }
            if (result.error) {
            } else {
              if (
                !store.getState().reduxReducer.OutlookContactList ||
                pageIndex === 1
              ) {
                localStorage.setItem(
                  "OutlookContactsPageCount",
                  JSON.stringify(pageIndex + 1)
                );
                dispatch({
                  outlookcontactlist: result.value,
                  type: index.ActionTypes.Outlook_Contact_List,
                });
                dispatch<any>(getOutLookContactlist(pageIndex + 1));
              } else if (result?.value?.length !== 999) {
                // If the result length is not 999 contacts, then it is the last page.
                localStorage.setItem(
                  "OutlookContactsPageCount",
                  JSON.stringify(pageIndex)
                );
                setTimeout(() => {
                  dispatch({
                    outlookcontactlist: [
                      ...store.getState().reduxReducer.OutlookContactList,
                      ...result.value,
                    ],
                    type: index.ActionTypes.Outlook_Contact_List,
                  });
                }, 500);
              } else {
                localStorage.setItem(
                  "OutlookContactsPageCount",
                  JSON.stringify(pageIndex + 1)
                );
                setTimeout(() => {
                  dispatch({
                    outlookcontactlist: [
                      ...store.getState().reduxReducer.OutlookContactList,
                      ...result.value,
                    ],
                    type: index.ActionTypes.Outlook_Contact_List,
                  });
                  dispatch<any>(getOutLookContactlist(pageIndex + 1));
                }, 500);
              }
            }
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};

export const editOutLookContact = (id: any, data: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice
          .editOutlookContact(id, data)
          .then(async (result: any) => {
            result = await result.json();
            if (result && result.id) {
              dispatch({
                contact: result,
                type: index.ActionTypes.UpdateOneOutlook_Contact_List,
              });
              dispatch({
                contact: { ...result, id },
                type: index.ActionTypes.UpdateOne_USER_LIST,
              });
              dispatch<any>(
                setContactSelected_OpenContact({ ...data, id, type: "Outlook" })
              );
              dispatch<any>(
                setAlertData({ className: "", text: "Contact has been saved." })
              );
              setTimeout(() => {
                dispatch<any>(setAlertData(null));
              }, 2000);
            }
          });
      } catch (error) {
        //console.log(error);
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error." })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      }
    }
  };
};

export const searchOutLookFolderstlist = (searchQuery: string) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice.getOutlookcontactfolders().then(async (result: any) => {
          result = await result.json();
          if (result.error) {
          } else {
            if (result?.value?.length !== 0) {
              if (result?.value?.length > 0) {
                var FoldersContactList = [
                  { ContactName: "", ConatctFolderId: "", ContactList: [] },
                ];
                result.value.map((x: any) =>
                  contactservice
                  .getOutlookcontactlistByContactFolders(x.id,1,searchQuery)
                    .then(async (result: any) => {
                      result = await result.json();
                      FoldersContactList.push({
                        ContactName: x.displayName,
                        ConatctFolderId: x.id,
                        ContactList: result.value,
                      });
                    })
                );
                setTimeout(function () {
                  dispatch({
                    OutLookContactListByFolders: FoldersContactList,
                    type: index.ActionTypes.Outlook_Contact_Folders_List,
                  }); 
                }, 500);        
              
              }
            }
          }
        });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};

export const searchoutlookContactList = (
  searchQuery: string,
  pageIndex: number
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice
          .searchoutlookContactList(searchQuery, pageIndex)
          .then(async (result: any) => {
            result = await result.json();
            for (let contact of result.value) {
              contact.avatarColor =
                avatarColorMap[contact.id.charCodeAt(10) % 10];
            }
            if (result.error) {
            } else {
              if (
                !store.getState().reduxReducer.OutlookContactList ||
                pageIndex === 1
              ) {
                localStorage.setItem(
                  "OutlookContactsPageCount",
                  JSON.stringify(pageIndex + 1)
                );
                dispatch({
                  outlookcontactlist: result.value,
                  type: index.ActionTypes.Outlook_Contact_List,
                });
              } else if (result?.value?.length !== 10) {
                localStorage.setItem(
                  "OutlookContactsPageCount",
                  JSON.stringify(pageIndex)
                );
                dispatch({
                  outlookcontactlist: [
                    ...store.getState().reduxReducer.OutlookContactList,
                    ...result.value,
                  ],
                  type: index.ActionTypes.Outlook_Contact_List,
                });
              } else {
                localStorage.setItem(
                  "OutlookContactsPageCount",
                  JSON.stringify(pageIndex + 1)
                );
                dispatch({
                  outlookcontactlist: [
                    ...store.getState().reduxReducer.OutlookContactList,
                    ...result.value,
                  ],
                  type: index.ActionTypes.Outlook_Contact_List,
                });
              }
            }
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};

export const getazurereusers = () => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        usersService.getazureusers().then(async (result: any) => {
          result = await result.json();
          if (result.value !== undefined) {
            for (let contact of result?.value) {
              contact.avatarColor =
                avatarColorMap[contact.id.charCodeAt(0) % 10];
            }
            dispatch({
              UserList: result.value,
              type: index.ActionTypes.USER_LIST,
            });
          }
        });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};

export const editAzureUsers = (id: any, data: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        usersService.editUser(id, data).then(async (result: any) => {
          result = await result.json();
          if (result && result.error) {
            dispatch<any>(
              setAlertData({ className: "danger", text: "There was an error." })
            );
            setTimeout(() => {
              dispatch<any>(setAlertData(null));
            }, 2000);
            return;
          }
          dispatch({
            contact: { ...data, id },
            type: index.ActionTypes.UpdateOne_USER_LIST,
          });
          dispatch<any>(
            setContactSelected_OpenContact({ ...data, id, type: "Users" })
          );
          dispatch<any>(
            setAlertData({ className: "", text: "Contact has been saved." })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
        });
      } catch (error) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error." })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        return;
      }
    }
  };
};

export const searchAzureUsers = (searchFilter: string) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        usersService
          .searchAzureUsers(searchFilter)
          .then(async (result: any) => {
            result = await result.json();
            for (let contact of result.value) {
              contact.avatarColor =
                avatarColorMap[contact.id.charCodeAt(0) % 10];
            }
            dispatch({
              UserList: result.value,
              type: index.ActionTypes.USER_LIST,
            });
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};

export const listAzureGroups = () => {
  return async (dispatch: Dispatch) => {
    try {
      var ExpirOnDate =
        store.getState().reduxReducer.expireOn == undefined
          ? store.getState().reduxReducer.loggedInUser.expiresOn
          : store.getState().reduxReducer.expireOn;
      var CurrentDate = new Date().toISOString();
      if (CurrentDate >= ExpirOnDate) {
        store.dispatch<any>({ type: "Is_Paused", isPaused: true });
        await AuthService.RefreshTokens();
      }

      if (store.getState().reduxReducer.isPaused === true) {
        setTimeout(function () {
          waitForIt();
        }, 100);
      } else {
        // https://graph.microsoft.com/v1.0/groups?$expand=members
        usersService.listAzureGroupsMembers().then(async (result: any) => {
          result = await result.json();
          result = result.value;
          ////////debugger;
          dispatch({
            azureGroups: result,
            type: index.ActionTypes.setAzureGroups,
          });
          dispatch<any>(setSpinnerInSideBar(false));
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };
};
export const getSharePointContacts = (pageIndex: number) => {
  var FoldersContactList:
    | {
        ContactName: "";
        ConatctFolderId: "";
        ContactList: [{ id: ""; title: ""; displayName: ""; mobilePhone: "" }];
      }
    | any = [];
  return async (dispatch: Dispatch) => {
    try {
      let lists: any = await contactservice.getsharepointContactsLists(
        pageIndex
      );
      lists = await lists.json();
      if (lists?.value !== undefined) {
        for (let contact of lists?.value) {
          var data =
            contact.mobilePhone !== undefined &&
            contact.mobilePhone !== null &&
            contact.mobilePhone !== ""
              ? contact.mobilePhone.charCodeAt(11) % 10
              : contact.id.charCodeAt(20) % 10;
          contact.avatarColor = avatarColorMap[Number.isNaN(data) ? 5 : data];
        }
      }
      // here we filter and retrieve the lists that are contacts,

      lists = lists.value?.filter((x: any) => x.list.template === "contacts");
      if (lists !== undefined) {
        lists = lists.sort((a: any, b: any) => {
          var nameA = a?.displayName.toUpperCase();
          var nameB = b?.displayName.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      }

      // the variable lists contains the lists retrieved from /sites/root/lists/
      let FoldersContactList: any = [];

      if (lists !== undefined) {
        //console.log("list", lists.length);
        for (let i = 0; i < lists?.length; i++) {
          const list = lists[i];
          let contactFolders: any =
            await contactservice.getsharepointContactsListItems(
              list.displayName,
              pageIndex
            );
          contactFolders = await contactFolders.json();

          let ContactsList: any = [];
          for (let j = 0; j < contactFolders?.value?.length; j++) {
            const contactFolder: any = contactFolders?.value[j];
            let contacts: any =
              await contactservice.getsharepointContactsListItemsItem(
                contactFolder.id,
                list.displayName
              );
            contacts = await contacts.json();
            ContactsList.push({
              id: contacts?.fields?.id,
              title: contacts?.fields?.Title,
              displayName: contacts?.fields?.FullName
                ? contacts.fields.FullName
                : contacts.fields.FirstName
                ? contacts.fields.FirstName
                : "" + " " + contacts.fields.LastName
                ? contacts.fields.LastName
                : "",
              mobilePhone:
                contacts?.fields?.CellPhone !== undefined
                  ? contacts.fields.CellPhone
                  : contacts.fields.CellPhone !== undefined
                  ? contacts.fields.CellPhone
                  : "",
              listId: list.displayName,
            });
          }
          ContactsList = ContactsList?.sort((a: any, b: any) => {
            var nameA =
              a?.displayName?.toUpperCase() !== undefined
                ? a?.displayName?.toUpperCase()
                : a?.title?.toUpperCase;
            var nameB =
              b?.displayName?.toUpperCase() !== undefined
                ? b?.displayName?.toUpperCase()
                : b?.title?.toUpperCase;
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });
          for (let contact of ContactsList) {
            contact.avatarColor = avatarColorMap[parseInt(contact?.id) % 10];
          }
          FoldersContactList.push({
            ContactName: list.displayName,
            ConatctFolderId: list.id,
            ContactList: ContactsList,
          });

          if (list.length === FoldersContactList && pageIndex !== 1) {
            dispatch({
              SharePointContactList: FoldersContactList,
              type: index.ActionTypes.Share_Point_Contact_List,
            });
            dispatch<any>(setSpinnerInSideBar(false));
          }
          // dispatch<any>(setSpinnerInSideBar(false));
        }
        const clone = _.cloneDeep(FoldersContactList);
        dispatch({
          SharePointContactList: clone,
          type: index.ActionTypes.Share_Point_Contact_List,
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };
};
export const searchSharePointContacts = (
  pageIndex: number,
  searchQuery: string
) => {
  var FoldersContactList = [
    {
      ContactName: "",
      ConatctFolderId: "",
      ContactList: [
        {
          id: "",
          title: "",
          displayName: "",
          mobilePhone: "",
          avatarColor: "",
        },
      ],
    },
  ];
  return async (dispatch: Dispatch) => {
    try {
      contactservice
        .getsharepointContactsLists(pageIndex)
        .then(async (result: any) => {
          result = await result.json();
          result = result.value.filter(
            (x: any) => x.list.template === "contacts"
          );
          // FoldersContactList=result;

          result.map((y: any) =>
            contactservice
              .searchSharepointContactsItems(
                y.displayName,
                searchQuery,
                pageIndex
              )
              .then(async (result: any) => {
                result = await result.json();
                var ContactsList = [
                  {
                    id: "",
                    title: "",
                    displayName: "",
                    mobilePhone: "",
                    avatarColor: "",
                  },
                ];
                result?.value?.map((x: any) => {
                  ContactsList.push({
                    id: x.fields.id,
                    title: x.fields.Title,
                    displayName:
                      x.fields.FirstName !== undefined
                        ? x.fields.FirstName
                        : "" + "" + x.fields.LastName !== undefined
                        ? x.fields.LastName
                        : null,
                    mobilePhone:
                      x.fields.CellPhone !== undefined
                        ? x.fields.CellPhone
                        : x.fields.CellPhone !== undefined
                        ? x.fields.CellPhone
                        : "",
                    avatarColor: avatarColorMap[parseInt(x.fields.id) % 10],
                  });
                });

                ContactsList.sort((a: any, b: any) => {
                  var nameA =
                    a?.displayName?.toUpperCase() !== undefined
                      ? a?.displayName?.toUpperCase()
                      : a?.title?.toUpperCase;
                  var nameB =
                    b?.displayName?.toUpperCase() !== undefined
                      ? b?.displayName?.toUpperCase()
                      : b?.title?.toUpperCase;
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                });
                var obj = {
                  ContactName: y.displayName,
                  ConatctFolderId: y.id,
                  ContactList: ContactsList,
                };
                FoldersContactList = FoldersContactList?.sort(
                  (a: any, b: any) => {
                    var nameA =
                      a?.ContactName?.toUpperCase() !== undefined
                        ? a?.displayName?.toUpperCase()
                        : a?.title?.toUpperCase;
                    var nameB =
                      b?.ContactName?.toUpperCase() !== undefined
                        ? b?.displayName?.toUpperCase()
                        : b?.title?.toUpperCase;
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  }
                );
                FoldersContactList = [...FoldersContactList, obj];
                dispatch({
                  SharePointContactList: FoldersContactList,
                  type: index.ActionTypes.Share_Point_Contact_List,
                });
              })
          );
          dispatch<any>(showCollapse(true));
          setTimeout(() => {
            dispatch<any>(showCollapse(true));
            dispatch<any>(setSpinnerInSideBar(false));
          }, 500);
        });
      // dispatch<any>(setSpinnerInSideBar(false));
    } catch (error) {
      //console.log(error);
    }
  };
};
// const someAsyncFunc = async (item: any, obj: any) => {
//   await contactservice
//     .getsharepointContactsListItemsItem(item.id, obj.displayName)
//     .then(async (result: any) => {
//       result = await result.json();
//       return result;
//     });
// };
export const editSharePointContact = (id: any, data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      contactservice
        .editSharePointContact(id.itemId, id.listId, data)
        .then(async (result: any) => {
          result = await result.json();
          if (result && result.fields) {
            const newContact = {
              id: id.itemId,
              displayName: result.fields.FirstName,
              title: result.fields.Title,
              mobilePhone: result.fields.CellPhone,
              listId: id.listId,
            };
            dispatch({
              contact: newContact,
              id,
              type: index.ActionTypes.UpdateOne_Share_Point_Contact,
            });
            dispatch<any>(
              setContactSelected_OpenContact({
                ...newContact,
                type: "Share Point",
              })
            );
            dispatch<any>(
              setAlertData({ className: "", text: "Contact has been saved." })
            );
            setTimeout(() => {
              dispatch<any>(setAlertData(null));
            }, 2000);
          }
        });
    } catch (error) {
      //console.log(error);
      dispatch<any>(
        setAlertData({ className: "danger", text: "There was an error." })
      );
      setTimeout(() => {
        dispatch<any>(setAlertData(null));
      }, 2000);
      return;
    }
  };
};
export const getContactsByListId = (
  group: any,
  lists: any,
  calledFrom: any,
  previousContacts: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var openlocalGroupcontact: any;
        if (group.ListData !== undefined) {
          group.ListData.localContacts.$values = [];
        }
        var ListId =
          group.ListData !== undefined ? group.ListData.Id : group.Id;
        let newOpenGroup = store.getState().reduxReducer.openGroup;
        if (group.localContacts !== undefined) {
          openlocalGroupcontact = group.localContacts.$values.length !== 0;
        } else {
          openlocalGroupcontact = group.contactCount !== 0;
        }
        if (ListId) {
          if (store.getState().reduxReducer.openGroup !== undefined) {
            if (store.getState().reduxReduceropenGroup.Id !== ListId) {
              dispatch<any>({ type: "setGroupCurrentlyEditing", newOpenGroup });
            } else {
              dispatch<any>({ type: "setGroupCurrentlyEditing", group });
            }
          } else {
            dispatch<any>({ type: "setGroupCurrentlyEditing", group });
          }
        }
        if (ListId) {
          contactservice
            .getContactsByListId(ListId)
            .then(async (result: any) => {
              var contacts: any = await result.json();
              var filteredContactList = await filterContactListWithRef(
                contacts
              );
              var listFilter = lists?.find(
                (x: any) => x.ListData?.Id === group.ListData?.Id
              );
              filteredContactList?.forEach((element: any) => {
                listFilter.ListData?.localContacts.$values.push(element);
              });
              dispatch<any>(storeActualContactsInList(filteredContactList));
              if (calledFrom === "RemoveContact") {
                var localContactsList = lists?.find((y: any) => {
                  return y.ListData !== undefined
                    ? y.ListData.Id === group.ListData?.Id
                    : y.Id === group?.Id;
                });
                dispatch<any>({
                  type: "updateListOfContactsInThisList",
                  Id: group.Id !== undefined ? group.Id : group.ListData?.Id,
                  newContacts:
                    localContactsList.ListData !== undefined
                      ? localContactsList?.ListData.localContacts
                      : localContactsList.localContacts,
                });
                const arr1 = lists
                  ? lists
                      ?.find((y: any) => y.ListData?.Id === group.ListData?.Id)
                      .ListData?.localContacts.$values.map(
                        (x: any) => x.ContactId
                      )
                  : previousContacts.map((x: any) => x.ContactId);
                const arr2 = listFilter.ListData?.localContacts.$values.map(
                  (x: any) => x.ContactId
                );
                if (
                  arr1?.length === arr2?.length &&
                  _.xor(arr1, arr2)?.length === 0
                ) {
                  dispatch<any>(setSelectedContacts(filteredContactList));
                  dispatch<any>({ type: "setGroupCurrentlyEditing", group });
                  dispatch<any>(
                    setAlertData({
                      className: "",
                      text: "The group has been saved.",
                    })
                  );
                  setTimeout(() => {
                    dispatch<any>(setAlertData(null));
                  }, 2000);
                } else {
                  dispatch<any>(
                    setAlertData({
                      className: "danger",
                      text: "Some contacts were not saved!",
                    })
                  );
                  setTimeout(() => {
                    dispatch<any>(setAlertData(null));
                  }, 2000);
                }
              } else if (calledFrom === "") {
                if (openlocalGroupcontact) {
                  dispatch<any>({
                    type: "updateListOfContactsInThisList",
                    Id: group?.ListData?.Id,
                    newContacts: lists?.find(
                      (y: any) => y?.ListData?.Id === group?.ListData?.Id
                    ).ListData?.localContacts,
                  });
                }
                const arr1 = lists
                  ? lists
                      ?.find(
                        (y: any) =>
                          (y?.ListData !== undefined
                            ? y?.ListData.Id
                            : y.Id) ===
                          (group?.ListData !== undefined
                            ? group?.ListData?.Id
                            : group.Id)
                      )
                      .ListData?.localContacts?.$values?.map(
                        (x: any) => x.ContactId
                      )
                  : previousContacts?.map((x: any) => x.ContactId);
                const arr2 = listFilter?.ListData?.localContacts?.$values.map(
                  (x: any) => x.ContactId
                );
                if (
                  arr1?.length === arr2?.length &&
                  _.xor(arr1, arr2)?.length === 0
                ) {
                  dispatch<any>(setSelectedContacts(filteredContactList));
                  // dispatch<any>({ type: "setGroupCurrentlyEditing", group });
                }
              } else {
                dispatch<any>({
                  type: index.ActionTypes.setYakchatLists,
                  lists,
                });
                dispatch<any>(setSelectedContacts(filteredContactList));

                dispatch<any>({ type: "setGroupCurrentlyEditing", group });
                dispatch<any>(setTabOpened("viewgroup"));
              }
            });
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const getContactsByListId_V2 = (
  group: any,
  lists: any,
  calledFrom: any,
  previousContacts: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        var openlocalGroupcontact: any;
        if (group.ListData !== undefined) {
          group.ListData.localContacts.$values = [];
        }
        var ListId =
          group.ListData !== undefined
            ? group.ListData.Id
            : group.Id !== undefined
            ? group.Id
            : group;
        let newOpenGroup = store.getState().reduxReducer.openGroup;
        if (group.localContacts !== undefined) {
          openlocalGroupcontact = group.localContacts.$values.length !== 0;
        } else {
          openlocalGroupcontact = group.contactCount !== 0;
        }
        if (ListId) {
          if (store.getState().reduxReducer.openGroup !== undefined) {
            if (store.getState().reduxReduceropenGroup.Id !== ListId) {
              dispatch<any>({ type: "setGroupCurrentlyEditing", newOpenGroup });
            } else {
              dispatch<any>({ type: "setGroupCurrentlyEditing", group });
            }
          } else {
            dispatch<any>({ type: "setGroupCurrentlyEditing", group });
          }
        }
        if (ListId) {
          ////////debugger;
          contactservice
            .getContactsByListId_V2(ListId)
            .then(async (result: any) => {
              var contacts: any = await result.json();
              var filteredContactList = await filterContactListWithRef(
                contacts.Data
              );
              var listFilter = lists?.find(
                (x: any) => x.ListData?.Id === group.ListData?.Id
              );
              filteredContactList?.forEach((element: any) => {
                listFilter.ListData?.localContacts.$values.push(element);
              });
              dispatch<any>(storeActualContactsInList(filteredContactList));
              if (calledFrom === "RemoveContact") {
                var localContactsList = lists?.find((y: any) => {
                  return y.ListData !== undefined
                    ? y.ListData.Id === group.ListData?.Id
                    : y.Id === group?.Id;
                });
                dispatch<any>({
                  type: "updateListOfContactsInThisList",
                  Id: group.Id !== undefined ? group.Id : group.ListData?.Id,
                  newContacts:
                    localContactsList.ListData !== undefined
                      ? localContactsList?.ListData.localContacts
                      : localContactsList.localContacts,
                });
                const arr1 = lists
                  ? lists
                      ?.find((y: any) => y.ListData?.Id === group.ListData?.Id)
                      .ListData?.localContacts.$values.map(
                        (x: any) => x.ContactId
                      )
                  : previousContacts.map((x: any) => x.ContactId);
                const arr2 = listFilter.ListData?.localContacts.$values.map(
                  (x: any) => x.ContactId
                );
                if (
                  arr1?.length === arr2?.length &&
                  _.xor(arr1, arr2)?.length === 0
                ) {
                  dispatch<any>(setSelectedContacts(filteredContactList));
                  dispatch<any>({ type: "setGroupCurrentlyEditing", group });
                  // dispatch<any>(
                  //   setAlertData({
                  //     className: "",
                  //     text: "The group has been saved.",
                  //   })
                  // );
                  // setTimeout(() => {
                  //   dispatch<any>(setAlertData(null));
                  // }, 2000);
                } else {
                  dispatch<any>(
                    setAlertData({
                      className: "danger",
                      text: "Some contacts were not saved!",
                    })
                  );
                  setTimeout(() => {
                    dispatch<any>(setAlertData(null));
                  }, 2000);
                }
              } else if (calledFrom === "") {
                if (openlocalGroupcontact) {
                  dispatch<any>({
                    type: "updateListOfContactsInThisList",
                    Id: group?.ListData?.Id,
                    newContacts: lists?.find(
                      (y: any) => y?.ListData?.Id === group?.ListData?.Id
                    ).ListData?.localContacts,
                  });
                }
                const arr1 = lists
                  ? lists
                      ?.find(
                        (y: any) =>
                          (y?.ListData !== undefined
                            ? y?.ListData.Id
                            : y.Id) ===
                          (group?.ListData !== undefined
                            ? group?.ListData?.Id
                            : group.Id)
                      )
                      .ListData?.localContacts?.$values?.map(
                        (x: any) => x.ContactId
                      )
                  : previousContacts?.map((x: any) => x.ContactId);
                const arr2 = listFilter?.ListData?.localContacts?.$values.map(
                  (x: any) => x.ContactId
                );
                if (
                  arr1?.length === arr2?.length &&
                  _.xor(arr1, arr2)?.length === 0
                ) {
                  dispatch<any>(setSelectedContacts(filteredContactList));
                  // dispatch<any>({ type: "setGroupCurrentlyEditing", group });
                }
              } else {
                dispatch<any>({
                  type: index.ActionTypes.setYakchatLists,
                  lists,
                });
                dispatch<any>(setSelectedContacts(filteredContactList));

                dispatch<any>({ type: "setGroupCurrentlyEditing", group });
                dispatch<any>(setTabOpened("viewgroup"));
              }
            });
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const getAzureContactsByListId = (
  group: any,
  lists: any,
  calledFrom: any,
  previousContacts: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var openlocalGroupcontact: any;
        // if (group.members !== undefined) {
        //   group.ListData.localContacts.$values = [];
        // }
        var ListId =
          group.ListData !== undefined ? group.ListData.id : group.id;
        let newOpenGroup = store.getState().reduxReducer.openGroup;
        // if (group.localContacts !== undefined) {
        //   openlocalGroupcontact = group.localContacts.$values.length !== 0;
        // } else {
        //   openlocalGroupcontact = group.contactCount !== 0;
        // }
        if (ListId) {
          if (store.getState().reduxReducer.openGroup !== undefined) {
            if (store.getState().reduxReduceropenGroup.id !== ListId) {
              dispatch<any>({ type: "setGroupCurrentlyEditing", newOpenGroup });
            } else {
              dispatch<any>({ type: "setGroupCurrentlyEditing", group });
            }
          } else {
            dispatch<any>({ type: "setGroupCurrentlyEditing", group });
          }
        }
        if (ListId) {
          usersService.listGroupMembers(ListId).then(async (result: any) => {
            var contacts: any = await result.json();
            var filteredContactList = await filterAzureContactListWithRef(
              contacts
            );
            var listFilter = lists?.find((x: any) => x.id === group.id);
            // filteredContactList?.forEach((element: any) => {
            //   listFilter.members.push(element);
            // });
            dispatch<any>(storeActualContactsInList(filteredContactList));
            // added by maksud
            dispatch<any>(setSelectedContacts(filteredContactList));
            ////////debugger;
            if (calledFrom === "RemoveContact") {
              var localContactsList = lists?.find((y: any) => {
                return y !== undefined
                  ? y.id === group?.id
                  : y.Id === group?.id;
              });
              dispatch<any>({
                type: "updateListOfContactsInThisList",
                id: group.id !== undefined ? group.id : group?.id,
                newContacts:
                  localContactsList !== undefined
                    ? localContactsList?.localContacts
                    : localContactsList.localContacts,
              });
              const arr1 = lists
                ? lists
                    ?.find((y: any) => y?.id === group?.id)
                    ?.localContacts.values.map((x: any) => x.ContactId)
                : previousContacts.map((x: any) => x.ContactId);
              const arr2 = listFilter?.members.map((x: any) => x.ContactId);
              if (
                arr1?.length === arr2?.length &&
                _.xor(arr1, arr2)?.length === 0
              ) {
                dispatch<any>(setSelectedContacts(filteredContactList));
                dispatch<any>({ type: "setGroupCurrentlyEditing", group });
                dispatch<any>(
                  setAlertData({
                    className: "",
                    text: "The group has been saved.",
                  })
                );
                setTimeout(() => {
                  dispatch<any>(setAlertData(null));
                }, 2000);
              } else {
                dispatch<any>(
                  setAlertData({
                    className: "danger",
                    text: "Some contacts were not saved!",
                  })
                );
                setTimeout(() => {
                  dispatch<any>(setAlertData(null));
                }, 2000);
              }
            } else if (calledFrom === "") {
              if (openlocalGroupcontact) {
                dispatch<any>({
                  type: "updateListOfContactsInThisList",
                  Id: group?.id,
                  newContacts: lists?.find((y: any) => y?.id === group?.id)
                    ?.localContacts,
                });
              }
              const arr1 = lists
                ? lists
                    ?.find(
                      (y: any) =>
                        (y !== undefined ? y?.id : y.id) ===
                        (group !== undefined ? group?.id : group.id)
                    )
                    .ListData?.members?.map((x: any) => x.ContactId)
                : previousContacts?.map((x: any) => x.ContactId);
              const arr2 = listFilter?.members?.map((x: any) => x.ContactId);
              if (
                arr1?.length === arr2?.length &&
                _.xor(arr1, arr2)?.length === 0
              ) {
                dispatch<any>(setSelectedContacts(filteredContactList));
              }
            } else {
              dispatch<any>({ type: index.ActionTypes.setAzureGroups, lists });
              dispatch<any>(setSelectedContacts(filteredContactList));
              dispatch<any>({ type: "setGroupCurrentlyEditing", group });
              dispatch<any>(setTabOpened("viewgroup"));
            }
          });
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
};

export const getyakchatContacts = (pageindex: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        var sharedInboxDataEmails =
          store.getState().reduxReducer.setSharedEmails !== undefined
            ? store
                .getState()
                .reduxReducer.setSharedEmails?.map((a: any) => a?.Email)
                .join(",")
            : store
                .getState()
                .reduxReducer.setSharedEmails?.map((a: any) => a?.Email)
                .join(",");
        //var userEmail = store.getState().reduxReducer.loggedInUser.Email + "," + sharedInboxDataEmails!==undefined?sharedInboxDataEmails:"";
        //Added by Maksud
        var userEmail =
          store.getState().reduxReducer.loggedInUser.Email +
          "," +
          (sharedInboxDataEmails !== undefined ? sharedInboxDataEmails : "");
        contactservice
          .getyakchatcontactlist(pageindex, userEmail)
          ?.then(async (result) => {
            var data: any = await result.json();
            if (data.ContactLists !== undefined) {
              data.ContactLists = data.ContactLists
                ? data.ContactLists?.$values
                  ? data?.ContactLists?.$values
                  : data?.ContactLists
                : data?.ContactLists;
              for (let contact of data.ContactLists) {
                contact.avatarColor = avatarColorMap[contact.ContactId % 10];
              }
              localStorage.setItem("YakchaTContactPageCount", data.PageCount);
              dispatch<any>(
                currentPageIndexForYakChat(data.CurrentPageIndex + 1)
              );
              dispatch<any>(nextPageIndexForYakChat(data.PageCount));
              if (pageindex === 1) {
                dispatch({
                  YakchatContactlist: data.ContactLists,
                  type: index.ActionTypes.Yak_Chat_Contact_List,
                });
                // dispatch<any>(setSpinnerInSideBar(false));
              } else {
                var previousRecords =
                  store.getState().reduxReducer.YakChatContactList;
                var newRecord = data.ContactLists;
                var valueArr = newRecord.map(function (item: any) {
                  return item.ContactId;
                });
                var isDuplicate = previousRecords.map((val: any) => {
                  return val.ContactId;
                });

                if (valueArr.some((item: any) => isDuplicate.includes(item))) {
                  dispatch({
                    YakchatContactlist: previousRecords,
                    type: index.ActionTypes.Yak_Chat_Contact_List,
                  });
                } else {
                  dispatch({
                    YakchatContactlist: previousRecords.concat(newRecord),
                    type: index.ActionTypes.Yak_Chat_Contact_List,
                  });
                }
                // dispatch<any>(setSpinnerInSideBar(false));
              }
              var contactId = data.ContactLists[0];
              dispatch<any>(
                setContactSelected_OpenContact({
                  ...contactId,
                  type: "YakChat",
                })
              );
              setTimeout(() => {
                dispatch<any>(setSpinnerInSideBar(false));
              }, 100);
            }
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const getyakchatContacts_V2 = (pageindex: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        contactservice
          .getyakchatcontactlist_V2(pageindex)
          ?.then(async (result) => {
            var data: any = await result.json();
            //////debugger;
            var object = {};
            var value: any = [];
            data.Data?.ContactLists?.$values.forEach((element: any) => {
              object = {
                InboxId:
                  element.contactsPermissions?.$values.length <= 0
                    ? 0
                    : element.contactsPermissions?.$values[0].InboxId,
                CompanyId: element.CompanyId,
                ContactEmail: element.ContactEmail,
                ContactId: element.ContactId,
                CreatedBy: element.CreatedBy,
                CreatedDateTime: element.CreatedDateTime,
                ForeName: element.ForeName,
                GroupEmail: element.GroupEmail,
                OwnerId: element.OwnerId,
                PhoneNumber: element.PhoneNumber,
                SureName: element.SureName,
                UserEmail: element.UserEmail,
                contactLists: element.contactLists,
              };
              value.push(object);
            });
            data = data.Data;
            var contactdata: any = value;
            if (data !== undefined && data !== null) {
              data.ContactLists = data.ContactLists
                ? data.ContactLists?.$values
                  ? data?.ContactLists?.$values
                  : data?.ContactLists
                : data?.ContactLists;
              for (let contact of contactdata) {
                contact.avatarColor = avatarColorMap[contact.ContactId % 10];
              }
              localStorage.setItem("YakchaTContactPageCount", data.PageCount);
              dispatch<any>(
                currentPageIndexForYakChat(data.CurrentPageIndex + 1)
              );
              dispatch<any>(nextPageIndexForYakChat(data.PageCount));
              if (pageindex === 1) {
                dispatch({
                  YakchatContactlist: contactdata,
                  type: index.ActionTypes.Yak_Chat_Contact_List,
                });
                // dispatch<any>(setSpinnerInSideBar(false));
              } else {
                var previousRecords =
                  store.getState().reduxReducer.YakChatContactList;
                var newRecord = contactdata;
                var valueArr = newRecord.map(function (item: any) {
                  return item.ContactId;
                });
                var isDuplicate = previousRecords.map((val: any) => {
                  return val.ContactId;
                });

                if (valueArr.some((item: any) => isDuplicate.includes(item))) {
                  dispatch({
                    YakchatContactlist: previousRecords,
                    type: index.ActionTypes.Yak_Chat_Contact_List,
                  });
                } else {
                  dispatch({
                    YakchatContactlist: previousRecords.concat(newRecord),
                    type: index.ActionTypes.Yak_Chat_Contact_List,
                  });
                }
                // dispatch<any>(setSpinnerInSideBar(false));
              }
              var contactId = contactdata[0];
              dispatch<any>(
                setContactSelected_OpenContact({
                  ...contactId,
                  type: "YakChat",
                })
              );
              setTimeout(() => {
                dispatch<any>(setSpinnerInSideBar(false));
              }, 100);
            }
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const searchYakChatContacts = (pageindex: any, searchFilter: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        contactservice
          .searchyakchatcontactlist(pageindex, searchFilter)
          .then(async (result: any) => {
            result = await result.json();
            var Newlist: any;
            var Contactlist: any;
            dispatch<any>(setSpinnerInSideBar(false));
            if (!result || !result.ContactLists) {
              localStorage.setItem("yakchatcontactPrevlist", "");
              dispatch({
                YakchatContactlist: [],
                type: index.ActionTypes.Yak_Chat_Contact_List,
              });
              return;
            }
            dispatch<any>(setSpinnerInSideBar(false));
            result.ContactLists = result.ContactLists.$values
              ? result.ContactLists.$values
              : result.ContactLists;
            for (let contact of result.ContactLists) {
              contact.avatarColor = avatarColorMap[contact.ContactId % 10];
            }
            if (pageindex === 1) {
              localStorage.setItem(
                "yakchatcontactPrevlist",
                JSON.stringify(
                  result.ContactLists.$values
                    ? result.ContactLists.$values
                    : result.ContactLists
                )
              );
              dispatch({
                YakchatContactlist: result.ContactLists.$values
                  ? result.ContactLists.$values
                  : result.ContactLists,
                type: index.ActionTypes.Yak_Chat_Contact_List,
              });
            } else {
              if (pageindex > 1) {
                var GetContactList = localStorage.getItem(
                  "yakchatcontactPrevlist"
                );
                if (typeof GetContactList === "string") {
                  Newlist = JSON.parse(GetContactList);
                }
                Contactlist = [
                  result?.ContactLists.$values
                    ? result.ContactLists.$values
                    : result.ContactLists,
                ];
                Newlist?.forEach((element: any) => {
                  Contactlist[0].push(element);
                });
                localStorage.setItem(
                  "yakchatcontactPrevlist",
                  JSON.stringify(Contactlist[0])
                );
                if (Contactlist[0]?.length > 0) {
                  Contactlist[0] = Contactlist[0].sort((a: any, b: any) => {
                    var nameA = a?.ForeName.toUpperCase();
                    var nameB = b?.ForeName.toUpperCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  });
                }
                dispatch({
                  YakchatContactlist: Contactlist[0],
                  type: index.ActionTypes.Yak_Chat_Contact_List,
                });
              }
            }
            dispatch<any>(currentPageIndexForYakChat(pageindex + 1));
            localStorage.setItem("YakchaTContactPageCount", result.PageCount);
            setTimeout(() => {
              dispatch<any>(setSpinnerInSideBar(false));
            }, 2000);
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const EditYakChatontact = (Contactid: any, ListId: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        let x: any = await contactservice.AddContactListsLocalContact(
          Contactid,
          ListId
        );
        x = await x.json();
        x = convertListsFromRefsIntoObjects(x);
        const list = x.find((y: any) => y.Id === ListId);
        if (!list) return;
        var GetLocalContacts = x.find(
          (y: any) => y.Id === ListId
        ).localContacts;
        for (let contact of GetLocalContacts.$values) {
          contact.avatarColor = avatarColorMap[contact.ContactId % 10];
        }
        dispatch<any>({
          type: "updateListOfContactsInThisList",
          Id: ListId,
          newContacts: GetLocalContacts,
        });
        dispatch<any>(
          setAlertData({ className: "", text: "The group has been saved." })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      } catch (error) {
        //console.log(error);
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      }
    }
  };
};
export const editYakChatContact = (id: any, data: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      if (data.ownerEmail != undefined) {
        if (
          data.ownerEmail !== store.getState().reduxReducer.loggedInUser.Email
        ) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text:
                "You cannot edit a contact that you do not own.  This contact is owned by <" +
                data.ownerEmail +
                ">.",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
          dispatch<any>(setTabOpened(""));
          dispatch<any>(setTabOpened("contactopen"));
          return;
        }
      }
      var numberToBeSavedInDatabase;
      if (data.Phone.length !== 5 && data.Phone.length !== 6) {
        numberToBeSavedInDatabase = data.Phone.startsWith("+")
          ? data.Phone
          : "+" +
            store.getState().reduxReducer.loggedInUser.DefaultCountryCode +
            data.Phone;
        data.Phone = numberToBeSavedInDatabase;
      }
      try {
        contactservice
          .editYakChatContact(id, data)
          .then(async (result: any) => {
            result = await result.json();
            if (!result || result.Message) {
              dispatch<any>(
                setAlertData({
                  className: "danger",
                  text: "There was an error",
                })
              );
              setTimeout(() => {
                dispatch<any>(setAlertData(null));
              }, 2000);
              return;
            }
            var userEmailsearch =
              store.getState().reduxReducer.loggedInUser.Email;
            var groupEmailsearch =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email;
            contactservice
              .searchConversation(
                false,
                data.Phone,
                groupEmailsearch,
                userEmailsearch
              )
              .then(async (result: any) => {
                result = await result.json();
                if (result.Rooms.$values.length !== 0) {
                  var obj = {
                    firstName: data.foreName,
                    lastName:
                      data.surename !== undefined && data.surename !== null
                        ? data.surename
                        : "",
                    phoneNumber: data.Phone,
                    groupEmail:
                      data.groupEmail !== undefined
                        ? data.groupEmail
                        : store.getState().reduxReducer.sharedInboxData
                            .currentSelected.email,
                  };
                  result.Rooms.$values.map((x: any) => {
                    x.Participants.$values.map((y: any) => {
                      if (y.Id) {
                        contactservice
                          .UpdateRoomParticipantTeams(
                            obj,
                            result.Rooms.$values[0].Participants.$values[0].Id
                          )
                          .then(async (res: any) => {
                            // res = await res.json();
                            res = await res.text();
                            //console.log(res);
                          });
                      }
                    });
                  });
                }
              });
            dispatch({
              contact: { ...result, id },
              type: index.ActionTypes.UpdateOne_Yak_Chat_Contact_List,
            });
            dispatch<any>(
              setContactSelected_OpenContact({ ...result, type: "YakChat" })
            );
            dispatch<any>(getYakChatGroupData(1));
            dispatch<any>(setTabOpened(""));
            dispatch<any>(setTabOpened("contactopen"));
            dispatch<any>(
              setAlertData({
                className: "",
                text: "Contact edited successfully",
              })
            );
            setTimeout(() => {
              dispatch<any>(setAlertData(null));
            }, 2000);
          });
      } catch (error) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      }
    }
  };
};
export const searchYakChatContacts_V2 = (pageindex: any, searchFilter: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice
          .searchyakchatcontactlist_V2(pageindex, searchFilter)
          .then(async (result: any) => {
            result = await result.json();
            var object = {};
            var value: any = [];
            result.Data?.ContactLists?.$values.forEach((element: any) => {
              object = {
                InboxId:
                  element.contactsPermissions?.$values.length <= 0
                    ? 0
                    : element.contactsPermissions?.$values[0].InboxId,
                CompanyId: element.CompanyId,
                ContactEmail: element.ContactEmail,
                ContactId: element.ContactId,
                CreatedBy: element.CreatedBy,
                CreatedDateTime: element.CreatedDateTime,
                ForeName: element.ForeName,
                GroupEmail: element.GroupEmail,
                OwnerId: element.OwnerId,
                PhoneNumber: element.PhoneNumber,
                SureName: element.SureName,
                UserEmail: element.UserEmail,
                contactLists: element.contactLists,
              };
              value.push(object);
            });
            result = result.Data;
            var contactdata: any = value;
            var Newlist: any;
            var Contactlist: any;
            dispatch<any>(setSpinnerInSideBar(false));
            if (!result || !contactdata) {
              localStorage.setItem("yakchatcontactPrevlist", "");
              dispatch({
                YakchatContactlist: [],
                type: index.ActionTypes.Yak_Chat_Contact_List,
              });
              return;
            }
            dispatch<any>(setSpinnerInSideBar(false));
            result.ContactLists = contactdata.$values
              ? contactdata.$values
              : contactdata;
            for (let contact of contactdata) {
              contact.avatarColor = avatarColorMap[contact.ContactId % 10];
            }
            if (pageindex === 1) {
              localStorage.setItem(
                "yakchatcontactPrevlist",
                JSON.stringify(
                  contactdata.$values ? contactdata.$values : contactdata
                )
              );
              dispatch({
                YakchatContactlist: contactdata.$values
                  ? contactdata.$values
                  : contactdata,
                type: index.ActionTypes.Yak_Chat_Contact_List,
              });
            } else {
              if (pageindex > 1) {
                var GetContactList = localStorage.getItem(
                  "yakchatcontactPrevlist"
                );
                if (typeof GetContactList === "string") {
                  Newlist = JSON.parse(GetContactList);
                }
                Contactlist = [
                  contactdata.$values ? contactdata.$values : contactdata,
                ];
                Newlist?.forEach((element: any) => {
                  Contactlist[0].push(element);
                });
                localStorage.setItem(
                  "yakchatcontactPrevlist",
                  JSON.stringify(Contactlist[0])
                );
                if (Contactlist[0]?.length > 0) {
                  Contactlist[0] = Contactlist[0].sort((a: any, b: any) => {
                    var nameA = a?.ForeName.toUpperCase();
                    var nameB = b?.ForeName.toUpperCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  });
                }
                dispatch({
                  YakchatContactlist: Contactlist[0],
                  type: index.ActionTypes.Yak_Chat_Contact_List,
                });
              }
            }
            dispatch<any>(currentPageIndexForYakChat(pageindex + 1));
            localStorage.setItem("YakchaTContactPageCount", result.PageCount);
            setTimeout(() => {
              dispatch<any>(setSpinnerInSideBar(false));
            }, 2000);
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const EditYakChatontact_V2 = (Contactid: any, ListId: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        let x: any = await contactservice.AddContactListsLocalContact_V2(
          Contactid,
          ListId
        );
        x = await x.json();
        x = convertListsFromRefsIntoObjects(x);
        const list = x.find((y: any) => y.Id === ListId);
        if (!list) return;
        var GetLocalContacts = x.find(
          (y: any) => y.Id === ListId
        ).localContacts;
        for (let contact of GetLocalContacts.$values) {
          contact.avatarColor = avatarColorMap[contact.ContactId % 10];
        }
        dispatch<any>({
          type: "updateListOfContactsInThisList",
          Id: ListId,
          newContacts: GetLocalContacts,
        });
        dispatch<any>(
          setAlertData({ className: "", text: "The group has been saved." })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      } catch (error) {
        //console.log(error);
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      }
    }
  };
};
export const editYakChatContact_V2 = (id: any, data: any) => {
  ////////debugger;
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      if (data.ownerEmail != undefined) {
        if (
          data.ownerEmail !==
          store.getState().reduxReducer.loggedInUser.user.Email
        ) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text:
                "You cannot edit a contact that you do not own.  This contact is owned by <" +
                data.ownerEmail +
                ">.",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
          dispatch<any>(setTabOpened(""));
          dispatch<any>(setTabOpened("contactopen"));
          return;
        }
      }
      var numberToBeSavedInDatabase;
      if (data.Phone.length !== 5 && data.Phone.length !== 6) {
        numberToBeSavedInDatabase = data.Phone.startsWith("+")
          ? data.Phone
          : "+" +
            store
              .getState()
              .reduxReducer.loggedInUser.inboxes.$values.filter(
                (item: any) =>
                  item.InboxId ==
                  store.getState().reduxReducer.sharedInboxData.currentSelected
                    .email
              )[0].DefaultCountryCode +
            data.Phone;
        data.Phone = numberToBeSavedInDatabase;
      }
      try {
        ////////debugger;
        if (
          store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 6 ||
          store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 7
        ) {
          //////debugger;
          // if (data.groupEmail == "" || data.groupEmail == null) {
          //   dispatch({
          //     alertData: {
          //       className: "danger",
          //       text: "Please choose Shared Inbox to share contact with, you are on Shared plan.",
          //     },
          //     type: index.ActionTypes.setAlertData,
          //   });
          //   setTimeout(
          //     () =>
          //       dispatch({
          //         alertData: null,
          //         type: index.ActionTypes.setAlertData,
          //       }),
          //     5000
          //   );
          //   return;
          // }
        }
        contactservice
          .editYakChatContact_V2(id, data)
          .then(async (result: any) => {
            //////debugger;
            result = await result.json();

            if (!result.Data || result.Message) {
              dispatch<any>(
                setAlertData({
                  className: "danger",
                  text:
                    result.Message != ""
                      ? result.Message
                      : "There was an error",
                })
              );
              setTimeout(() => {
                dispatch<any>(setAlertData(null));
              }, 3000);
              return;
            }
            var object = {};
            object = {
              InboxId: result.Data.contactPermissions?.$values[0].InboxId,
              CompanyId: result.Data.contact.CompanyId,
              ContactEmail: result.Data.contact.ContactEmail,
              ContactId: result.Data.contact.ContactId,
              CreatedBy: result.Data.contact.CreatedBy,
              CreatedDateTime: result.Data.contact.CreatedDateTime,
              ForeName: result.Data.contact.ForeName,
              GroupEmail: result.Data.contact.GroupEmail,
              OwnerId: result.Data.contact.OwnerId,
              PhoneNumber: result.Data.contact.PhoneNumber,
              SureName: result.Data.contact.SureName,
              UserEmail: result.Data.contact.UserEmail,
              contactLists: result.Data.contact.contactLists,
            };
            result.Data = object;
            var userEmailsearch =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email;
            var groupEmailsearch =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email;
            var inboxId =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email;
            contactservice
              .searchConversation_V2(false, data.phoneNumber, inboxId)
              .then(async (resultsearch: any) => {
                resultsearch = await resultsearch.json();
                resultsearch = resultsearch.Data;
                if (resultsearch.Rooms.$values.length !== 0) {
                  var obj = {
                    firstName: data.foreName,
                    lastName:
                      data.surename !== undefined && data.surename !== null
                        ? data.surename
                        : "",
                    phoneNumber: data.phoneNumber,
                    groupEmail:
                      data.groupEmail !== undefined
                        ? data.groupEmail
                        : store.getState().reduxReducer.sharedInboxData
                            .currentSelected.email,
                  };
                  // resultsearch.Rooms.$values.map((x: any) => {
                  //   x.Participants.$values.map((y: any) => {
                  //     if (y.Id) {
                  //       contactservice
                  //         .UpdateRoomParticipantTeams(
                  //           obj,
                  //           resultsearch.Rooms.$values[0].Participants
                  //             .$values[0].Id
                  //         )
                  //         .then(async (res: any) => {
                  //           // res = await res.json();
                  //           res = await res.text();
                  //           //console.log(res);
                  //         });
                  //     }
                  //   });
                  // });
                }
              });
            dispatch({
              contact: { ...result.Data, id },
              type: index.ActionTypes.UpdateOne_Yak_Chat_Contact_List,
            });
            dispatch<any>(
              setContactSelected_OpenContact({
                ...result.Data,
                type: "YakChat",
              })
            );
            dispatch<any>(getYakChatGroupData_V2(1));
            dispatch<any>(setTabOpened(""));
            dispatch<any>(setTabOpened("contactopen"));
            dispatch<any>(
              setAlertData({
                className: "",
                text: "Contact edited successfully",
              })
            );
            setTimeout(() => {
              dispatch<any>(setAlertData(null));
            }, 2000);
          });
      } catch (error) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      }
    }
  };
};
export const handleOutgoingMessageResponse = (
  res: any,
  text: string,
  roomId: number,
  fileBlob: any
) => {
  return async (dispatch: Dispatch) => {};
};
export const handleFailedOutgoingMessage = (err: any) => {
  return (dispatch: Dispatch) => {
    if (err.includes("Authentication")) {
      dispatch<any>(
        setAlertData({
          className: "danger",
          text: err,
          refreshlink: ".",
          refreshlinkTitle: " please refresh",
        }) // "Failed to send the message" })
      );
    } else {
      dispatch<any>(
        setAlertData({ className: "danger", text: err }) // "Failed to send the message" })
      );
    }
    setTimeout(() => {
      dispatch<any>(setAlertData(null));
    }, 5000);
    dispatch<any>(setSpinner(false));
  };
};
export const SendMessageToNumber = (
  numberType: any,
  roomId: any,
  text: any,
  groupEmail: any,
  loggedInEmail: any,
  file: any,
  calledFrom: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var userEmail = store.getState().reduxReducer.loggedInUser.Email;
        if (file.length > 0) {
          dispatch<any>(
            uploadToAzureBlob(roomId, text, groupEmail, userEmail, file)
          );
        } else {
          dispatch<any>(
            queueTextMessageTwillio(
              roomId,
              text,
              groupEmail,
              userEmail,
              calledFrom
            )
          );
          //////debugger;
          dispatch<any>(
            getPagingMessagesByRoomId(userEmail, roomId, 1, "chatPanel")
          );
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const SendMessageToNumber_V2 = (
  data: any,
  numberType: any,
  roomId: any,
  text: any,
  groupEmail: any,
  file: any,
  calledFrom: any
) => {
  ////////debugger;
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var userEmail =
          store.getState().reduxReducer.sharedInboxData.currentSelected.email;
        if (file.length > 0) {
          dispatch<any>(
            uploadToAzureBlob_V2(
              data,
              roomId,
              text,
              groupEmail,
              userEmail,
              file
            )
          );
        } else {
          dispatch<any>(
            queueTextMessageTwillio_V2(
              data,
              roomId,
              text,
              groupEmail,
              calledFrom
            )
          );
          // dispatch<any>(
          //   getPagingMessagesByRoomId_V2( roomId, 1, "chatPanel")
          // );
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
function setNativeValue(element: any, value: any) {
  let lastValue = element.value;
  element.value = value;
  let event = new Event("input", { bubbles: true });
  let tracker = element._valueTracker;
  if (tracker) {
    tracker.setValue(lastValue);
  }
  element.dispatchEvent(event);
}
export const queueMessageMMSTwillio = (
  roomId: any,
  text: any,
  groupEmail: any,
  uploadedFiles: any,
  userEmail: any
) => {
  return async (dispatch: Dispatch) => {
    text = encodeURIComponent(text);
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      var groupEmails = userEmail === groupEmail ? "''" : groupEmail;
      var serviceproviderid =
        store.getState().reduxReducer.loggedInUser.Subscription
          .ServiceProviderID;
      var channelName = "mms";
      //console.log("text", text);
      if (text === "") {
        text = "null";

        //console.log("empty", text);
      }
      if (roomId !== 0) {
        let res: any = await contactservice.queueMessageChannel(
          roomId,
          text,
          groupEmail,
          uploadedFiles,
          serviceproviderid,
          channelName
        );
        //////debugger;
        // .then(async (res: any) => {
        if (typeof res === "number") {
          let el = document.getElementById("mainInput-SendMessage");
          setNativeValue(el, "");

          if (roomId !== undefined) {
            dispatch<any>(GetGroupRoomPrimaryMember(roomId, "QueueMessage"));
            dispatch<any>(
              getPagingMessagesByRoomId(userEmail, roomId, 1, "ChatPanel")
            );
          }
        } else {
          dispatch<any>(handleFailedOutgoingMessage(res));
          return;
        }
        // })
        // .catch((error) => {
        //   ////////debugger;
        //   //console.log(error);
        //   console.log(error.response.data); // you can get the response like this
        //     console.log(error.response.status); // status code of the request

        //   dispatch<any>(handleFailedOutgoingMessage(error));
        // });
      }
    }
  };
};
export const queueTextMessageTwillio = (
  roomId: any,
  text: any,
  groupEmail: any,
  userEmail: any,
  calledFrom: any
) => {
  return async (dispatch: Dispatch) => {
    text = encodeURIComponent(text);
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      var groupEmails = userEmail === groupEmail ? "''" : groupEmail;
      var serviceproviderid =
        store.getState().reduxReducer.loggedInUser.Subscription
          .ServiceProviderID;
      var channelName = "sms";
      var fileBlob = "";
      var fileName = "";
      if (roomId !== 0) {
        let res: any = await contactservice.queueMessageChannel(
          roomId,
          text,
          groupEmail,
          fileBlob,
          serviceproviderid,
          channelName
        );
        //////debugger;
        if (typeof res === "number") {
          if (calledFrom === "chatPanel") {
            if (roomId !== undefined) {
              let companyVersion = store.getState().reduxReducer.companyVersion;
              dispatch<any>(GetGroupRoomPrimaryMember(roomId, "chatPanel"));
              dispatch<any>(
                getPagingMessagesByRoomId(userEmail, roomId, 1, "ChatPanel")
              );
            }
          } else {
            if (roomId !== undefined) {
              let el = document.getElementById("mainInput-SendMessage");
              setNativeValue(el, "");
              dispatch<any>(GetGroupRoomPrimaryMember(roomId, "QueueMessage"));
              dispatch<any>(
                getPagingMessagesByRoomId(userEmail, roomId, 1, "ChatPanel")
              );
            }
          }
        } else {
          ////////debugger;

          dispatch<any>(handleFailedOutgoingMessage(res));
          return;
        }
      }
    }
  };
};
export const queueMessageMMSTwillio_V2 = (
  data: any,
  roomId: any,
  text: any,
  inboxId: any,
  uploadedFiles: any
) => {
  return async (dispatch: Dispatch) => {
    text = encodeURIComponent(text);
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      var serviceproviderid = store
        .getState()
        .reduxReducer.loggedInUser.inboxes.$values.filter(
          (item: any) => item.InboxId == inboxId
        )[0].ServiceProviderId;
      //store.getState().reduxReducer.loggedInUser.user.ServiceProviderID;
      var channelName = "mms";
      //console.log("text", text);
      if (text === "") {
        text = "null";

        //console.log("empty", text);
      }
      if (roomId !== 0) {
        let res: any = await contactservice.queueMessageChannel_V2(
          data,
          roomId,
          text,
          inboxId,
          uploadedFiles,
          serviceproviderid,
          channelName
        );
        //////debugger;
        if (res.Code != undefined && res.Code == 200) {
          ////////debugger;
          let el = document.getElementById("mainInput-SendMessage");
          setNativeValue(el, "");
          res = res.Data;
          if (roomId !== undefined) {
            dispatch<any>(GetGroupRoomPrimaryMember_V2(roomId, "QueueMessage"));
            dispatch<any>(getPagingMessagesByRoomId_V2(roomId, 1, "ChatPanel"));
          }
        } else if (res.Code != undefined && res.Code !== 200) {
          //////debugger;
          let errorMsg = "Unable to send text: " + res.Message;
          dispatch<any>(handleFailedOutgoingMessage(errorMsg));
        } else {
          dispatch<any>(handleFailedOutgoingMessage(res));
        }
      }
    }
  };
};
export const queueTextMessageTwillio_V2 = (
  data: any,
  roomId: any,
  text: any,
  inboxId: any,
  calledFrom: any
) => {
  return async (dispatch: Dispatch) => {
    text = encodeURIComponent(text);
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      var serviceproviderid = store
        .getState()
        .reduxReducer.loggedInUser.inboxes.$values.filter(
          (item: any) => item.InboxId == inboxId
        )[0].ServiceProviderId;
      var channelName = "sms";
      var uploadedFileUrl = null;
      var fileName = "";
      if (roomId !== 0) {
        let res: any = await contactservice.queueMessageChannel_V2(
          data,
          roomId,
          text,
          inboxId,
          uploadedFileUrl,
          serviceproviderid,
          channelName
        );
        //////debugger;
        if (res.Code != undefined && res.Code == 200) {
          ////////debugger;
          let el = document.getElementById("mainInput-SendMessage");
          setNativeValue(el, "");
          res = res.Data;
          if (calledFrom === "chatPanel") {
            if (roomId !== undefined) {
              dispatch<any>(GetGroupRoomPrimaryMember_V2(roomId, "chatPanel"));
              dispatch<any>(
                getPagingMessagesByRoomId_V2(roomId, 1, "ChatPanel")
              );
            }
          } else {
            if (roomId !== undefined) {
              dispatch<any>(
                GetGroupRoomPrimaryMember_V2(roomId, "QueueMessage")
              );
              dispatch<any>(
                getPagingMessagesByRoomId_V2(roomId, 1, "ChatPanel")
              );
            }
          }
        } else if (res.Code != undefined && res.Code !== 200) {
          let errorMsg = "Unable to send text: " + res.Message;
          dispatch<any>(handleFailedOutgoingMessage(errorMsg));
        } else {
          dispatch<any>(handleFailedOutgoingMessage(res));
        }
      }
    }
  };
};

export const createRoom = (
  IsRoomContact: any,
  data: any,
  text: any,
  email: any,
  roomId: any,
  isGroup: any,
  file: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var value: any = [];
        var object: any = {};
        data.forEach((element: any) => {
          if (
            element.type === "Outlook" ||
            element.type === "Users Group" ||
            element.type === "Share Point" ||
            element.type === "Users"
          ) {
            object = {
              $type:
                "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
              Name: element.displayName,
              ContactUri: element.mobilePhone,
              PhoneNumber: element.mobilePhone,
            };
          } else if (element.type === "New Contact") {
            if (!element.mobilePhone.includes("+")) {
              var countryCode =
                store.getState().reduxReducer.loggedInUser.DefaultCountryCode;
              object = {
                $type:
                  "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
                Name: "+" + countryCode + element.mobilePhone,
                ContactUri: "+" + countryCode + element.mobilePhone,
                PhoneNumber: "+" + countryCode + element.mobilePhone,
              };
            } else {
              var countryCode =
                store.getState().reduxReducer.loggedInUser.DefaultCountryCode;
              object = {
                $type:
                  "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
                Name: element.mobilePhone,
                ContactUri: element.mobilePhone,
                PhoneNumber: element.mobilePhone,
              };
            }
          } else {
            let Surname = element.SureName;
            object = {
              $type:
                "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
              Name:
                element.ForeName == null || element.ForeName == undefined
                  ? element.SureName
                  : element.ForeName +
                    " " +
                    (Surname != null && Surname != undefined ? Surname : ""),
              ContactUri: element.PhoneNumber ?? element.mobilePhone,
              PhoneNumber: element.PhoneNumber ?? element.mobilePhone,
            };
          }

          value.push(object);
        });
        var indexphone = 0;
        value.forEach((item: any) => {
          if (
            object.PhoneNumber === null ||
            object.PhoneNumber === undefined ||
            object.PhoneNumber == ""
          ) {
            dispatch<any>(
              setAlertData({
                className: "danger",
                text: "One or more of the contacts contains a telephone format error.",
              })
            );
            setTimeout(() => {
              dispatch<any>(setAlertData(null));
            }, 5000);
            indexphone += 1;
            return;
          }
        });
        if (indexphone <= 0) {
          if (!isGroup) {
            if (IsRoomContact === false) {
              roomId = await contactservice.CreateRoom(value, email); //.then(async (result: any) => {
              // result = await result.json();
              if (roomId === "Can not create an empty room.") {
                return;
              } else if (
                roomId === "You cannot message another YakChat number" ||
                roomId === "PhoneNumber is not right Format E164."
              ) {
                dispatch<any>(setSpinner(false));
                dispatch<any>(
                  setAlertData({
                    className: "danger",
                    text: roomId,
                  })
                );
                setTimeout(() => {
                  dispatch<any>(setAlertData(null));
                }, 5000);
                return;
              } else {
                dispatch<any>(
                  getPersonalInbox(1, email, false, email, "CreateRoom", roomId)
                );
              }
              if (text !== "" || file != null) {
                var currentSelectedUniqueNumber =
                  store.getState().reduxReducer.sharedInboxData.currentSelected
                    .UniqueNumber;
                dispatch<any>(
                  getPhoneNumberTypeAndSendMessage(
                    currentSelectedUniqueNumber,
                    roomId,
                    text,
                    email,
                    file,
                    ""
                  )
                );
              } else {
                dispatch<any>(
                  getPersonalInbox(1, email, false, email, "CreateRoom", roomId)
                );
              }
              //});
            } else {
              roomId =
                roomId !== ""
                  ? roomId
                  : store.getState().reduxReducer.openMessageRoom.Id;
              if (text !== "" || file != null) {
                var currentSelectedUniqueNumber =
                  store.getState().reduxReducer.sharedInboxData.currentSelected
                    .UniqueNumber;
                dispatch<any>(
                  getPhoneNumberTypeAndSendMessage(
                    currentSelectedUniqueNumber,
                    roomId,
                    text,
                    email,
                    file,
                    ""
                  )
                );
              } else {
                dispatch<any>(
                  getPersonalInbox(1, email, false, email, "CreateRoom", roomId)
                );
              }
            }
          } else {
            setTimeout(() => {
              dispatch<any>(
                CreateRoomGroup(
                  IsRoomContact,
                  value,
                  email,
                  text,
                  roomId,
                  email,
                  file
                )
              );
            }, 500);
          }
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const CreateRoomGroup = (
  IsRoomContact: any,
  participants: any,
  email: any,
  text: any,
  roomId: any,
  groupEmail: any,
  file: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      //////debugger;
      var loggedInUser = store.getState().reduxReducer.loggedInUser.Email;
      if (IsRoomContact === false) {
        //////debugger;
        roomId = await contactservice.createRoomGroup(participants, email); //.then(async (result: any) => {
        if (roomId === "Can not create an empty room.") {
          return;
        } else if (
          roomId === "You cannot message another YakChat number" ||
          roomId === "PhoneNumber is not right Format E164."
        ) {
          dispatch<any>(setSpinner(false));
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: roomId,
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 5000);
          return;
        } else {
          if (text !== "" || file != null) {
            var currentSelectedUniqueNumber =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .UniqueNumber;
            dispatch<any>(
              getPhoneNumberTypeAndSendMessage(
                currentSelectedUniqueNumber,
                roomId,
                text,
                groupEmail,
                file,
                ""
              )
            );
          } else {
            dispatch<any>(
              getPersonalInbox(
                1,
                loggedInUser,
                false,
                groupEmail,
                "CreateRoom",
                roomId
              )
            );
          }
        }
        // if (text !== "" || fileBlob !== "") {
        //   var currentSelectedUniqueNumber =
        //     store.getState().reduxReducer.sharedInboxData.currentSelected
        //       .UniqueNumber;
        //   dispatch<any>(
        //     getPhoneNumberTypeAndSendMessage(
        //       currentSelectedUniqueNumber,
        //       roomId,
        //       text,
        //       groupEmail,
        //       fileBlob,
        //       fileName,
        //       ""
        //     )
        //   );
        // } else {
        //   dispatch<any>(
        //     getPersonalInbox(
        //       1,
        //       loggedInUser,
        //       false,
        //       groupEmail,
        //       "CreateRoom",
        //       roomId
        //     )
        //   );
        // }
        // });
      } else {
        roomId =
          roomId !== ""
            ? roomId
            : store.getState().reduxReducer.openMessageRoom.Id;
        if (text !== "" || file != null) {
          var currentSelectedUniqueNumber =
            store.getState().reduxReducer.sharedInboxData.currentSelected
              .UniqueNumber;
          dispatch<any>(
            getPhoneNumberTypeAndSendMessage(
              currentSelectedUniqueNumber,
              roomId,
              text,
              groupEmail,
              file,
              ""
            )
          );
        } else {
          dispatch<any>(
            getPersonalInbox(
              1,
              loggedInUser,
              false,
              groupEmail,
              "CreateRoom",
              roomId
            )
          );
        }
      }
    }
  };
};
export const createRoom_V2 = (
  IsRoomContact: any,
  data: any,
  text: any,
  email: any,
  roomId: any,
  isGroup: any,
  file: any
) => {
  ////////debugger;
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var sendvalue: any = [];
        var roomvalue: any = [];
        var object: any = {};
        var object2: any = {};
        data.forEach((element: any) => {
          if (
            element.type === "Outlook" ||
            element.type === "Users Group" ||
            element.type === "Share Point" ||
            element.type === "Users"
          ) {
            object = {
              // $type:
              //   "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
              Name: element.displayName,
              ContactUri: element.mobilePhone,
              PhoneNumber: element.mobilePhone,
            };
            object2 = {
              $type:
                "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
              Name: element.displayName,
              ContactUri: element.mobilePhone,
              PhoneNumber: element.mobilePhone,
            };
          } else if (element.type === "New Contact") {
            if (!element.mobilePhone.includes("+")) {
              var countryCode = store
                .getState()
                .reduxReducer.loggedInUser.inboxes.$values.filter(
                  (item: any) =>
                    item.InboxId ==
                    store.getState().reduxReducer.sharedInboxData
                      .currentSelected.email
                )[0].DefaultCountryCode;
              object = {
                // $type:
                //   "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
                Name: "+" + countryCode + element.mobilePhone,
                ContactUri: "+" + countryCode + element.mobilePhone,
                PhoneNumber: "+" + countryCode + element.mobilePhone,
              };
              object2 = {
                $type:
                  "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
                Name: "+" + countryCode + element.mobilePhone,
                ContactUri: "+" + countryCode + element.mobilePhone,
                PhoneNumber: "+" + countryCode + element.mobilePhone,
              };
            } else {
              var countryCode = store
                .getState()
                .reduxReducer.loggedInUser.inboxes.$values.filter(
                  (item: any) =>
                    item.InboxId ==
                    store.getState().reduxReducer.sharedInboxData
                      .currentSelected.email
                )[0].DefaultCountryCode;
              object = {
                // $type:
                //   "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
                Name: element.mobilePhone,
                ContactUri: element.mobilePhone,
                PhoneNumber: element.mobilePhone,
              };
              object2 = {
                $type:
                  "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
                Name: element.mobilePhone,
                ContactUri: element.mobilePhone,
                PhoneNumber: element.mobilePhone,
              };
            }
          } else {
            ////////debugger;
            let Surname = element.SureName;
            object = {
              // $type:
              //   "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
              Name:
                element.ForeName +
                " " +
                (Surname != null && Surname != undefined ? Surname : ""),
              ContactUri: element.PhoneNumber,
              PhoneNumber: element.PhoneNumber,
            };
            object2 = {
              $type:
                "Chata.Common.Objects.Internal.Participant.S4BPhoneNumberParticipant, Chata.Common.Objects",
              Name:
                element.ForeName +
                " " +
                (Surname != null && Surname != undefined ? Surname : ""),
              ContactUri: element.PhoneNumber,
              PhoneNumber: element.PhoneNumber,
            };
          }
sendvalue.push(object);
          roomvalue.push(object2);
        });
        var indexphone = 0;
        roomvalue.forEach((item: any) => {
          ////////debugger;
          if (
            object.PhoneNumber === null ||
            object.PhoneNumber === undefined ||
            object.PhoneNumber == ""
          ) {
            dispatch<any>(
              setAlertData({
                className: "danger",
                text: "One or more of the contacts contains a telephone format error.",
              })
            );
            setTimeout(() => {
              dispatch<any>(setAlertData(null));
            }, 5000);
            indexphone += 1;
            return;
          }
        });
        sendvalue.forEach((item: any) => {
          ////////debugger;
          if (
            object.PhoneNumber === null ||
            object.PhoneNumber === undefined ||
            object.PhoneNumber == ""
          ) {
            dispatch<any>(
              setAlertData({
                className: "danger",
                text: "One or more of the contacts contains a telephone format error.",
              })
            );
            setTimeout(() => {
              dispatch<any>(setAlertData(null));
            }, 5000);
            indexphone += 1;
            return;
          }
        });
        if (indexphone <= 0) {
          if (!isGroup) {
            if (IsRoomContact === false) {
              ////////debugger;
              roomId = contactservice
                .CreateRoom_V2(roomvalue, email)
                .then(async (result: any) => {
                  result = await result.json();
                  if (result.Code === 200) {
                    roomId = result.Data;
                  }
                  if (result.Code != 200) {
                    if (result.Message === "Can not create an empty room.") {
                      return;
                    } else if (
                      result.Message ===
                        "You cannot message another YakChat number" ||
                      result.Message === "PhoneNumber is not right Format E164."
                    ) {
                      dispatch<any>(setSpinner(false));
                      dispatch<any>(
                        setAlertData({
                          className: "danger",
                          text: result.Message,
                        })
                      );
                      setTimeout(() => {
                        dispatch<any>(setAlertData(null));
                      }, 5000);
                      return;
                    }
                  }
//////debugger;
                  dispatch<any>(
                    getPersonalInbox_V2(
                      1,
                      email,
                      false,
                      email,
                      "CreateRoom",
                      roomId
                    )
                  );

                  if (text !== "" || file != null) {
                    var currentSelectedUniqueNumber =
                      store.getState().reduxReducer.sharedInboxData
                        .currentSelected.UniqueNumber;
                    dispatch<any>(
                      getPhoneNumberTypeAndSendMessage_V2(
                        sendvalue,
                        currentSelectedUniqueNumber,
                        roomId,
                        text,
                        email,
                        file,
                        ""
                      )
                    );
                  } else {
                    //////debugger;
                    dispatch<any>(
                      getPersonalInbox_V2(
                        1,
                        email,
                        false,
                        email,
                        "CreateRoom",
                        roomId
                      )
                    );
                  }
                });
            } else {
              roomId =
                roomId !== ""
                  ? roomId
                  : store.getState().reduxReducer.openMessageRoom.Id;
              if (text !== "" || file != null) {
                var currentSelectedUniqueNumber =
                  store.getState().reduxReducer.sharedInboxData.currentSelected
                    .UniqueNumber;
                dispatch<any>(
                  getPhoneNumberTypeAndSendMessage_V2(
                    sendvalue,
                    currentSelectedUniqueNumber,
                    roomId,
                    text,
                    email,
                    file,
                    ""
                  )
                );
              } else {
                //////debugger;
                dispatch<any>(
                  getPersonalInbox_V2(
                    1,
                    email,
                    false,
                    email,
                    "CreateRoom",
                    roomId
                  )
                );
              }
            }
          } else {
            setTimeout(() => {
              dispatch<any>(
                CreateRoomGroup_V2(
                  IsRoomContact,
                  roomvalue,
                  email,
                  text,
                  roomId,
                  email,
                  file
                )
              );
            }, 500);
          }
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const CreateRoomGroup_V2 = (
  IsRoomContact: any,
  participants: any,
  email: any,
  text: any,
  roomId: any,
  groupEmail: any,
  file: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      var loggedInUser =
        store.getState().reduxReducer.sharedInboxData.currentSelected.email;
      if (IsRoomContact === false) {
        roomId = await contactservice.CreateRoom_V2(participants, loggedInUser); //.then(async (result: any) => {
        if (roomId === "Can not create an empty room.") {
          return;
        } else if (
          roomId === "You cannot message another YakChat number" ||
          roomId === "PhoneNumber is not right Format E164."
        ) {
          dispatch<any>(setSpinner(false));
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: roomId,
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 5000);
          return;
        } else {
          if (text !== "" || file != null) {
            var currentSelectedUniqueNumber =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .UniqueNumber;
            dispatch<any>(
              getPhoneNumberTypeAndSendMessage_V2(
                participants,
                currentSelectedUniqueNumber,
                roomId,
                text,
                groupEmail,
                file,
                ""
              )
            );
          } else {
            
            //////debugger;
            dispatch<any>(
              getPersonalInbox_V2(
                1,
                loggedInUser,
                false,
                groupEmail,
                "CreateRoom",
                roomId
              )
            );
          }
        }
        // if (text !== "" || fileBlob !== "") {
        //   var currentSelectedUniqueNumber =
        //     store.getState().reduxReducer.sharedInboxData.currentSelected
        //       .UniqueNumber;
        //   dispatch<any>(
        //     getPhoneNumberTypeAndSendMessage(
        //       participants,
        //       currentSelectedUniqueNumber,
        //       roomId,
        //       text,
        //       groupEmail,
        //       fileBlob,
        //       fileName,
        //       ""
        //     )
        //   );
        // } else {
        //   dispatch<any>(
        //     getPersonalInbox(
        //       1,
        //       loggedInUser,
        //       false,
        //       groupEmail,
        //       "CreateRoom",
        //       roomId
        //     )
        //   );
        // }
        // });
      } else {
        roomId =
          roomId !== ""
            ? roomId
            : store.getState().reduxReducer.openMessageRoom.Id;
        if (text !== "" || file != null) {
          var currentSelectedUniqueNumber =
            store.getState().reduxReducer.sharedInboxData.currentSelected
              .UniqueNumber;
          dispatch<any>(
            getPhoneNumberTypeAndSendMessage_V2(
              participants,
              currentSelectedUniqueNumber,
              roomId,
              text,
              groupEmail,
              file,
              ""
            )
          );
        } else {
          //////debugger;
          dispatch<any>(
            getPersonalInbox_V2(
              1,
              loggedInUser,
              false,
              groupEmail,
              "CreateRoom",
              roomId
            )
          );
        }
      }
    }
  };
};
export const updateParticipantName = (
  roomParticipantId: any,
  contactUri: any,
  name: any,
  userEmail: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        await contactservice
          .updateRoomParticipants(
            roomParticipantId,
            contactUri,
            name,
            userEmail
          )
          .then(async (res: any) => {
            if (
              res.Message === "Authorization has been denied for this request."
            ) {
              AuthService.signOut();
              return;
            }
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const checkParticipantTobeUpdated = (
  isRoomContact: any,
  participant: any,
  text: any,
  email: any,
  room: any,
  shouldCreateRoomGroupOrRoomGroup: any,
  file: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        room.Participants.$values.forEach((element: any) => {
          var attendeesExist = participant.filter((a: any) => {
            if (a?.type === "YakChat") {
              return a?.PhoneNumber === element.SfBPhoneNumber.PhoneNumber;
            } else {
              return a?.mobilePhone === element.SfBPhoneNumber.PhoneNumber;
            }
          });
          if (attendeesExist?.length !== 0) {
            var attname = "";
            if (attendeesExist[0].type === "YakChat") {
              attname =
                attendeesExist[0].ForeName +
                " " +
                (attendeesExist[0].SureName != null &&
                attendeesExist[0].SureName != undefined
                  ? attendeesExist[0].SureName
                  : "");
            } else {
              attname = attendeesExist[0].displayName;
            }
            if (attname) {
              if (element.Name !== attname) {
                dispatch<any>(
                  updateParticipantName(
                    element.Id,
                    element.SfBPhoneNumber.ContactUri,
                    attname,
                    email
                  )
                );
              }
            }
          }
        });
        dispatch<any>(
          createRoom(
            isRoomContact,
            participant,
            text,
            email,
            room.Id,
            shouldCreateRoomGroupOrRoomGroup,
            file
          )
        );
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const SendMessage = (data: any, text: any, email: any, file: any) => {
  var IsRoomContact: any = false;
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var shouldCreateRoomGroupOrRoomGroup = true;
        if (email === store.getState().reduxReducer.loggedInUser.Email) {
          shouldCreateRoomGroupOrRoomGroup = false;
        }
        //////debugger;
        await contactservice
          .getOrderedUnArchivedRoom(email)
          .then(async (res: any) => {
            res = await res.json();
            if (
              res.Message === "Authorization has been denied for this request."
            ) {
              AuthService.signOut();
              return;
            }
            if (
              res.$values !== undefined
                ? res.$values.length > 0
                : res.length > 0
            ) {
              //////debugger;
              var checkWhetherRoomExists: any = false;
              var checkElement = res.$values !== undefined ? res.$values : res;
              checkElement?.forEach((element: any) => {
                if (element.Participants?.$values?.length === data?.length) {
                  var participantMatch = element?.Participants?.$values.filter(
                    (obj: any) => {
                      var matchedValue = data?.filter((x: any) => {
                        var mobileNumber = "";
                        if (
                          x.type === "YakChat" ||
                          x.type === "YakChat Group"
                        ) {
                          mobileNumber = x.PhoneNumber;
                        } else {
                          mobileNumber = x.mobilePhone;
                        }
                        if (mobileNumber?.indexOf("+") !== -1) {
                          if (
                            mobileNumber?.trim() ===
                            obj.SfBPhoneNumber.PhoneNumber?.trim()
                          )
                            return x;
                        } else {
                          mobileNumber =
                            "+" +
                            store.getState().reduxReducer.loggedInUser
                              .DefaultCountryCode +
                            mobileNumber;
                          if (
                            mobileNumber?.trim() ===
                            obj.SfBPhoneNumber.PhoneNumber?.trim()
                          )
                            return x;
                        }
                      });
                      if (matchedValue?.length !== 0) {
                        return matchedValue;
                      }
                    }
                  );
//////debugger;
                  if (participantMatch?.length === data?.length) {
                    if (element.Archived === true) {
                     //Task 6521
                      // dispatch<any>(closeConversation(element.Id, false));
                      dispatch<any>(closeConversation(element, false));
                    }
                    checkWhetherRoomExists = true;
                    dispatch<any>(
                      checkParticipantTobeUpdated(
                        true,
                        data,
                        text,
                        email,
                        element,
                        shouldCreateRoomGroupOrRoomGroup,
                        file
                      )
                    );
                    return false;
                  }
                }
              });
              if (!checkWhetherRoomExists) {
                dispatch<any>(
                  createRoom(
                    false,
                    data,
                    text,
                    email,
                    "",
                    shouldCreateRoomGroupOrRoomGroup,
                    file
                  )
                );
              }
            } else {
              dispatch<any>(
                createRoom(
                  false,
                  data,
                  text,
                  email,
                  "",
                  shouldCreateRoomGroupOrRoomGroup,
                  file
                )
              );
            }
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const SendMessage_V2 = (data: any, text: any, email: any, file: any) => {
  var IsRoomContact: any = false;
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var shouldCreateRoomGroupOrRoomGroup = true;
        if (
          email ===
          store.getState().reduxReducer.sharedInboxData.currentSelected.email
        ) {
          shouldCreateRoomGroupOrRoomGroup = false;
        }
        // await contactservice
        //   .getOrderedUnArchivedRoom(email)
        //   .then(async (res: any) => {
        //     res = await res.json();
        //     if (
        //       res.Message === "Authorization has been denied for this request."
        //     ) {
        //       AuthService.signOut();
        //       return;
        //     }
        //     if (
        //       res.$values !== undefined ? res.$values.length > 0 : res.length > 0
        //     ) {
        var checkWhetherRoomExists: any = false;
        //var checkElement = res.$values !== undefined ? res.$values : res;
        // checkElement?.forEach((element: any) => {
        //   if (element.Participants?.$values?.length === data?.length) {
        //     var participantMatch = element?.Participants?.$values.filter(
        //       (obj: any) => {
        //         var matchedValue = data?.filter((x: any) => {
        //           var mobileNumber = "";
        //           if (x.type === "YakChat" || x.type === "YakChat Group") {
        //             mobileNumber = x.PhoneNumber;
        //           } else {
        //             mobileNumber = x.mobilePhone;
        //           }
        //           if (mobileNumber?.indexOf("+") !== -1) {
        //             if (
        //               mobileNumber?.trim() ===
        //               obj.SfBPhoneNumber.PhoneNumber?.trim()
        //             )
        //               return x;
        //           } else {
        //             mobileNumber =
        //               "+" +
        //               store.getState().reduxReducer.loggedInUser
        //                 .DefaultCountryCode +
        //               mobileNumber;
        //             if (
        //               mobileNumber?.trim() ===
        //               obj.SfBPhoneNumber.PhoneNumber?.trim()
        //             )
        //               return x;
        //           }
        //         });
        //         if (matchedValue?.length !== 0) {
        //           return matchedValue;
        //         }
        //       }
        //     );

        //     if (participantMatch?.length === data?.length) {
        //       if (element.Archived === true) {
        //         dispatch<any>(closeConversation(element.Id, false));
        //       }
        //       checkWhetherRoomExists = true;
        //       dispatch<any>(
        //         checkParticipantTobeUpdated(
        //           true,
        //           data,
        //           text,
        //           email,
        //           element,
        //           shouldCreateRoomGroupOrRoomGroup,
        //           fileBlob,
        //           fileName
        //         )
        //       );
        //       return false;
        //     }
        //   }
        // });
        // if (!checkWhetherRoomExists) {
        dispatch<any>(
          createRoom_V2(
            false,
            data,
            text,
            email,
            "",
            shouldCreateRoomGroupOrRoomGroup,
            file
          )
        );
        //}
        //   } else {
        //     dispatch<any>(
        //       createRoom(
        //         false,
        //         data,
        //         text,
        //         email,
        //         "",
        //         shouldCreateRoomGroupOrRoomGroup,
        //         fileBlob,
        //         fileName
        //       )
        //     );
        //   }
        // });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const getInboxListFromSendMessage = (
  shouldIncludePersonalInbox: number,
  data: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      //debugger;
      try {
        let Sharedinboxemail = localStorage.getItem("SharedInboxemail");
        const resp = localStorage.getItem("InboxList");
        contactservice.GetGroupsEmails().then(async (res: any) => {
          res = await res.json();
          if (
            !res ||
            res?.length === 0 ||
            !res?.groupemailUsers ||
            res.groupemailUsers?.$values?.length === 0
          )
            return;
          res = res.groupemailUsers.$values;
          if (res?.length > 0) {
            res = res.sort((a: any, b: any) => {
              var nameA = a?.UserName.toUpperCase();
              var nameB = b?.UserName.toUpperCase();
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            });
          }
          let otherInboxes: any;
          let obj: any;
          var NewGroupmessagesCount = [{ Email: "", Istrue: "", UserName: "" }];
          const currrentselected = res.find(
            (x: any) => x.Email === Sharedinboxemail
          );
          otherInboxes = res.map((x: any, i: number) => {
            return {
              email: x.Email,
              name: x.UserName,
              UniqueNumber: x.UniqueNumber,
            };
          });
          obj = {
            currentSelected: {
              email: currrentselected.Email,
              name: currrentselected.UserName,
              UniqueNumber: currrentselected.UniqueNumber,
            },
            other: [...otherInboxes],
            NewOtherGroupMessage: NewGroupmessagesCount,
          };
          const sharedInboxData = JSON.parse(
            JSON.stringify(store.getState().reduxReducer.sharedInboxData)
          );
          let getselecteddata = sharedInboxData.other.filter(
            (x: any) => x.email === obj.currentSelected.email
          );
          if (getselecteddata?.length > 0) {
            dispatch({
              obj: obj.currentSelected,
              type: index.ActionTypes.setCurrentSelectedSharedEmailInboxData,
            });
          }
          dispatch<any>(getRoomsBySendMessages(1, Sharedinboxemail, data));
        });
      } catch (err) {
        //console.log(err);
        // await AuthService.saveToken();
      }
    }
  };
};

export const getRoomsBySendMessages = (
  pageIndex: number,
  email: any,
  data: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice.getRooms(pageIndex, email).then(async (res: any) => {
          res = await res.json();
          if (
            res.Message === "Authorization has been denied for this request."
          ) {
            AuthService.signOut();
            return;
          }
          res.sort((x: any, y: any) => {
            if (!x.Messages.$values[0] || !y.Messages.$values[0]) return 0;
            if (
              new Date(x.Messages.$values[0].TimeStamp) >
              new Date(y.Messages.$values[0].TimeStamp)
            )
              return -1;
            else return 1;
          });
          var UpdateroomData: any = [];
          if (res?.length > 0) {
            for (let room of res) {
              var getroomFirstElement = room.Participants?.$values?.filter(
                (x: any) => x.RoomId === data.Id
              );
              if (getroomFirstElement?.length > 0) {
                UpdateroomData.push(room);
              }
            }
            for (let room of res.filter((x: any) => x.Id !== data.Id)) {
              room.avatarColor = avatarColorMap[room.Id % 10];
              for (let participant of room.Participants.$values)
                participant.avatarColor = avatarColorMap[participant.Id % 10];
              UpdateroomData.push(room);
            }
          }
          dispatch({
            roomData: UpdateroomData,
            type: index.ActionTypes.setRooms,
          });
          dispatch({ type: "clearOpenMessageRoom" });
          const openmessageroomId: any =
            store.getState().reduxReducer.openMessageRoom;
          let message: any;
          try {
            message = JSON.parse(openmessageroomId);
            var refreshedMessage =
              UpdateroomData?.length !== 0
                ? UpdateroomData.find((x: any) => x.Id === message.Id)
                : message;
            if (!refreshedMessage) refreshedMessage = res[0];
            dispatch<any>(setOpenMessageRoom(refreshedMessage));
            dispatch<any>(setTabOpened("chatpanel"));
          } catch (err) {
            return;
          }
        });
      } catch (err) {
        //console.log(err);
        //await AuthService.saveToken();
      }
    }
  };
};
export const SaveTransltion = (roomId: any, language: any, showalert: any) => {
  return async (dispatch: Dispatch) => {
    ////////debugger;
    var checkFlag;
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        let res: any = await contactservice.SaveTransltion(roomId, language);
        if (showalert) {
          dispatch({
            alertData: {
              className: "success",
              text: "Language saved successfully!",
            },
            type: index.ActionTypes.setAlertData,
          });
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
        }
      } catch (error) {
        if (showalert) {
          dispatch({
            alertData: {
              className: "danger",
              text: "Language were not saved. Please try again.",
            },
            type: index.ActionTypes.setAlertData,
          });
          setTimeout(
            () =>
              dispatch({
                alertData: null,
                type: index.ActionTypes.setAlertData,
              }),
            2000
          );
        }
        //console.log(error);
      }
    }
  };
};
export const saveYakChatContact = (data: any, savedFrom: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice
          .saveYakChatContact(data, savedFrom)
          .then(async (result: any) => {
            result = await result.json();
            var countContact = 0;
            countContact++;
            setTimeout(
              () =>
                dispatch<any>(
                  setContactSelected_OpenContact({ ...result, type: "YakChat" })
                ),
              2000
            );
            if (result.GroupEmail) {
              dispatch<any>(getYakChatGroupData(1));
              dispatch<any>(getyakchatContacts(1));
              setTimeout(
                () =>
                  dispatch<any>(
                    setContactSelected_OpenContact({
                      ...result,
                      type: "YakChat",
                    })
                  ),
                1500
              );
            } else {
              dispatch<any>(getyakchatContacts(1));
              setTimeout(
                () =>
                  dispatch<any>(
                    setContactSelected_OpenContact({
                      ...result,
                      type: "YakChat",
                    })
                  ),
                1500
              );
            }
            if (store.getState().reduxReducer.isFromAddContact === true) {
              dispatch<any>(UpdateRoomParticipantTeams(data, savedFrom));
              setTimeout(
                () =>
                  dispatch<any>(
                    setContactSelected_OpenContact({
                      ...result,
                      type: "YakChat",
                    })
                  ),
                // dispatch<any>(getPagingMessagesByRoomId(userEmail, roomId, 1, ""))
                1000
              );
            } else {
              dispatch<any>(contactFromAddContact(false));
            }
            contactservice
              .searchConversation(
                false,
                encodeURIComponent(data.phoneNumber),
                data.groupEmail,
                store.getState().reduxReducer.loggedInUser.Email
              )
              .then(async (result: any) => {
                result = await result.json();
                if (result.Rooms.$values[0] !== undefined) {
                  contactservice
                    .UpdateRoomParticipantTeams(
                      data,
                      result.Rooms.$values[0].Participants.$values[0].Id
                    )
                    .then(async (res: any) => {
                      res = await res.json();
                    });
                  dispatch<any>(
                    UpdateRoomParticipantTeams(
                      data,
                      result.Rooms.$values[0].Participants.$values[0].Id
                    )
                  );
                } else {
                  //console.log(result);
                }
              });
            dispatch({
              alertData: {
                className: "success",
                text: "The contact was saved successfully!",
              },
              type: index.ActionTypes.setAlertData,
            });
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              2000
            );
            dispatch<any>(setTabOpened("contactopen"));
            setTimeout(() => {
              dispatch<any>(setSpinner(false));
              setTimeout(
                () =>
                  dispatch<any>(
                    setContactSelected_OpenContact({
                      ...result,
                      type: "YakChat",
                    })
                  ),
                700
              );
            }, 400);
            setTimeout(
              () =>
                dispatch<any>(
                  setContactSelected_OpenContact({ ...result, type: "YakChat" })
                ),
              700
            );
          })
          .catch((messages) => {
            console.log(messages.response);
            ////////debugger;
            dispatch({
              alertData: {
                className: "success",
                text: "You cannot add a local contact with a telephone number that matches an existing local contact.",
              },
              type: index.ActionTypes.setAlertData,
            });
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              5000
            );
          });
      } catch (error) {
        window.scrollTo(window.scrollX, 0);
        dispatch({
          alertData: {
            className: "danger",
            text: "The contact was not saved. Please try again.",
          },
          type: index.ActionTypes.setAlertData,
        });
        //console.log(error);
      }
    }
  };
};
export const saveYakChatContact_V2 = (data: any, savedFrom: any) => {
  ////////debugger;
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        if (
          store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 6 ||
          store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 7
        ) {
          // if (data.groupEmail == "" || data.groupEmail == null) {
          //   //////debugger;
          //   dispatch({
          //     alertData: {
          //       className: "danger",
          //       text: "Please choose Shared Inbox to share contact with, you are on Shared plan.",
          //     },
          //     type: index.ActionTypes.setAlertData,
          //   });
          //   setTimeout(
          //     () =>
          //       dispatch({
          //         alertData: null,
          //         type: index.ActionTypes.setAlertData,
          //       }),
          //     5000
          //   );
          //   return;
          // }
        }
        contactservice
          .saveYakChatContact_V2(data, savedFrom)
          .then(async (result: any) => {
            //////debugger;
            result = await result.json();
            var countContact = 0;
            countContact++;
            if (result.Data == null) {
              dispatch({
                alertData: {
                  className: "success",
                  text: result.Message,
                },
                type: index.ActionTypes.setAlertData,
              });
              setTimeout(
                () =>
                  dispatch({
                    alertData: null,
                    type: index.ActionTypes.setAlertData,
                  }),
                2000
              );
              return;
            }
            var object = {};
            object = {
              InboxId: result.Data.contactPermissions?.$values[0].InboxId,
              CompanyId: result.Data.contact.CompanyId,
              ContactEmail: result.Data.contact.ContactEmail,
              ContactId: result.Data.contact.ContactId,
              CreatedBy: result.Data.contact.CreatedBy,
              CreatedDateTime: result.Data.contact.CreatedDateTime,
              ForeName: result.Data.contact.ForeName,
              GroupEmail: result.Data.contact.GroupEmail,
              OwnerId: result.Data.contact.OwnerId,
              PhoneNumber: result.Data.contact.PhoneNumber,
              SureName: result.Data.contact.SureName,
              UserEmail: result.Data.contact.UserEmail,
              contactLists: result.Data.contact.contactLists,
            };
            result.Data = object;
            setTimeout(
              () =>
                dispatch<any>(
                  setContactSelected_OpenContact({
                    ...result.Data,
                    type: "YakChat",
                  })
                ),
              2000
            );
            ////////debugger;
            if (result.Data != null) {
              if (result.Data.GroupEmail) {
                dispatch<any>(getYakChatGroupData_V2(1));
                dispatch<any>(getyakchatContacts_V2(1));
                setTimeout(
                  () =>
                    dispatch<any>(
                      setContactSelected_OpenContact({
                        ...result,
                        type: "YakChat",
                      })
                    ),
                  1500
                );
              } else {
                dispatch<any>(getyakchatContacts_V2(1));
                setTimeout(
                  () =>
                    dispatch<any>(
                      setContactSelected_OpenContact({
                        ...result.Data,
                        type: "YakChat",
                      })
                    ),
                  1500
                );
              }

              if (store.getState().reduxReducer.isFromAddContact === true) {
                //dispatch<any>(UpdateRoomParticipantTeams_V2(data, savedFrom));
                setTimeout(
                  () =>
                    dispatch<any>(
                      setContactSelected_OpenContact({
                        ...result.Data,
                        type: "YakChat",
                      })
                    ),
                  // dispatch<any>(getPagingMessagesByRoomId(userEmail, roomId, 1, ""))
                  1000
                );
              } else {
                dispatch<any>(contactFromAddContact(false));
              }
            }
            contactservice
              .searchConversation_V2(
                false,
                encodeURIComponent(data.phoneNumber),
                store.getState().reduxReducer.sharedInboxData.currentSelected
                  .email
              )
              .then(async (resultsearch: any) => {
                resultsearch = await resultsearch.json();
                resultsearch = resultsearch.Data;
                ////////debugger;
                // if (resultsearch.Rooms.$values[0] !== undefined) {

                //   dispatch<any>(
                //     UpdateRoomParticipantTeams_V2(
                //       data,
                //       resultsearch.Rooms.$values[0].Participants.$values[0].Id
                //     )
                //   );

                // } else {
                //   //console.log(resultsearch);
                // }
              });
            dispatch({
              alertData: {
                className: "success",
                text: "The contact was saved successfully!",
              },
              type: index.ActionTypes.setAlertData,
            });
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              2000
            );
            dispatch<any>(setTabOpened("contactopen"));
            setTimeout(() => {
              dispatch<any>(setSpinner(false));
              setTimeout(
                () =>
                  dispatch<any>(
                    setContactSelected_OpenContact({
                      ...result.Data,
                      type: "YakChat",
                    })
                  ),
                700
              );
            }, 400);
            setTimeout(
              () =>
                dispatch<any>(
                  setContactSelected_OpenContact({
                    ...result.Data,
                    type: "YakChat",
                  })
                ),
              700
            );
          })
          .catch((messages) => {
            console.log(messages.response);
            ////////debugger;
            dispatch({
              alertData: {
                className: "success",
                text: "You cannot add a local contact with a telephone number that matches an existing local contact.",
              },
              type: index.ActionTypes.setAlertData,
            });
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              5000
            );
          });
      } catch (error) {
        window.scrollTo(window.scrollX, 0);
        dispatch({
          alertData: {
            className: "danger",
            text: "The contact was not saved. Please try again.",
          },
          type: index.ActionTypes.setAlertData,
        });
        //console.log(error);
      }
    }
  };
};

export const uploadYakChatContact_V2 = (data: any, savedFrom: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      dispatch({
        alertData: {
          className: "success",
          text: "Please Wait Uploading Contacts ............!",
        },
        type: index.ActionTypes.setAlertData,
      });
      try {
        ////////debugger;

        contactservice
          .uploadYakChatContact_V2(data, savedFrom)
          .then(async (result: any) => {
            result = await result.json();
          });
        if (result?.length !== 0) {
          dispatch<any>(
            setContactSelected_OpenContact({ ...result, type: "YakChat" })
          );
          dispatch<any>(setTabOpened("contactopen"));
          if (savedFrom > 500) {
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              25000
            );
          } else if (savedFrom > 200 && savedFrom < 500) {
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              15000
            );
          } else if (savedFrom > 100 && savedFrom < 200) {
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              10000
            );
          } else if (savedFrom > 40 && savedFrom < 100) {
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              10000
            );
          } else {
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              8000
            );
          }
        }
        setTimeout(() => dispatch<any>(getyakchatContacts_V2(1)), 6500);
      } catch (error) {
        //console.log(error);
      }
      dispatch<any>(setSpinner(false));
    }
  };
};
export const uploadYakChatContact = (data: any, savedFrom: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      dispatch({
        alertData: {
          className: "success",
          text: "Please Wait Uploading Contacts ............!",
        },
        type: index.ActionTypes.setAlertData,
      });
      try {
        contactservice
          .uploadYakChatContact(data, savedFrom)
          .then(async (result: any) => {
            result = await result.json();
          });
        if (result?.length !== 0) {
          dispatch<any>(
            setContactSelected_OpenContact({ ...result, type: "YakChat" })
          );
          dispatch<any>(setTabOpened("contactopen"));
          if (savedFrom > 500) {
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              25000
            );
          } else if (savedFrom > 200 && savedFrom < 500) {
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              15000
            );
          } else if (savedFrom > 100 && savedFrom < 200) {
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              10000
            );
          } else if (savedFrom > 40 && savedFrom < 100) {
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              10000
            );
          } else {
            setTimeout(
              () =>
                dispatch({
                  alertData: null,
                  type: index.ActionTypes.setAlertData,
                }),
              8000
            );
          }
        }
        setTimeout(() => dispatch<any>(getyakchatContacts(1)), 6500);
      } catch (error) {
        //console.log(error);
      }
      dispatch<any>(setSpinner(false));
    }
  };
};
export const UpdateRoomParticipantTeams_V2 = (
  data: any,
  participantId: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice
          .UpdateRoomParticipantTeams(data, participantId)
          .then(async (res: any) => {
            //////debugger;
            var selectedEmail =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email;
            var loggedInEmail =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email;
            if (selectedEmail === loggedInEmail) {
              //////debugger;
              dispatch<any>(
                getPersonalInbox_V2(
                  1,
                  loggedInEmail,
                  false,
                  selectedEmail,
                  "updateRooms",
                  ""
                )
              );
            } else if (selectedEmail === "Closed") {
              dispatch<any>(
                getPersonalInbox_V2(
                  1,
                  loggedInEmail,
                  true,
                  "''",
                  "updateRooms",
                  ""
                )
              );
            } else {
              dispatch<any>(
                getPersonalInbox_V2(
                  1,
                  loggedInEmail,
                  false,
                  selectedEmail,
                  "updateRooms",
                  ""
                )
              );
            }
          });
        // dispatch<any>(setTabOpened("contactopen"));
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const UpdateRoomParticipantTeams = (data: any, participantId: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice
          .UpdateRoomParticipantTeams(data, participantId)
          .then(async (res: any) => {
            var selectedEmail =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email;
            var loggedInEmail =
              store.getState().reduxReducer.loggedInUser.Email;
            if (selectedEmail === loggedInEmail) {
              dispatch<any>(
                getPersonalInbox(
                  1,
                  loggedInEmail,
                  false,
                  selectedEmail,
                  "updateRooms",
                  ""
                )
              );
            } else if (selectedEmail === "Closed") {
              dispatch<any>(
                getPersonalInbox(
                  1,
                  loggedInEmail,
                  true,
                  "''",
                  "updateRooms",
                  ""
                )
              );
            } else {
              dispatch<any>(
                getPersonalInbox(
                  1,
                  loggedInEmail,
                  false,
                  selectedEmail,
                  "updateRooms",
                  ""
                )
              );
            }
          });
        // dispatch<any>(setTabOpened("contactopen"));
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const getYakChatGroupData = (pageIndex: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var sharedInboxDataEmails =
          store.getState().reduxReducer.shareMsgCount?.$values !== undefined
            ? store
                .getState()
                .reduxReducer.shareMsgCount?.$values.map(
                  (a: any) => a?.sharedEmail
                )
                .join(",")
            : store
                .getState()
                .reduxReducer.shareMsgCount?.map((a: any) => a?.sharedEmail)
                .join(",");
        sharedInboxDataEmails =
          sharedInboxDataEmails !== undefined ? sharedInboxDataEmails : "";
        var userEmail =
          store.getState().reduxReducer.loggedInUser.Email +
          "," +
          sharedInboxDataEmails;
        contactservice.getYakChatGroupData().then(async (res: any) => {
          res = await res.json();

          try {
            res = res.groupemailUsers.$values
              ? res.groupemailUsers.$values
              : res.groupemailUsers;
          } catch (err) {
            //console.log(err);
            return;
          }
          for (let i in res) {
            const group = res[i];
            if (res[i] !== null)
              await contactservice
                .getyakchatcontactlist(pageIndex, group?.Email)
                .then(async (r: any) => {
                  r = await r?.json();
                  res[i].members = r;
                });
          }

          dispatch({
            groupList: res,
            type: index.ActionTypes.Yak_Chat_GroupEmail_List,
          });
        });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const getYakChatGroupData_V2 = (pageIndex: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var sharedInboxDataEmails =
          store.getState().reduxReducer.shareMsgCount?.$values !== undefined
            ? store
                .getState()
                .reduxReducer.shareMsgCount?.$values.map(
                  (a: any) => a?.sharedEmail
                )
                .join(",")
            : store
                .getState()
                .reduxReducer.shareMsgCount?.map((a: any) => a?.sharedEmail)
                .join(",");
        sharedInboxDataEmails =
          sharedInboxDataEmails !== undefined ? sharedInboxDataEmails : "";
        var userEmail =
          store.getState().reduxReducer.sharedInboxData.currentSelected.email +
          "," +
          sharedInboxDataEmails;
        contactservice.getYakChatGroupData().then(async (res: any) => {
          res = await res.json();

          try {
            res = res.groupemailUsers.$values
              ? res.groupemailUsers.$values
              : res.groupemailUsers;
          } catch (err) {
            //console.log(err);
            return;
          }
          for (let i in res) {
            const group = res[i];
            if (res[i] !== null)
              await contactservice
                .getyakchatcontactlist_V2(pageIndex)
                .then(async (r: any) => {
                  r = await r?.json();
                  res[i].members = r;
                });
          }

          dispatch({
            groupList: res,
            type: index.ActionTypes.Yak_Chat_GroupEmail_List,
          });
        });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const showInfo = (Showinfo: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.SHOW_HIDE_Info,
      ShowhideInfo: Showinfo,
    });
  };
};

export const showCollapse = (opencollapse: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.OPEN_COLLAPSE, opencollapse });
  };
};

export const setSelectedContacts = (contact: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      selectedContactsNewMessage: contact,
      type: index.ActionTypes.setSelectedContacts_NewMessage,
    });
  };
};

export const storeActualContactsInList = (contact: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      storeActualContactsInListAction: contact,
      type: index.ActionTypes.ACTUAL_CONTACTS_IN_LIST,
    });
  };
};

export const setnewcontactData = (contact: any) => {
  return async (dispatch: Dispatch) => {
    dispatch<any>(contactFromAddContact(true));
    dispatch({
      NewContactData: contact,
      type: index.ActionTypes.SetNewContactData,
    });
  };
};
export const setTabOpened = (tab: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      PrevTab: store.getState().reduxReducer.tabOpened,
      type: index.ActionTypes.SetPrevious_Tab,
    });
    dispatch({ tabOpened: tab, type: index.ActionTypes.setTabOpened_Main });
  };
};

export const setContactSelected_OpenContact = (contact: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      contactSelected_OpenContact: contact,
      type: index.ActionTypes.setContactSelected_OpenContact,
    });
  };
};

export const getUsableCreditForUser = () => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        let companyVersion = store.getState().reduxReducer.companyVersion;
        if (companyVersion === 2) {
          dispatch({
            usableCredit:
              store.getState().reduxReducer.loggedInUser.user.Credit,
            type: index.ActionTypes.usableCredit,
          });
        } else {
          contactservice.getUsableCreditForUser().then(async (res: any) => {
            res = await res.json();
            if (res.Message) {
              return;
            }
            dispatch({
              usableCredit: res,
              type: index.ActionTypes.usableCredit,
            });
          });
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
};

export const saveYakChatSettings = (
  checkedvalue: string,
  isSharePoint: string
) => {
  return async (dispatch: Dispatch) => {
    var checkFlag;
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice
          .saveYakChatSettings(checkedvalue, isSharePoint)
          .then(async (res: any) => {
            res = await res.json();
            dispatch({
              checkedvalue: checkedvalue,
              type: index.ActionTypes.SAVE_YAKCHAT_SETTINGS,
            });
            dispatch({
              alertData: {
                className: "success",
                text: "Settings saved successfully!",
              },
              type: index.ActionTypes.setAlertData,
            });
            setTimeout(() => {
              dispatch<any>(setAlertData(null));
            }, 2000);
          });
      } catch (error) {
        dispatch({
          alertData: {
            className: "danger",
            text: "Settings were not saved. Please try again.",
          },
          type: index.ActionTypes.setAlertData,
        });
        setTimeout(
          () =>
            dispatch({ alertData: null, type: index.ActionTypes.setAlertData }),
          2000
        );
        //console.log(error);
      }
    }
  };
};
export const saveYakChatSettings_V2 = (
  checkedvalue: string,
  isSharePoint: string
) => {
  return async (dispatch: Dispatch) => {
    var checkFlag;
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice
          .saveYakChatSettings_V2(checkedvalue, isSharePoint)
          .then(async (res: any) => {
            res = await res.json();
            res = res.Data;
            dispatch({
              checkedvalue: checkedvalue,
              type: index.ActionTypes.SAVE_YAKCHAT_SETTINGS,
            });
            dispatch({
              alertData: {
                className: "success",
                text: "Settings saved successfully!",
              },
              type: index.ActionTypes.setAlertData,
            });
            setTimeout(() => {
              dispatch<any>(setAlertData(null));
            }, 2000);
          });
      } catch (error) {
        dispatch({
          alertData: {
            className: "danger",
            text: "Settings were not saved. Please try again.",
          },
          type: index.ActionTypes.setAlertData,
        });
        setTimeout(
          () =>
            dispatch({ alertData: null, type: index.ActionTypes.setAlertData }),
          2000
        );
        //console.log(error);
      }
    }
  };
};
export const updateFirsReceiveConversation = (roomId: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        if (roomId !== undefined) {
          contactservice
            .UpdateFirstReceiveConvName(roomId)
            .then(async (res: any) => {
              // res = await res.json();
              res = await res.text();
            });
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const SetRoomsByMessage = (pageIndex: number, email: string) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice.getRooms(pageIndex, email).then(async (res: any) => {
          res = await res.json();
          if (
            res.Message === "Authorization has been denied for this request."
          ) {
            AuthService.signOut();
            return;
          }
          res.sort((x: any, y: any) => {
            if (!x.Messages.$values[0] || !y.Messages.$values[0]) return 0;
            if (
              new Date(x.Messages.$values[0].TimeStamp) >
              new Date(y.Messages.$values[0].TimeStamp)
            )
              return -1;
            else return 1;
          });
          // res = res.Rooms.$values ? res.Rooms.$values : res.Rooms;
          for (let room of res) {
            room.avatarColor = avatarColorMap[room.Id % 10];
            for (let participant of room.Participants.$values)
              participant.avatarColor = avatarColorMap[participant.Id % 10];
          }
          dispatch({
            roomData: res,
            type: index.ActionTypes.setRooms,
          });
        });
      } catch (err) {
        //console.log(err);
        // await AuthService.saveToken();
      }
    }
  };
};
export const setRoomsAction = (roomData: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({ roomData: roomData, type: index.ActionTypes.setRooms });
  };
};
export const getRoomsCount = (pageIndex: number, email: string) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice.getRooms(pageIndex, email).then(async (res: any) => {
          res = await res.json();
          if (
            res.Message === "Authorization has been denied for this request."
          ) {
            AuthService.signOut();
            return;
          }
          res.sort((x: any, y: any) => {
            if (!x.Messages.$values[0] || !y.Messages.$values[0]) return 0;
            if (
              new Date(x.Messages.$values[0].TimeStamp) >
              new Date(y.Messages.$values[0].TimeStamp)
            )
              return -1;
            else return 1;
          });
          if (res?.length > 0) {
            res.map((x: any, i: number) => {
              if (x.Messages?.$values?.length > 0) {
                var IncomingMessages = x.Messages?.$values?.filter(
                  (x: any) => x.Direction === -10
                );
                if (IncomingMessages?.length > 0) {
                  IncomingMessages.map((y: any, j: number) => {
                    if (x.LastSeen !== undefined && y.Id > x.LastSeen) {
                      if (
                        NewGroupMessageCount > 0 &&
                        NewGroupMessageCount ===
                          store.getState().reduxReducer.NewGroupUnreadMessage
                      ) {
                        return;
                      }
                      NewGroupMessageCount = NewGroupMessageCount + 1;
                    }
                  });
                }
              }
            });
          }
        });
      } catch (err) {
        //console.log(err);
        // await AuthService.saveToken();
      }
    }
  };
};
export const shouldIncludePersonalInbox0 = () => {
  return async (dispatch: Dispatch) => {
    var NewGroupmessagesCount = [{ Email: "", Istrue: "", UserName: "" }];
    const obj = {
      currentSelected: {
        email: store.getState().reduxReducer.loggedInUser.Email,
        name: store.getState().reduxReducer.loggedInUser.UserName,
        UniqueNumber: store.getState().reduxReducer.loggedInUser.UniqueNumber,
      },
      other: [{ email: "Closed", name: "Closed" }],
      NewOtherGroupMessage: NewGroupmessagesCount,
    };
    dispatch({
      sharedInboxData: obj,
      type: index.ActionTypes.setSharedInboxData,
    });
    var loggedInUserEmail = store.getState().reduxReducer.loggedInUser.Email;
    dispatch<any>(
      getPersonalInbox(
        1,
        loggedInUserEmail,
        false,
        "''",
        "IncludePersonalInbox",
        ""
      )
    );
  };
};

export const shouldIncludePersonalInbox0_V2 = () => {
  return async (dispatch: Dispatch) => {
    ////debugger;
    var NewGroupmessagesCount = [{ Email: "", Istrue: "", UserName: "" }];
    const obj = {
      currentSelected: {
        email:store.getState().reduxReducer.loggedInUser.inboxes.$values.filter(
          (item: any) => item.InboxType == 0 )[0].InboxId,
        name: store.getState().reduxReducer.loggedInUser.inboxes.$values.filter(
          (item: any) => item.InboxType == 0 )[0].Name,
        UniqueNumber:store.getState().reduxReducer.loggedInUser.inboxes.$values.filter(
          (item: any) => item.InboxType == 0 )[0].PhoneNumber,
      },
      other: [{ email: "Closed", name: "Closed" }],
      NewOtherGroupMessage: NewGroupmessagesCount,
    };
    dispatch({
      sharedInboxData: obj,
      type: index.ActionTypes.setSharedInboxData,
    });
    var loggedInUserEmail =
      store.getState().reduxReducer.sharedInboxData.currentSelected.email;
    dispatch<any>(
      getPersonalInbox_V2(
        1,
        loggedInUserEmail,
        false,
        "''",
        "IncludePersonalInbox",
        ""
      )
    );
  };
};
export const getInboxList = (shouldIncludePersonalInbox: number) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        // dispatch<any>(getArchivedRooms(1));

        if (shouldIncludePersonalInbox === 0) {
          dispatch<any>(shouldIncludePersonalInbox0());
        } else {
          contactservice.GetGroupsEmails().then(async (res: any) => {
            res = await res.json();
            ////////debugger;
            localStorage.setItem("InboxList", res);
            var memberEmailSplit = res.members.split("*").toString();
            var membersEmails = memberEmailSplit.split("#").toString();
            var userEmail = store.getState().reduxReducer.loggedInUser.Email;
            var isExist = membersEmails.includes(userEmail);

            if (isExist === false || res.groupemailUsers?.$values[0] === null) {
              dispatch<any>(opensharedError(true));
              return;
            }

            if (
              !res ||
              res?.length === 0 ||
              !res.groupemailUsers ||
              res.groupemailUsers?.$values?.length === 0
            )
              return;
            res = res.groupemailUsers.$values;
            var allSharedEmails = res.filter((sharedEmail: any) => {
              return sharedEmail !== null ? sharedEmail.Email : "";
            });
            dispatch({
              setSharedEmails: allSharedEmails,
              type: index.ActionTypes.setSharedEmails,
            });
            if (res?.length > 0) {
              res = res.sort((a: any, b: any) => {
                if (a !== null) var nameA = a?.UserName?.toUpperCase();
                var nameB = b?.UserName?.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              });
              dispatch({
                SetGroupemail: res[0]?.Email,
                type: index.ActionTypes.SetAplhaBetGroupEmail,
              });
            }
            let otherInboxes: any;
            let obj: any;
            var NewGroupmessagesCount = [
              { Email: "", Istrue: "", UserName: "" },
            ];

            if (
              shouldIncludePersonalInbox === 2 &&
              store.getState().reduxReducer.loggedInUser.Subscription
                .ServicePlanId !== 7
            ) {
              otherInboxes = res.map((x: any, i: number) => {
                if (NewGroupMessageCount > 0) {
                  NewGroupmessagesCount.push({
                    Email: x?.Email,
                    Istrue: "true",
                    UserName: x?.UserName,
                  });
                }
                return {
                  email: x?.Email,
                  name: x?.UserName,
                  UniqueNumber: x?.UniqueNumber,
                };
              });
              obj = {
                currentSelected: {
                  email: store.getState().reduxReducer.loggedInUser.Email,
                  name: store.getState().reduxReducer.loggedInUser.UserName,
                  UniqueNumber:
                    store.getState().reduxReducer.loggedInUser.UniqueNumber,
                },
                other: [...otherInboxes, { email: "Closed", name: "Closed" }],
                NewOtherGroupMessage: NewGroupmessagesCount,
              };
              dispatch({
                sharedInboxData: obj,
                type: index.ActionTypes.setSharedInboxData,
              });
            } else {
              otherInboxes = res.slice(1).map((x: any, i: number) => {
                return {
                  email: x !== null ? x.Email : "",
                  name: x !== null ? x.UserName : "",
                  UniqueNumber: x !== null ? x.UniqueNumber : "",
                };
              });
              obj = {
                currentSelected: {
                  email: res[0].Email,
                  name: res[0].UserName,
                  UniqueNumber: res[0].UniqueNumber,
                },
                other: [...otherInboxes, { email: "Closed", name: "Closed" }],
                NewOtherGroupMessage: NewGroupmessagesCount,
              };
              dispatch({
                sharedInboxData: obj,
                type: index.ActionTypes.setSharedInboxData,
              });
            }
            const toSend = obj.other.find(
              (x: any) => x.email === obj.currentSelected.email
            );
            if (toSend !== undefined) {
              dispatch<any>(setCurrentSelectedSharedEmail(toSend, "", ""));
            } else {
              dispatch<any>(
                setCurrentSelectedSharedEmail(
                  obj.currentSelected,
                  "getInboxList",
                  ""
                )
              );
            }
          });
        }
      } catch (err) {
        //console.log(err);
        // await AuthService.saveToken();
      }
    }
  };
};
export const getInboxList_V2 = (shouldIncludePersonalInbox: number) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////debugger;
        // dispatch<any>(getArchivedRooms(1));

        if (shouldIncludePersonalInbox === 0) {
          dispatch<any>(shouldIncludePersonalInbox0_V2());
        } else {
          let res = store
            .getState()
            .reduxReducer.loggedInUser.inboxes.$values.filter(
              (item: any) => item.InboxType !== 0
            );

          localStorage.setItem("InboxList", res);
          // var memberEmailSplit = res.members.split("*").toString();
          // var membersEmails = memberEmailSplit.split("#").toString();
          // var userEmail =store.getState().reduxReducer.sharedInboxData.currentSelected.email;
          // var isExist = membersEmails.includes(userEmail);
          ////////debugger;
          // if (isExist === false) {
          //   dispatch<any>(opensharedError(true));
          // }
          if (
            (!res || res?.length === 0) &&
            (store.getState().reduxReducer.loggedInUser.user.ServicePlanId ===
              6 ||
              store.getState().reduxReducer.loggedInUser.user.ServicePlanId ===
                7)
          ) {
            dispatch<any>(opensharedError(true));
          } // return;
          //res = res.groupemailUsers.$values;
          var allSharedEmails = res.filter((sharedEmail: any) => {
            return sharedEmail.Name;
          });
          dispatch({
            setSharedEmails: allSharedEmails,
            type: index.ActionTypes.setSharedEmails,
          });
          if (res?.length > 0) {
            res = res.sort((a: any, b: any) => {
              if (a !== null) var nameA = a?.Name?.toUpperCase();
              var nameB = b?.Name?.toUpperCase();
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            });
            dispatch({
              SetGroupemail: res[0]?.Email,
              type: index.ActionTypes.SetAplhaBetGroupEmail,
            });
          }
          let otherInboxes: any;
          let obj: any;
          var NewGroupmessagesCount = [{ Email: "", Istrue: "", UserName: "" }];

          if (
            shouldIncludePersonalInbox === 2 &&
            store.getState().reduxReducer.loggedInUser.user.ServicePlanId !== 7
          ) {
            otherInboxes = res.map((x: any, i: number) => {
              if (NewGroupMessageCount > 0) {
                NewGroupmessagesCount.push({
                  Email: x?.InboxId,
                  Istrue: "true",
                  UserName: x?.Name,
                });
              }
              return {
                email: x?.InboxId,
                name: x?.Name,
                UniqueNumber: x?.PhoneNumber,
              };
            });
            let personalinbox = store
              .getState()
              .reduxReducer.loggedInUser.inboxes.$values.filter(
                (item: any) => item.InboxType === 0
              );
              if(personalinbox.length>0)
              {
            obj = {
              currentSelected: {
                email: personalinbox[0].InboxId,
                name: personalinbox[0].Name,
                UniqueNumber: personalinbox[0].PhoneNumber,
              },
              other: [...otherInboxes, { email: "Closed", name: "Closed" }],
              NewOtherGroupMessage: NewGroupmessagesCount,
            };
          }
          else
          {
            otherInboxes = res.slice(1).map((x: any, i: number) => {
              return {
                email: x.InboxId,
                name: x.Name,
                UniqueNumber: x.PhoneNumber,
              };
            });
            obj={
              currentSelected: {
                email: res[0].InboxId,
                name: res[0].Name,
                UniqueNumber: res[0].PhoneNumber,
              },
              other: [...otherInboxes, { email: "Closed", name: "Closed" }],
              NewOtherGroupMessage: NewGroupmessagesCount,
            }
          }
            dispatch({
              sharedInboxData: obj,
              type: index.ActionTypes.setSharedInboxData,
            });
          } else {
            otherInboxes = res.slice(1).map((x: any, i: number) => {
              return {
                email: x.InboxId,
                name: x.Name,
                UniqueNumber: x.PhoneNumber,
              };
            });
            obj = {
              currentSelected: {
                email: res[0].InboxId,
                name: res[0].Name,
                UniqueNumber: res[0].PhoneNumber,
              },
              other: [...otherInboxes, { email: "Closed", name: "Closed" }],
              NewOtherGroupMessage: NewGroupmessagesCount,
            };
            dispatch({
              sharedInboxData: obj,
              type: index.ActionTypes.setSharedInboxData,
            });
          }
          const toSend = obj.other.find(
            (x: any) => x.email === obj.currentSelected.email
          );
          if (toSend !== undefined) {
            dispatch<any>(setCurrentSelectedSharedEmail(toSend, "", ""));
          } else {
            dispatch<any>(
              setCurrentSelectedSharedEmail(
                obj.currentSelected,
                "getInboxList",
                ""
              )
            );
          }
        }
      } catch (err) {
        //console.log(err);
        // await AuthService.saveToken();
      }
    }
  };
};
// export const setSharedEmails =() =>{
//   return async (dispatch: Dispatch) => {
//     dispatch({
//       type: index.ActionTypes.CURRENT_PAGE_INDEX_FOR_INBOX,
//       currentPageIndexForInbox: currentPageIndexForInbox,
//     });
//   };
// }

export const currentPageIndexForInbox = (currentPageIndexForInbox: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.CURRENT_PAGE_INDEX_FOR_INBOX,
      currentPageIndexForInbox: currentPageIndexForInbox,
    });
  };
};
export const nextPageIndexForInbox = (nextPageIndexForInbox: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.PAGE_COUNT_PAGE_INDEX_FOR_INBOX,
      nextPageIndexForInbox: nextPageIndexForInbox,
    });
  };
};
export const currentPageIndexForYakChat = (currentPageIndexForYakChat: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.CURRENT_PAGE_INDEX_FOR_YAKCHAT,
      currentPageIndexForYakChat: currentPageIndexForYakChat,
    });
  };
};
export const nextPageIndexForYakChat = (nextPageIndexForYakChat: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.PAGE_COUNT_PAGE_INDEX_FOR_YAKCHAT,
      nextPageIndexForYakChat: nextPageIndexForYakChat,
    });
  };
};
export const displayPreviousAndNewRooms = (
  response: any,
  newRooms: any,
  oldRooms: any,
  groupEmail: any,
  loggedInUserEmail: any
) => {
  return async (dispatch: Dispatch) => {
    var roomsData = [...oldRooms, ...newRooms];
    var data = oldRooms.concat(newRooms);
    var oldIds = oldRooms.map((val: any) => {
      return val.Id;
    });
    var newIds = newRooms.map((val: any) => {
      return val.Id;
    });
    if (oldIds.some((item: any) => newIds.includes(item))) {
      dispatch({ roomData: oldRooms, type: index.ActionTypes.setRooms });
    } else {
      dispatch({ roomData: roomsData, type: index.ActionTypes.setRooms });
    }
    var currentRoom = oldRooms?.length !== 0 ? oldRooms[0] : [];
    var roomId = Object.keys(currentRoom)?.length !== 0 ? currentRoom.Id : 0;
    if (roomId !== 0) {
      var userEmail =
        currentRoom.Archived === true
          ? currentRoom.CreatorEmail
          : groupEmail !== "''"
          ? groupEmail
          : loggedInUserEmail;
      // dispatch<any>(getPagingMessagesByRoomId(userEmail, roomId, 1, ""))
    } else {
      dispatch<any>(setOpenMessageRoom(currentRoom));
      dispatch<any>(setTabOpened("chatpanel"));
    }
  };
};
export const UpdateThisMessageStatusInThisRoom = (
  roomId: any,
  pageIndex: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        let openMessageRoomId = store.getState().reduxReducer.openMessageRoom;
        var userEmail =
          store.getState().reduxReducer.sharedInboxData?.currentSelected
            ?.email === "Closed"
            ? openMessageRoomId.CreatorEmail
            : store.getState().reduxReducer.sharedInboxData?.currentSelected
                ?.email;
        if (roomId === openMessageRoomId?.Id) {
          let res2: any = null;
          let companyVersion = store.getState().reduxReducer.companyVersion;
          if (companyVersion === 2) {
            res2 = await contactservice.getPagingMessagesByRoomId_V2(
              roomId,
              pageIndex
            );
          } else {
            res2 = await contactservice.getPagingMessagesByRoomId(
              userEmail,
              roomId,
              pageIndex
            );
          }
          res2 = await res2?.json();
          ////////debugger;
          if (companyVersion === 2) {
            res2 = res2.Data;
          }
          if (!res2) return;
          let messageSort: any =
            res2.Rooms?.$values?.length !== 0
              ? res2.Rooms?.$values[0]?.Messages.$values
              : [];
          if (messageSort?.length !== 0) {
            messageSort.sort((x: any, y: any) => {
              if (!x || !y) return 0;
              if (new Date(x.TimeStamp) <= new Date(y.TimeStamp)) return -1;
              else return 1;
            });
            res2.Rooms.$values[0].Messages.$values = messageSort;
          }
          dispatch<any>(currentPageIndexForRoomMessages(res2.CurrentPageIndex));
          dispatch<any>(nextPageIndexForRoomMessages(res2.PageCount));
          var openRoom =
            res2.Rooms?.$values?.length !== 0 ? res2.Rooms?.$values[0] : [];
          var filterColor = store
            .getState()
            .reduxReducer?.roomData?.filter((x: any) => x.Id === roomId)[0];
          openRoom.avatarColor = filterColor.avatarColor;
          dispatch<any>(setOpenMessageRoom(openRoom));
          setTimeout(() => {
            dispatch<any>(setOpenMessageRoom(openRoom));
          }, 800);
        }
      } catch (err) {
        //console.log(err);
        //   await AuthService.saveToken();
      }
    }
  };
};
export const GetGroupRoomPrimaryMember = (roomId: any, calledFrom: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      contactservice
        .GetGroupRoomPrimaryMember(roomId)
        .then(async (resp: any) => {
          resp = await resp.json();
          if (
            resp.Message === "Authorization has been denied for this request."
          ) {
            AuthService.signOut();
            return;
          }
          if (resp.FirstUpdateRoomName !== true) {
            if (roomId !== undefined) {
              dispatch<any>(updateFirsReceiveConversation(roomId));
            }
            setTimeout(() => {
              var selectedEmail =
                store.getState().reduxReducer.sharedInboxData.currentSelected
                  .email;
              var loggedInEmail =
                store.getState().reduxReducer.loggedInUser.Email;
              if (selectedEmail === loggedInEmail) {
                dispatch<any>(
                  getPersonalInbox(
                    1,
                    loggedInEmail,
                    false,
                    selectedEmail,
                    calledFrom,
                    ""
                  )
                );
              } else if (selectedEmail === "Closed") {
                dispatch<any>(
                  getPersonalInbox(1, loggedInEmail, true, "''", calledFrom, "")
                );
              } else {
                dispatch<any>(
                  getPersonalInbox(
                    1,
                    loggedInEmail,
                    false,
                    selectedEmail,
                    calledFrom,
                    ""
                  )
                );
              }
            }, 100);
          } else {
            var loggedInEmail =
              store.getState().reduxReducer.loggedInUser.Email;
            var sharedEmail =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email;
            var calledFromValue =
              calledFrom === "SignalR" ? "SignalR" : "CreateRoom";
            var userEmail = loggedInEmail;
            // if(calledFrom !== "SignalR") {
            if (loggedInEmail === sharedEmail) {
              dispatch<any>(
                getPersonalInbox(
                  1,
                  loggedInEmail,
                  false,
                  "''",
                  calledFrom,
                  roomId
                )
              );
            } else if (sharedEmail === "Closed") {
              dispatch<any>(
                getPersonalInbox(
                  1,
                  loggedInEmail,
                  true,
                  "''",
                  calledFrom,
                  roomId
                )
              );
            } else {
              dispatch<any>(
                getPersonalInbox(
                  1,
                  loggedInEmail,
                  false,
                  sharedEmail,
                  calledFrom,
                  roomId
                )
              );
            }
            // }
            // document.getElementById("msg_tab")?.click();
          }
        });
    }
  };
};
export const GetGroupRoomPrimaryMember_V2 = (roomId: any, calledFrom: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      ////////debugger;
      contactservice
        .GetGroupRoomPrimaryMember(roomId)
        .then(async (resp: any) => {
          resp = await resp.json();
          if (
            resp.Message === "Authorization has been denied for this request."
          ) {
            AuthService.signOut();
            return;
          }
          if (resp.FirstUpdateRoomName !== true) {
            // if (roomId !== undefined) {
            //   dispatch<any>(updateFirsReceiveConversation(roomId));
            // }
            setTimeout(() => {
              var selectedEmail =
                store.getState().reduxReducer.sharedInboxData.currentSelected
                  .email;
              var loggedInEmail =
                store.getState().reduxReducer.loggedInUser.inboxes.$values[0]
                  .InboxId;
              if (selectedEmail === loggedInEmail) {
                dispatch<any>(
                  getPersonalInbox_V2(
                    1,
                    loggedInEmail,
                    false,
                    selectedEmail,
                    calledFrom,
                    ""
                  )
                );
              } else if (selectedEmail === "Closed") {
                dispatch<any>(
                  getPersonalInbox_V2(
                    1,
                    loggedInEmail,
                    true,
                    "''",
                    calledFrom,
                    ""
                  )
                );
              } else {
                dispatch<any>(
                  getPersonalInbox_V2(
                    1,
                    loggedInEmail,
                    false,
                    selectedEmail,
                    calledFrom,
                    ""
                  )
                );
              }
            }, 100);
          } else {
            var loggedInEmail =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email;
            var sharedEmail =
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email;
            var calledFromValue =
              calledFrom === "SignalR" ? "SignalR" : "CreateRoom";
            var userEmail = loggedInEmail;
            // if(calledFrom !== "SignalR") {
            if (loggedInEmail === sharedEmail) {
              dispatch<any>(
                getPersonalInbox_V2(
                  1,
                  loggedInEmail,
                  false,
                  "''",
                  calledFrom,
                  roomId
                )
              );
            } else if (sharedEmail === "Closed") {
              dispatch<any>(
                getPersonalInbox_V2(
                  1,
                  loggedInEmail,
                  true,
                  "''",
                  calledFrom,
                  roomId
                )
              );
            } else {
              dispatch<any>(
                getPersonalInbox_V2(
                  1,
                  loggedInEmail,
                  false,
                  sharedEmail,
                  calledFrom,
                  roomId
                )
              );
            }
            // }
            // document.getElementById("msg_tab")?.click();
          }
        });
    }
  };
};
export const updateRoomsFromWebSocket = (roomData: any, calledFrom: any) => {
  return async (dispatch: Dispatch) => {
    if (roomData?.RoomId !== undefined) {
      let companyVersion = store.getState().reduxReducer.companyVersion;
      if (companyVersion === 2) {
        if (roomData?.RoomId !== undefined) {
          dispatch<any>(
            GetGroupRoomPrimaryMember_V2(roomData.RoomId, calledFrom)
          );
        } else {
          dispatch<any>(
            GetGroupRoomPrimaryMember_V2(roomData[0]?.RoomId, calledFrom)
          );
        }
      } else {
        if (roomData?.RoomId !== undefined) {
          dispatch<any>(GetGroupRoomPrimaryMember(roomData.RoomId, calledFrom));
        } else {
          dispatch<any>(
            GetGroupRoomPrimaryMember(roomData[0]?.RoomId, calledFrom)
          );
        }
      }
    }
  };
};
export const getCircleIndicator = (newMessagesCount: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      CircleIndicator: newMessagesCount,
      type: index.ActionTypes.OwnerCircleIndicator,
    });
  };
};
export const getSharedInboxMsgCount = (shareMsgCount: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      sharedInboxMsgCount: shareMsgCount,
      type: index.ActionTypes.SHARED_INBOX_MESSAGE_COUNT,
    });
  };
};
export const getPersonalInbox = (
  pageIndex: number,
  loggedInUserEmail: any,
  archivedFlag: any,
  groupEmail: any,
  calledFrom: any,
  data: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      ////////debugger;
      //console.log(store.getState().reduxReducer.expireOn);
      //console.log(store.getState().reduxReducer);
      ////////debugger;
      var ExpirOnDate =
        store.getState().reduxReducer.expireOn == undefined
          ? store.getState().reduxReducer.loggedInUser.expiresOn
          : store.getState().reduxReducer.expireOn;
      var CurrentDate = new Date().toISOString();
      if (CurrentDate >= ExpirOnDate) {
        await AuthService.RefreshTokens();
      }
      //console.log("------------");
      //console.log(store.getState().reduxReducer);
      contactservice
        .GetPersonalInbox(
          pageIndex,
          loggedInUserEmail,
          archivedFlag,
          groupEmail
        )
        .then(async (resp: any) => {
          resp = await resp.json();
          ////debugger;
          if (
            resp.Message === "Authorization has been denied for this request."
          ) {
            AuthService.signOut();
            return;
          }
          resp.Rooms.$values.map((x: any) => {
            x.Participants.$values.map((y: any) => {
              if (y.Name === y.SfBPhoneNumber.PhoneNumber) {
                if (y !== undefined) {
                  if (
                    calledFrom !== "SideBarComponent" &&
                    calledFrom !== "updateRooms" &&
                    calledFrom !== "fromselectedEmails" &&
                    calledFrom !== "IncludePersonalInbox"
                  ) {
                    // contactservice
                    //   .GetContactsByUserEmailPhoneNumber(
                    //     store.getState().reduxReducer.loggedInUser.Email,
                    //     y.SfBPhoneNumber.PhoneNumber
                    //   )
                    //   .then(async (res: any) => {
                    //     res = await res.json();
                    //     if (res.ContactId !== 0) {
                    //       if (y.Name === y.SfBPhoneNumber.PhoneNumber) {
                    //         var obj = {
                    //           firstName: res.ForeName,
                    //           lastName:
                    //             res.SureName !== undefined&&res.SureName!=null ? res.SureName : "",
                    //           phoneNumber: res.PhoneNumber,
                    //           groupEmail:
                    //             res.GroupEmail !== undefined
                    //               ? res.GroupEmail
                    //               : store.getState().reduxReducer
                    //                   .sharedInboxData.currentSelected.email,
                    //         };
                    //         contactservice
                    //           .UpdateRoomParticipantTeams(obj, y.Id)
                    //           .then(async (res: any) => {
                    //             res = await res.status;
                    //             if (res) {
                    //               var selectedEmail =
                    //                 store.getState().reduxReducer
                    //                   .sharedInboxData.currentSelected.email;
                    //               var loggedInEmail =
                    //                 store.getState().reduxReducer.loggedInUser
                    //                   .Email;
                    //               if (selectedEmail === loggedInEmail) {
                    //                 dispatch<any>(
                    //                   getPersonalInbox(
                    //                     1,
                    //                     loggedInEmail,
                    //                     false,
                    //                     selectedEmail,
                    //                     "updateRooms",
                    //                     ""
                    //                   )
                    //                 );
                    //               } else if (selectedEmail === "Closed") {
                    //                 dispatch<any>(
                    //                   getPersonalInbox(
                    //                     1,
                    //                     loggedInEmail,
                    //                     true,
                    //                     "''",
                    //                     "updateRooms",
                    //                     ""
                    //                   )
                    //                 );
                    //               } else {
                    //                 dispatch<any>(
                    //                   getPersonalInbox(
                    //                     1,
                    //                     loggedInEmail,
                    //                     false,
                    //                     selectedEmail,
                    //                     "updateRooms",
                    //                     ""
                    //                   )
                    //                 );
                    //               }
                    //             }
                    //           });
                    //       }
                    //     }
                    //   });
                  }
                }
              }
              // dispatch<any>(updateRoomsFromWebSocket(y));
            });
          });
          setTimeout(() => {
            var newroomDataValues = resp.Rooms.$values;
          }, 600);
          var newroomDataValues = resp.Rooms.$values;
          let shareMsgCount: any = [];
          let res: any = resp.Rooms !== undefined ? resp.Rooms.$values : [];
          dispatch<any>(currentPageIndexForInbox(resp.CurrentPageIndex));
          dispatch<any>(nextPageIndexForInbox(resp.PageCount));
          var openRoomMessageId = store.getState().reduxReducer.openMessageRoom;
          if (resp.NewMessagesCount > 0) {
            dispatch({
              CircleIndicator: resp.NewMessagesCount,
              type: index.ActionTypes.OwnerCircleIndicator,
            });
          }
          dispatch({
            unreadMessagecount:
              resp.NewMessagesCount +
              store.getState().reduxReducer.NewGroupUnreadMessage,
            type: index.ActionTypes.NewUnreadMessageCount,
          });
          // if(data ===  openRoomMessageId?.Id){
          //   dispatch({ unreadMessagecount: resp.NewMessagesCount + store.getState().reduxReducer.NewGroupUnreadMessage, type: index.ActionTypes.NewUnreadMessageCount });
          // }
          //This part is for not displaying conversation counter
          var openRoomMessageId = store.getState().reduxReducer.openMessageRoom;
          var checkRoomExists = data === openRoomMessageId?.Id ? true : false;
          var selectedRoomData = res?.find(
            (x: any) => x.Id === openRoomMessageId?.Id
          );
          var selectedEmail =
            store.getState().reduxReducer.sharedInboxData?.currentSelected
              ?.email;
          var sharedGroupData =
            resp?.sharedRoomMsgCount !== undefined
              ? resp?.sharedRoomMsgCount
              : resp?.sharedRoomMsgCount.$values.find(
                  (x: any) => x?.sharedEmail === selectedEmail
                );
          if (
            checkRoomExists &&
            (sharedGroupData || loggedInUserEmail === selectedEmail)
          ) {
            var isImage = openRoomMessageId?.Messages?.$values.filter(
              (x: any) => {
                return x.Img === true;
              }
            );
            if (isImage !== undefined) {
              dispatch<any>(getSasTokenBlob());
            }
            var lastMessageText = openRoomMessageId?.Messages?.$values.filter(
              (x: any) => x.Text === openRoomMessageId.LastMsgText
            );
            lastMessageText.sort((x: any, y: any) => {
              if (!x || !y) return 0;
              if (new Date(x.TimeStamp) > new Date(y.TimeStamp)) return -1;
              else return 1;
            });
            ////debugger;
            if(selectedRoomData!=undefined)
            selectedRoomData.UnreadMsgCount =
            selectedRoomData!=undefined? selectedRoomData.UnreadMsgCount - 1:0;
            selectedRoomData.avatarColor =
              avatarColorMap[openRoomMessageId?.Id % 10];
            if (sharedGroupData !== undefined) {
              sharedGroupData.msgCount = sharedGroupData.msgCount - 1;
              shareMsgCount =
                resp.sharedRoomMsgCount !== undefined
                  ? resp.sharedRoomMsgCount
                  : [];
            } else {
              dispatch({
                CircleIndicator: resp.NewMessagesCount - 1,
                type: index.ActionTypes.OwnerCircleIndicator,
              });
            }
            var userEmail =
              groupEmail !== "''" ? groupEmail : loggedInUserEmail;
            userEmail = userEmail !== undefined ? userEmail : loggedInUserEmail;
            if (calledFrom !== "QueueMessage" && calledFrom !== "CreateRoom") {
              dispatch<any>(
                unreadCounterUpdate(
                  openRoomMessageId?.Id,
                  lastMessageText[0]?.Id,
                  userEmail,
                  0
                )
              );
            }
          } else {
            shareMsgCount =
              resp.sharedRoomMsgCount !== undefined
                ? resp.sharedRoomMsgCount
                : [];
          }
          for (let room of res) {
            room.avatarColor = avatarColorMap[room.Id % 10];
          }
          if (pageIndex === 1) {
            dispatch({ roomData: res, type: index.ActionTypes.setRooms });
            dispatch({
              sharedInboxMsgCount: shareMsgCount,
              type: index.ActionTypes.SHARED_INBOX_MESSAGE_COUNT,
            });
            if (data) {
              var currentRoom =
                resp.Rooms !== undefined
                  ? resp.Rooms?.$values?.length !== 0
                    ? resp.Rooms.$values.filter(
                        (x: any) => x.Id === Number(data)
                      )[0]
                    : []
                  : [];
              if (currentRoom !== undefined || null) {
                var roomId =
                  Object.keys(currentRoom)?.length !== 0 ? currentRoom.Id : 0;
                if (
                  roomId !== openRoomMessageId?.Id &&
                  calledFrom == "SignalR"
                ) {
                  roomId = openRoomMessageId?.Id;
                }
                // //console.log(" roomId chat",roomId)
                if (roomId !== 0) {
                  var userEmail =
                    currentRoom.Archived === true
                      ? currentRoom.CreatorEmail
                      : groupEmail !== "''"
                      ? groupEmail
                      : loggedInUserEmail;
                  if (store.getState().reduxReducer.isFromSignalR !== true) {
                    dispatch<any>(
                      getPagingMessagesByRoomId(
                        userEmail,
                        roomId,
                        1,
                        "updateRooms"
                      )
                    );
                  }
                  if (calledFrom !== "SignalR") {
                    dispatch<any>(messageFromSignal(false));
                  }
                  // document.getElementById("msg_tab")?.click();
                  // setTabOpened
                  // setTimeout(() => { dispatch<any>(setOpenMessageRoom(currentRoom));}, 500);
                  //  dispatch<any>(setOpenMessageRoom(currentRoom));
                  //  dispatch<any>(setTabOpened("chatpanel"));
                  //  document.getElementById("msg_tab")?.click();
                  //  dispatch<any>(setSpinner(false));
                } else {
                  document.getElementById("msg_tab")?.click();
                  setTimeout(() => {
                    dispatch<any>(setOpenMessageRoom(currentRoom));
                  }, 500);
                  dispatch<any>(setOpenMessageRoom(currentRoom));
                  dispatch<any>(setTabOpened("chatpanel"));
                }
              }
            } else {
              var currentRoom =
                resp.Rooms !== undefined
                  ? resp.Rooms?.$values?.length !== 0
                    ? resp.Rooms.$values[0]
                    : []
                  : [];
              var roomId =
                Object.keys(currentRoom)?.length !== 0 ? currentRoom.Id : 0;
              if (roomId !== 0) {
                var userEmail =
                  currentRoom.Archived === true
                    ? currentRoom.CreatorEmail
                    : groupEmail !== "''"
                    ? groupEmail
                    : loggedInUserEmail;
                var deepLinkRoom = store.getState().reduxReducer.deepLinkRoom; // window.location.href;
                //let numb = currentUrl.split("?")[1]!=undefined?currentUrl.split("?")[1].replace(/\D/g, ""):0;
                if (deepLinkRoom != 0 && deepLinkRoom != undefined) {
                  roomId = Number(deepLinkRoom);
                }
                dispatch<any>(
                  getPagingMessagesByRoomId(userEmail, roomId, 1, calledFrom)
                );
              } else {
                document.getElementById("msg_tab")?.click();
                dispatch<any>(setOpenMessageRoom(currentRoom));
                dispatch<any>(setTabOpened("chatpanel"));
              }
            }
          } else {
            dispatch<any>(
              displayPreviousAndNewRooms(
                resp,
                newroomDataValues,
                store.getState().reduxReducer.roomData,
                groupEmail,
                loggedInUserEmail
              )
            );
          }
        });
      // added by maksud
      dispatch<any>(readOnlyUser(loggedInUserEmail, groupEmail));
    } catch (err) {
      //console.log("errr", err);
    }
  };
};

export const getPersonalInbox_V2 = (
  pageIndex: number,
  loggedInUserEmail: any,
  archivedFlag: any,
  inboxId: any,
  calledFrom: any,
  data: any
) => {
  ////////debugger;
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        // inboxId=inboxId=="''"?loggedInUserEmail:inboxId;
        if (inboxId == undefined || inboxId == "''" || inboxId == "") {
          inboxId =
            store.getState().reduxReducer.sharedInboxData.currentSelected.email=="Closed"? store.getState().reduxReducer.openMessageRoom?.InboxId:store.getState().reduxReducer.sharedInboxData.currentSelected.email;
        }
        contactservice
          .GetPersonalInbox_V2(pageIndex, inboxId, archivedFlag)

          .then(async (resp: any) => {
            //////debugger;
            resp = await resp.json();
            resp = resp;
            //////debugger;
            if (
              resp.Message === "Authorization has been denied for this request."
            ) {
              AuthService.signOut();
              return;
            }
            resp = resp.Data;
            /*resp.Rooms.$values.map((x: any) => {
              ////////debugger;
              x.Participants.$values.map((y: any) => {
                if (y.Name === y.SfBPhoneNumber.PhoneNumber) {
                  if (y !== undefined) {
                    if (
                      calledFrom !== "SideBarComponent" &&
                      calledFrom !== "updateRooms" &&
                      calledFrom !== "fromselectedEmails" &&
                      calledFrom !== "IncludePersonalInbox"
                    ) {
                      // contactservice
                      //   .GetContactsByUserEmailPhoneNumber(
                      //     y.SfBPhoneNumber.PhoneNumber
                      //   )
                      //   .then(async (res: any) => {
                      //     res = await res.json();
                      //     if (res.ContactId !== 0) {
                      //       if (y.Name === y.SfBPhoneNumber.PhoneNumber) {
                      //         var obj = {
                      //           firstName: res.ForeName,
                      //           lastName:
                      //             res.SureName !== undefined&&res.SureName!=null ? res.SureName : "",
                      //           phoneNumber: res.PhoneNumber,
                      //           groupEmail:
                      //             res.GroupEmail !== undefined
                      //               ? res.GroupEmail
                      //               : store.getState().reduxReducer
                      //                   .sharedInboxData.currentSelected.email,
                      //         };
                      //         contactservice
                      //           .UpdateRoomParticipantTeams(obj, y.Id)
                      //           .then(async (res: any) => {
                      //             res = await res.status;
                      //             if (res) {
                      //               var selectedEmail =
                      //                 store.getState().reduxReducer
                      //                   .sharedInboxData.currentSelected.email;
                      //               var loggedInEmail =
                      //                 store.getState().reduxReducer.loggedInUser
                      //                   .Email;
                      //               if (selectedEmail === loggedInEmail) {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     false,
                      //                     selectedEmail,
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               } else if (selectedEmail === "Closed") {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     true,
                      //                     "''",
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               } else {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     false,
                      //                     selectedEmail,
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               }
                      //             }
                      //           });
                      //       }
                      //     }
                      //   });
                    }
                  }
                }
                // dispatch<any>(updateRoomsFromWebSocket(y));
              });
            });*/
            setTimeout(() => {
              var newroomDataValues = resp.Rooms.$values;
            }, 600);
            var newroomDataValues = resp.Rooms.$values;
            let shareMsgCount: any = [];
            let res: any = resp.Rooms !== undefined ? resp.Rooms.$values : [];
            dispatch<any>(currentPageIndexForInbox(resp.CurrentPageIndex));
            dispatch<any>(nextPageIndexForInbox(resp.PageCount));
            ////debugger;
            var openRoomMessageId =
              store.getState().reduxReducer.openMessageRoom;
            if (resp.NewMessagesCount > 0) {
              dispatch({
                CircleIndicator: resp.NewMessagesCount,
                type: index.ActionTypes.OwnerCircleIndicator,
              });
            }
            dispatch({
              unreadMessagecount:
                resp.NewMessagesCount +
                store.getState().reduxReducer.NewGroupUnreadMessage,
              type: index.ActionTypes.NewUnreadMessageCount,
            });
            // if(data ===  openRoomMessageId?.Id){
            //   dispatch({ unreadMessagecount: resp.NewMessagesCount + store.getState().reduxReducer.NewGroupUnreadMessage, type: index.ActionTypes.NewUnreadMessageCount });
            // }
            //This part is for not displaying conversation counter
            var openRoomMessageId =
              store.getState().reduxReducer.openMessageRoom;
            var checkRoomExists = data === openRoomMessageId?.Id ? true : false;
            var selectedRoomData = res?.find(
              (x: any) => x.Id === openRoomMessageId?.Id
            );
            var selectedEmail =
              store.getState().reduxReducer.sharedInboxData?.currentSelected
                ?.email=="Closed"?inboxId:store.getState().reduxReducer.sharedInboxData?.currentSelected
                ?.email;
            var sharedGroupData =
              resp?.sharedRoomMsgCount !== undefined
                ? resp?.sharedRoomMsgCount
                : resp?.sharedRoomMsgCount.$values.find(
                    (x: any) => x?.sharedInbox === selectedEmail
                  );
            if (
              checkRoomExists &&
              (sharedGroupData || loggedInUserEmail === selectedEmail)
            ) {
              var isImage = openRoomMessageId?.Messages?.$values.filter(
                (x: any) => {
                  return x.Img === true;
                }
              );
              if (isImage !== undefined) {
                let companyVersion =
                  store.getState().reduxReducer.companyVersion;
                if (companyVersion === 2) {
                  dispatch<any>(getSasTokenBlob_V2());
                } else {
                  dispatch<any>(getSasTokenBlob());
                }
              }
              var lastMessageText = openRoomMessageId?.Messages?.$values.filter(
                (x: any) => x.Text === openRoomMessageId.LastMsgText
              );
              if(lastMessageText!=undefined)
              {
              lastMessageText.sort((x: any, y: any) => {
                if (!x || !y) return 0;
                if (new Date(x.TimeStamp) > new Date(y.TimeStamp)) return -1;
                else return 1;
              });}
              ////////debugger;

              if (selectedRoomData != undefined) {
                selectedRoomData.UnreadMsgCount =
                  selectedRoomData.UnreadMsgCount - 1;
                selectedRoomData.avatarColor =
                  avatarColorMap[openRoomMessageId?.Id % 10];
              }
              if (sharedGroupData !== undefined) {
                sharedGroupData.msgCount = sharedGroupData.msgCount - 1;
                shareMsgCount =
                  resp.sharedRoomMsgCount !== undefined
                    ? resp.sharedRoomMsgCount
                    : [];
              } else {
                dispatch({
                  CircleIndicator: resp.NewMessagesCount - 1,
                  type: index.ActionTypes.OwnerCircleIndicator,
                });
              }
              var userEmail = inboxId !== "''" ? inboxId : loggedInUserEmail;
              userEmail =
                userEmail !== undefined ? userEmail : loggedInUserEmail;
              if (
                calledFrom !== "QueueMessage" &&
                calledFrom !== "CreateRoom"
              ) {
                if(lastMessageText!=undefined)
                {
                dispatch<any>(
                  unreadCounterUpdate_V2(
                    openRoomMessageId?.Id,
                    lastMessageText[0]?.Id,
                    0
                  )
                );
                  }
              }
            } else {
              shareMsgCount =
                resp.sharedRoomMsgCount !== undefined
                  ? resp.sharedRoomMsgCount
                  : [];
            }
            for (let room of res) {
              room.avatarColor = avatarColorMap[room.Id % 10];
            }
            if (pageIndex === 1) {
              if(res.length>0)
              {
              dispatch({ roomData: res, type: index.ActionTypes.setRooms });
              dispatch({
                sharedInboxMsgCount: shareMsgCount,
                type: index.ActionTypes.SHARED_INBOX_MESSAGE_COUNT,
              });
              if (data) {
                var currentRoom =
                  resp.Rooms !== undefined
                    ? resp.Rooms?.$values?.length !== 0
                      ? resp.Rooms.$values.filter(
                          (x: any) => x.Id === Number(data)
                        )[0]
                      : []
                    : [];
                if (currentRoom !== undefined || null) {
                  var roomId =
                    Object.keys(currentRoom)?.length !== 0 ? currentRoom.Id : 0;
                  // //console.log(" roomId chat",roomId)
                  if (
                    roomId !== openRoomMessageId?.Id &&
                    calledFrom == "SignalR"
                  ) {
                    roomId = openRoomMessageId?.Id;
                  }
                  if (roomId !== 0) {
                    var userEmail =
                      currentRoom.Archived === true
                        ? currentRoom.CreatorEmail
                        : inboxId !== "''"
                        ? inboxId
                        : loggedInUserEmail;
                    if (store.getState().reduxReducer.isFromSignalR !== true) {
                      dispatch<any>(
                        getPagingMessagesByRoomId_V2(roomId, 1, "updateRooms")
                      );
                    }
                    if (calledFrom !== "SignalR") {
                      dispatch<any>(messageFromSignal(false));
                    }
                    // document.getElementById("msg_tab")?.click();
                    // setTabOpened
                    // setTimeout(() => { dispatch<any>(setOpenMessageRoom(currentRoom));}, 500);
                    //  dispatch<any>(setOpenMessageRoom(currentRoom));
                    //  dispatch<any>(setTabOpened("chatpanel"));
                    //  document.getElementById("msg_tab")?.click();
                    //  dispatch<any>(setSpinner(false));
                  } else {
                    document.getElementById("msg_tab")?.click();
                    setTimeout(() => {
                      dispatch<any>(setOpenMessageRoom(currentRoom));
                    }, 500);
                    dispatch<any>(setOpenMessageRoom(currentRoom));
                    dispatch<any>(setTabOpened("chatpanel"));
                  }
                }
              } else {
                var currentRoom =
                  resp.Rooms !== undefined
                    ? resp.Rooms?.$values?.length !== 0
                      ? resp.Rooms.$values[0]
                      : []
                    : [];
                var roomId =
                  Object.keys(currentRoom)?.length !== 0 ? currentRoom.Id : 0;
                if (roomId !== 0) {
                  var userEmail =
                    currentRoom.Archived === true
                      ? currentRoom.CreatorEmail
                      : inboxId !== "''"
                      ? inboxId
                      : loggedInUserEmail;
                  dispatch<any>(
                    getPagingMessagesByRoomId_V2(roomId, 1, calledFrom)
                  );
                } else {
                  document.getElementById("msg_tab")?.click();
                  dispatch<any>(setOpenMessageRoom(currentRoom));
                  dispatch<any>(setTabOpened("chatpanel"));
                }
              }
            }
            } else {
              dispatch<any>(
                displayPreviousAndNewRooms(
                  resp,
                  newroomDataValues,
                  store.getState().reduxReducer.roomData,
                  inboxId,
                  loggedInUserEmail
                )
              );
            }
          });
        
        // added by maksud
        ////////debugger;
        dispatch<any>(readOnlyUser_V2(inboxId));
      } catch (err) {
        //console.log("errr", err);
      }
    }
  };
};
export const getInbox_V2 = (
  pageIndex: number,
  loggedInUserEmail: any,
  archivedFlag: any,
  inboxId: any,
  calledFrom: any,
  data: any
) => {
debugger;
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        // inboxId=inboxId=="''"?loggedInUserEmail:inboxId;
        if (inboxId == undefined || inboxId == "''" || inboxId == "") {
          inboxId = store
            .getState()
            .reduxReducer.loggedInUser.inboxes.$values.filter(
              (item: any) => item.InboxType === 0
            )[0].InboxId;
        }
        //////debugger;
        contactservice
          .GetPersonalInbox_V2(pageIndex, inboxId, archivedFlag)

          .then(async (resp: any) => {
            ////////debugger;
            resp = await resp.json();
            resp = resp;
            ////////debugger;
            if (
              resp.Message === "Authorization has been denied for this request."
            ) {
              AuthService.signOut();
              return;
            }
            resp = resp.Data;
            resp.Rooms.$values.map((x: any) => {
              ////////debugger;
              x.Participants.$values.map((y: any) => {
                if (y.Name === y.SfBPhoneNumber.PhoneNumber) {
                  if (y !== undefined) {
                    if (
                      calledFrom !== "SideBarComponent" &&
                      calledFrom !== "updateRooms" &&
                      calledFrom !== "fromselectedEmails" &&
                      calledFrom !== "IncludePersonalInbox"
                    ) {
                      // contactservice
                      //   .GetContactsByUserEmailPhoneNumber(
                      //     y.SfBPhoneNumber.PhoneNumber
                      //   )
                      //   .then(async (res: any) => {
                      //     res = await res.json();
                      //     if (res.ContactId !== 0) {
                      //       if (y.Name === y.SfBPhoneNumber.PhoneNumber) {
                      //         var obj = {
                      //           firstName: res.ForeName,
                      //           lastName:
                      //             res.SureName !== undefined&&res.SureName!=null ? res.SureName : "",
                      //           phoneNumber: res.PhoneNumber,
                      //           groupEmail:
                      //             res.GroupEmail !== undefined
                      //               ? res.GroupEmail
                      //               : store.getState().reduxReducer
                      //                   .sharedInboxData.currentSelected.email,
                      //         };
                      //         contactservice
                      //           .UpdateRoomParticipantTeams(obj, y.Id)
                      //           .then(async (res: any) => {
                      //             res = await res.status;
                      //             if (res) {
                      //               var selectedEmail =
                      //                 store.getState().reduxReducer
                      //                   .sharedInboxData.currentSelected.email;
                      //               var loggedInEmail =
                      //                 store.getState().reduxReducer.loggedInUser
                      //                   .Email;
                      //               if (selectedEmail === loggedInEmail) {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     false,
                      //                     selectedEmail,
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               } else if (selectedEmail === "Closed") {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     true,
                      //                     "''",
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               } else {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     false,
                      //                     selectedEmail,
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               }
                      //             }
                      //           });
                      //       }
                      //     }
                      //   });
                    }
                  }
                }
                // dispatch<any>(updateRoomsFromWebSocket(y));
              });
            });
            setTimeout(() => {
              var newroomDataValues = resp.Rooms.$values;
            }, 600);
            var newroomDataValues = resp.Rooms.$values;
            let shareMsgCount: any = [];
            let res: any = resp.Rooms !== undefined ? resp.Rooms.$values : [];
            dispatch<any>(currentPageIndexForInbox(resp.CurrentPageIndex));
            dispatch<any>(nextPageIndexForInbox(resp.PageCount));
            var openRoomMessageId =
              store.getState().reduxReducer.openMessageRoom;
            if (resp.NewMessagesCount > 0) {
              dispatch({
                CircleIndicator: resp.NewMessagesCount,
                type: index.ActionTypes.OwnerCircleIndicator,
              });
            }
            dispatch({
              unreadMessagecount:
                resp.NewMessagesCount +
                store.getState().reduxReducer.NewGroupUnreadMessage,
              type: index.ActionTypes.NewUnreadMessageCount,
            });
            // if(data ===  openRoomMessageId?.Id){
            //   dispatch({ unreadMessagecount: resp.NewMessagesCount + store.getState().reduxReducer.NewGroupUnreadMessage, type: index.ActionTypes.NewUnreadMessageCount });
            // }
            //This part is for not displaying conversation counter
            var openRoomMessageId =
              store.getState().reduxReducer.openMessageRoom;
            var checkRoomExists = data === openRoomMessageId?.Id ? true : false;
            var selectedRoomData = res?.find(
              (x: any) => x.Id === openRoomMessageId?.Id
            );
            var selectedEmail =
              store.getState().reduxReducer.sharedInboxData?.currentSelected
                ?.email=="Closed"?inboxId: store.getState().reduxReducer.sharedInboxData?.currentSelected
                ?.email;
            var sharedGroupData =
              resp?.sharedRoomMsgCount !== undefined
                ? resp?.sharedRoomMsgCount
                : resp?.sharedRoomMsgCount.$values.find(
                    (x: any) => x?.sharedInbox === selectedEmail
                  );
                  var selectedEmailData =sharedGroupData.$values.find(
                    (x: any) => x?.sharedInbox === selectedEmail
                  );
              //   if (selectedEmailData !== undefined) {
              //     var object={
              //       UniqueNumber: "",
              //       email: selectedEmailData.sharedInbox,
              //       name:"Closed"// selectedEmailData.sharedEmail
              //     }
              //   dispatch<any>(setCurrentSelectedSharedEmail(object, "", selectedRoomData?.Id));
              // }
            if (
              checkRoomExists &&
              (sharedGroupData || loggedInUserEmail === selectedEmail)
            ) {
              var isImage = openRoomMessageId?.Messages?.$values.filter(
                (x: any) => {
                  return x.Img === true;
                }
              );
              if (isImage !== undefined) {
                let companyVersion =
                  store.getState().reduxReducer.companyVersion;
                if (companyVersion === 2) {
                  dispatch<any>(getSasTokenBlob_V2());
                } else {
                  dispatch<any>(getSasTokenBlob());
                }
              }
              var lastMessageText = openRoomMessageId?.Messages?.$values.filter(
                (x: any) => x.Text === openRoomMessageId.LastMsgText
              );
              lastMessageText.sort((x: any, y: any) => {
                if (!x || !y) return 0;
                if (new Date(x.TimeStamp) > new Date(y.TimeStamp)) return -1;
                else return 1;
              });
              selectedRoomData.UnreadMsgCount =
                selectedRoomData.UnreadMsgCount - 1;
              selectedRoomData.avatarColor =
                avatarColorMap[openRoomMessageId?.Id % 10];
              if (sharedGroupData !== undefined) {
                sharedGroupData.msgCount = sharedGroupData.msgCount - 1;
                shareMsgCount =
                  resp.sharedRoomMsgCount !== undefined
                    ? resp.sharedRoomMsgCount
                    : [];
              } else {
                dispatch({
                  CircleIndicator: resp.NewMessagesCount - 1,
                  type: index.ActionTypes.OwnerCircleIndicator,
                });
              }
              var userEmail = inboxId !== "''" ? inboxId : loggedInUserEmail;
              userEmail =
                userEmail !== undefined ? userEmail : loggedInUserEmail;
              if (
                calledFrom !== "QueueMessage" &&
                calledFrom !== "CreateRoom"
              ) {
                dispatch<any>(
                  unreadCounterUpdate_V2(
                    openRoomMessageId?.Id,
                    lastMessageText[0]?.Id,
                    0
                  )
                );
              }
            } else {
              shareMsgCount =
                resp.sharedRoomMsgCount !== undefined
                  ? resp.sharedRoomMsgCount
                  : [];
            }
            for (let room of res) {
              room.avatarColor = avatarColorMap[room.Id % 10];
            }
            if (pageIndex === 1) {
              dispatch({ roomData: res, type: index.ActionTypes.setRooms });
              dispatch({
                sharedInboxMsgCount: shareMsgCount,
                type: index.ActionTypes.SHARED_INBOX_MESSAGE_COUNT,
              });
              // if (data) {
              //   var currentRoom =
              //     resp.Rooms !== undefined
              //       ? resp.Rooms?.$values?.length !== 0
              //         ? resp.Rooms.$values.filter((x: any) => x.Id === Number(data))[0]
              //         : []
              //       : [];
              //   if (currentRoom !== undefined || null) {
              //     var roomId =
              //       Object.keys(currentRoom)?.length !== 0 ? currentRoom.Id : 0;
              //     // //console.log(" roomId chat",roomId)
              //     // if (roomId !== 0) {
              //     //   var userEmail =
              //     //     currentRoom.Archived === true
              //     //       ? currentRoom.CreatorEmail
              //     //       : inboxId !== "''"
              //     //       ? inboxId
              //     //       : loggedInUserEmail;
              //     //   if (store.getState().reduxReducer.isFromSignalR !== true) {
              //     //     dispatch<any>(
              //     //       getPagingMessagesByRoomId_V2(
              //     //         userEmail,
              //     //         roomId,
              //     //         1,
              //     //         "updateRooms"
              //     //       )
              //     //     );
              //     //   }
              //     //   if (calledFrom !== "SignalR") {
              //     //     dispatch<any>(messageFromSignal(false));
              //     //   }
              //     //   // document.getElementById("msg_tab")?.click();
              //     //   // setTabOpened
              //     //   // setTimeout(() => { dispatch<any>(setOpenMessageRoom(currentRoom));}, 500);
              //     //   //  dispatch<any>(setOpenMessageRoom(currentRoom));
              //     //   //  dispatch<any>(setTabOpened("chatpanel"));
              //     //   //  document.getElementById("msg_tab")?.click();
              //     //   //  dispatch<any>(setSpinner(false));
              //     // } else {
              //     //   document.getElementById("msg_tab")?.click();
              //     //   setTimeout(() => {
              //     //     dispatch<any>(setOpenMessageRoom(currentRoom));
              //     //   }, 500);
              //     //   dispatch<any>(setOpenMessageRoom(currentRoom));
              //     //   dispatch<any>(setTabOpened("chatpanel"));
              //     // }
              //   }
              // } else {
              //   var currentRoom =
              //     resp.Rooms !== undefined
              //       ? resp.Rooms?.$values?.length !== 0
              //         ? resp.Rooms.$values[0]
              //         : []
              //       : [];
              //   var roomId =
              //     Object.keys(currentRoom)?.length !== 0 ? currentRoom.Id : 0;
              //   if (roomId !== 0) {
              //     var userEmail =
              //       currentRoom.Archived === true
              //         ? currentRoom.CreatorEmail
              //         : inboxId !== "''"
              //         ? inboxId
              //         : loggedInUserEmail;
              //     dispatch<any>(
              //       getPagingMessagesByRoomId_V2(userEmail, roomId, 1, calledFrom)
              //     );
              //   } else {
              //     document.getElementById("msg_tab")?.click();
              //     dispatch<any>(setOpenMessageRoom(currentRoom));
              //     dispatch<any>(setTabOpened("chatpanel"));
              //   }
              // }
            } else {
              dispatch<any>(
                displayPreviousAndNewRooms(
                  resp,
                  newroomDataValues,
                  store.getState().reduxReducer.roomData,
                  inboxId,
                  loggedInUserEmail
                )
              );
            }
          });
        
        // added by maksud
        ////////debugger;
        dispatch<any>(readOnlyUser_V2(inboxId));
      } catch (err) {
        //console.log("errr", err);
      }
    }
  };
};
export const getArchivedRooms = (pageIndex: number) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        let companyVersion = store.getState().reduxReducer.companyVersion;
        if (companyVersion === 2) {
          var userEmail =
            store.getState().reduxReducer.sharedInboxData.currentSelected.email;
          dispatch<any>(
            getPersonalInbox_V2(
              pageIndex,
              userEmail,
              true,
              "",
              "archiveRooms",
              "''"
            )
          );
        } else {
          var userEmail = store.getState().reduxReducer.loggedInUser.Email;
          dispatch<any>(
            getPersonalInbox(
              pageIndex,
              userEmail,
              true,
              "",
              "archiveRooms",
              "''"
            )
          );
        }
      } catch (err) {
        //console.log(err);
        //  await AuthService.saveToken();
      }
    }
  };
};

export const setCurrentSelectedSharedEmail = (
  email: any,
  calledFrom: any,
  roomId: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        debugger;
        let companyVersion = store.getState().reduxReducer.companyVersion;
        if (calledFrom === "InfoPanel") {
          dispatch({
            obj: email,
            type: index.ActionTypes.setCurrentSelectedSharedEmailInboxData,
          });
        } else {
          var loggedInUserEmail =
            store.getState().reduxReducer.loggedInUser.Email;
          dispatch({ roomData: [], type: index.ActionTypes.setRooms });
          if (
            email?.name === store.getState().reduxReducer.loggedInUser.UserName
          ) {
            let companyVersion = store.getState().reduxReducer.companyVersion;
            if (companyVersion === 2) {
              dispatch<any>(
                getPersonalInbox_V2(
                  1,
                  loggedInUserEmail,
                  false,
                  email.email,
                  "fromselectedEmails",
                  roomId
                )
              );
            } else {
              dispatch<any>(
                getPersonalInbox(
                  1,
                  loggedInUserEmail,
                  false,
                  email.email,
                  "fromselectedEmails",
                  roomId
                )
              );
            }
          } else if (email?.name === "Closed") {
            if (companyVersion === 2) {
              dispatch<any>(
                getPersonalInbox_V2(
                  1,
                  loggedInUserEmail,
                  true,
                  "''",
                  "fromselectedEmails",
                  roomId
                )
              );
            } else {
              dispatch<any>(
                getPersonalInbox(
                  1,
                  loggedInUserEmail,
                  true,
                  "''",
                  "fromselectedEmails",
                  roomId
                )
              );
            }
          } else {
            if (companyVersion === 2) {
              dispatch<any>(
                getPersonalInbox_V2(
                  1,
                  loggedInUserEmail,
                  false,
                  email.email,
                  "fromselectedEmails",
                  roomId
                )
              );
            } else {
              dispatch<any>(
                getPersonalInbox(
                  1,
                  loggedInUserEmail,
                  false,
                  email.email,
                  "fromselectedEmails",
                  roomId
                )
              );
            }
          }
          if (calledFrom !== "getInboxList") {
            dispatch({
              obj: email,
              type: index.ActionTypes.setCurrentSelectedSharedEmailInboxData,
            });
          }
        }
      } catch (err) {
        ///  await AuthService.saveToken();
      }
    }
  };
};
export const currentSelectedInbox = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      obj: data,
      type: index.ActionTypes.setCurrentSelectedSharedEmailInboxData,
    });
  };
};
export const getPagingMessagesByRoomId = (
  userEmail: any,
  roomId: any,
  pageIndex: any,
  calledFrom: any
) => {
  return async (dispatch: Dispatch) => {
    //////debugger;

    console.log(userEmail + "**" + roomId + "**" + calledFrom);
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      // //console.log("lowcreditbanner");
      try {
        //////debugger;
        dispatch<any>(selectedroom(roomId));
        // dispatch<any>(currentPageIndexForRoomMessages(pageIndex));
        let res2: any = await contactservice.getPagingMessagesByRoomId(
          userEmail,
          roomId,
          pageIndex
        );
        res2 = await res2.json();
        ////////debugger;
        if (!res2) return;

        if (res2.Rooms?.$values?.length !== 0) {
          dispatch<any>(
            UserTransLang(
              res2.Rooms?.$values[0].TranslationLanguage !== undefined ||
                res2.Rooms?.$values[0].TranslationLanguage !== null
                ? res2.Rooms?.$values[0].TranslationLanguage
                : ""
            )
          );
        }
        let messageSort: any =
          res2.Rooms?.$values?.length !== 0
            ? res2.Rooms?.$values[0].Messages?.$values
            : [];
        if (messageSort.find((x: any) => x.MessageMedia.$values.length !== 0)) {
          var messageUrl = messageSort.find(
            (x: any) => x.MessageMedia.$values.length
          );
          let companyVersion = store.getState().reduxReducer.companyVersion;
          if (companyVersion === 2) {
            dispatch<any>(getSasTokenBlob_V2());
          } else {
            dispatch<any>(getSasTokenBlob());
          }
        }
        if (messageSort?.length !== 0) {
          messageSort.sort((x: any, y: any) => {
            if (!x || !y) return 0;
            if (new Date(x.TimeStamp) <= new Date(y.TimeStamp)) return -1;
            else return 1;
          });
          res2.Rooms.$values[0].Messages.$values = messageSort;
        }
        dispatch<any>(currentPageIndexForRoomMessages(pageIndex));
        dispatch<any>(currentPageIndexForRoomMessages(res2.CurrentPageIndex));
        dispatch<any>(nextPageIndexForRoomMessages(res2.PageCount));
        if (pageIndex > 1 && calledFrom !== "CreateRoom") {
          dispatch<any>(
            displayPreviousAndNewChat(
              res2,
              res2.Rooms.$values[0],
              store.getState().reduxReducer.openMessageRoom
            )
          );
          return;
        }
        if (calledFrom === "CreateRoom") {
          var UpdateroomData: any = [];
          var getroomdata = res2.Rooms.$values;
          var getroomFirstElement = getroomdata.filter(
            (x: any) => x.Id === roomId
          );
          UpdateroomData.push(getroomFirstElement[0]);
          let obj = {
            email: "",
            name: store.getState().reduxReducer.loggedInUser.UserName,
            UniqueNumber:
              store.getState().reduxReducer.loggedInUser.UniqueNumber,
          };
          dispatch({ type: "clearOpenMessageRoom" });
          document.getElementById("msg_tab")?.click();
          dispatch<any>(setOpenMessageRoom(UpdateroomData[0]));
          dispatch<any>(setTabOpened("chatpanel"));
          dispatch<any>(setSpinner(false));
          //commonMethod.hideLeftSection();
          commonMethod.showRightSection();
          dispatch<any>(setSelectedContacts(""));
        } else if (calledFrom === "SignalR") {
          dispatch<any>(setSpinner(false));
          return;
        } else {
          var openRoom =
            res2.Rooms?.$values?.length !== 0 ? res2.Rooms.$values[0] : [];
          var filterColor = store
            .getState()
            .reduxReducer.roomData.filter((x: any) => x.Id === roomId)[0];
          if (filterColor && Object.keys(openRoom)?.length !== 0) {
            openRoom.avatarColor = filterColor.avatarColor;
            if (calledFrom !== "SignalR") {
              dispatch<any>(setOpenMessageRoom(openRoom));
            }
          } else {
            dispatch<any>(setOpenMessageRoom(filterColor));
          }
          dispatch<any>(setSpinner(false));
          dispatch<any>(setSelectedContacts(""));
          // commonMethod.hideLeftSection();

          document.getElementById("msg_tab")?.click();
          commonMethod.showRightSection();
          dispatch<any>(setTabOpened("chatpanel"));
          if (calledFrom === "SideBar") {
            var lastMessageText =
              res2.Rooms?.$values?.length !== 0
                ? res2.Rooms?.$values[0].LastMsgText
                : "";
            var lastMessageId = res2.Rooms?.$values[0]?.Messages.$values.filter(
              (x: any) => x.Text === lastMessageText
            );
            if (lastMessageId?.length > 1) {
              lastMessageId.sort((x: any, y: any) => {
                if (!x || !y) return 0;
                if (new Date(x.TimeStamp) > new Date(y.TimeStamp)) return -1;
                else return 1;
              });
            }
            var newCount =
              res2.Rooms?.$values?.length !== 0
                ? res2.Rooms.$values[0]?.UnreadMsgCount
                : 0;
            //  if (lastMessageId?.length !== 0) {
            if (
              calledFrom !== "QueueMessage" &&
              calledFrom !== "CreateRoom" &&
              res2.Rooms.$values[0]?.UnreadMsgCount > 0
            ) {
              dispatch<any>(
                unreadCounterUpdate(
                  roomId,
                  lastMessageId[0]?.Id,
                  userEmail,
                  newCount
                )
              );
            }
            // }
          }
        }
      } catch (err) {
        //console.log(err);
        // await AuthService.saveToken();
      }
    }
  };
};
export const getPagingMessagesByRoomId_V2 = (
  roomId: any,
  pageIndex: any,
  calledFrom: any
) => {
  return async (dispatch: Dispatch) => {
    //////debugger;
    console.log(store.getState().reduxReducer);
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      // //console.log("lowcreditbanner");
      try {
        dispatch<any>(selectedroom(roomId));
        // dispatch<any>(currentPageIndexForRoomMessages(pageIndex));
        let res2: any = await contactservice.getPagingMessagesByRoomId_V2(
          roomId,
          pageIndex
        );
        ////////debugger;
        res2 = await res2.json();
        res2 = res2.Data;
        if (!res2) return;
        if (res2.Rooms?.$values?.length !== 0) {
          dispatch<any>(
            UserTransLang(
              res2.Rooms?.$values[0].TranslationLanguage !== undefined ||
                res2.Rooms?.$values[0].TranslationLanguage !== null
                ? res2.Rooms?.$values[0].TranslationLanguage
                : ""
            )
          );
        }
        let messageSort: any =
          res2.Rooms?.$values?.length !== 0
            ? res2.Rooms?.$values[0].Messages?.$values
            : [];
        if (messageSort.find((x: any) => x.MessageMedia.$values.length !== 0)) {
          var messageUrl = messageSort.find(
            (x: any) => x.MessageMedia.$values.length
          );
          dispatch<any>(getSasTokenBlob_V2());
        }
        if (messageSort?.length !== 0) {
          messageSort.sort((x: any, y: any) => {
            if (!x || !y) return 0;
            if (new Date(x.TimeStamp) <= new Date(y.TimeStamp)) return -1;
            else return 1;
          });
          res2.Rooms.$values[0].Messages.$values = messageSort;
        }
        dispatch<any>(currentPageIndexForRoomMessages(pageIndex));
        dispatch<any>(currentPageIndexForRoomMessages(res2.CurrentPageIndex));
        dispatch<any>(nextPageIndexForRoomMessages(res2.PageCount));
        if (pageIndex > 1 && calledFrom !== "CreateRoom") {
          dispatch<any>(
            displayPreviousAndNewChat(
              res2,
              res2.Rooms.$values[0],
              store.getState().reduxReducer.openMessageRoom
            )
          );
          return;
        }
        if (calledFrom === "CreateRoom") {
          var UpdateroomData: any = [];
          var getroomdata = res2.Rooms.$values;
          var getroomFirstElement = getroomdata.filter(
            (x: any) => x.Id === roomId
          );
          UpdateroomData.push(getroomFirstElement[0]);
          let obj = {
            email: "",
            name: store.getState().reduxReducer.loggedInUser.UserName,
            UniqueNumber:
              store.getState().reduxReducer.loggedInUser.UniqueNumber,
          };
          dispatch({ type: "clearOpenMessageRoom" });
          document.getElementById("msg_tab")?.click();
          dispatch<any>(setOpenMessageRoom(UpdateroomData[0]));
          dispatch<any>(setTabOpened("chatpanel"));
          dispatch<any>(setSpinner(false));
          //commonMethod.hideLeftSection();
          commonMethod.showRightSection();
          dispatch<any>(setSelectedContacts(""));
        } else if (calledFrom === "SignalR") {
          dispatch<any>(setSpinner(false));
          return;
        } else {
          var openRoom =
            res2.Rooms?.$values?.length !== 0 ? res2.Rooms.$values[0] : [];
          var filterColor = store
            .getState()
            .reduxReducer.roomData.filter((x: any) => x.Id === roomId)[0];
          if (filterColor && Object.keys(openRoom)?.length !== 0) {
            openRoom.avatarColor = filterColor.avatarColor;
            if (calledFrom !== "SignalR") {
              dispatch<any>(setOpenMessageRoom(openRoom));
            }
          } else {
            dispatch<any>(setOpenMessageRoom(filterColor));
          }
          dispatch<any>(setSpinner(false));
          dispatch<any>(setSelectedContacts(""));
          // commonMethod.hideLeftSection();
          document.getElementById("msg_tab")?.click();
          commonMethod.showRightSection();
          dispatch<any>(setTabOpened("chatpanel"));
         //7564
          if (calledFrom === "SideBar" || "chatpanel") {
            var lastMessageText =
              res2.Rooms?.$values?.length !== 0
                ? res2.Rooms?.$values[0].LastMsgText
                : "";
            var lastMessageId = res2.Rooms?.$values[0]?.Messages.$values.filter(
              (x: any) => x.Text === lastMessageText
            );
            if (lastMessageId?.length > 1) {
              lastMessageId.sort((x: any, y: any) => {
                if (!x || !y) return 0;
                if (new Date(x.TimeStamp) > new Date(y.TimeStamp)) return -1;
                else return 1;
              });
            }
            var newCount =
              res2.Rooms?.$values?.length !== 0
                ? res2.Rooms.$values[0]?.UnreadMsgCount
                : 0;
            // if (lastMessageId?.length !== 0) {
              debugger;
            if (
              calledFrom !== "QueueMessage" &&
              calledFrom !== "CreateRoom" &&
              res2.Rooms.$values[0]?.UnreadMsgCount > 0
            ) {
              dispatch<any>(
                unreadCounterUpdate_V2(roomId, lastMessageId[0]?.Id, newCount)
              );
            }
            // }
          }
        }
      } catch (err) {
        //console.log(err);
        // await AuthService.saveToken();
      }
    }
  };
};
export const unreadCounterUpdate = (
  roomId: any,
  lastIncomingMessageId: any,
  userEmail: any,
  newCount: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      if (roomId !== undefined)
        try {
          contactservice
            .unreadCounterUpdate(roomId, lastIncomingMessageId, userEmail)
            .then(async (resp: any) => {});
        } catch (error) {
          //console.log(error);
        }
    }
  };
};
export const unreadCounterUpdate_V2 = (
  roomId: any,
  lastIncomingMessageId: any,
  newCount: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      if (roomId !== undefined)
        try {
          contactservice
            .unreadCounterUpdate_V2(roomId, lastIncomingMessageId)
            .then(async (resp: any) => {});
        } catch (error) {
          //console.log(error);
        }
    }
  };
};
export const displayPreviousAndNewChat = (
  response: any,
  newConversation: any,
  oldConversation: any
) => {
  return async (dispatch: Dispatch) => {
    if (oldConversation !== undefined && newConversation !== undefined) {
      var data = newConversation?.Messages?.$values.concat(
        oldConversation?.Messages?.$values
      );
      // var data =oldConversation?.unshift(newConversation?.Messages?.$values);
      // var data =[...newConversation,...oldConversation]
      newConversation.Messages.$values = data;
      dispatch<any>(setOpenMessageRoom(newConversation));
      dispatch<any>(setTabOpened("chatpanel"));
      dispatch<any>(currentPageIndexForRoomMessages(response.CurrentPageIndex));
      dispatch<any>(nextPageIndexForRoomMessages(response.PageCount));
    }
  };
};
export const currentPageIndexForRoomMessages = (
  currentPageIndexForRoomMessages: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.CURRENT_PAGE_INDEX_FOR_ROOM_MESSAGES,
      currentPageIndexForRoomMessages: currentPageIndexForRoomMessages,
    });
  };
};
export const nextPageIndexForRoomMessages = (
  nextPageIndexForRoomMessages: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.PAGE_COUNT_PAGE_INDEX_FOR_ROOM_MESSAGES,
      nextPageIndexForRoomMessages: nextPageIndexForRoomMessages,
    });
  };
};
export const setOpenMessageRoom = (room: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.setOpenMessageRoom,
      openMessageRoom: room,
    });
  };
};

export const setCurrentTheme = (theme: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.setCurrentTheme, theme });
  };
};
export const setMFA = (theme: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.setMFA, theme });
  };
};

export const setRegisterTheme = (theme: string) => {
  var test = store.getState().reduxReducer.registerTheme;
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.SET_REGISTER_THEME, theme });
  };
};
export const checkRegisterUser = (checkUser: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.CHECK_REGISTER_USER, checkUser });
  };
};
export const checkBandwidthNumber = (isNumber: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.CHECK_BANDWIDTH_NUMBER, isNumber });
  };
};
export const updateRoomsLatestMsgs = (accessTokenApi: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice.updateRoomsLatestMsgs(accessTokenApi);
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const readOnlyUser = (userEmail: any, groupEmail: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        let res2: any = await contactservice.getMFAuser(userEmail, groupEmail);
        res2 = await res2.json();
        dispatch<any>(setMFA(res2));
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const readOnlyUser_V2 = (InboxId: Number) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        let res2: any = store
          .getState()
          .reduxReducer.loggedInUser.inboxes.$values.filter(
            (item: any) => item.InboxId === InboxId
          )[0].servicePhoneNumber.OnlyReceiveMFA;
        dispatch<any>(setMFA(res2));
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export async function getLanguage() {
  ////////debugger;
  let languages: any = [];
  let res: any = await contactservice.GetLanguages();
  if (res !== undefined) {
    Object.keys(res.translation).map((key, index) => {
      let langueage = { label: res.translation[key].name, value: key };
      if (!languages.includes(langueage)) {
        languages.push(langueage);
      }
    });
    return languages;
  }
}
//get chatId and save it
// export const setActivityConfigurations = (
//   data: any,
//   userId: any,
//   channelId: any,
//   chatId: any,
//   teamId: any,
//   tid:any,
//   userEmail:any
// ) => {
//   return async (dispatch: Dispatch) => {
//     try {
//       //////debugger;
//       var object: any = {};
//       let rowKey: any = null;
//       let result: any =  contactservice.getInstlationAppId(userId,data.GraphToken).then(async (AppInstalation: any) => {
//       AppInstalation = await AppInstalation.json();
//       if (
//         AppInstalation != "undefined" &&
//         AppInstalation != undefined &&
//         AppInstalation != "null" &&
//         AppInstalation != null &&
//         AppInstalation != ""
//       ) {
//         if (
//           AppInstalation?.value[0].id != "undefined" &&
//           AppInstalation?.value[0].id != undefined &&
//           AppInstalation?.value[0].id != "null" &&
//           AppInstalation?.value[0].id != null &&
//           AppInstalation?.value[0].id != ""
//         ) {
//           let TeamsActivityRegistrations: any;
//           TeamsActivityRegistrations = await contactservice.GetUserTeamsActivityRegistrations(data.ApiToken);
//           TeamsActivityRegistrations = await TeamsActivityRegistrations.json();
//           if (TeamsActivityRegistrations.Data.$values.length<=0) {
//           let ChatDataResult: any = await contactservice.getChatId(
//             userId,
//             AppInstalation?.value[0].id,
//             data.GraphToken
//           ).then(async (ChatData: any) => {
//             ChatData = await ChatData.json();
//           //////debugger;
//           if (
//             ChatData != "undefined" &&
//             ChatData != undefined &&
//             ChatData != "null" &&
//             ChatData != null &&
//             ChatData != ""
//           ) {
//             chatId =
//               ChatData.id != undefined && ChatData.id != null
//                 ? ChatData.id
//                 : "";
//                 if (
//                   chatId != "undefined" &&
//                   chatId != undefined &&
//                   chatId != "null" &&
//                   chatId != null &&
//                   chatId != ""
//                 ) {
//                   //check if user have the same data
                 
//                     //////debugger;
//                     object = {
//                       UserEmail: userEmail,
//                       UserId: userId,
//                       ChatId: chatId,
//                       TenantId: tid,
//                     };
//                   } 
//                   // else {
//                   //   object = {
//                   //     UserEmail: userEmail,
//                   //     UserId: userId,
//                   //     ChatId: chatId,
//                   //     TenantId: tid,
//                   //     RowKey: TeamsActivityRegistrations.Data.$values[0].RowKey,
//                   //   };
//                   // }
//                   TeamsActivityRegistrations = await contactservice.SaveTeamsActivityUser(
//                     object,
//                     data.ApiToken
//                   );
//                   TeamsActivityRegistrations = await TeamsActivityRegistrations.json();
//                 }
          
//         });
//       }
//     }
//   }
// });
// if (
//   teamId != "undefined" &&
//   teamId != undefined &&
//   teamId != "null" &&
//   teamId != null &&
//   teamId != ""
// ) {
//   //check if user have the same data
//   let TeamsActivityRegistrations: any;
//   TeamsActivityRegistrations =
//     await contactservice.GetgroupTeamsActivityRegistrations(data.ApiToken);
//   TeamsActivityRegistrations = await TeamsActivityRegistrations.json();
//   if (TeamsActivityRegistrations.Data.$values.length<=0) {
//     //////debugger;
//     object = {
//       UserId: userId,
//       TeamId: teamId,
//       TenantId: tid,
//     };
//   } else {
//     object = {
//       UserId: userId,
//       TeamId: teamId,
//       TenantId: tid,
//       RowKey: TeamsActivityRegistrations.Data.$values[0].RowKey,
//     };
//   }
//   TeamsActivityRegistrations = await contactservice.SaveTeamsActivityGroup(
//     object,
//     data.ApiToken
//   );
//   TeamsActivityRegistrations = await TeamsActivityRegistrations.json();
// }   
    
//     } catch (err) {
//       dispatch<any>(openTrialExpiredPage("SystemError"));
//       //console.log(err);
//     }
//   };
// };
// async function getWebexConfig(token: any) {
//   try {
//     var req = window.location.href;
//     let brand=req.includes("cttext-webex")?"cttext":"yakchat";
//     if (req.includes("webex")) {
//       let webexR: any = localStorage.getItem("webexR"); //store.getState().reduxReducer.webexRToken;
//       let webexT: any = localStorage.getItem("webexToken"); //store.getState().reduxReducer.webexToken;
//       let useremail: any = localStorage.getItem("email");
//       if (
//         (webexT == undefined ||
//           webexT == "null" ||
//           webexT == null ||
//           webexT == "") &&
//         localStorage.getItem("webexC") != "" &&
//         localStorage.getItem("webexC") != undefined &&
//         localStorage.getItem("webexC") != "null" &&
//         localStorage.getItem("webexC") != null
//       ) {
//         let tokenhash: any = await contactservice.getTokenWebex(
//           localStorage.getItem("webexC"),brand
//         );
//         webexT = tokenhash.access_token;
//         webexR = tokenhash.refresh_token;
//         let userinfo:any=await contactservice.GetWebexUser(webexT);
//         useremail=userinfo.email;
//         localStorage.setItem("webexToken", tokenhash.access_token);
//         localStorage.setItem("email",useremail);
//         store.dispatch<any>(webexToken(webexT));
//         localStorage.setItem("webexR", tokenhash.refresh_token);
//         store.dispatch<any>(webexRToken(webexR));
//         localStorage.setItem("webexConfigDate", Date().toLocaleString());
//         localStorage.setItem("webexC", "");
//       } else if (
//         webexR != "undefined" &&
//         webexR != undefined &&
//         webexR != "null" &&
//         webexR != null &&
//         webexR != ""
//       ) {
//         let tokenhash: any = await contactservice.getTokenRefreshWebex(webexR,brand);
//         webexT = tokenhash.access_token;
//         webexR = tokenhash.refresh_token;
//         let userinfo:any=await contactservice.GetWebexUser(webexT);
//         useremail=userinfo.email;
//         localStorage.setItem("email",useremail);
//         localStorage.setItem("webexToken", tokenhash.access_token);
//         store.dispatch<any>(webexToken(webexT));
//         localStorage.setItem("webexR", tokenhash.refresh_token);
//         store.dispatch<any>(webexRToken(webexR));
//         localStorage.setItem("webexConfigDate", Date().toLocaleString());
//       } else {
//         return null;
//       }
//       let webexRoomId: any = 0;
//       let webexTabRoomId: any = 0;
//       var object: any = {};
//       let rowKey: any = null;
//       let webexConfig: any;
//       webexConfig = await contactservice.GetWebexRegistrations(token);
//       webexConfig = await webexConfig.json();
//       if (webexConfig.Data.UserEmail == null) {
//         //////debugger;
//         let WebexRoomsData: any = await contactservice.GetWebexRooms(webexT);
//         let webRoom = WebexRoomsData.items.some(
//           (item: { title: any }) => item.title === (brand=="cttext"?configData.webexRoomName:configData.yakchatwebexRoomName)
//         );
//         if (webRoom == false) {
//           var Wroom: any = {};
//           Wroom = { title: brand=="cttext"?configData.webexRoomName:configData.yakchatwebexRoomName };
//           let WebexRoom: any = await contactservice.CreateWebexRoom(
//             Wroom,
//             webexT
//           );
//           webexRoomId = WebexRoom.id;
//           var Tabroom: any = {
//             roomId: webexRoomId,
//             contentUrl:brand=="cttext"? configData.webexPublishUrl:configData.yakchatwebexPublishUrl,
//             displayName:brand=="cttext"? configData.webexTabName:configData.yakchatwebexTabName,
//           };
//           let WebexTabRoom: any = await contactservice.CreateWebexTab(
//             Tabroom,
//             webexT
//           );
//           webexTabRoomId = WebexTabRoom.id;
//         } else {
//           webexRoomId = WebexRoomsData.items.filter(
//             (x: any) => x.value == (brand=="cttext"?configData.webexRoomName:configData.yakchatwebexRoomName)
//           ).id;
//         }
//       } //if there is configuration in AzureTable for this user
//       else {
//         webexRoomId = webexConfig.Data.RoomId;
//         rowKey = webexConfig.Data.rowKey;
//       }
//       object = {
//         RoomId: webexRoomId,
//         RefreshCode: webexR,
//         rowKey: rowKey,
//       };
//       webexConfig = await contactservice.SaveWebexRegistrations(object, token);
//       webexConfig = await webexConfig.json();
//       return webexConfig;
//     }
//   } catch (error) {
//     alert("Test" + error);
//   }
// }
export const setLoggedInUser = (res: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let res2: any = await contactservice.getUser(
        res.accessTokenApi,
        res.account.userName
      );
      res2 = await res2.json();
      // res2 = await res2.text();
      // //console.log("res2",res2);
      ////////debugger;
      //let webexdata = await getWebexConfig(res.accessTokenApi);
      const registerDate = res2 ? res2.RegistrationDate : "";
      const trialDays = registerDate
        ? Moment(Moment().format("YYYY-MM-DDTHH:mm:ss")).diff(
            Moment(registerDate),
            "days"
          )
        : 0;
      const trialId = res2 ? res2?.Subscription?.ServicePlanId : "";
      if (res2 === null || res2 === "" || res2 === undefined) {
        if (
          res.account.userName.includes(".onmicrosoft.com") ||
          res.account.userName.endsWith(".tk")
        ) {
          dispatch<any>(openTrialExpiredPage("DomainValidation"));
          return;
        }
        dispatch<any>(openRegistrationPage(true));
        dispatch<any>(accessTokenRegister(res.accessTokenApi));
      } else {
        if (res2.Disabled === true || res2.Subscription.Disabled === true) {
          dispatch<any>(openTrialExpiredPage("DisabledError"));
          return;
        }
        if (res2.Email.endsWith(".tk")) {
          dispatch<any>(openTrialExpiredPage("DomainValidation"));
          return;
        }
        if (res2.Subscription.EnableTranslation === true) {
          let languages = await getLanguage();
          store.dispatch<any>(TranslationLanguages(languages));
        }
        if (res2 !== undefined) {
          var userEmail = res2.Email;
          let findbandwidthUser = res2?.UniqueNumber;
          if (findbandwidthUser) {
            dispatch<any>(newBandwidthUser(true));
          }
        }
        if (res2.WhiteLabelBrand === "calltower") {
          dispatch<any>(setCurrentTheme("calltower"));
          dispatch<any>(whiteLabelBrandThem("calltower"));
        }
        if (res2.WhiteLabelBrand === "commschoice") {
          dispatch<any>(setCurrentTheme("commschoice"));
          dispatch<any>(whiteLabelBrandThem("commschoice"));
        }
        if (res2.WhiteLabelBrand === "onenet") {
          dispatch<any>(setCurrentTheme("onenet"));
          dispatch<any>(whiteLabelBrandThem("onenet"));
        }
        if (res2.WhiteLabelBrand === "mobex") {
          dispatch<any>(setCurrentTheme("mobex"));
          dispatch<any>(whiteLabelBrandThem("mobex"));
        }
        if (res2.WhiteLabelBrand === "berkshire") {
          dispatch<any>(setCurrentTheme("berkshire"));
          dispatch<any>(whiteLabelBrandThem("berkshire"));
        }
        if (res2.WhiteLabelBrand === "teligentip") {
          dispatch<any>(setCurrentTheme("teligentip"));
          dispatch<any>(whiteLabelBrandThem("teligentip"));
        }
        if (res2.WhiteLabelBrand === "oneuc") {
          dispatch<any>(setCurrentTheme("oneuc"));
          dispatch<any>(whiteLabelBrandThem("oneuc"));
        }
        if (res2.WhiteLabelBrand === "setup4") {
          dispatch<any>(setCurrentTheme("setup"));
          dispatch<any>(whiteLabelBrandThem("setup"));
        } else if (trialDays > 14 && (trialId === 1 || trialId === 7)) {
          await contactservice.registerUserToZoho(
            userEmail,
            "",
            registerDate,
            trialId,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            res.accessTokenApi
          );
          await contactservice.registerUserToMailChimp(
            userEmail,
            registerDate,
            trialId,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            res.accessTokenApi
          );
          dispatch<any>(openTrialExpiredPage(true));
        } else if (trialDays >= 10 && (trialId === 1 || trialId === 7)) {
          dispatch<any>(
            setAlertData({
              className: "success",
              text: "Your YakChat trial is ending soon. ",
              redirectlink: "https://yakchat.com/purchase",
              redirectlinkTitle: "Subscribe here",
              text2: " to keep texting from Microsoft Teams.",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 10000);
          await contactservice.registerUserToZoho(
            userEmail,
            "",
            registerDate,
            trialId,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            res.accessTokenApi
          );
          await contactservice.registerUserToMailChimp(
            userEmail,
            registerDate,
            trialId,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            res.accessTokenApi
          );
        }
        //console.log(res2?.Subscription);

        var CreditAlert = res2?.Subscription.CreditAlert;
        var FreeCredit =
          res2?.Subscription.FreeCreditBalance == undefined
            ? 0
            : res2?.Subscription.FreeCreditBalance;
        var LowFreeCreditThreshold =
          res2?.Subscription.LowFreeCreditThreshold == undefined
            ? 0
            : res2?.Subscription.LowFreeCreditThreshold;
        var Credit =
          res2?.Subscription.Credit == undefined
            ? 0
            : res2?.Subscription.Credit;
        var LowCreditThreshold =
          res2?.Subscription.LowCreditThreshold == undefined
            ? 0
            : res2?.Subscription.LowCreditThreshold;
        var ServicePlanId = res2?.Subscription.ServicePlanId;
        var companyId =
          res2?.Subscription.Company.ParentId == 0
            ? res2?.Subscription.CompanyId
            : res2?.Subscription.Company.ParentId;
        var hasParentCompany =
          res2?.Subscription.Company.ParentId == 0 ? false : true;

        let companyData;
        if (hasParentCompany) {
          companyData = await contactservice.getUserCompany(
            res.accessTokenApi,
            res2?.Subscription.Company.ParentId
          );
        } else companyData = res2?.Subscription.Company;
        var LowCreditMessage =
          companyData != undefined && companyData != null
            ? companyData.LowCreditMessage != "" &&
              companyData.LowCreditMessage != null
              ? companyData.LowCreditMessage.replace(
                  /(?:https?|ftp):\/\/[\n\S]+/g,
                  ""
                )
              : "You are running low credit"
            : "You are running low credit";
        var urlRegex = /(https?:\/\/[^ ]*)/;

        const urltopup =
          companyData.LowCreditMessage != "" &&
          companyData.LowCreditMessage != null
            ? companyData.LowCreditMessage
            : "https://yakchat.com/add_credit";
        let url = urltopup.match(urlRegex);
        if (
          CreditAlert === true &&
          FreeCredit < LowFreeCreditThreshold &&
          Credit < LowCreditThreshold &&
          (ServicePlanId != 1 || ServicePlanId != 7)
        ) {
          dispatch<any>(
            setAlertData({
              className: "success",
              text: "WARNING:  Credit balance low.",
              redirectlink:
                url != null
                  ? url[0].toString()
                  : "https://yakchat.com/add_credit",
              redirectlinkTitle: " Top up your account here.",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 10000);
        }
        if (
          CreditAlert === true &&
          Credit == 0 &&
          FreeCredit == 0 &&
          (ServicePlanId != 1 || ServicePlanId != 7)
        ) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: "WARNING:  Out of credit.",
              redirectlink:
                url != null
                  ? url[0].toString()
                  : "https://yakchat.com/add_credit",
              redirectlinkTitle: " Top up your account here.",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 10000);
        }

        if (res2.FirstUpdateMsgCount !== true) {
          dispatch<any>(updateRoomsLatestMsgs(res.accessTokenApi));
        }

        dispatch({
          type: index.ActionTypes.setLoggedInUser,
          loggedInUser: { ...res2, ...res },
        });
        if (res2 !== null) {
          //////debugger;
          dispatch<any>(
            getInboxesBasedOnServicePlanId(res2?.Subscription?.ServicePlanId)
          );
          dispatch<any>(getUserByEmail(userEmail));
        }
      }
    } catch (err) {
      dispatch({ type: index.ActionTypes.setLoggedInUser, loggedInUser: res });
      dispatch<any>(openTrialExpiredPage("SystemError"));
      //console.log(err);
    }
  };
};
export async function getCompanyVersion_V2(res: any) {
  ////////debugger;
  let res2: any = await contactservice.getCompanyVersion_V2(res);
  res2 = await res2.json();
  if (res2.Data == null) {
    res2.Data = 1;
  }
  return res2;
}
export const setLoggedInUser_V2 = (res: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let res2: any = await contactservice.getUser_V2(res.accessTokenApi);
      ////////debugger;
      res2 = await res2.json();
      if(res2.Data==null&&res2.Message.includes("UserInboxMapping not found"))
      { dispatch<any>(opensharedError(true));
        return;}
      res2 = res2.Data;
     // let webexdata = await getWebexConfig(res.accessTokenApi);
      // res2 = await res2.text();
      // //console.log("res2",res2);
      const registerDate = res2 ? res2.user?.RegistrationDate : "";
      const trialDays : number = registerDate
        ? Moment(Moment().format("YYYY-MM-DDTHH:mm:ss")).diff(
            Moment(registerDate),
            "days"
          )
        :0;

      const trialId = res2 ? res2?.inboxes.$values[0].ServicePlanId : "";//just for trial
      if (res2 === null || res2 === "" || res2 === undefined) {
        ////////debugger;
        if (
          res.account.userName.includes(".onmicrosoft.com") ||
          res.account.userName.endsWith(".tk")
        ) {
          dispatch<any>(openTrialExpiredPage("DomainValidation"));
          return;
        }
        dispatch<any>(openRegistrationPage(true));
        dispatch<any>(accessTokenRegister(res.accessTokenApi));
      } else {
        if (res2.user.Disabled === true || res2.user.Disabled === true) {
          dispatch<any>(openTrialExpiredPage("DisabledError"));
          return;
        }
        if (res2.user.Email.endsWith(".tk")) {
          dispatch<any>(openTrialExpiredPage("DomainValidation"));
          return;
        }
        if (res2?.inboxes.$values[0].EnableTranslation === true) {
          let languages = await getLanguage();
          store.dispatch<any>(TranslationLanguages(languages));
        }
        if (res2 !== undefined) {
          var userEmail = res2.user.Email;
          var userId = res2.user.Id;
          let findbandwidthUser = res2?.inboxes.$values[0].PhoneNumber;
          if (findbandwidthUser) {
            dispatch<any>(newBandwidthUser(true));
          }
        }
        if (res2.user.WhiteLabelBrand === "calltower") {
          dispatch<any>(setCurrentTheme("calltower"));
          dispatch<any>(whiteLabelBrandThem("calltower"));
        }
        if (res2.user.WhiteLabelBrand === "commschoice") {
          dispatch<any>(setCurrentTheme("commschoice"));
          dispatch<any>(whiteLabelBrandThem("commschoice"));
        }
        if (res2.user.WhiteLabelBrand === "onenet") {
          dispatch<any>(setCurrentTheme("onenet"));
          dispatch<any>(whiteLabelBrandThem("onenet"));
        }
        if (res2.user.WhiteLabelBrand === "mobex") {
          dispatch<any>(setCurrentTheme("mobex"));
          dispatch<any>(whiteLabelBrandThem("mobex"));
        }
        if (res2.user.WhiteLabelBrand === "berkshire") {
          dispatch<any>(setCurrentTheme("berkshire"));
          dispatch<any>(whiteLabelBrandThem("berkshire"));
        }
        if (res2.user.WhiteLabelBrand === "teligentip") {
          dispatch<any>(setCurrentTheme("teligentip"));
          dispatch<any>(whiteLabelBrandThem("teligentip"));
        }
        if (res2.user.WhiteLabelBrand === "setup4") {
          dispatch<any>(setCurrentTheme("setup"));
          dispatch<any>(whiteLabelBrandThem("setup"));
        } else if (trialDays > 14 && (trialId === 1 || trialId === 7)) {
          // await contactservice.registerUserToZoho(userEmail, "", registerDate, trialId, "", "", "", "", "", "", "", "", "",res.accessTokenApi);
          // await contactservice.registerUserToMailChimp(userEmail,  registerDate, trialId, "", "", "", "", "", "", "", "",res.accessTokenApi);
          dispatch<any>(openTrialExpiredPage(true));
        } else if (trialDays >= 10 && (trialId === 1 || trialId === 7)) {
          dispatch<any>(
            setAlertData({
              className: "success",
              text: "Your YakChat trial is ending soon. ",
              redirectlink: "https://yakchat.com/purchase",
              redirectlinkTitle: "Subscribe here",
              text2: " to keep texting from Microsoft Teams.",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 10000);
          // await contactservice.registerUserToZoho(userEmail, "", registerDate, trialId, "", "", "", "", "", "", "", "", "",res.accessTokenApi);
          //await contactservice.registerUserToMailChimp(userEmail,  registerDate, trialId, "", "", "", "", "", "", "", "",res.accessTokenApi);
        }
        //console.log(res2);
        ////////debugger;
        var CreditAlert = res2?.inboxes.$values[0].CreditAlert;
        var FreeCredit =
        res2?.inboxes.$values[0].FreeCreditBalance == undefined
            ? 0
            : res2?.inboxes.$values[0].FreeCreditBalance;
        var LowFreeCreditThreshold =
        res2?.inboxes.$values[0].LowFreeCreditThreshold == undefined
            ? 0
            :res2?.inboxes.$values[0].LowFreeCreditThreshold;
        var Credit = res2?.inboxes.$values[0].Credit == undefined ? 0 : res2?.inboxes.$values[0].Credit;
        var LowCreditThreshold =
        res2?.inboxes.$values[0].LowCreditThreshold == undefined
            ? 0
            : res2?.inboxes.$values[0].LowCreditThreshold;
        var ServicePlanId = res2?.user.ServicePlanId;
        var LowCreditMessage =
          res2?.companyDetails != undefined && res2?.companyDetails != null
            ? res2?.companyDetails.LowCreditMessage != "" &&
              res2?.companyDetails.LowCreditMessage != null
              ? res2?.companyDetails.LowCreditMessage.replace(
                  /(?:https?|ftp):\/\/[\n\S]+/g,
                  ""
                )
              : "You are running low credit"
            : "You are running low credit";
        var urlRegex = /(https?:\/\/[^ ]*)/;

        const urltopup =
          res2?.companyDetails.LowCreditMessage != "" &&
          res2?.companyDetails.LowCreditMessage != null
            ? res2?.companyDetails.LowCreditMessage
            : "https://yakchat.com/add_credit";
        let url = urltopup.match(urlRegex);
        if (
          CreditAlert === true &&
          FreeCredit < LowFreeCreditThreshold &&
          Credit < LowCreditThreshold &&
          (ServicePlanId != 1 || ServicePlanId != 7)
        ) {
          dispatch<any>(
            setAlertData({
              className: "success",
              text: "WARNING:  Credit balance low.",
              redirectlink:
                url != null
                  ? url[0].toString()
                  : "https://yakchat.com/add_credit",
              redirectlinkTitle: " Top up your account here.",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 10000);
        }
        if (
          CreditAlert === true &&
          Credit == 0 &&
          FreeCredit == 0 &&
          (ServicePlanId != 1 || ServicePlanId != 7)
        ) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: "WARNING:  Out of credit.",
              redirectlink:
                url != null
                  ? url[0].toString()
                  : "https://yakchat.com/add_credit",
              redirectlinkTitle: " Top up your account here.",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 10000);
        }

        // if (res2.user.FirstUpdateMsgCount !== true) {
        //   dispatch<any>(updateRoomsLatestMsgs(res.accessTokenApi));
        // }
        dispatch({
          type: index.ActionTypes.setLoggedInUser,
          loggedInUser: { ...res2, ...res },
        });
        if (res2 !== null) {
          //////debugger;
          dispatch<any>(
            getInboxesBasedOnServicePlanId(4)
          );
          dispatch<any>(getUserByEmail(userEmail));
        }
      }
    } catch (err) {
      dispatch({ type: index.ActionTypes.setLoggedInUser, loggedInUser: res });
      dispatch<any>(openTrialExpiredPage("SystemError"));
      //console.log(err);
    }
  };
};
export const getPhoneNumberTypeAndSendMessage = (
  phoneNumber: any,
  roomId: any,
  text: any,
  userEmail: any,
  file: any,
  calledFrom: any
) => {
  return async (dispatch: Dispatch) => {
    var serviceProviderType =
      store.getState().reduxReducer.loggedInUser.Subscription.ServiceProviderID;
    dispatch<any>(
      SendMessageToNumber(
        serviceProviderType,
        roomId,
        text,
        userEmail,
        userEmail,
        file,
        calledFrom
      )
    );
  };
};
export const getPhoneNumberTypeAndSendMessage_V2 = (
  data: any,
  phoneNumber: any,
  roomId: any,
  text: any,
  userEmail: any,
  file: any,
  calledFrom: any
) => {
  return async (dispatch: Dispatch) => {
    //////debugger;
    // if(isNaN(userEmail))
    // {
    //   dispatch<any>(
    //     setAlertData({
    //       className: "danger",
    //       text: "Re-Open the conversation to be able to send",
    //     })
    //   );
     
    //   setTimeout(() => {
    //     dispatch<any>(setAlertData(null));
    //   }, 2000);
    //   return;
    // }
    var serviceProviderType = store
      .getState()
      .reduxReducer.loggedInUser.inboxes.$values.filter(
        (item: any) => item.InboxId == userEmail
      )[0].ServiceProviderId;
    dispatch<any>(
      SendMessageToNumber_V2(
        data,
        serviceProviderType,
        roomId,
        text,
        userEmail,
        file,
        calledFrom
      )
    );
  };
};
export const insertMessageIntoRoom = (
  newMessage: any,
  roomId: number,
  calledFrom: any
) => {
  ////////debugger;
  let newOpenMessageRoom = store.getState().reduxReducer.openMessageRoom;
  if (newOpenMessageRoom.Id === (roomId || roomId)) {
    newOpenMessageRoom.Messages.$values.push(newMessage);
    newOpenMessageRoom.LastMsgText = newMessage.Text;
    newOpenMessageRoom.LastMsgTime = newMessage.TimeStamp;
  }
  const newRoomData = store.getState().reduxReducer.roomData;
  if (newRoomData?.length !== 0) {
    var insertMessageToRoom = newRoomData.find((x: any) => x.Id === roomId);
    if (
      insertMessageToRoom !== undefined &&
      insertMessageToRoom?.length !== 0
    ) {
      insertMessageToRoom.Messages.$values.push(newMessage);
      insertMessageToRoom.LastMsgText = newMessage.Text;
      insertMessageToRoom.LastMsgTime = newMessage.TimeStamp;
    }
    newRoomData.unshift(
      newRoomData.splice(
        newRoomData.findIndex((item: any) => item.Id === roomId),
        1
      )[0]
    );
  }
  return async (dispatch: Dispatch) => {
    let companyVersion = store.getState().reduxReducer.companyVersion;
    if (companyVersion === 2) {
      dispatch<any>(GetGroupRoomPrimaryMember_V2(roomId, "SignalR"));
    } else {
      dispatch<any>(GetGroupRoomPrimaryMember(roomId, "SignalR"));
    }
    //debugger;
    var selected = store
      .getState()
      .reduxReducer.sharedInboxData.other.filter(
        (x: any) => x.email === insertMessageToRoom?.CreatorEmail
      );
    if (selected?.length !== 0) {
      dispatch<any>(
        setCurrentSelectedSharedEmail(selected[0], "SignalR", roomId)
      );
    }
  };
};
export const getInboxesBasedOnServicePlanId = (servicePlanId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: index.ActionTypes.SetServicePlanId,
        SetServicePlanId: servicePlanId,
      });
      let companyVersion = store.getState().reduxReducer.companyVersion;
      switch (servicePlanId) {
        case 1:
          if (companyVersion === 2) {
            dispatch<any>(getInboxList_V2(0));
          } else {
            dispatch<any>(getInboxList(0));
          }
          break;
        case 3:
          if (companyVersion === 2) {
            dispatch<any>(getInboxList_V2(0));
          } else {
            dispatch<any>(getInboxList(0));
          }
          break;
        case 4:
          if (companyVersion === 2) {
            dispatch<any>(getInboxList_V2(2));
          } else {
            dispatch<any>(getInboxList(2));
          }
          break;
        case 6:
          if (companyVersion === 2) {
            dispatch<any>(getInboxList_V2(1));
          } else {
            dispatch<any>(getInboxList(1));
          }
          break;
        default:
          if (companyVersion === 2) {
            dispatch<any>(getInboxList_V2(2));
          } else {
            dispatch<any>(getInboxList(2));
          }
          break;
      }
    } catch (err) {
      //console.log(err);
      //  await AuthService.saveToken();
    }
  };
};
export const setNewCreatedGroup = (openGroup: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      openNewGroup: openGroup,
      type: index.ActionTypes.SET_NEW_GROUP,
    });
  };
};
export const AddContactList = (
  groupName: string,
  newContacts: any,
  groupEmail: string
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        const contactLists = {
          Id: 0,
          ListName: groupName,
          CreatorEmail:
            store.getState().reduxReducer.loggedInUser.account.userName,
          GroupEmail: groupEmail,
          ListAdmins: "",
          localContacts: [],
        };
        let response: any = await contactservice.AddContactList(contactLists);
        response = await response.json();
        let id = 0;
        if (response)
          id =
            response.$values !== undefined
              ? response.$values?.find((x: any) => x.ListName === groupName).Id
              : response.find((x: any) => x.ListName === groupName).Id;
        dispatch<any>(shiftContactsInContactList([], newContacts, id));
        dispatch<any>({
          type: "addNewListToLists",
          newList: {
            ListData: {
              ...contactLists,
              localContacts: { $values: [] },
              Id: id,
            },
            contactCount: 0,
          },
        });

        let openGroup =
          response.$values !== undefined
            ? response.$values?.find((x: any) => x.Id === id)
            : response.find((x: any) => x.Id === id);
        if (newContacts.length === 0) {
          dispatch<any>(setNewCreatedGroup(openGroup));
          document.getElementById("groupClickIcon")?.click();
        }
        dispatch<any>(
          setAlertData({
            className: "normal",
            text: "The Contact List was saved successfully!",
          })
        );
        setTimeout(() => {
          dispatch<any>(setTabOpened("viewgroup"));
        }, 1000);
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      } catch (err) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error!" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        //console.log(err);
      }
    }
  };
};
export const EditContactList = (
  groupId: any,
  groupName: string,
  newContacts: any,
  selectedContactsNewMessage: any,
  groupEmail: string
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        const contactLists = {
          Id: groupId,
          ListName: groupName,
          CreatorEmail:
            store.getState().reduxReducer.loggedInUser.account.userName,
          GroupEmail: groupEmail,
          ListAdmins: "",
          localContacts: [],
        };
        let response: any = await contactservice.AddContactList(contactLists);
        response = await response.json();
        let openGroup =
          response.$values !== undefined
            ? response.$values?.find((x: any) => x.Id === groupId)
            : response.find((x: any) => x.Id === groupId);
        dispatch<any>(setNewCreatedGroup(openGroup));
        dispatch<any>(getYakChatLists());
        dispatch<any>(
          setAlertData({
            className: "normal",
            text: "The Contact List was saved successfully!",
          })
        );
        setTimeout(() => {
          dispatch<any>(setTabOpened("viewgroup"));
        }, 1000);
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      } catch (err) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error!" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        //console.log(err);
      }
    }
  };
};
export const AddContactList_V2 = (
  groupName: string,
  newContacts: any,
  InboxId: string
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        const contactLists = {
          Id: 0,
          ListName: groupName,
          CreatorEmail:
            store.getState().reduxReducer.loggedInUser.account.userName,
          GroupEmail: InboxId,
          InboxId: InboxId,
          ListAdmins: "",
          localContacts: [],
        };
        ////////debugger;
        if (
          store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 6 ||
          store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 7
        ) {
          // if (
          //   contactLists.GroupEmail == "" ||
          //   contactLists.GroupEmail == null
          // ) {
          //   dispatch({
          //     alertData: {
          //       className: "danger",
          //       text: "Please choose Shared Inbox to share contact with, you are on Shared plan.",
          //     },
          //     type: index.ActionTypes.setAlertData,
          //   });
          //   setTimeout(
          //     () =>
          //       dispatch({
          //         alertData: null,
          //         type: index.ActionTypes.setAlertData,
          //       }),
          //     5000
          //   );
          //   return;
          // }
        }
        let response: any = await contactservice.AddContactList_V2(
          contactLists
        );
        response = await response.json();
        var object = {};
        object = {
          Id: response.Data.list.Id,
          InboxId: response.Data.contactPermissions?.$values[0].InboxId,
          CompanyId: response.Data.list.CompanyId,
          CreatorEmail: response.Data.list.CreatorEmail,
          ListName: response.Data.list.ListName,
          CreatedBy: response.Data.list.CreatedBy,
          CreatedDateTime: response.Data.list.CreatedDateTime,
          GroupEmail: response.Data.list.GroupEmail,
          OwnerId: response.Data.list.OwnerId,
          localContacts: response.Data.list.localContacts,
        };
        response.Data = object;
        let id = 0;
        if (response.Code !== 200) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: response.Message,
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
          return;
        }
        //////debugger;
        if (response.Code == 200) id = response.Data.Id;
        dispatch<any>(shiftContactsInContactList([], newContacts, id));
        dispatch<any>({
          type: "addNewListToLists",
          newList: {
            ListData: {
              ...response.Data,
              localContacts: { $values: [] },
              Id: id,
            },
            contactCount: 0,
          },
        });

        let openGroup = response.Data;
        if (newContacts.length === 0) {
          dispatch<any>(setNewCreatedGroup(openGroup));
          document.getElementById("groupClickIcon")?.click();
        }
        dispatch<any>(
          setAlertData({
            className: "normal",
            text: "The Contact List was saved successfully!",
          })
        );
        setTimeout(() => {
          dispatch<any>(setTabOpened("viewgroup"));
        }, 1000);
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      } catch (err) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error!" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        //console.log(err);
      }
    }
  };
};
export const EditContactList_V2 = (
  groupId: any,
  groupName: string,
  newContacts: any,
  selectedContactsNewMessage: any,
  groupEmail: string
) => {
  ////////debugger;
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        const contactLists = {
          ListId: groupId,
          ListName: groupName,
          CreatorEmail:
            store.getState().reduxReducer.loggedInUser.account.userName,
          GroupEmail: groupEmail,
          ListAdmins: "",
          localContacts: [],
        };
        ////////debugger;
        if (
          store.getState().reduxReducer.loggedInUser.user.ServicePlanId == 6 ||
          store.getState().reduxReducer.loggedInUser.user.ServicePlanId == 7
        ) {
          // if (
          //   contactLists.GroupEmail == "" ||
          //   contactLists.GroupEmail == null
          // ) {
          //   dispatch<any>(
          //     setAlertData({
          //       className: "danger",
          //       text: "please choose shared with group , you are on shared plan.",
          //     })
          //   );
          //   return;
          // }
        }
        let response: any = await contactservice.UpdateContactList_V2(
          contactLists
        );
        response = await response.json();
        let id = 0;
        //////debugger;
        if (response.Code !== undefined && response.Code !== 200) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: response.Message,
            })
          );
          return;
        }
        if (response.status !== undefined && response.status == 400) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: "you can share with just one inbox.",
            })
          );
          return;
        }
        var object = {};
        object = {
          Id: response.Data.list.Id,
          InboxId: response.Data.contactPermissions?.$values[0].InboxId,
          CompanyId: response.Data.list.CompanyId,
          CreatorEmail: response.Data.list.CreatorEmail,
          ListName: response.Data.list.ListName,
          CreatedBy: response.Data.list.CreatedBy,
          CreatedDateTime: response.Data.list.CreatedDateTime,
          GroupEmail: response.Data.list.GroupEmail,
          OwnerId: response.Data.list.OwnerId,
          localContacts: response.Data.list.localContacts,
        };
        response.Data = object;
        if (response.Code == 200) id = response.Data.Id;
        let openGroup = response.Data.Id;
        dispatch<any>(setNewCreatedGroup(openGroup));
        dispatch<any>(getYakChatLists_V2());

        dispatch<any>(
          setAlertData({
            className: "normal",
            text: "The Contact List was saved successfully!",
          })
        );
        setTimeout(() => {
          dispatch<any>(setTabOpened("viewgroup"));
        }, 1000);
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      } catch (err) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error!" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        //console.log(err);
      }
    }
  };
};

export const shiftContactsInContactList = (
  previousContacts: any,
  newContacts: any,
  id: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      //////debugger;
      const oldIds = previousContacts.map((x: any) => x.ContactId);
      const newIds = newContacts.map((x: any) => x.ContactId);
      var newContactId = newContacts.filter(
        (x: any) => !oldIds.includes(x.ContactId)
      );
      var loggedInUserEmail =
        store.getState().reduxReducer.loggedInUser.account.userName;
      var yakchatList = store.getState().reduxReducer.yakchatLists;
      //If new Contact Is not zero.
      let valcontact: any = [];
      if (newContactId?.length !== 0) {
        var formatIdForAddApiMethod = newContactId.map((newId: any) => {
          valcontact.push(newId.ContactId);
          return newId.ContactId + "%23";
        });
        var contactIdForAdd = formatIdForAddApiMethod
          .toString()
          ?.replace(/,/g, "");
        let companyVersion = store.getState().reduxReducer.companyVersion;
        if (companyVersion == 2) {
          dispatch<any>(
            addContactsInsideLists_V2(
              valcontact,
              id,
              previousContacts,
              yakchatList
            )
          );
        } else {
          dispatch<any>(
            addContactsInsideLists(
              contactIdForAdd,
              id,
              previousContacts,
              yakchatList
            )
          );
        }
      }
      var filterContactIdForRemove = previousContacts.filter(
        (x: any) => !newIds.includes(x.ContactId)
      );
      //IF removed contact is not 0.
      if (filterContactIdForRemove?.length !== 0) {
        var formatIdForRemovMethod = filterContactIdForRemove.map(
          (newId: any) => {
            return newId.ContactId + "%23";
          }
        );
        var contactIdForRemove = formatIdForRemovMethod
          .toString()
          ?.replace(/,/g, "");
        let companyVersion = store.getState().reduxReducer.companyVersion;
        if (companyVersion === 2) {
          dispatch<any>(
            removeContactsFromLists_V2(
              contactIdForRemove,
              id || "null",
              loggedInUserEmail,
              previousContacts,
              yakchatList
            )
          );
        } else {
          dispatch<any>(
            removeContactsFromLists(
              contactIdForRemove,
              id || "null",
              loggedInUserEmail,
              previousContacts,
              yakchatList
            )
          );
        }
      }
    } catch (err) {
      dispatch<any>(
        setAlertData({
          className: "danger",
          text: "Some contacts were not saved!",
        })
      );
      setTimeout(() => {
        dispatch<any>(setAlertData(null));
      }, 2000);
      //console.log(err);
    }
  };
};
// export const removeContactListsLocalContact
export const addContactsInsideLists = (
  contactId: any,
  listId: any,
  previousContacts: any,
  yakChatList: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        await contactservice
          .AddContactListsLocalContact(contactId, listId)
          .then(async (x: any) => {
            x = await x.json();
            yakChatList = store.getState().reduxReducer.yakchatLists;
            var groupDetail = yakChatList.filter((x: any) =>
              x.Id === undefined ? x.ListData?.Id === listId : x.Id === listId
            )[0];

            if (groupDetail.localContacts !== undefined) {
              groupDetail.localContacts.$values = [];
            } else if (groupDetail.ListData?.localContacts !== undefined) {
              groupDetail.ListData.localContacts.$values = [];
            }
            let openGroupDetails = groupDetail.ListData;
            let companyVersion = store.getState().reduxReducer.companyVersion;
            if (companyVersion === 2) {
              dispatch<any>(
                getContactsByListId_V2(
                  groupDetail,
                  yakChatList,
                  "RemoveContact",
                  previousContacts
                )
              );
            } else {
              dispatch<any>(
                getContactsByListId(
                  groupDetail,
                  yakChatList,
                  "RemoveContact",
                  previousContacts
                )
              );
            }
            dispatch<any>(setTabOpened("viewgroup"));
          });
      } catch (err) {
        //console.log(err);
        dispatch<any>(
          setAlertData({
            className: "danger",
            text: "Some contacts were not saved!",
          })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        //console.log(err);
      }
    }
  };
};
export const addContactsInsideLists_V2 = (
  contactId: any,
  listId: any,
  previousContacts: any,
  yakChatList: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        await contactservice
          .AddContactListsLocalContact_V2(contactId, listId)
          .then(async (x: any) => {
            x = await x.json();
            yakChatList = store.getState().reduxReducer.yakchatLists;
            var groupDetail = yakChatList.filter((x: any) =>
              x.Id === undefined ? x.ListData?.Id === listId : x.Id === listId
            )[0];

            if (groupDetail.localContacts !== undefined) {
              groupDetail.localContacts.$values = [];
            } else if (groupDetail.ListData?.localContacts !== undefined) {
              groupDetail.ListData.localContacts.$values = [];
            }
            let openGroupDetails = groupDetail.ListData;
            let companyVersion = store.getState().reduxReducer.companyVersion;
            dispatch<any>(
              getContactsByListId_V2(
                groupDetail,
                yakChatList,
                "RemoveContact",
                previousContacts
              )
            );

            dispatch<any>(setTabOpened("viewgroup"));
            if (x.Code != 200) {
              dispatch<any>(
                setAlertData({
                  className: "danger",
                  text: x.Message,
                })
              );
              setTimeout(() => {
                dispatch<any>(setAlertData(null));
              }, 2000);
            }
          });
      } catch (err) {
        //console.log(err);
        dispatch<any>(
          setAlertData({
            className: "danger",
            text: "Some contacts were not saved!",
          })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        //console.log(err);
      }
    }
  };
};
export const removeContactsFromLists_V2 = (
  contactId: any,
  listId: any,
  loggedInUserEmail: any,
  previousContacts: any,
  yakChatList: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      //////debugger;
      let x: any = await contactservice.removeContactListsLocalContact(
        contactId,
        listId,
        loggedInUserEmail
      );
      // .then(async (x: any) => {
      // x = await x.json();
      if (x.includes("User not authorized to modify list")) {
        dispatch<any>(setAlertData({ className: "danger", text: x }));
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        dispatch<any>(getYakChatLists_V2());
        //return;
      } else {
        var groupDetail = yakChatList.filter(
          (x: any) => x.ListData?.Id === listId
        )[0];
        dispatch<any>(
          getContactsByListId_V2(
            groupDetail,
            yakChatList,
            "RemoveContact",
            previousContacts
          )
        );
      }
      // });
    }
  };
};
export const removeContactsFromLists = (
  contactId: any,
  listId: any,
  loggedInUserEmail: any,
  previousContacts: any,
  yakChatList: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      let x: any = await contactservice.removeContactListsLocalContact(
        contactId,
        listId,
        loggedInUserEmail
      );
      // .then(async (x: any) => {
      // x = await x.json();
      if (x.includes("User not authorized to modify list")) {
        dispatch<any>(setAlertData({ className: "danger", text: x }));
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        dispatch<any>(getYakChatLists_V2());
        //return;
      } else {
        var groupDetail = yakChatList.filter(
          (x: any) => x.ListData?.Id === listId
        )[0];
        dispatch<any>(
          getContactsByListId(
            groupDetail,
            yakChatList,
            "RemoveContact",
            previousContacts
          )
        );
      }
      // });
    }
  };
};
export const getYakChatLists = () => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        var loggedInEmail = store.getState().reduxReducer.loggedInUser.Email;

        var sharedEmails =
          store.getState().reduxReducer.shareMsgCount?.$values !== undefined
            ? store
                .getState()
                .reduxReducer.shareMsgCount?.$values.map(
                  (a: any) => a?.sharedEmail
                )
                .toString()
            : store
                .getState()
                .reduxReducer.shareMsgCount?.map((a: any) => a?.sharedEmail)
                .toString();
        await contactservice
          .GetContactLists(loggedInEmail + "," + sharedEmails)
          .then(async (resp: any) => {
            let lists = await resp.json();
            // //console.log("lsit",lists);
            lists = lists.$values
              ? lists.$values.sort((a: any, b: any) => {
                  var nameA = a?.ListData?.ListName.toUpperCase();
                  var nameB = b?.ListData?.ListName.toUpperCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                })
              : lists.sort((a: any, b: any) => {
                  var nameA = a?.ListData?.ListName.toUpperCase();
                  var nameB = b?.ListData?.ListName.toUpperCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                });
            dispatch<any>({ type: index.ActionTypes.setYakchatLists, lists });
          });
      } catch (err) {
        //console.log(err);
      }
    }
  };
};
export const getYakChatLists_V2 = () => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        var loggedInEmail =
          store.getState().reduxReducer.sharedInboxData.currentSelected.email;

        var sharedEmails =
          store.getState().reduxReducer.shareMsgCount?.$values !== undefined
            ? store
                .getState()
                .reduxReducer.shareMsgCount?.$values.map(
                  (a: any) => a?.sharedEmail
                )
                .toString()
            : store
                .getState()
                .reduxReducer.shareMsgCount?.map((a: any) => a?.sharedEmail)
                .toString();
        await contactservice
          .GetContactLists_V2(loggedInEmail + "," + sharedEmails)
          .then(async (resp: any) => {
            let lists = await resp.json();
            // //console.log("lsit",lists);
            //////debugger;
            lists = lists.Data != null ? lists.Data : [];

            lists = lists.$values
              ? lists.$values.sort((a: any, b: any) => {
                  var nameA = a?.ListData?.ListName.toUpperCase();
                  var nameB = b?.ListData?.ListName.toUpperCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                })
              : lists.sort((a: any, b: any) => {
                  var nameA = a?.ListData?.ListName.toUpperCase();
                  var nameB = b?.ListData?.ListName.toUpperCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                });
            ////////debugger;
            {
              dispatch<any>({ type: index.ActionTypes.setYakchatLists, lists });
            }
          });
      } catch (err) {
        //console.log(err);
      }
    }
  };
};
export const deleteThisList = (id: string) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        let res: any = await contactservice.DeleteThisList(id);
        res = await res.json();
        if (res.Message) {
          dispatch<any>(
            setAlertData({ className: "danger", text: "There was an error" })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
          return;
        }

        if (res === 1) {
          if (store.getState().reduxReducer.yakchatLists.length > 1) {
            dispatch<any>(setTabOpened("viewgroup"));
          } else {
            dispatch<any>(setTabOpened("contactopen"));
          }
          const lists = _.cloneDeep(store.getState().reduxReducer.yakchatLists);
          lists.splice(
            lists?.findIndex((x: any) => x.$id === id),
            1
          );
          dispatch<any>({ type: index.ActionTypes.setYakchatLists, lists });
          dispatch<any>(
            setAlertData({
              className: "success",
              text: "The Contact List was deleted successfully!",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
          dispatch<any>(getYakChatLists());
        } else if (res === 2) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: "The Contact Group cannot be deleted when it still contains contacts.  Please remove the contacts and retry",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
        } else if (res === 0) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: "This Contact Group cannot be deleted because there are associated messages.Please contact YakChat Support",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
        } else {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: "Oops, unable to complete this request",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 5000);
        }
      } catch (err) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        return;
      }
    }
  };
};
export const deleteThisContact = (contact: any, nextContactId: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        //console.log("companyVersion "+store.getState().reduxReducer.companyVersion);
        let companyVersion = store.getState().reduxReducer.companyVersion;
        var loginemail =
          companyVersion === 1
            ? store.getState().reduxReducer.loggedInUser.Email
            : store.getState().reduxReducer.loggedInUser.user.Email;
        if (contact.UserEmail !== loginemail) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text:
                "You cannot delete a contact that you do not own.  This contact is owned by <" +
                contact.UserEmail +
                ">.",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 5000);
          dispatch<any>(setTabOpened(""));
          dispatch<any>(setTabOpened("contactopen"));
          return;
        }
        const id = contact.ContactId;
        const phoneNumber = contact.PhoneNumber;
        let res: any = await contactservice.DeleteThisContact(id, phoneNumber);
        res = await res.json();
        if (res === 1) {
          dispatch<any>(getyakchatContacts(1));
          dispatch<any>(
            setAlertData({
              className: "success",
              text: "The Contact was deleted successfully!",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
          setTimeout(() => {
            dispatch<any>(
              setContactSelected_OpenContact({
                ...nextContactId,
                type: "YakChat",
              })
            );
          }, 3000);
        } else if (res === 0) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: "There was an error.  Contact is a member of a group(s)",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 3000);
          return;
        }
      } catch (err) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        //console.log(err);
        return;
      }
    }
  };
};
export const deleteThisList_V2 = (id: string) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        let res: any = await contactservice.DeleteThisList_V2(id);
        res = await res.json();
        if (res.Message) {
          dispatch<any>(
            setAlertData({ className: "danger", text: "There was an error" })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
          return;
        }

        if (res.Data === 1) {
          if (store.getState().reduxReducer.yakchatLists.length > 1) {
            dispatch<any>(setTabOpened("viewgroup"));
          } else {
            dispatch<any>(setTabOpened("contactopen"));
          }
          const lists = _.cloneDeep(store.getState().reduxReducer.yakchatLists);
          lists.splice(
            lists?.findIndex((x: any) => x.$id === id),
            1
          );
          dispatch<any>({ type: index.ActionTypes.setYakchatLists, lists });
          dispatch<any>(
            setAlertData({
              className: "success",
              text: "The Contact List was deleted successfully!",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
          dispatch<any>(getYakChatLists_V2());
        } else if (res.Data === 2) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: "The Contact Group cannot be deleted when it still contains contacts.  Please remove the contacts and retry",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
        } else if (res.Data === 0) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: "This Contact Group cannot be deleted because there are associated messages.Please contact YakChat Support",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
        } else {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: "Oops, unable to complete this request",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 5000);
        }
      } catch (err) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        return;
      }
    }
  };
};
export const deleteThisContact_V2 = (contact: any, nextContactId: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        const id = contact.ContactId;
        const phoneNumber = contact.PhoneNumber;
        let res: any = await contactservice.DeleteThisContact_V2(
          id,
          phoneNumber
        );
        res = await res.json();
        if (res.Data >= 1) {
          dispatch<any>(getyakchatContacts_V2(1));
          dispatch<any>(
            setAlertData({
              className: "success",
              text: "The Contact was deleted successfully!",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 2000);
          setTimeout(() => {
            dispatch<any>(
              setContactSelected_OpenContact({
                ...nextContactId,
                type: "YakChat",
              })
            );
          }, 3000);
        } else if (res.Data === 0 || res.Data === null) {
          dispatch<any>(
            setAlertData({
              className: "danger",
              text: "You cannot edit a contact that you do not own.",
            })
          );
          setTimeout(() => {
            dispatch<any>(setAlertData(null));
          }, 3000);
          return;
        }
      } catch (err) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        //console.log(err);
        return;
      }
    }
  };
};
export const selectContactWithCtrl = (contact: any) => {
  return async (dispatch: Dispatch) => {
    try {
      if (contact === null) {
        dispatch({ type: "setSelectedContactsWithCtrl", payload: [] });
        return;
      }
      let contacts: any = [];
      if (store.getState().reduxReducer.selectedContactsWithCtrl?.length > 0) {
        contacts = [
          ...store.getState().reduxReducer.selectedContactsWithCtrl,
          contact,
        ];
      } else if (
        store.getState().reduxReducer.contactSelected_OpenContact &&
        store.getState().reduxReducer.contactSelected_OpenContact !== contact
      ) {
        contacts = [
          store.getState().reduxReducer.contactSelected_OpenContact,
          contact,
        ];
      } else contacts = [contact];
      dispatch({ type: "setSelectedContactsWithCtrl", payload: contacts });
      dispatch<any>(setTabOpened("selectedContacts"));
    } catch (err) {
      dispatch<any>(
        setAlertData({ className: "danger", text: "There was an error" })
      );
      setTimeout(() => {
        dispatch<any>(setAlertData(null));
      }, 2000);
      //console.log(err);
      //  await AuthService.saveToken();
      return;
    }
  };
};
export const getPersonalInboxFromMessage = (pageIndex: number, data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      var UpdateroomData: any = [];
      var getroomdata = store.getState().reduxReducer.roomData;
      var getroomFirstElement = getroomdata.filter(
        (x: any) => x.Id === data.Id
      );
      UpdateroomData.push(getroomFirstElement[0]);
      for (let room of getroomdata.filter((x: any) => x.Id !== data.Id)) {
        room.avatarColor = avatarColorMap[room.Id % 10];
        UpdateroomData.push(room);
      }
      dispatch({ roomData: UpdateroomData, type: index.ActionTypes.setRooms });
      dispatch<any>(setOpenMessageRoom(data));
      let obj = {
        email: "",
        name: store.getState().reduxReducer.loggedInUser.UserName,
        UniqueNumber: store.getState().reduxReducer.loggedInUser.UniqueNumber,
      };
      dispatch({ type: "clearOpenMessageRoom" });
      const openmessageroomId: any = localStorage.getItem("openMessageRoom");
      // const openmessageroomId: any = store.getState().reduxReducer.openMessageRoom;
      let message: any;
      message = JSON.parse(JSON.stringify(openmessageroomId));
      var refreshedMessage =
        UpdateroomData?.length !== 0
          ? UpdateroomData.find((x: any) => x.Id === message.Id)
          : message;
      if (!refreshedMessage) refreshedMessage = UpdateroomData[0];
      dispatch<any>(setOpenMessageRoom(refreshedMessage));
      dispatch<any>(setTabOpened("chatpanel"));
    } catch (err) {
      //console.log(err);
      // await AuthService.saveToken();
    }
  };
};
export const updateGroupPrimaryOwner = (id: number, email: string) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ////////debugger;
        let companyVersion = store.getState().reduxReducer.companyVersion;

        let res: any = await contactservice.UpdateGroupPrimaryOwner(id, email);

        if (companyVersion === 2) {
          dispatch<any>(
            getPersonalInbox_V2(
              1,
              store.getState().reduxReducer.loggedInUser.user.Email,
              false,
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email,
              "updateGroupPrimary",
              ""
            )
          );
        } else {
          dispatch<any>(
            getPersonalInbox(
              1,
              store.getState().reduxReducer.loggedInUser.Email,
              false,
              store.getState().reduxReducer.sharedInboxData.currentSelected
                .email,
              "updateGroupPrimary",
              ""
            )
          );
        }
      } catch (err) {
        dispatch<any>(
          setAlertData({ className: "danger", text: "There was an error" })
        );
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
        return;
      }
    }
  };
};
export const closeConversation = (data: any, value: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        //////debugger;
        var roomId =
          data.Participants !== undefined ? data.Participants[0]!=undefined?data.Participants[0].RoomId:data.Participants.$values[0].RoomId : data;
        contactservice
          .CloseConversation(roomId, value)
          .then(async (resp: any) => {
            if (resp.status === 204 || resp.status === 200) {
              var getroomdata = store.getState().reduxReducer.roomData;
              getroomdata = getroomdata.filter((x: any) => x.Id !== data.Id);
              dispatch({
                roomData: getroomdata,
                type: index.ActionTypes.setRooms,
              });
              if (value !== "false") {
                dispatch<any>(
                  setAlertData({ className: "", text: "Successful close" })
                );
                setTimeout(() => {
                  dispatch<any>(setAlertData(null));
                }, 2000);
              } else {
                var selected = store
                  .getState()
                  .reduxReducer.sharedInboxData.other.filter(
                    (x: any) => x.email === data.CreatorEmail
                  );

                dispatch<any>(
                  setCurrentSelectedSharedEmail(selected[0], "", data.Id)
                );
                dispatch<any>(setOpenMessageRoom(data));
              }
              return;
            } else {
              dispatch<any>(
                setAlertData({
                  className: "danger",
                  text: "There was an error",
                })
              );
              setTimeout(() => {
                dispatch<any>(setAlertData(null));
              }, 2000);
            }
          });
      } catch (err) {
        //console.log(err);
        //  await AuthService.saveToken();
        dispatch<any>(setAlertData({ className: "danger", text: err }));
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      }
    }
  };
};
export const closeConversation_V2 = (data: any, value: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        var roomId =
          data.Participants !== undefined ? data.Participants[0].RoomId : data;
        contactservice
          .CloseConversation_V2(roomId, value)
          .then(async (resp: any) => {
         ////debugger;
            if (resp.status === 204 || resp.status === 200) {
              var getroomdata = store.getState().reduxReducer.roomData;
              getroomdata = getroomdata.filter((x: any) => x.Id !== data.Id);
              dispatch({
                roomData: getroomdata,
                type: index.ActionTypes.setRooms,
              });
              if (value !== "false") {
                dispatch<any>(
                  setAlertData({ className: "", text: "Successful close" })
                );
                setTimeout(() => {
                  dispatch<any>(setAlertData(null));
                }, 2000);
              } else {
                var selected = store
                  .getState()
                  .reduxReducer.sharedInboxData.other.filter(
                    (x: any) => x.email === data.InboxId
                  );

                dispatch<any>(
                  setCurrentSelectedSharedEmail(selected[0], "", data.Id)
                );
                dispatch<any>(
                  getPersonalInbox_V2(
                    1,
                    data.CreatorEmail,
                    true,
                    data.InboxId,
                    "updateRooms",
                    ""
                  )
                );
                dispatch<any>(setOpenMessageRoom(data));
              }
              return;
            } else {
              dispatch<any>(
                setAlertData({
                  className: "danger",
                  text: "There was an error",
                })
              );
              setTimeout(() => {
                dispatch<any>(setAlertData(null));
              }, 2000);
            }
          });
      } catch (err) {
        //console.log(err);
        //  await AuthService.saveToken();
        dispatch<any>(setAlertData({ className: "danger", text: err }));
        setTimeout(() => {
          dispatch<any>(setAlertData(null));
        }, 2000);
      }
    }
  };
};
export const getroomDataBypersonalInbox = (pageIndex: number) => {
  return async (dispatch: Dispatch) => {
    var loggedInUserEmail = store.getState().reduxReducer.loggedInUser.Email;
    dispatch<any>(
      getPersonalInbox(pageIndex, loggedInUserEmail, false, "''", "", "")
    );
  };
};
export const setAlertData = (data: any) => {
  window.scrollTo(window.scrollX, 0);
  return async (dispatch: Dispatch) => {
    dispatch({ alertData: data, type: index.ActionTypes.setAlertData });
  };
};
export const setSpinner = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      spin: value,
      type: index.ActionTypes.SET_SPINNER,
    });
  };
};

export const messageFromSignal = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      isFromSignalR: value,
      type: index.ActionTypes.MESSAGE_FROMSIGNALR,
    });
  };
};
export const contactFromAddContact = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      isFromAddContact: value,
      type: index.ActionTypes.From_AddContact,
    });
  };
};
export const contactFromNewContact = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      isFormNewContact: value,
      type: index.ActionTypes.From_NewContact,
    });
  };
};

export const setSpinnerInSideBar = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      spinSideBar: value,
      type: index.ActionTypes.SET_SPINNER_SIDE_BAR,
    });
  };
};
export const setDefaultContactList = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      defaultContactList: value,
      type: index.ActionTypes.SET_DEFAULT_CONTACT_LIST,
    });
  };
};

export const setContactSourceSharePoint = (isSharePoint: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      contactSource: isSharePoint,
      type: index.ActionTypes.SET_CONTACT_SOURCESHAREPOINT,
    });
  };
};
export const setContactSourceOutlook = (isOutlook: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      contactSourceOutlook: isOutlook,
      type: index.ActionTypes.SET_CONTACT_SOURCEOUTLOOK,
    });
  };
};
export const setDisplayMode = (displayMode: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      displayMode: displayMode,
      type: index.ActionTypes.SET_DISPLAY_MODE,
    });
  };
};
export const setContactSourceActiveDirectory = (isActiveDirectory: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      contactSourceDirectory: isActiveDirectory,
      type: index.ActionTypes.SET_CONTACT_SOURCEACTIVEDIRECTORY,
    });
  };
};

export const messageSelectedContactsCount = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      newCount: value,
      type: index.ActionTypes.MESSAGE_SELECTED_CONTACTS_COUNT,
    });
  };
};

export const mobileMessageSection = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      classAdded: data,
      type: index.ActionTypes.MOBILE_MESSAGE_SECTION,
    });
  };
};
export const selectedroom = (selectedroom: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      selectedroom: selectedroom,
      type: index.ActionTypes.selectedroom,
    });
  };
};
export const whiteLabelBrandThem = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      whiteLabelBrand: data,
      type: index.ActionTypes.WHITE_LABLE_BRAND_THEM,
    });
  };
};
export const setWhiteLabelBrandThem = (theme: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.setWhiteLabelBrandThem, theme });
  };
};

export const setUserDetails = (userdetailObj: any, accessToken: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.USER_DETAILS,
      userDetails: userdetailObj,
    });
  };
};
export const setCompanyDetails = (companyDetailsObj: any, accessToken: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.COMPANY_DETAILS,
      companyDetails: companyDetailsObj,
    });
  };
};
export const setSubscriptionDetails = (
  subscriptionDetailsObj: any,
  accessToken: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.SUBSCRIPTION_DETAILS,
      subscriptionDetails: subscriptionDetailsObj,
    });
  };
};

export const registerCompanyTrail = (
  companyDetailsObj: any,
  subscription: any,
  user: any,
  accessToken: any
) => {
  var splitEmail = "@".concat(user.email.split("@")[1].toString());
  return async (dispatch: Dispatch) => {
    if (user.email.includes(".onmicrosoft.com") || user.email.endsWith(".tk")) {
      dispatch<any>(openTrialExpiredPage("DomainValidation"));
      return;
    }
    var companyData = {
      Name: companyDetailsObj.Companyname,
      IsParent: false,
      ParentId: 0,
      RegistrationDate: new Date().toISOString(),
      SubscriptionCost: 10,
      BillingAddress: {
        Id: 0,
        Line1: companyDetailsObj.BillingAddress.street,
        Line2: "",
        Line3: "",
        ZipCode: companyDetailsObj.BillingAddress.State,
        City: companyDetailsObj.BillingAddress.city,
        State: "",
        Country: companyDetailsObj.BillingAddress.Country,
      },
      Currency: "USD",
      PrimaryContactName: user.fullname,
      PrimaryContactEmail: user.email,
      PrimaryContactPhone: user.PhoneNumber,
      WhiteLabelBrand: "",
    };
    let companyVersion = store.getState().reduxReducer.companyVersion;
    contactservice
      .registerCompanyTrail(companyData, accessToken)
      .then(async (res: any) => {
        res = await res.json();
        var CompanyId = res.Id;
        var memberLimit = 0;
        var planName;
        var loginEmail =
          companyVersion !== 2
            ? store.getState().reduxReducer.loggedInUser.Email !== undefined
              ? store.getState().reduxReducer.loggedInUser.Email
              : ""
            : store.getState().reduxReducer.loggedInUser.user.Email !==
              undefined
            ? store.getState().reduxReducer.loggedInUser.user.Email
            : "";
        if (
          subscription.Subscription === 7 ||
          subscription.Subscription === 6
        ) {
          memberLimit = 4;
          planName = "-Shared";
        }
        if (subscription.Subscription === 1) {
          planName = "-Trail";
        }
        var subscriptionData = {
          CompanyId: CompanyId,
          PaymentType: -10,
          Credit: 1,
          UsedCredit: 0.0,
          Name: companyDetailsObj.Companyname.concat(planName),
          UserLicenseLimitation: 1,
          Disabled: false,
          BlockingSchema: "",
          EnableTranslation: true,
          EnableLists: true,
          ServicePlanId: subscription.Subscription === 1 ? 1 : 7,
          IncludeGroups:
            subscription.Subscription === 7 || subscription.Subscription === 6
              ? true
              : false,
          MembersLimit: memberLimit,
          FreeTextQty: 0,
          TextPrice: 0,
          MonthlyFreeCredit: 0,
          FreeCreditBalance: 1,
          UsedFreeCredit: 0,
          ServiceProviderID: subscription.Subscription === 6 ? 4 : 0,
          StripeSubscriptionID: 0,
          FreeCreditRenewalDate: "2022-05-25T00:00:00Z",
          CreatedDateTime: new Date().toISOString(),
          ModifiedDateTime: new Date().toISOString(),
        };
        var groupEmail = "";
        var strAdditionalUserEmail = "";
        var groupName = "";
        var bandwidthNumber = "";
        if (
          subscription.Subscription === 7 ||
          subscription.Subscription === 6
        ) {
          var additionalEmail1 =
            subscription?.emailUser1 === undefined
              ? ""
              : subscription?.emailUser1.concat(";");
          var additionalEmail2 =
            subscription?.emailUser2 === undefined
              ? ""
              : subscription?.emailUser2.concat(";");
          var additionalEmail3 =
            subscription?.emailUser3 === undefined
              ? ""
              : subscription?.emailUser3.concat(";");
          if (subscription.Subscription === 6) {
            let res = await contactservice
              .servicePhoneNumberGetTrail(accessToken)
              .then(async (res: any) => {
                res = await res.json();
                if (res.$values !== undefined) {
                  bandwidthNumber = res.$values[0]?.PhoneNumber;
                } else {
                  bandwidthNumber = res[0]?.PhoneNumber;
                  //console.log("bandwidthNumber1",bandwidthNumber)
                }
                if (bandwidthNumber === undefined) {
                  bandwidthNumber = "";
                  dispatch<any>(checkBandwidthNumber(true));
                }
                bandwidthNumber = encodeURIComponent(bandwidthNumber);
              })
              .catch((error) => {
                //console.log(error);
              });
          }
          strAdditionalUserEmail =
            additionalEmail1 + additionalEmail2 + additionalEmail3;
          groupEmail = subscription?.sharedInboxName
            ?.replace(/[^A-Z0-9]+/gi, "")
            .toLowerCase()
            .concat(splitEmail);
          groupName =
            subscription?.sharedInboxName !== undefined
              ? subscription?.sharedInboxName
              : "";
        }
        // Register Trail api call
        var resSubscriptionId;
        contactservice
          .registerSubscriptionTrail(subscriptionData, accessToken)
          .then(async (res: any) => {
            res = await res.json();
            // Response of registerTrail Api

            resSubscriptionId = res.Id;

            var userData = {
              Email: user.email,
              SubscriptionId: res.Id,
              Role: 20,
              StoreText: true,
              CreditLimit: "",
              UsedCredit: 0,
              RegistrationDate: new Date().toISOString(),
              DefaultCountryCode:
                companyDetailsObj.BillingAddress.Country === "Australia"
                  ? 61
                  : companyDetailsObj.BillingAddress.Country === "USA"
                  ? 1
                  : companyDetailsObj.BillingAddress.Country ===
                    "United Kingdom"
                  ? 44
                  : companyDetailsObj.BillingAddress.Country === "Canada"
                  ? 1
                  : 0,
              UserName: user.fullname,
              WhiteLabelBrand: "",
              EmailEnabled: false,
              FirstUpdateMsgCount: true,
              IsGroup: false, //subscription.Subscription === 7 || subscription.Subscription === 6  ? true : false,
              UniqueNumber: "",
              UniqueNumberCost: 0,
              CostCode: "",
              MentionData: "",
              Disabled: false,
              TrialUsed: false,
            };
            // UserTrail Api Call

            if (subscription.Subscription === 7) {
              contactservice
                .registerUserTrail(
                  strAdditionalUserEmail,
                  groupEmail,
                  groupName,
                  "",
                  accessToken,
                  userData
                )
                .then(async (res: any) => {
                  res = await res.json();
                })
                .catch((error) => {
                  //console.log(error);
                });
            } else if (subscription.Subscription === 6) {
              contactservice
                .registerUserTrail(
                  strAdditionalUserEmail,
                  groupEmail,
                  groupName,
                  bandwidthNumber,
                  accessToken,
                  userData
                )
                .then(async (res: any) => {
                  res = await res.json();
                  // //console.log('registerUser2', res)
                  //ServicePhoneNumber Update
                  if (bandwidthNumber != undefined && bandwidthNumber != "") {
                    contactservice
                      .servicePhoneNumberUpdateTrail(
                        bandwidthNumber,
                        false,
                        accessToken
                      )
                      .then(async (res: any) => {
                        res = await res.json();
                      })
                      .catch((error) => {
                        //console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  //console.log(error);
                });
            } else {
              contactservice
                .registerUserTrail("", "", "", "", accessToken, userData)
                .then(async (res: any) => {
                  res = await res.json();
                })
                .catch((error) => {
                  //console.log(error);
                });
            }
          })
          .catch((error) => {
            //console.log(error);
          });
        var Street = companyDetailsObj.BillingAddress.street;
        Street = encodeURIComponent(Street);
        var City = companyDetailsObj.BillingAddress.city;
        City = encodeURIComponent(City);
        var saveState = companyDetailsObj.BillingAddress.State;
        saveState = encodeURIComponent(saveState);
        var saveCountry = companyDetailsObj.BillingAddress.Country;

        //Zoho RegisterUser api call
        contactservice
          .registerUserToZoho(
            user.email,
            companyDetailsObj.Companyname,
            new Date().toISOString(),
            subscription.Subscription,
            user.fullname.split(" ")[0],
            user.fullname.split(" ")[1] == null
              ? "lastname"
              : user.fullname.split(" ")[1],
            Street,
            City,
            user.PhoneNumber,
            saveState,
            saveCountry,
            saveState,
            subscription.Subscription === 6 ? bandwidthNumber : "",
            accessToken
          )
          .then(async (res: any) => {
            res = await res.json();
          })
          .catch((error) => {
            //console.log(error);
          });
        // MailChimp RegisterUser api call

        contactservice
          .registerUserToMailChimp(
            user.email,
            new Date().toISOString(),
            subscription.Subscription,
            user.fullname,
            "",
            Street,
            City,
            user.PhoneNumber,
            saveState,
            saveCountry,
            subscription.Subscription === 7 ? "" : user.PhoneNumber,
            accessToken
          )
          .then(async (res: any) => {
            res = await res.json();
          })
          .catch((error) => {
            //console.log(error);
          });
      })
      .catch((error) => {
        //console.log(error);
      });
  };
};
export const registerCompanyTrail_V2 = (
  companyDetailsObj: any,
  subscription: any,
  user: any,
  accessToken: any
) => {
  var splitEmail = "@".concat(user.email.split("@")[1].toString());
  return async (dispatch: Dispatch) => {
    if (user.email.includes(".onmicrosoft.com")) {
      dispatch<any>(openTrialExpiredPage("DomainValidation"));
      return;
    }
    var companyData = {
      Name: companyDetailsObj.Companyname,
      IsParent: false,
      ParentId: 0,
      RegistrationDate: new Date().toISOString(),
      SubscriptionCost: 10,
      BillingAddress: {
        Id: 0,
        Line1: companyDetailsObj.BillingAddress.street,
        Line2: "",
        Line3: "",
        ZipCode: companyDetailsObj.BillingAddress.State,
        City: companyDetailsObj.BillingAddress.city,
        State: "",
        Country: companyDetailsObj.BillingAddress.Country,
      },
      Currency: "USD",
      PrimaryContactName: user.fullname,
      PrimaryContactEmail: user.email,
      PrimaryContactPhone: user.PhoneNumber,
      WhiteLabelBrand: "",
    };
    // contactservice
    //   .registerCompanyTrail(companyData, accessToken)
    //   .then(async (res: any) => {
    //     res = await res.json();
    //var CompanyId = res.Id;
    var memberLimit = 0;
    var planName;
    var loginEmail =
      store.getState().reduxReducer.loggedInUser.user.Email !== undefined
        ? store.getState().reduxReducer.loggedInUser.user.Email
        : "";
    if (subscription.Subscription === 7 || subscription.Subscription === 6) {
      memberLimit = 4;
      planName = "-Shared";
    }
    if (subscription.Subscription === 1) {
      planName = "-Trail";
    }
    var subscriptionData = {
      PaymentType: -10,
      Credit: 1,
      UsedCredit: 0.0,
      Name: companyDetailsObj.Companyname.concat(planName),
      UserLicenseLimitation: 1,
      Disabled: false,
      BlockingSchema: "",
      EnableTranslation: true,
      EnableLists: true,
      ServicePlanId: subscription.Subscription === 1 ? 1 : 7,
      IncludeGroups:
        subscription.Subscription === 7 || subscription.Subscription === 6
          ? true
          : false,
      MembersLimit: memberLimit,
      FreeTextQty: 0,
      TextPrice: 0,
      MonthlyFreeCredit: 0,
      FreeCreditBalance: 1,
      UsedFreeCredit: 0,
      ServiceProviderID: subscription.Subscription === 6 ? 4 : 0,
      StripeSubscriptionID: 0,
      FreeCreditRenewalDate: "2022-05-25T00:00:00Z",
      CreatedDateTime: new Date().toISOString(),
      ModifiedDateTime: new Date().toISOString(),
    };
    var groupEmail = "";
    var strAdditionalUserEmail = "";
    var groupName = "";
    var bandwidthNumber = "";
    if (subscription.Subscription === 7 || subscription.Subscription === 6) {
      var additionalEmail1 =
        subscription?.emailUser1 === undefined
          ? ""
          : subscription?.emailUser1.concat(";");
      var additionalEmail2 =
        subscription?.emailUser2 === undefined
          ? ""
          : subscription?.emailUser2.concat(";");
      var additionalEmail3 =
        subscription?.emailUser3 === undefined
          ? ""
          : subscription?.emailUser3.concat(";");
      if (subscription.Subscription === 6) {
        let res = await contactservice
          .servicePhoneNumberGetTrail(accessToken)
          .then(async (res: any) => {
            res = await res.json();
            if (res.$values !== undefined) {
              bandwidthNumber = res.$values[0]?.PhoneNumber;
            } else {
              bandwidthNumber = res[0]?.PhoneNumber;
              //console.log("bandwidthNumber1",bandwidthNumber)
            }
            if (bandwidthNumber === undefined) {
              bandwidthNumber = "";
              dispatch<any>(checkBandwidthNumber(true));
            }
            bandwidthNumber = encodeURIComponent(bandwidthNumber);
          })
          .catch((error) => {
            //console.log(error);
          });
      }
      strAdditionalUserEmail =
        additionalEmail1 + additionalEmail2 + additionalEmail3;
      groupEmail = subscription?.sharedInboxName
        ?.replace(/[^A-Z0-9]+/gi, "")
        .toLowerCase()
        .concat(splitEmail);
      groupName =
        subscription?.sharedInboxName !== undefined
          ? subscription?.sharedInboxName
          : "";
    }
    // Register Trail api call
    var resSubscriptionId;
    // contactservice
    //   .registerSubscriptionTrail(subscriptionData, accessToken)
    //   .then(async (res: any) => {
    //     res = await res.json();
    // Response of registerTrail Api

    //  resSubscriptionId = res.Id;

    var userData = {
      Email: user.email,
      //SubscriptionId: res.Id,
      Role: 20,
      StoreText: true,
      CreditLimit: "",
      UsedCredit: 0,
      RegistrationDate: new Date().toISOString(),
      DefaultCountryCode:
        companyDetailsObj.BillingAddress.Country === "Australia"
          ? 61
          : companyDetailsObj.BillingAddress.Country === "USA"
          ? 1
          : companyDetailsObj.BillingAddress.Country === "United Kingdom"
          ? 44
          : companyDetailsObj.BillingAddress.Country === "Canada"
          ? 1
          : 0,
      UserName: user.fullname,
      WhiteLabelBrand: "",
      EmailEnabled: false,
      FirstUpdateMsgCount: true,
      IsGroup: false, //subscription.Subscription === 7 || subscription.Subscription === 6  ? true : false,
      UniqueNumber: "",
      UniqueNumberCost: 0,
      CostCode: "",
      MentionData: "",
      Disabled: false,
      TrialUsed: false,
    };
    // UserTrail Api Call

    if (subscription.Subscription === 7) {
      var userTrial = {
        company: companyData,
        subscription: subscriptionData,
        user: userData,
        stradditionalEmails: strAdditionalUserEmail,
        groupName: groupName,
        dedicatedNumber: "",
      };
      contactservice
        .registerUserTrail_V2(accessToken, userTrial)
        .then(async (res: any) => {
          res = await res.json();
        })
        .catch((error) => {
          //console.log(error);
        });
    } else if (subscription.Subscription === 6) {
      var userTrial = {
        company: companyData,
        subscription: subscriptionData,
        user: userData,
        stradditionalEmails: strAdditionalUserEmail,
        groupName: groupName,
        dedicatedNumber: bandwidthNumber,
      };
      contactservice
        .registerUserTrail_V2(accessToken, userTrial)
        .then(async (res: any) => {
          res = await res.json();
          // //console.log('registerUser2', res)
          //ServicePhoneNumber Update
          if (bandwidthNumber != undefined && bandwidthNumber != "") {
            contactservice
              .servicePhoneNumberUpdateTrail(
                bandwidthNumber,
                false,
                accessToken
              )
              .then(async (res: any) => {
                res = await res.json();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    } else {
      var userTrial = {
        company: companyData,
        subscription: subscriptionData,
        user: userData,
        stradditionalEmails: "",
        groupName: "",
        dedicatedNumber: "",
      };
      contactservice
        .registerUserTrail_V2(accessToken, userTrial)
        .then(async (res: any) => {
          res = await res.json();
        })
        .catch((error) => {
          //console.log(error);
        });
    }
    // })
    // .catch((error) => {
    //   //console.log(error);
    // });
    var Street = companyDetailsObj.BillingAddress.street;
    Street = encodeURIComponent(Street);
    var City = companyDetailsObj.BillingAddress.city;
    City = encodeURIComponent(City);
    var saveState = companyDetailsObj.BillingAddress.State;
    saveState = encodeURIComponent(saveState);
    var saveCountry = companyDetailsObj.BillingAddress.Country;

    //Zoho RegisterUser api call
    // contactservice.registerUserToZoho(
    //   user.email,
    //   companyDetailsObj.Companyname,
    //   new Date().toISOString(),
    //   subscription.Subscription,
    //   user.fullname.split(" ")[0],
    //   user.fullname.split(" ")[1] == null
    //     ? "lastname"
    //     : user.fullname.split(" ")[1],
    //   Street,
    //   City,
    //   user.PhoneNumber,
    //   saveState,
    //   saveCountry,
    //   saveState,
    //   subscription.Subscription === 6 ? bandwidthNumber : "",
    //   accessToken
    // )
    // .then(async (res: any) => {
    //   res = await res.json();
    // })
    // .catch((error) => {
    //   //console.log(error);
    // });
    // MailChimp RegisterUser api call

    // contactservice.registerUserToMailChimp(
    //     user.email,
    //     new Date().toISOString(),
    //     subscription.Subscription,
    //     user.fullname,
    //     "",
    //     Street,
    //     City,
    //     user.PhoneNumber,
    //     saveState,
    //     saveCountry,
    //     subscription.Subscription === 7 ? "" : user.PhoneNumber,
    //     accessToken
    //   )
    //   .then(async (res: any) => {
    //     res = await res.json();
    //   })
    //   .catch((error) => {
    //     //console.log(error);
    //   });

    // })
    // .catch((error) => {
    //   //console.log(error);
    // });
  };
};

//MailChimp sendmessage api call

export const sendNewMsgMailChimp_Zoho = (email: any) => {
  return async (dispatch: Dispatch) => {
    email = encodeURIComponent(email);
    var PlanId =
      store.getState().reduxReducer.loggedInUser.Subscription.ServicePlanId;
    if (PlanId === 1 || PlanId === 7) {
      if (email !== "inbox") {
        contactservice
          .checkTrailUser(email)
          .then(async (res: any) => {
            res = await res.json();
            if (res === false) {
              //ZOHO
              contactservice
                .sendNewMsgZoho(email)
                .then(async (res: any) => {
                  res = await res.json();
                })
                .catch((error) => {
                  //console.log(error);
                });
              //MailChimp
              contactservice
                .sendNewMsgMailChimp(email)
                .then(async (res: any) => {
                  res = await res.json();
                })
                .catch((error) => {
                  //console.log(error);
                });
            }
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    }
  };
};

export const sendNewMsgMailChimp_Zoho_V2 = (email: any) => {
  return async (dispatch: Dispatch) => {
    email = encodeURIComponent(email);
    var PlanId = store.getState().reduxReducer.loggedInUser.user.ServicePlanId;
    if (PlanId === 1 || PlanId === 7) {
      if (email !== "inbox") {
        contactservice
          .checkTrailUser(email)
          .then(async (res: any) => {
            res = await res.json();
            if (res === false) {
              //ZOHO
              // contactservice
              // .sendNewMsgZoho(email)
              // .then(async (res: any) => {
              //   res = await res.json();
              // }) .catch((error) => {
              //   //console.log(error);
              // });
              //MailChimp
              contactservice
                .sendNewMsgMailChimp(email)
                .then(async (res: any) => {
                  res = await res.json();
                })
                .catch((error) => {
                  //console.log(error);
                });
            }
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    }
  };
};
//Zoho sendmessage api call
export function sendNewMsgZoho_V2(email: any) {
  return async (dispatch: Dispatch) => {
    ////////debugger;
    email = encodeURIComponent(email);
    var PlanId = store.getState().reduxReducer.loggedInUser.user.ServicePlanId;
    if (PlanId === 1 || PlanId === 7) {
      ////////debugger;
      if (email !== "inbox") {
        contactservice
          .checkTrailUser(email)
          .then(async (res: any) => {
            res = await res.json();
            if (res === false) {
              // contactservice
              // .sendNewMsgZoho(email)
              // .then(async (res: any) => {
              //   res = await res.json();
              // })
              // .catch((error) => {
              //   //console.log(error);
              // });
            }
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    }
  };
}

// MailChimp Cancel Api call

export const cancelBtnMailChimp_V2 = (
  companyDetailsObj: any,
  subscription: any,
  user: any,
  accessToken: any
) => {
  var Street = companyDetailsObj.BillingAddress.street;
  Street = encodeURIComponent(Street);
  var City = companyDetailsObj.BillingAddress.city;
  City = encodeURIComponent(City);
  var saveState = companyDetailsObj.BillingAddress.State;
  saveState = encodeURIComponent(saveState);
  var saveCountry = companyDetailsObj.BillingAddress.Country;
  saveCountry = encodeURIComponent(saveCountry);
  return async (dispatch: Dispatch) => {
    contactservice
      .cancelUserToMailChimp(
        user.email,
        new Date().toISOString(),
        subscription.Subscription,
        user.fullname,
        "",
        Street,
        City,
        user.PhoneNumber,
        saveState,
        saveCountry,
        user.PhoneNumber,
        accessToken
      )
      .then(async (res: any) => {
        res = await res.json();
      })
      .catch((error) => {
        //console.log(error);
      });
  };
};
// Zoho  Cancel Api call
export const cancelBtnZoho_V2 = (
  companyDetailsObj: any,
  subscription: any,
  user: any,
  accessToken: any
) => {
  var Street = companyDetailsObj.BillingAddress.street;
  Street = encodeURIComponent(Street);
  var City = companyDetailsObj.BillingAddress.city;
  City = encodeURIComponent(City);
  var saveState = companyDetailsObj.BillingAddress.State;
  saveState = encodeURIComponent(saveState);
  var saveCountry = companyDetailsObj.BillingAddress.Country;
  saveCountry = encodeURIComponent(saveCountry);
  return async (dispatch: Dispatch) => {
    contactservice
      .cancelUserToZoho(
        user.email,
        companyDetailsObj.Companyname,
        new Date().toISOString(),
        subscription.Subscription,
        user.fullname,
        "",
        Street,
        City,
        user.PhoneNumber,
        saveState,
        saveCountry,
        saveState,
        user.PhoneNumber,
        accessToken
      )
      .then(async (res: any) => {
        res = await res.json();
      })
      .catch((error) => {
        //console.log(error);
      });
  };
};
//Zoho sendmessage api call
export function sendNewMsgZoho(email: any) {
  return async (dispatch: Dispatch) => {
    email = encodeURIComponent(email);
    var PlanId =
      store.getState().reduxReducer.loggedInUser.Subscription.ServicePlanId;
    if (PlanId === 1 || PlanId === 7) {
      if (email !== "inbox") {
        contactservice
          .checkTrailUser(email)
          .then(async (res: any) => {
            res = await res.json();
            if (res === false) {
              contactservice
                .sendNewMsgZoho(email)
                .then(async (res: any) => {
                  res = await res.json();
                })
                .catch((error) => {
                  //console.log(error);
                });
            }
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    }
  };
}

// MailChimp Cancel Api call

export const cancelBtnMailChimp = (
  companyDetailsObj: any,
  subscription: any,
  user: any,
  accessToken: any
) => {
  var Street = companyDetailsObj.BillingAddress.street;
  Street = encodeURIComponent(Street);
  var City = companyDetailsObj.BillingAddress.city;
  City = encodeURIComponent(City);
  var saveState = companyDetailsObj.BillingAddress.State;
  saveState = encodeURIComponent(saveState);
  var saveCountry = companyDetailsObj.BillingAddress.Country;
  saveCountry = encodeURIComponent(saveCountry);
  return async (dispatch: Dispatch) => {
    contactservice
      .cancelUserToMailChimp(
        user.email,
        new Date().toISOString(),
        subscription.Subscription,
        user.fullname,
        "",
        Street,
        City,
        user.PhoneNumber,
        saveState,
        saveCountry,
        user.PhoneNumber,
        accessToken
      )
      .then(async (res: any) => {
        res = await res.json();
      })
      .catch((error) => {
        //console.log(error);
      });
  };
};
// Zoho  Cancel Api call
export const cancelBtnZoho = (
  companyDetailsObj: any,
  subscription: any,
  user: any,
  accessToken: any
) => {
  var Street = companyDetailsObj.BillingAddress.street;
  Street = encodeURIComponent(Street);
  var City = companyDetailsObj.BillingAddress.city;
  City = encodeURIComponent(City);
  var saveState = companyDetailsObj.BillingAddress.State;
  saveState = encodeURIComponent(saveState);
  var saveCountry = companyDetailsObj.BillingAddress.Country;
  saveCountry = encodeURIComponent(saveCountry);
  return async (dispatch: Dispatch) => {
    contactservice
      .cancelUserToZoho(
        user.email,
        companyDetailsObj.Companyname,
        new Date().toISOString(),
        subscription.Subscription,
        user.fullname,
        "",
        Street,
        City,
        user.PhoneNumber,
        saveState,
        saveCountry,
        saveState,
        user.PhoneNumber,
        accessToken
      )
      .then(async (res: any) => {
        res = await res.json();
      })
      .catch((error) => {
        //console.log(error);
      });
  };
};

export const openRegistrationPage = (openPage: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.OPEN_REGISTRATION_PAGE, openPage });
  };
};

export const openTrialExpiredPage = (trailExpier: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.OPEN_TRIAL_PAGE, trailExpier });
  };
};
export const newBandwidthUser = (isUsersBandwidth: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.NEW_BANDWIDTH_USER, isUsersBandwidth });
  };
};

export const opensharedError = (sharedError: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.OPEN_SHARED_ERROR, sharedError });
  };
};
export const openTrialExpiredMessage = (traiMessage: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.openTrialExpiredMessage, traiMessage });
  };
};
export const openLoginConsent = (loginValue: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: index.ActionTypes.OPEN_LOGIN_CONSENT, loginValue });
  };
};
export const savedContactWithSharedInbox = (lastContact: any, count: any) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      var lstContact = [
        {
          ContactId: count,
          PhoneNumber: lastContact.PhoneNumber,
          UserEmail: lastContact.UserEmail,
          ForeName: lastContact.ForeName,
          SureName: lastContact.SureName,
          ContactEmail: lastContact.UserEmail,
          GroupEmail: lastContact.GroupEmail,
          saved: 0,
        },
      ];
      let companyVersion = store.getState().reduxReducer.companyVersion;
      if (companyVersion === 2) {
        contactservice
          .addSharedContactApi_V2(lstContact)
          .then(async (res: any) => {
            res = await res.json();
          })
          .catch((error) => {
            //console.log(error);
          });
      } else {
        contactservice
          .addSharedContactApi(lstContact)
          .then(async (res: any) => {
            res = await res.json();
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    }
  };
};
export const saveProfileMode = (userEmail: any, displayMode: any) => {
  let companyVersion = store.getState().reduxReducer.companyVersion;
  if (companyVersion === 2) {
    userEmail = store.getState().reduxReducer.loggedInUser.user.Email;
  } else {
    userEmail = store.getState().reduxReducer.loggedInUser.Email;
  }
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      contactservice
        .saveProfileMode(userEmail, displayMode)
        .then(async (res: any) => {
          res = await res.json();
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  };
};

export const accessTokenRegister = (accessTokenApi: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.ACCESS_TOKEN_REGISTER,
      accessTokenApi: accessTokenApi,
    });
  };
};
export const accessTokenExpireOn = (expireOn: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.Expire_On,
      expireOn: expireOn,
    });
  };
};
export const isPaused = (IsPaused: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.Is_Paused,
      isPaused: IsPaused,
    });
  };
};

export const retrySend = (RetrySend: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.Retry_Send,
      retrysend: RetrySend,
    });
  };
};
// export const webexToken = (webexToken: any) => {
//   return async (dispatch: Dispatch) => {
//     dispatch({
//       type: index.ActionTypes.webexToken,
//       webexToken: webexToken,
//     });
//   };
// };
// export const webexRToken = (webexRToken: any) => {
//   return async (dispatch: Dispatch) => {
//     dispatch({
//       type: index.ActionTypes.webexRToken,
//       webexRToken: webexRToken,
//     });
//   };
// };
// export const webexC = (webexC: any) => {
//   return async (dispatch: Dispatch) => {
//     dispatch({
//       type: index.ActionTypes.webexC,
//       webexC: webexC,
//     });
//   };
// };
export const deepLinkRoom = (deepLinkRoom: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.deepLinkRoom,
      deepLinkRoom: deepLinkRoom,
    });
  };
};
export const companyVersion = (companyVersion: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.companyVersion,
      companyVersion: companyVersion,
    });
  };
};
export const TranslationLanguages = (TranslationLanguages: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.TranslationLanguages,
      TranslationLanguages: TranslationLanguages,
    });
  };
};
export const UserTransLang = (UserTransLang: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.UserTransLang,
      UserTransLang: UserTransLang,
    });
  };
};
export const emptyChatInput = (isChatInput: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.ActionTypes.emptyChatInput,
      isChatInput: isChatInput,
    });
  };
};
export const getSharePointContactsPageIndex = (pageIndex: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let contactFolders: any =
        await contactservice.getsharepointContactsListItems("", pageIndex);
      dispatch<any>(getSharePointContacts(pageIndex));
    } catch (error) {
      //console.log(error);
    }
  };
};
export const getUserByEmail = (userEmail: any) => {
  return async (dispatch: Dispatch) => {
    //////debugger;
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice.getUserByEmail(userEmail).then(async (result: any) => {
          result = await result.json();
          if (result) {
            if (result.DisplayMode === "dark") {
              dispatch<any>(setCurrentTheme("dark"));
              dispatch<any>(setDisplayMode("dark"));
            } else if (result.DisplayMode === "calltower-dark") {
              dispatch<any>(setCurrentTheme("calltowerdark"));
            } else if (result.DisplayMode === "commschoicedark") {
              dispatch<any>(setCurrentTheme("commschoicedark"));
              dispatch<any>(setDisplayMode("commschoicedark"));
            } else if (result.DisplayMode === "light") {
              dispatch<any>(setCurrentTheme("light"));
              dispatch<any>(setDisplayMode("light"));
            }
            if (result.ContactTypeFlag === "contacts") {
              dispatch<any>(setContactSourceOutlook(true));
            } else if (result.ContactTypeFlag === "users") {
              dispatch<any>(setContactSourceActiveDirectory(true));
            } else if (result.ContactTypeFlag === "contacts&users") {
              dispatch<any>(setContactSourceActiveDirectory(true));
              dispatch<any>(setContactSourceOutlook(true));
            }
            if (result.isSharePoint === "isSharePoint") {
              dispatch<any>(setContactSourceSharePoint(true));
            }

            var fileNewDetail = result;
            var userEmail = store.getState().reduxReducer.loggedInUser.Email;
            var groupEmail =
              store.getState().reduxReducer?.sharedInboxData?.currentSelected
                .email;
            let companyVersion = store.getState().reduxReducer.companyVersion;
            //////debugger;
            if (companyVersion === 2) {
              let inboxId =
                store.getState().reduxReducer.loggedInUser.inboxes.$values[0]
                  .InboxId;
              dispatch<any>(readOnlyUser_V2(inboxId));
            } else {
              dispatch<any>(readOnlyUser(userEmail, groupEmail));
            }
          } else {
            //console.log("no res.accessTokenApi");
          }
        });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};

export const getPersonalInboxNew = (
  pageIndex: number,
  loggedInUserEmail: any,
  archivedFlag: any,
  groupEmail: any,
  calledFrom: any,
  data: any
) => {
  return async (dispatch: Dispatch) => {
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      // //console.log("groupEmail",groupEmail);
      dispatch<any>(getSasTokenBlob());
      try {
        contactservice
          .GetPersonalInbox(
            pageIndex,
            loggedInUserEmail,
            archivedFlag,
            groupEmail
          )
          .then(async (resp: any) => {
            resp = await resp.json();

            resp.Rooms?.$values?.map((x: any) => {
              x.Participants?.$values?.map((y: any) => {
                if (y.Name === y.SfBPhoneNumber.PhoneNumber) {
                  if (y !== undefined) {
                    if (
                      calledFrom !== "SideBarComponent" &&
                      calledFrom !== "updateRooms" &&
                      calledFrom !== "fromselectedEmails"
                    ) {
                      // contactservice
                      //   .GetContactsByUserEmailPhoneNumber(
                      //     store.getState().reduxReducer.loggedInUser.Email,
                      //     y.SfBPhoneNumber.PhoneNumber
                      //   )
                      //   .then(async (res: any) => {
                      //     res = await res.json();
                      //     if (res.ContactId !== 0) {
                      //       if (y.Name === y.SfBPhoneNumber.PhoneNumber) {
                      //         var obj = {
                      //           firstName: res.ForeName,
                      //           lastName:
                      //             res.SureName !== undefined&&res.SureName!=null ? res.SureName : "",
                      //           phoneNumber: res.PhoneNumber,
                      //           groupEmail:
                      //             res.GroupEmail !== undefined
                      //               ? res.GroupEmail
                      //               : store.getState().reduxReducer
                      //                   .sharedInboxData.currentSelected.email,
                      //         };
                      //         contactservice
                      //           .UpdateRoomParticipantTeams(obj, y.Id)
                      //           .then(async (res: any) => {
                      //             res = await res.status;
                      //             if (res) {
                      //               var selectedEmail =
                      //                 store.getState().reduxReducer
                      //                   .sharedInboxData.currentSelected.email;
                      //               var loggedInEmail =
                      //                 store.getState().reduxReducer.loggedInUser
                      //                   .Email;
                      //               if (selectedEmail === loggedInEmail) {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     false,
                      //                     selectedEmail,
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               } else if (selectedEmail === "Closed") {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     true,
                      //                     "''",
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               } else {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     false,
                      //                     selectedEmail,
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               }
                      //             }
                      //           });
                      //       }
                      //     }
                      //   });
                    }
                  }
                }
                setTimeout(() => {
                  var newroomDataValues = resp.Rooms.$values;
                }, 600);
                var newroomDataValues = resp.Rooms.$values;
                //console.log("newroomDataValues", newroomDataValues);

                let shareMsgCount: any = [];
                let res: any =
                  resp.Rooms !== undefined ? resp.Rooms.$values : [];
                dispatch<any>(currentPageIndexForInbox(resp.CurrentPageIndex));
                dispatch<any>(nextPageIndexForInbox(resp.PageCount));
                if (resp.NewMessagesCount > 0) {
                  dispatch({
                    CircleIndicator: resp.NewMessagesCount,
                    type: index.ActionTypes.OwnerCircleIndicator,
                  });
                }
                dispatch({
                  unreadMessagecount:
                    resp.NewMessagesCount +
                    store.getState().reduxReducer.NewGroupUnreadMessage,
                  type: index.ActionTypes.NewUnreadMessageCount,
                });

                //This part is for not displaying conversation counter
                var openRoomMessageId =
                  store.getState().reduxReducer.openMessageRoom;
                var checkRoomExists =
                  data === openRoomMessageId?.Id ? true : false;
                var selectedRoomData = res?.find(
                  (x: any) => x.Id === openRoomMessageId?.Id
                );

                var selectedEmail =
                  store.getState().reduxReducer.sharedInboxData?.currentSelected
                    ?.email;
                var sharedGroupData =
                  resp?.sharedRoomMsgCount !== undefined
                    ? resp?.sharedRoomMsgCount
                    : resp?.sharedRoomMsgCount.$values.find(
                        (x: any) => x?.sharedEmail === selectedEmail
                      );
                if (
                  checkRoomExists &&
                  (sharedGroupData || loggedInUserEmail === selectedEmail)
                ) {
                  var lastMessageText =
                    openRoomMessageId?.Messages?.$values.filter(
                      (x: any) => x.Text === openRoomMessageId.LastMsgText
                    );
                  lastMessageText.sort((x: any, y: any) => {
                    if (!x || !y) return 0;
                    if (new Date(x.TimeStamp) > new Date(y.TimeStamp))
                      return -1;
                    else return 1;
                  });
                  selectedRoomData.UnreadMsgCount =
                    selectedRoomData.UnreadMsgCount - 1;
                  selectedRoomData.avatarColor =
                    avatarColorMap[openRoomMessageId?.Id % 10];
                  if (sharedGroupData !== undefined) {
                    sharedGroupData.msgCount = sharedGroupData.msgCount - 1;
                    shareMsgCount =
                      resp.sharedRoomMsgCount !== undefined
                        ? resp.sharedRoomMsgCount
                        : [];
                  } else {
                    dispatch({
                      CircleIndicator: resp.NewMessagesCount - 1,
                      type: index.ActionTypes.OwnerCircleIndicator,
                    });
                  }
                  var userEmail =
                    groupEmail !== "''" ? groupEmail : loggedInUserEmail;
                  //  //console.log("x-message",x);
                  if (
                    calledFrom !== "QueueMessage" &&
                    calledFrom !== "CreateRoom"
                  ) {
                    dispatch<any>(
                      unreadCounterUpdate(
                        openRoomMessageId?.Id,
                        lastMessageText[0]?.Id,
                        userEmail,
                        0
                      )
                    );
                  }
                } else {
                  shareMsgCount =
                    resp.sharedRoomMsgCount !== undefined
                      ? resp.sharedRoomMsgCount
                      : [];
                }
                for (let room of res) {
                  room.avatarColor = avatarColorMap[room.Id % 10];
                }
                if (pageIndex === 1) {
                  dispatch({ roomData: res, type: index.ActionTypes.setRooms });
                  dispatch({
                    sharedInboxMsgCount: shareMsgCount,
                    type: index.ActionTypes.SHARED_INBOX_MESSAGE_COUNT,
                  });
                  if (data) {
                    var currentRoom =
                      resp.Rooms !== undefined
                        ? resp.Rooms?.$values?.length !== 0
                          ? resp.Rooms.$values.filter(
                              (x: any) => x.Id === data
                            )[0]
                          : []
                        : [];
                    if (currentRoom !== undefined || null) {
                      var roomId =
                        Object.keys(currentRoom)?.length !== 0
                          ? currentRoom.Id
                          : 0;
                      if (roomId !== 0) {
                        var userEmail =
                          currentRoom.Archived === true
                            ? currentRoom.CreatorEmail
                            : groupEmail !== "''"
                            ? groupEmail
                            : loggedInUserEmail;
                        if (calledFrom !== "SignalR") {
                          dispatch<any>(messageFromSignal(false));
                        }
                      } else {
                        document.getElementById("msg_tab")?.click();
                        //dispatch<any>(setOpenMessageRoom(currentRoom));
                        dispatch<any>(setTabOpened("chatpanel"));
                      }
                    }
                  } else {
                    var currentRoom =
                      resp.Rooms !== undefined
                        ? resp.Rooms?.$values?.length !== 0
                          ? resp.Rooms.$values[0]
                          : []
                        : [];
                    var roomId =
                      Object.keys(currentRoom)?.length !== 0
                        ? currentRoom.Id
                        : 0;
                  }
                }
                dispatch<any>(updateRoomsFromWebSocket(y, "SignalR"));
                document.getElementById("msg_tab")?.click();
                //dispatch<any>(setOpenMessageRoom(currentRoom));
                dispatch<any>(setTabOpened("chatpanel"));
              });
            });
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const getPersonalInboxNew_V2 = (
  pageIndex: number,
  loggedInUserEmail: any,
  archivedFlag: any,
  groupEmail: any,
  calledFrom: any,
  data: any
) => {
  ////////debugger;
  return async (dispatch: Dispatch) => {
    //////debugger;
    // //console.log("groupEmail",groupEmail);
    dispatch<any>(getSasTokenBlob_V2());
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        contactservice
          .GetPersonalInbox_V2(pageIndex, loggedInUserEmail, archivedFlag)
          .then(async (resp: any) => {
            resp = await resp.json();

            resp.Rooms?.$values?.map((x: any) => {
              x.Participants?.$values?.map((y: any) => {
                if (y.Name === y.SfBPhoneNumber.PhoneNumber) {
                  if (y !== undefined) {
                    if (
                      calledFrom !== "SideBarComponent" &&
                      calledFrom !== "updateRooms" &&
                      calledFrom !== "fromselectedEmails"
                    ) {
                      // contactservice
                      //   .GetContactsByUserEmailPhoneNumber(
                      //     y.SfBPhoneNumber.PhoneNumber
                      //   )
                      //   .then(async (res: any) => {
                      //     res = await res.json();
                      //     if (res.ContactId !== 0) {
                      //       if (y.Name === y.SfBPhoneNumber.PhoneNumber) {
                      //         var obj = {
                      //           firstName: res.ForeName,
                      //           lastName:
                      //             res.SureName !== undefined&&res.SureName!=null ? res.SureName : "",
                      //           phoneNumber: res.PhoneNumber,
                      //           groupEmail:
                      //             res.GroupEmail !== undefined
                      //               ? res.GroupEmail
                      //               : store.getState().reduxReducer
                      //                   .sharedInboxData.currentSelected.email,
                      //         };
                      //         contactservice
                      //           .UpdateRoomParticipantTeams(obj, y.Id)
                      //           .then(async (res: any) => {
                      //             res = await res.status;
                      //             if (res) {
                      //               var selectedEmail =
                      //                 store.getState().reduxReducer
                      //                   .sharedInboxData.currentSelected.email;
                      //               var loggedInEmail =
                      //                 store.getState().reduxReducer.loggedInUser.user
                      //                   .Email;
                      //               if (selectedEmail === loggedInEmail) {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     false,
                      //                     selectedEmail,
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               } else if (selectedEmail === "Closed") {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     true,
                      //                     "''",
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               } else {
                      //                 dispatch<any>(
                      //                   getPersonalInbox(
                      //                     1,
                      //                     loggedInEmail,
                      //                     false,
                      //                     selectedEmail,
                      //                     "updateRooms",
                      //                     ""
                      //                   )
                      //                 );
                      //               }
                      //             }
                      //           });
                      //       }
                      //     }
                      //   });
                    }
                  }
                }
                setTimeout(() => {
                  var newroomDataValues = resp.Rooms.$values;
                }, 600);
                var newroomDataValues = resp.Rooms.$values;
                //console.log("newroomDataValues", newroomDataValues);

                let shareMsgCount: any = [];
                let res: any =
                  resp.Rooms !== undefined ? resp.Rooms.$values : [];
                dispatch<any>(currentPageIndexForInbox(resp.CurrentPageIndex));
                dispatch<any>(nextPageIndexForInbox(resp.PageCount));
                if (resp.NewMessagesCount > 0) {
                  dispatch({
                    CircleIndicator: resp.NewMessagesCount,
                    type: index.ActionTypes.OwnerCircleIndicator,
                  });
                }
                dispatch({
                  unreadMessagecount:
                    resp.NewMessagesCount +
                    store.getState().reduxReducer.NewGroupUnreadMessage,
                  type: index.ActionTypes.NewUnreadMessageCount,
                });

                //This part is for not displaying conversation counter
                var openRoomMessageId =
                  store.getState().reduxReducer.openMessageRoom;
                var checkRoomExists =
                  data === openRoomMessageId?.Id ? true : false;
                var selectedRoomData = res?.find(
                  (x: any) => x.Id === openRoomMessageId?.Id
                );
//debugger;
                var selectedEmail =
                  store.getState().reduxReducer.sharedInboxData?.currentSelected
                    ?.email;
                var sharedGroupData =
                  resp?.sharedRoomMsgCount !== undefined
                    ? resp?.sharedRoomMsgCount
                    : resp?.sharedRoomMsgCount.$values.find(
                        (x: any) => x?.sharedEmail === selectedEmail
                      );
                if (
                  checkRoomExists &&
                  (sharedGroupData || loggedInUserEmail === selectedEmail)
                ) {
                  var lastMessageText =
                    openRoomMessageId?.Messages?.$values.filter(
                      (x: any) => x.Text === openRoomMessageId.LastMsgText
                    );
                  lastMessageText.sort((x: any, y: any) => {
                    if (!x || !y) return 0;
                    if (new Date(x.TimeStamp) > new Date(y.TimeStamp))
                      return -1;
                    else return 1;
                  });
                  selectedRoomData.UnreadMsgCount =
                    selectedRoomData.UnreadMsgCount - 1;
                  selectedRoomData.avatarColor =
                    avatarColorMap[openRoomMessageId?.Id % 10];
                  if (sharedGroupData !== undefined) {
                    sharedGroupData.msgCount = sharedGroupData.msgCount - 1;
                    shareMsgCount =
                      resp.sharedRoomMsgCount !== undefined
                        ? resp.sharedRoomMsgCount
                        : [];
                  } else {
                    dispatch({
                      CircleIndicator: resp.NewMessagesCount - 1,
                      type: index.ActionTypes.OwnerCircleIndicator,
                    });
                  }
                  var userEmail =
                    groupEmail !== "''" ? groupEmail : loggedInUserEmail;
                  //  //console.log("x-message",x);
                  if (
                    calledFrom !== "QueueMessage" &&
                    calledFrom !== "CreateRoom"
                  ) {
                    dispatch<any>(
                      unreadCounterUpdate_V2(
                        openRoomMessageId?.Id,
                        lastMessageText[0]?.Id,
                        0
                      )
                    );
                  }
                } else {
                  shareMsgCount =
                    resp.sharedRoomMsgCount !== undefined
                      ? resp.sharedRoomMsgCount
                      : [];
                }
                for (let room of res) {
                  room.avatarColor = avatarColorMap[room.Id % 10];
                }
                if (pageIndex === 1) {
                  dispatch({ roomData: res, type: index.ActionTypes.setRooms });
                  dispatch({
                    sharedInboxMsgCount: shareMsgCount,
                    type: index.ActionTypes.SHARED_INBOX_MESSAGE_COUNT,
                  });
                  if (data) {
                    var currentRoom =
                      resp.Rooms !== undefined
                        ? resp.Rooms?.$values?.length !== 0
                          ? resp.Rooms.$values.filter(
                              (x: any) => x.Id === data
                            )[0]
                          : []
                        : [];
                    if (currentRoom !== undefined || null) {
                      var roomId =
                        Object.keys(currentRoom)?.length !== 0
                          ? currentRoom.Id
                          : 0;
                      if (roomId !== 0) {
                        var userEmail =
                          currentRoom.Archived === true
                            ? currentRoom.CreatorEmail
                            : groupEmail !== "''"
                            ? groupEmail
                            : loggedInUserEmail;
                        if (calledFrom !== "SignalR") {
                          dispatch<any>(messageFromSignal(false));
                        }
                      } else {
                        document.getElementById("msg_tab")?.click();
                        // dispatch<any>(setOpenMessageRoom(currentRoom));
                        dispatch<any>(setTabOpened("chatpanel"));
                      }
                    }
                  } else {
                    var currentRoom =
                      resp.Rooms !== undefined
                        ? resp.Rooms?.$values?.length !== 0
                          ? resp.Rooms.$values[0]
                          : []
                        : [];
                    var roomId =
                      Object.keys(currentRoom)?.length !== 0
                        ? currentRoom.Id
                        : 0;
                  }
                }
                dispatch<any>(updateRoomsFromWebSocket(y, "SignalR"));
                document.getElementById("msg_tab")?.click();
                //dispatch<any>(setOpenMessageRoom(currentRoom));
                dispatch<any>(setTabOpened("chatpanel"));
              });
            });
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const showGroupContacts = (ContactIds: any) => {
  return async (dispatch: Dispatch) => {
    var Contactdata;
    var ExpirOnDate =
      store.getState().reduxReducer.expireOn == undefined
        ? store.getState().reduxReducer.loggedInUser.expiresOn
        : store.getState().reduxReducer.expireOn;
    var CurrentDate = new Date().toISOString();
    if (CurrentDate >= ExpirOnDate) {
      store.dispatch<any>({ type: "Is_Paused", isPaused: true });
      await AuthService.RefreshTokens();
    }

    if (store.getState().reduxReducer.isPaused === true) {
      setTimeout(function () {
        waitForIt();
      }, 100);
    } else {
      try {
        ContactIds.map((x: any) => {
          if (x) {
            contactservice.getContactsByListId(x).then(async (res: any) => {
              res = await res.json();
              // //console.log("result",res);
              if (res !== undefined) dispatch<any>(setContact(res));
              // dispatch<any>({ type: "setGroupCurrentlyEditing", group });
            });
          }
          // Contactdata.push(result);
        });
      } catch (error) {
        //console.log(error);
      }
    }
  };
};
export const setContact = (yakChatGroupContacts: any) => {
  let newyakChatGroupContacts: any = [];
  yakChatGroupContacts = filterContactListWithRef(yakChatGroupContacts);
  yakChatGroupContacts.then((x: any) => {
    // newyakChatGroupContacts.push(...x,x);
    // //console.log(x);
  });
  // //console.log("displayMode",newyakChatGroupContacts);
  return async (dispatch: Dispatch) => {
    dispatch({
      yakChatGroupContacts: newyakChatGroupContacts,
      type: index.ActionTypes.SHOW_GROUP_CONTACTS,
    });
  };
};
