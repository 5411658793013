import * as React from "react";
import ContactComponent from "../sharedComponent/contactsComponent";
export default class UserListComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      calledfrom: "",
      GetUsersList: [],
      userselected: this.props.UserSelected,
    };
  }
  componentDidMount() {}
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.UsersList !== prevState.GetUsersList ||
      nextProps.calledFrom !== prevState.calledfrom ||
      nextProps.UserSelected !== prevState.userselected
    ) {
      return {
        GetUsersList: nextProps.UsersList,
        calledfrom: nextProps.calledFrom,
        userselected: nextProps.UserSelected,
      };
    } else {
      return null;
    }
  }

  public render() {
    return (
      <React.Fragment>
        {
          <ContactComponent
            searchFilter={this.props.searchFilter}
            tabOpened={this.props.tabOpened}
            UsersList={this.state.GetUsersList}
            calledFrom={this.state.calledfrom}
            Status={this.state.userselected}
            azureGroupData={this.props.azureGroupData}
          />
        }
      </React.Fragment>
    );
  }
}
