import React, { Fragment, ReactEventHandler, useState } from "react";
import "./chatPanel.css";
import Cowtail from "./Cowtail";
import { sanitize } from 'dompurify';
import Status from "./Status";
// import Lightbox from 'react-image-lightbox';
// import { Gallery } from "react-grid-gallery";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { saveAs } from 'file-saver';
import { promises } from "dns";
import {isMobile} from 'react-device-detect';
const Bubble = (props: any) => {
  let message = props.message;
  let token = props.token;
  let currentThem = props.currentThem;
  let displayMode = props.displayMode;
  const [index, setIndex] = useState(-1);

 

  const messageText = () => {

    const parsedMessage = message.Text!=null?message.Text.replace(/\r\n/g, '<br />').replace(/[\r\n]/g, '<br />'):"";
    return { __html: sanitize(parsedMessage) };
  }

  const downloadImage = (imagesrc:any,xx:any) => {
    debugger;
   
    let newVariable: any;
    newVariable = window.navigator;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", `${imagesrc}`);
    xhr.responseType = 'blob';
    xhr.send();
    xhr.onload = function (data: any) {
      if (data) {
        // Handle response
        if (window.navigator && newVariable.msSaveOrOpenBlob) {
          //IE11 and the legacy version Edge support
          var blob = data.currentTarget.response;
          newVariable.msSaveOrOpenBlob(blob, 'image.jpg');
        } else {
          const url = window.URL.createObjectURL(new Blob([data.currentTarget.response]));
          // Creating anchor tag for download
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'image.jpg');
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }
      }
    };
  
  }
//   function  urlToPromise(){
//     var zip = new JSZip();
//     let urls=getImage();
//    let index=1;
// var count = 1;
// var zipFilename = "zipFilename.zip";
// urls.forEach((url:string)=>{
// // Fetch the image and par
//     return new Promise(function(resolve, reject) {
//         JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
//             if(err) {
//                 reject(err);
//             } else {
//               zip.file("image"+count+".jpg", data, { binary: true });
//               count++;                    
                                     
//                   zip.generateAsync({ type: "blob" }).then(function (base64) {                            
//                   //    window.location.replace("data:application/zip;base64," + base64);

//                       saveAs(base64, `archive.zip`);

//                       console.log("inner");
//                   });
//             }
//         });
//     });
//     });
//   }
//   const downloadAllImages = (xx:any) => {
//     debugger;
   
// urlToPromise();
//   }
  const getImage = () => {
    let images:any=[];
      message.Img &&
      message.MessageMedia.$values.map((image: any) => {
        images.push({src: image.FileURL+token,
        original: image.FileURL+token,
        style:{padding:"10px"}}
        );
       
        // return (
        //   <Fragment key={image.Id}>
        //     <img
        //       src={
        //         `${image.FileURL}${token}` 
        //       }
        //       alt="chat Visuals"
        //       className={
        //         !isImageClicked ? "img_bubble orientation" : "hide orientation"}
        //       onClick={() => {
        //         setIsImageClicked(!isImageClicked);
        //         setImageUrl(image.FileURL)
        //       }}
        //       onLoad={(event: any) => {
        //         if (
        //           event.currentTarget.naturalWidth >
        //           event.currentTarget.naturalHeight
        //         ) {
        //           setOrientation("landscape");
        //         } else {
        //           setOrientation("portrait");
        //         }
        //       }}
        //     />
        //     <div
        //       className={isImageClicked ? "none" : "hide"}
        //     >
        //       {
        //         isImageClicked ?
        //           <>
        //             <div className="copyDownloadBtns">
        //               <Lightbox mainSrc={`${image.FileURL}${token}` || ''} onCloseRequest={() => setIsImageClicked(!isImageClicked)} />
        //               <a onClick={downloadImage} className="copyBtn">Download</a>
        //             </div>
        //           </> : ""
        //       }
        //       <img
        //         src={token !== undefined
        //           ? `${image.FileURL}${token}`
        //           : image.FileURL
        //         }
        //         alt="chat Visuals"
        //         className=""
        //       />
        //     </div>
            
     
        //   </Fragment>
          
        // );
      })
      // images.push({
      //   src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
      //   original: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg"})
      //   images.push({  src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
      //   original: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg"})
      //   images.push({
      //     src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
      //     original: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg"})
      //     images.push({  src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
      //     original: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg"})
    return images;
  };
  const currentImage = getImage()[index];
  const nextIndex = (index + 1) % getImage().length;
  const nextImage = getImage()[nextIndex] || currentImage;
  const prevIndex = (index + getImage().length - 1) % getImage().length;
  const prevImage = getImage()[prevIndex] || currentImage;

  const handleClick = (index: number) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  const [isImageClicked, setIsImageClicked] = useState<null | boolean>(null);
  const [orientation, setOrientation] = useState("portrait");
  const [imageUrl, setImageUrl] = useState();
  const styleSmall=()=>{
    return ({
      borderRadius: "10px",
      cursor: "pointer",
      marginLeft: '0px',
      marginTop: '0px',
      padding:'3px'
    
    });
 }
 if(isMobile) {
  return (
    <div
      className={`bubble ${message.Direction === 10
        ? currentThem === "setup" && displayMode === "dark" ? "theme_yakchat_going_setupDark" 
        : currentThem === "mobex" ? "theme_yakchat_going_setupMobex" 
        : currentThem === "berkshire" ? "theme_yakchat_going_setupBerkshire" 
        : currentThem === "teligentip" ? "theme_yakchat_going_setupTeligentip" 
        : currentThem === "oneuc" ? "theme_yakchat_going_setupOneuc"  : "theme_yakchat_going"
        : "theme_yakchat_coming"
        }`}
    >
    
    <Gallery
        images={getImage()}
        onClick={handleClick}
        enableImageSelection={false}
        thumbnailStyle={styleSmall()}
        tileViewportStyle={{borderRadius: "10px"}}
      />
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox toolbarButtons={[
         
        // <i aria-label="downloadAllImages" onClick={dd=>downloadAllImages(dd)} className="fa fa-file-archive ril__builtinButton " aria-hidden="true"  ></i>,
        
        <a href={currentImage.original}  target="_blank"><i aria-label="downloadImage"  className="fa fa-download  ril__builtinButton " aria-hidden="true"   ></i></a>

                  
      ]}
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
          discourageDownloads={false}
          ></Lightbox>
       
      )}
      <div className={"bubble_text"} dangerouslySetInnerHTML={messageText()} />
      <Cowtail direction={message.Direction} />
      <Status
        status={message.ServiceMessages?.$values[0]?.Status}
        errorMessage={message.ServiceMessages?.$values[0]?.ErrorMessage}
        timeStamp={message.TimeStamp}
        textMsg={message.Text}
        direction={message.Direction}
      />
    </div>
    );
 }
 else{
  return (
    <div
      className={`bubble ${message.Direction === 10
        ? currentThem === "setup" && displayMode === "dark" ? "theme_yakchat_going_setupDark" 
        : currentThem === "mobex" ? "theme_yakchat_going_setupMobex" 
        : currentThem === "berkshire" ? "theme_yakchat_going_setupBerkshire" 
        : currentThem === "teligentip" ? "theme_yakchat_going_setupTeligentip" 
        : currentThem === "oneuc" ? "theme_yakchat_going_setupOneuc"  : "theme_yakchat_going"
        : "theme_yakchat_coming"
        }`}
    >
    
    <Gallery
        images={getImage()}
        onClick={handleClick}
        enableImageSelection={false}
        thumbnailStyle={styleSmall()}
        tileViewportStyle={{borderRadius: "10px"}}
      />
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox toolbarButtons={[
         
        // <i aria-label="downloadAllImages" onClick={dd=>downloadAllImages(dd)} className="fa fa-file-archive ril__builtinButton " aria-hidden="true"  ></i>,
         <a onClick={x=>downloadImage(currentImage.original,x)} className="copyBtn"><i aria-label="downloadImage"  className="fa fa-download  ril__builtinButton " aria-hidden="true"   ></i></a>
                  
      ]}
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
          discourageDownloads={false}
          ></Lightbox>
       
      )}
      <div className={"bubble_text"} dangerouslySetInnerHTML={messageText()} />
      <Cowtail direction={message.Direction} />
      <Status
        status={message.ServiceMessages?.$values[0]?.Status}
        errorMessage={message.ServiceMessages?.$values[0]?.ErrorMessage}
        timeStamp={message.TimeStamp}
        textMsg={message.Text}
        direction={message.Direction}
      />
    </div>
    );
 }
};

export default Bubble;
