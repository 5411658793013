import React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import WithContext, { WithContext as ReactTags } from "react-tag-input";
import _ from "lodash";
import * as action from "../../../redux/actions/actions";
import * as commonMethod from "../../../commonMethods";
import { Drawer } from "@material-ui/core";
import { ReactComponent as SendIcon } from "../../../images/send_blk.svg";
import { ReactComponent as MinusOutlineSvg } from "../../../images/minus-icon-ouline-orange.svg";
import { ReactComponent as EditImage } from "../../../images/edit.svg";
import { ReactComponent as DeleteImage } from "../../../images/trash2.svg";
import { ReactComponent as DotsIcon } from "../../../images/wht_dots.svg";
import { ReactComponent as BackIcon } from "../../../images/left_arrow.svg";
import { Select } from "antd";
import { setTimeout } from "timers";
import yakchatGroups from "../../sharedComponent/yakchatGroups";
import { setNewCreatedGroup } from "../../../redux/actions/actions";
import { store } from "../../../..";
import { Console } from "console";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
class EditGroup extends React.Component<any, any> {
  reactTags: React.RefObject<WithContext>;
  constructor(props: any) {
    super(props);
    this.reactTags = React.createRef();
  }
  state = {
    id: "",
    previousContacts: [],
    dialogBox: "",
    sharedWithTags: [],
    temporarySharedWith: "",
    ownerTags: [],
    sharedWith: "",
    name: "",
    owners: "",
    temporaryOwner: "",
    activebutton: false,
    SendMessageEmail: "",
    listName: "",
    yakChatListId: "",
    groupEmail: "",
    IsEmptyEmail: false,
    savedEmailDatabase: "",
  };
  

  static getDerivedStateFromProps(nextProps: any, prevState: any) {


    if (nextProps.editing !== prevState.editing ||
      nextProps.$id !== prevState.id ||
      nextProps.groupData !== prevState.groupDataState ||
      nextProps.storeActualContactsInList !== prevState.previousContacts ||
      nextProps.yakchatLists !== prevState.yakChatListId
    ) {
      return {   
        
        groupData: nextProps.groupData,
        yakChatListId: nextProps.yakchatLists,
        listName: nextProps.groupData?.ListData !== undefined ? nextProps.groupData?.ListData.ListName : nextProps.groupData?.ListName?? nextProps.groupData?.displayName, 
        previousContacts: nextProps.storeActualContactsInLists,
        //groupEmail: nextProps.groupData?.ListData?.GroupEmail !== undefined ? nextProps.groupData?.ListData?.GroupEmail : nextProps.groupData?.GroupEmail !== undefined ? nextProps.groupData?.GroupEmail : "" ,
        groupEmail: nextProps.groupData?.ListData?.GroupEmail !== undefined ? nextProps.groupData?.ListData?.GroupEmail : nextProps.groupData?.GroupEmail !== undefined ? nextProps.groupData?.GroupEmail : nextProps.groupData?.mail !== undefined ? nextProps.groupData?.mail : "",
    
      }
    } else return null;
    
  }
  handleChangetxt=(event:any)=> {
     this.setState({name: event.target.value});
  this.setState({listName: event.target.value});
  if( this.props.groupData.ListData)
  {  this.props.groupData.ListData.ListName  = event.target.value;
    this.props.setGroupCurrentlyEditing(this.props.groupData);}
  else{
    this.props.groupData.ListName = event.target.value;
    this.props.setGroupCurrentlyEditing(this.props.groupData);
  }}
  
  handleChange = (selectedItems: any) => {
    debugger;
    let companyVersion=store.getState().reduxReducer.companyVersion;
    if (selectedItems[0] !== undefined) {
      this.setState({ IsEmptyEmail: false });
      if( this.props.groupData.ListData)
      {  this.props.groupData.ListData.GroupEmail  = selectedItems[0];}
      else{
        this.props.groupData.GroupEmail = selectedItems[0];
      }
      this.props.setGroupCurrentlyEditing(this.props.groupData);
      
      this.setState({ sharedWith: selectedItems[0], IsEmptyEmail: false });
      if (this.props.yakChatGroupData && this.props.yakChatGroupData !== undefined) {
      
      if(companyVersion===2)
        {
          debugger;
          var savedEmail = this.props.yakChatGroupData.find((c: any) => { if (c.InboxId === selectedItems[0]) { return (c) } })
          this.setState({ savedEmailDatabase: savedEmail!=undefined?savedEmail.Name:"" })
        }
        else{
        var savedEmail = this.props.yakChatGroupData.find((c: any) => { if (c.UserName === selectedItems[0]) { return (c) } })
        this.setState({ savedEmailDatabase: savedEmail.Email })
        }
      }
    } else {
      if(companyVersion==2)
      {
        debugger;
    
        let inboxId=store
        .getState()
        .reduxReducer.loggedInUser.inboxes.$values.filter(
          (item: any) => item.InboxType ==0)!=undefined&&store
          .getState()
          .reduxReducer.loggedInUser.inboxes.$values.filter(
            (item: any) => item.InboxType ==0).length>0?store
          .getState()
          .reduxReducer.loggedInUser.inboxes.$values.filter(
            (item: any) => item.InboxType == 0)[0].InboxId:0;
            this.setState({ sharedWith: inboxId });
            this.setState({ IsEmptyEmail: true });
            this.props.setGroupCurrentlyEditing(this.props.groupData);
        }
        else{
      this.setState({ sharedWith: "" });
      this.setState({ IsEmptyEmail: true });
      this.props.setGroupCurrentlyEditing(this.props.groupData);}
    }
  }
  SendMessagetogroupContact = (email: any) => {
    var SharedInboxemail = localStorage.getItem("SharedInboxemail");
    let list: any = [];
    this.props.selectedContactsNewMessage.forEach((element: any) => {
      list.push(element)
    });
    if (this.props.sharedinboxData !== undefined && this.props.sharedinboxData?.other?.length > 0) {
      var Getselectedinbox = this.props.sharedinboxData?.other?.filter((x: any) => x.email === email);
      if (Getselectedinbox?.length > 0) {
        if (this.props.sharedinboxData.currentSelected.email !== email) {
          this.props.CurrentSelectInbox(Getselectedinbox[0])
        }
        this.props.sendmessage(list, "", Getselectedinbox[0].email);
      } else {
        if (this.props.sharedinboxData.currentSelected.email !== email) {
          this.props.CurrentSelectInbox(this.props.sharedinboxData.currentSelected)
        }
        this.props.sendmessage(list, "", this.props.sharedinboxData.currentSelected.email);
        
      }
    }
  };
  componentDidMount() { 
    let SendMessageEmail = this.props.sharedinboxData.currentSelected.email;
    this.setState({
      sharedWith:
        this.props.yakChatGroupData &&
          this.props.yakChatGroupData.find(
            (x: any) => x.$id === this.props.groupData?.ListData?.$id ) ? this.props.yakChatGroupData.find(
            (x: any) => x.$id === this.props.groupData?.ListData?.$id ).UserName : this.props.groupData?.ListData?.$id,
      editing: this.props.editing,
      name: this.props.groupData?.ListName ?? this.props.groupData?.displayName,
      owners: this.props.groupData?.ListAdmins,
      id: this.props.groupData?.$id ?? this.props.groupData?.id,
      SendMessageEmail,
    });  }
  // shouldComponentUpdate(nextProps: any) { // error here
  //   console.log(nextProps, 'nextProps')
  //   return true
  // }
  SendMessageTogroup = () => {
    if (this.props.sharedinboxData.length >1){
      this.setState({ dialogBox: "SendMessage"});
    }else{
      this.SendMessagetogroupContact(this.state.SendMessageEmail);
      this.setState({
        dialogBox: "",
      });
    }
  
  };
  removeFromSelected_newMessage(c: any): void {
    const newSelectedContacts: [any] = _.cloneDeep(
      this.props.selectedContactsNewMessage
    );
    var filterArray = newSelectedContacts.filter((x: any) => x.ContactId !== c.ContactId);
    this.props.setSelectedContacts(filterArray);
  }
  render() {
    debugger;
   
    let companyVersion=store.getState().reduxReducer.companyVersion;
  let shre=  companyVersion!==2?
    (this.state.IsEmptyEmail === true ? "" :
     this.props.yakChatGroupData &&
      this.props.yakChatGroupData.find((x: any) => x.Email === this.state.groupEmail) ?
       this.props.yakChatGroupData.find((x: any) => x.Email === this.state.groupEmail).UserName 
       : this.state.groupEmail || ""):
       (this.props.yakChatGroupData && 
        this.props.yakChatGroupData.find((x: any) => x.InboxId === Number(this.state.groupEmail)) ?
         this.props.yakChatGroupData.find((x: any) => x.InboxId === Number(this.state.groupEmail)).Name 
    : this.state.groupEmail || "")
    let groupEmailTagSuggestions;
      if(companyVersion===2)
        {
          groupEmailTagSuggestions =
      this.props.yakChatGroupData &&
      this.props.yakChatGroupData.map((i: any) => { return {label: i?.Name, value: i?.InboxId }; });
        }
        else{
     groupEmailTagSuggestions =
      this.props.yakChatGroupData &&
      this.props.yakChatGroupData.map((i: any) => { return { label: i.UserName, value: i.UserName }; });
        }
    let name, sharedWith, owners;
    name = this.state.name;
    sharedWith = this.state.sharedWith;
    owners = this.state.owners;
    return (
      <div className="right_Sec" id="right_Sec_main">
        <div className="top_row">
          <div className="top_left">
            <div className="usr_imgcon">
              {
                // Will only display when in mobile view
              }
              <a
                href="javascript:;void(0);"
                className="left_arrow"
                onClick={() => {
                  commonMethod.hideLeftSection();
                  setTimeout(() => {
                    document.getElementById("contacts_tab")?.click();
                  }, 200);
                }}
              >
                <BackIcon id="newMessage-backArrow" className="blk_icon" />
              </a>
              <div className="usr_imgbx">
                <div className="grp_bx">
                  {commonMethod.generateFirstLastNameIntials(this.state?.listName !== null ? this.state?.listName :"")}
                </div>
              </div>
              <div className="usr_txt">
                <h3>{this.state.listName}</h3>
              </div>
            </div>
          </div>
          <div className="top_right d-flex">
            <a
              href="javascript:;void(0);"
              className={`more_icon crcl_icon ${this.props.editing ? "ylw_bg" : ""
                } togl_btn`}
              id=""
              onClick={() => {
                this.setState({
                  temporarySharedWith: this.state.sharedWith,
                  temporaryOwner: this.state.owners,
                });
                if (this.props.editing) {
                  this.props.setTabOpened("viewgroup")
                }
                else {
                  this.props.setTabOpened("editgroup");
                }
              }}
            >
              <EditImage className="blk_icon"></EditImage>
            </a>
            <a
              href="javascript:;void(0);"
              className={`more_icon crcl_icon ${this.state.dialogBox === "delete" ? "ylw_bg" : ""
                } outln_btn`}
              id=""
              onClick={() => this.setState({ dialogBox: "delete" })}
            >
              <DeleteImage className="blk_icon"></DeleteImage>
            </a>
            <a
              href="javascript:;void(0);"
              className={`more_icon crcl_icon ${this.props.infoPanelOpened ? "ylw_bg" : ""
                } togl_btn`}
              id="show_info"
              style={{
                // backgroundColor: this.props.infoPanelOpened
                //   ? "var(--primary)"
                //   : "var(--onsurfacelow)",
                transition: "background 1s",
              }}
              onClick={(event) =>
                this.props.setInfoPanelOpened(!this.props.infoPanelOpened)
              }
            >
              <DotsIcon className="blk_icon" />
            </a>
          </div>
        </div>
        <div className="chat_or_info">
          <div
            className="chat_sec"
            style={{ marginRight: this.props.infoPanelOpened ? "220px" : "0" }}
          >
            <div className="chat_bx">
              <div className="cntct_frm cntct_view contct_upl">
                <div className="row">
                  <div className="col-md-6">
                    <div className="cntcts_grp">
                      <div className="cntct_hedr">
                        <h2>Contacts in group</h2>
                        <span className="sub_txt">
                          {this.props.selectedContactsNewMessage &&
                            this.props.selectedContactsNewMessage?.filter(
                              (x: any) => x.ContactId ?? x.id !== undefined
                            ).length}{" "}
                          contacts
                        </span>
                      </div>
                      <ul className="usr_list import_contactbox">
                        {this.props.selectedContactsNewMessage.map(
                          (c: any, i: number) => {
                            if (c.ContactId ?? c.id ! !== undefined)
                              return (
                                <li key={i}>
                                  <div className="usr_imgbx">
                                    <div className="usr_img2">
                                      {commonMethod.generateFirstLastNameIntials(
                                        c.ForeName ?? c.givenName + " " + ((c.SureName ?? c.surname)!=undefined&& (c.SureName ?? c.surname)==null?c.SureName ?? c.surname:"")
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="usr_txt"
                                    style={{ display: "flex" }}
                                  >
                                    <div>
                                      <h3
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          textAlign: "left",
                                          paddingRight: "5px",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {c.ForeName ?? c.givenName} {c.SureName ?? c.surname !== null ? c.SureName ?? c.surname : ""}
                                      </h3>
                                      <div className="txt_div">
                                        <span className="sub_txt">
                                        {c.PhoneNumber ?? c.mobilePhone}
                                        </span>
                                      </div>
                                    </div>
                                    {this.props.editing ? (
                                      <div
                                        style={{
                                          flex: 1,
                                          alignSelf: "center",
                                          float: "right",
                                          textAlign: "right",
                                          padding: "5px"
                                        }}
                                      >
                                        <MinusOutlineSvg className="ctThem"
                                          onClick={() => {
                                            this.removeFromSelected_newMessage(
                                              c
                                            );
                                          }

                                          }

                                        />
                                        { }
                                      </div>
                                    ) : null}

                                    {/* <a
                                          href="#"
                                          className="add_btn ylw_bg"
                                          onClick={() => {
                                            const newContactsToBeSaved = this.state.contactsToBeSaved.filter(
                                              (c: any, j: number) => i !== j
                                            );
                                            this.setState({
                                              contactsToBeSaved: newContactsToBeSaved,
                                            });
                                          }}
                                        >
                                          {" "}
                                          <img
                                            src="images/minus.svg"
                                            alt="Add"
                                          />{" "}
                                        </a> */}
                                  </div>
                                </li>
                              );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="grp_info">
                      <div className="cntct_hedr">
                        <h2> Group Information</h2>
                      </div>
                      {this.props.editing ? (
                        <>
                          <div className="mb-3">
                            <label className="form-label">GROUP NAME</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Group Name"
                              defaultValue={this.state.listName}
                              onChange={this.handleChangetxt}
                              // onChange={(e) => {
                              //   e.preventDefault();
                              //   // this.props.setAlertData({ className: "danger", text: "The Contact Group title cannot be modified", })
                              //   // setTimeout(() => { this.props.setAlertData(null); }, 2000);
                              //   this.setState({ name: e.target.value });
                              // }}
                            />
                          </div>
                          {this.props.ServicePlanId === 1 || this.props.ServicePlanId === 3 ? "" :
                            <div className="mb-3">
                              <label className="form-label">{companyVersion!==2? "Shared with" :"Assigned to inbox"}</label>
                              {console.log("this.props.yakchatGroupData",this.props.yakChatGroupData)}
                              {groupEmailTagSuggestions && (
                                this.state.groupEmail||(this.props.groupData?.InboxId) ? this.state.IsEmptyEmail === false ?
                                  <Select
                                    className="sharedwith-dropdown "
                                    mode="multiple"
                                    placeholder="Start typing inbox name..."
                                    
                                    defaultValue={companyVersion!==2? (this.props.yakChatGroupData && this.props.yakChatGroupData.find((x: any) => x.Email === this.state.groupEmail) ? this.props.yakChatGroupData.find((x: any) => x.Email === this.state.groupEmail).UserName : this.state.groupEmail):
                                      (this.props.yakChatGroupData && this.props.yakChatGroupData.find((x: any) => x.InboxId === this.props.groupData.InboxId) ? this.props.yakChatGroupData.find((x: any) => x.InboxId === Number( this.props.groupData.InboxId)).Name : this.state.groupEmail || "")}
                                    onChange={this.handleChange}
                                    filterOption={(v, option) => {
                                      v = v.toLowerCase();
                                      const a: any = option;
                                      return (
                                        a?.label?.toLowerCase().includes(v) ||
                                        a?.value?.toLowerCase().includes(v)
                                      );
                                    }}
                                    style={{ width: "100%" }}
                                    options={groupEmailTagSuggestions}
                                  ></Select> :
                                  <Select
                                    className="sharedwith-dropdown"
                                    mode="multiple"
                                    placeholder="Start typing inbox name..."
                                    onChange={this.handleChange}
                                    filterOption={(v, option) => {
                                      v = v.toLowerCase();
                                      const a: any = option;
                                      return (
                                        a?.label.toLowerCase().includes(v) ||
                                        a?.value.toLowerCase().includes(v)
                                      );
                                    }}
                                    style={{ width: "100%" }}
                                    options={groupEmailTagSuggestions}
                                  >test test we3434</Select> :
                                  <Select
                                    className="sharedwith-dropdown"
                                    mode="multiple"
                                    placeholder="Start typing inbox name..."
                                    onChange={this.handleChange}
                                    filterOption={(v, option) => {
                                      v = v.toLowerCase();
                                      const a: any = option;
                                      return (
                                        a?.label.toLowerCase().includes(v) ||
                                        a?.value.toLowerCase().includes(v)
                                      );
                                    }}
                                    style={{ width: "100%" }}
                                    options={groupEmailTagSuggestions}
                                  >test test we3434</Select>
                              )}
                            </div>}
                          {this.props.ServicePlanId === 1 || this.props.ServicePlanId === 3 ? "" :
                            <div className="mb-3">

                            </div>
                          }
                        </>
                      ) : (
                        <>
                          <div className="mb-3">
                            <label className="form-label">GROUP NAME</label>
                            <input
                              type="text"
                              disabled
                              className="form-control no_editing"
                              placeholder=""
                              value={this.state.listName}
                            />
                          </div>
                          {this.props.ServicePlanId === 1 || this.props.ServicePlanId === 3 ? "" :
                            <div className="mb-3">
                              <label className="form-label">{companyVersion!==2? "Shared with" :"Assigned to inbox"} </label>
                              <input
                                type="text"
                                disabled
                                className="form-control no_editing"
                                placeholder=""
                                value={companyVersion!==2?(this.state.IsEmptyEmail === true ? "" : this.props.yakChatGroupData && this.props.yakChatGroupData.find((x: any) => x.Email === this.state.groupEmail) ? this.props.yakChatGroupData.find((x: any) => x.Email === this.state.groupEmail).UserName : this.state.groupEmail || ""):(this.props.yakChatGroupData && this.props.yakChatGroupData.find((x: any) => x.InboxId === Number( this.props.groupData.InboxId)) ? this.props.yakChatGroupData.find((x: any) => x.InboxId === Number( this.props.groupData.InboxId)).Name : this.state.groupEmail || "")}
                              />
                            </div>
                          }
                          {this.props.ServicePlanId === 1 || this.props.ServicePlanId === 3 ? "" :
                            <div className="mb-3">
                            </div>
                          }

                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.props.editing ? (
              <div className="drag_btnbox">
                <a
                  href="javascript:;void(0);"
                  className="drag_btn cancel_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      this.props.selectedContactsNewMessage !==
                      this.state.previousContacts
                    )
                      this.setState({ dialogBox: "cancel" });
                    else this.props.setTabOpened("viewgroup");
                  }}
                >
                  Cancel
                </a>
                <a
                  href="javascript:;void(0);"
                  className="drag_btn next_btn next_yellow"
                  onClick={() => {
                    if (this.props.editing) {
                      this.props.setTabOpened("viewgroup")
                    }
                    
                    // use this.state.temporarySharedWith here to save the new groupEmail of the list.
                    // use this.state.temporaryOwner here to save the new owners of the list.

                    if (this.props.tabOpened === "editgroup")
                    {
                      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
                        var sharedEmail;
                        debugger;
                        if(this.state.sharedWith!== undefined&& this.state.sharedWith!==null)
                        {
                          sharedEmail=this.state.sharedWith;
                        }
                        else if(this.props.groupData?.InboxId!== undefined&& this.props.groupData?.InboxId!==null)
                        {
                          sharedEmail=this.props.groupData;
                          sharedEmail = sharedEmail !== undefined ? sharedEmail.InboxId : "";
                        }
                        else if(this.state.groupEmail !== undefined&& this.state.groupEmail!==null){
                          sharedEmail = this.props.yakChatGroupData?.find((c:any) => {if (c.InboxId === Number((this.state.groupEmail===null||this.state.groupEmail===undefined)?this.props.groupData.InboxId:this.state.groupEmail)) {return (c.InboxId)} })
                          sharedEmail = sharedEmail !== undefined ? sharedEmail.InboxId : "";
                        }
                       else
                        sharedEmail = "";
                        debugger;
                        if(store.getState().reduxReducer.loggedInUser.user.ServicePlanId===6||store.getState().reduxReducer.loggedInUser.user.ServicePlanId===7)
      {
        if( sharedEmail == ""||sharedEmail == null ){
          this.props.setAlertData({ className: "danger", text: "Please choose Shared Inbox to share contact with, you are on Shared plan.", })
           setTimeout(() => { this.props.setAlertData(null); }, 2000);
         
         return;
       }
     }
                        this.props.AddContactList(this.props.groupData !== undefined ?this.props.groupData.Id : this.props.groupData.Id
                          ,this.props.groupData.ListName!=undefined?this.props.groupData.ListName:this.state.name, this.props.storeActualContactsInList, this.props.selectedContactsNewMessage, sharedEmail);
                      }
                      else{

                      
                      this.props.AddContactList(this.props.groupData.ListData !== undefined ?this.props.groupData.ListData.Id : this.props.groupData.Id,this.state.name, this.props.storeActualContactsInList, this.props.selectedContactsNewMessage,  this.state.savedEmailDatabase);
                      }
                    }
                    this.props.groupData.ListName=this.state.name!==undefined?this.state.name: this.props.groupData.ListName;
                    setTimeout(() => this.props.shiftContactsInContactList(this.props.storeActualContactsInList, this.props.selectedContactsNewMessage, this.props.groupData.ListData !== undefined ?this.props.groupData.ListData.Id : this.props.groupData.Id), 1000);
                    this.props.updateGroupInList(this.props.groupData.ListData !== undefined ?this.props.groupData.ListData.Id : this.props.groupData.Id, this.props.groupData?.ListData !== undefined ? this.props.groupData.ListData.localContacts : this.props.groupData.localContacts, this.state.sharedWith);
                    setTimeout(()=>{this.props.getYakChatGroupData(1)},1000)                   
                    
                    setNewCreatedGroup(this.props.groupData);
                  }}
                >
                  Save group
                </a>
              </div>
            ) : this.props.ServicePlanId === 1 ||
              this.props.ServicePlanId === 3 ? (
              <div className="drag_btnbox">
                <a
                  href="javascript:;void(0);"
                  onClick={this.SendMessageTogroup}
                  className={
                    this.state.activebutton
                      ? " drag_btn next_btn next_yellow btn-send-group"
                      : "drag_btn next_btn next_yellow btn-group-focus"
                  }
                //  className="drag_btn next_btn next_yellow"
                >
                  <SendIcon
                    style={{
                      fill: "var(--onPrimary)",
                      width: 18,
                      height: 18,
                      marginRight: 7,
                    }}
                  />
                  Send message to group
                </a>
              </div>
            ) : (
              <div className="drag_btnbox">
                <a
                  href="javascript:;void(0);"
                  onClick={(e) => {
                    if (this.props.selectedContactsNewMessage.length < 51) {
                      this.setState({ dialogBox: "SendMessage", });
                    }
                    else{
                      this.props.limitSendmessage("")
                    }
                  }}
                  className={
                    this.state.activebutton
                      ? " drag_btn next_btn next_yellow btn-send-group"
                      : "drag_btn next_btn next_yellow btn-group-focus"
                  }
                //  className="drag_btn next_btn next_yellow"
                >
                  <SendIcon
                    style={{
                      fill: "var(--onPrimary)",
                      width: 18,
                      height: 18,
                      marginRight: 7,
                    }}
                  />
                  Send message to group
                </a>
              </div>
            )}
          </div>
          <Drawer
            className={
              this.props.infoPanelOpened ? "Drawer" : "Drawer Drawer-Open"
            }
            classes={{ paper: "MuiDrawer-paper2" }}
            variant="persistent"
            anchor="right"
            open={this.props.infoPanelOpened}
          >
            <div className="info_sec" style={{ left: "0px" }}>
              <div className="cntct_profile text-center">
                <div className="profl_img grp_icn">
                  <span className="">
                    <span className="left_Bx">
                      <span className="grp_bx">
                        {commonMethod.generateFirstLastNameIntials(this.state.listName)}
                      </span>
                    </span>
                  </span>
                </div>
                <div className="phone_txt"> {this.state.listName} </div>
              </div>

              <div
                className="cntct_info cnvrt_actn"
                style={{ padding: "6px 0px" }}
              >
                <h5 className="grey_hedln" style={{ paddingLeft: 15 }}>
                  {" "}
                  Group actions{" "}
                </h5>
                <ul className="optn_list">
                  {this.props.ServicePlanId === 1 ||
                    this.props.ServicePlanId === 3 ? (
                    <li
                      style={{ padding: "0 10px" }}
                      className="group_action-li"
                    >
                      {" "}
                      <a
                        href="javascript:;void(0);"
                        title="Send message to group"
                        onClick={this.SendMessageTogroup}
                      >
                        {" "}
                        <SendIcon
                          className="blk_icon"
                          style={{ width: 50 }}
                        />{" "}
                        Send message to group{" "}
                      </a>{" "}
                    </li>
                  ) : (
                    <li
                      style={{ padding: "0 10px" }}
                      className="group_action-li"
                    >
                      {" "}
                      <a
                        href="javascript:;void(0);"
                        title="Send message to group"
                        onClick={(e) => {this.setState({ dialogBox: "SendMessage",});}}
                      >
                        {" "}
                        <SendIcon
                          className="blk_icon"
                          style={{ width: 50 }}
                        />{" "}
                        Send message to group{" "}
                      </a>{" "}
                    </li>
                  )}
                  <li
                    onClick={() => this.props.setTabOpened("editgroup")}
                    style={{ padding: "0 10px" }}
                  >
                    {" "}
                    <a href="javascript:;void(0);" title="Pin to top">
                      <EditImage
                        className="Edit Group"
                        style={{
                          fill: "var(--onsurfacehighest)",
                          width: 50,
                        }}
                      ></EditImage>
                      Edit Group
                    </a>
                  </li>
                  <li
                    onClick={() => this.setState({ dialogBox: "delete" })}
                    style={{ padding: "0 10px" }} className="group_action-li">
                    {" "}
                    <a href="javascript:;void(0);" title="Delete Group" className="red">
                      <DeleteImage style={{ width: 50 }} />
                      Delete Group{" "}
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </Drawer>
        </div>

        {this.state.dialogBox === "SendMessage" ? (
          <div className="dialogBox">
            <div className="addcontact_box" key="AddContactsToGroup">
              <div className="addcontact_detail">
                <div className="addcont_txt">
                  <h3>Send Message</h3>
                  <p>
                    Select the Inbox you want to send your message contact to be
                    placed in
                  </p>
                </div>
                <button
                  type="button"
                  className="pop-closebtn"
                  onClick={() => this.setState({ dialogBox: "" })}
                >
                  <img src="images/cross.svg" />
                </button>

                <ul className="usr_list import_contactbox add_groupbox">
                  {this.props.sharedinboxData.currentSelected.name !== "Closed" ?
                    <li>
                      <div className="usr_imgbx">
                        <div className="grp_bx">
                          {commonMethod.generateFirstLastNameIntials(
                            this.props.sharedinboxData
                              ? this.props.sharedinboxData.currentSelected.name
                              : ""
                          )}
                        </div>
                      </div>
                      <div className="usr_txt">
                        <h3>
                          {" "}
                          {this.props.sharedinboxData
                            ? this.props.sharedinboxData.currentSelected.name :
                            ""}
                        </h3>
                        <div className="txt_div">
                          <span className="sub_txt"></span>
                        </div>
                      </div>
                      <label className="group_check">
                        <input
                          type="radio"
                          checked={
                            this.state.SendMessageEmail === this.props.sharedinboxData.currentSelected.email
                          }
                          id="groupEmailSelected"
                          name="groupEmailSelected"
                          onChange={(e: any) => {
                            if (this.state.SendMessageEmail === this.props.sharedinboxData.currentSelected.email) {
                              this.setState({ SendMessageEmail: "" });
                            } else {
                              this.setState({
                                SendMessageEmail: this.props.sharedinboxData
                                  .currentSelected.email,
                              });
                            }
                          }
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    :
                    ""
                  }
                  {this.props.sharedinboxData?.other?.length > 0
                    ? this.props.sharedinboxData.other.map(
                      (x: any, i: number) => {
                        if (x.email === "Closed") return null;
                        else {
                          return (
                            <li>
                              <div className="usr_imgbx">
                                <div className="grp_bx">
                                  {commonMethod.generateFirstLastNameIntials(
                                    x.name
                                  )}
                                </div>
                              </div>
                              <div className="usr_txt">
                                <h3>{x.name}</h3>
                                <div className="txt_div">
                                  <span className="sub_txt">
                                    {/* {c.localContacts &&
                                  c.localContacts.$values.length}{" "}
                                Contacts */}
                                  </span>
                                </div>
                              </div>
                              <label className="group_check">
                                <input
                                  type="radio"
                                  onChange={(e: any) => {
                                    if (
                                      this.state.SendMessageEmail === x.email) {
                                      this.setState({ SendMessageEmail: "" });
                                    } else {
                                      this.setState({
                                        SendMessageEmail: x.email,
                                      });
                                    }
                                  }}
                                  id="groupEmailSelected"
                                  name="groupEmailSelected"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          );
                        }
                      }
                    )
                    : null}
                </ul>
                <div className="addgr_btnbox d-flex justify-space-between" style={{justifyContent:"space-between"}}>
                <button
                  style={{
                    color: "var(--onPrimary)",
                    backgroundColor: " var(--secondary)",
                    minWidth: "90px",
                  }}
                  className="blk_btn drag_btn"
                  onClick={() => this.setState({ dialogBox: "" })}
                >
                  Cancel
                </button>
                  <button
                  className="drag_btn next_btn next_yellow add_grbtn"
                    onClick={(e) => {
                      e.preventDefault();
                      this.SendMessagetogroupContact(
                        this.state.SendMessageEmail
                      );
                      this.setState({
                        dialogBox: "",
                      });
                    }}
                  >
                    Send
                  </button>
                  
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.dialogBox === "delete" ? (
          <div className="dialogBox">
            <div className="addcontact_box" key={2}>
              <div className="sure_detail">
                <div className="addcont_txt">
                  <h3>Are you sure you want to delete {this.props.groupData?.ListName}</h3>
                  <p className="save_press">
                    You won’t be able to undo this action
                  </p>
                </div>
                <div className="addgr_btnbox">
                  <button className="btn cstm_btn blk_btn cancelbtn"  onClick={() => this.setState({ dialogBox: "" })}  >
                    {" "}
                    Cancel{" "}
                  </button>
                  <button
                    className="btn  cstm_btn del_btn"
                    onClick={() => {
                      var newIds = this.props.storeActualContactsInList?.map((x: any) => {return(x.ContactId)})
                      if (this.props.groupData?.ListData !== undefined ? this.props.groupData?.ListData : this.props.groupData?.ListName)
                      this.props.shiftContactsInContactList(this.props.storeActualContactsInList, [], this.props.groupData?.ListData !== undefined ? this.props.groupData?.ListData : this.props.groupData?.Id);
                      setTimeout(() => this.props.deleteThisList(this.props.groupData?.ListData !== undefined? this.props.groupData?.ListData.Id : this.props.groupData?.Id), 1000);
                      this.setState({ dialogBox: "" })
                      let index = this.props.yakchatLists.findIndex((x: any) => x.ListData !== undefined ? x.ListData : x.Id === this.props.groupData.ListData !== undefined ? this.props.groupData.ListData :this.props.groupData.Id);
                      if (this.props.yakchatLists[index + 1] !== undefined) {
                        setTimeout(() => this.props.setGroupCurrentlyEditing(this.props.yakchatLists[index + 1], this.props.yakchatLists[index + 1].ListData !== undefined ?  this.props.yakchatLists[index + 1].ListData :  this.props.yakchatLists[index + 1].Id,"ww",[]), 1000);
                        setTimeout(() => { document.getElementById("setting_cion_button")?.click(); }, 2000);
                  }
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.dialogBox === "cancel" ? (
          <div className="dialogBox">
            <div className="addcontact_box" key="cancel">
              <div className="sure_detail">
                <div className="addcont_txt">
                  <h3>Are you sure you want to cancel?</h3>
                  <p className="save_press">
                    If you don’t press save you will lose all the changes you
                    have made
                  </p>
                </div>
                <div className="addgr_btnbox">
                  <button
                    className="btn cstm_btn blk_btn cancelbtn"
                    onClick={() => {
                      this.setState({ dialogBox: "" });
                    }}
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                  <button
                    className="btn  cstm_btn ylo_btn2"
                    onClick={() => {
                      this.setState({ dialogBox: "" });
                      this.props.setSelectedContacts(
                        this.state.previousContacts
                      );
                      this.props.setTabOpened("viewgroup");
                    }}
                  >
                    {" "}
                    Okay{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (props: any) => {

  return {
    groupData: props.reduxReducer.groupCurrentlyEditing,
    selectedContactsNewMessage: props.reduxReducer.selectedContactsNewMessage || [],
    tabOpened: props.reduxReducer.tabOpened,
    infoPanelOpened: props.reduxReducer.infoPanelOpened,
    yakChatGroupData: props.reduxReducer.setSharedEmails,
    yakChatUsers: props.reduxReducer.yakChatUsers,
    sharedinboxData: props.reduxReducer.sharedInboxData,
    ServicePlanId: props.reduxReducer.ServicePlanId,
    storeActualContactsInList: props.reduxReducer.storeActualContactsInListReducer,
    loggedInUser: props.reduxReducer.loggedInUser,
    yakchatLists: props.reduxReducer.yakchatLists,
  };

};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    limitSendmessage: (data: any) => {
      dispatch<any>(
        action.setAlertData({ className: "danger", text: "You cannot send a single message to more than 50 contacts!", })
      );
      setTimeout(() => {
        dispatch<any>(action.setAlertData(null));
      }, 2000);
    },
    sendmessage: async (data: any, text: any, email: any) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
      {
      return dispatch<any>(action.SendMessage_V2(data, text, email, null));
      }
      else
      return dispatch<any>(action.SendMessage(data, text, email,null));

    },
    setGroupCurrentlyEditing: (group: any) => {
      dispatch<any>({ type: "setGroupCurrentlyEditing", group });
    },

    CurrentSelectInbox: (data: any) => {
      dispatch<any>(action.currentSelectedInbox(data))
    },
    setAlertData: (data: any) => {
      dispatch<any>(action.setAlertData(data));
    },
    setTabOpened: (tab: string) => {
      dispatch<any>(action.setTabOpened(tab));
    },
    setSelectedContacts: (contact: any) => {
      dispatch<any>(action.setSelectedContacts(contact));
    },
    shiftContactsInContactList: (previousContacts: any, newContacts: any, id: number) => {
   
      dispatch<any>(action.shiftContactsInContactList(previousContacts, newContacts, id));
    },
    deleteThisList: (id: string) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
      dispatch<any>(action.deleteThisList_V2(id));
        }
        else{
          dispatch<any>(action.deleteThisList(id));
        }
    },
  
    setInfoPanelOpened: (open: boolean) => {
      dispatch<any>({ type: "setInfoPanelOpened", payload: open });
    },
    AddContactList: (groupId:any,groupName: any, newContacts: any,selectedContactsNewMessage:any, groupEmail: any) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
      dispatch<any>(action.EditContactList_V2(groupId,groupName, newContacts,selectedContactsNewMessage, groupEmail));
        }
        else{
          dispatch<any>(action.EditContactList(groupId,groupName, newContacts,selectedContactsNewMessage, groupEmail));
        }
    },
    updateGroupInList: (ListId: any, GetLocalContacts: any, groupEmail: any) => {
      dispatch<any>({ type: "updateListOfContactsInThisList", Id: ListId, newContacts: GetLocalContacts, groupEmail: groupEmail });
    },

    SendGroupMesssageDialog: (data: any, email: any) => {
      // dispatch<any>(action.SetMessageToGroupDialog(data, email));
    },
    getYakChatGroupData: (pageIndex:any) => {
      dispatch<any>(action.getYakChatGroupData(pageIndex));
    },
    removeContactsFromLists: (contactId: any, listId: any, loggedInUserEmail: any, previousContacts: any, yakChatList: any) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
      {
        dispatch<any>(action.removeContactsFromLists_V2(contactId, listId, loggedInUserEmail, previousContacts, yakChatList));
      }
      else{
      dispatch<any>(action.removeContactsFromLists(contactId, listId, loggedInUserEmail, previousContacts, yakChatList));
      }
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);
