import React from "react";
import "./chatPanel.css";

interface Props {
  type: string;
}
const Skeleton = (props: Props) => {
  return <div className={`${props.type}`}></div>;
};

export default Skeleton;
