import React from "react";
import { connect, } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
// import Logo from "../../images/login_logo.png";
import sponser2 from "../../../TeamsApp/images/commsgroup-alt-logo 2.png"
import logo from "../../../TeamsApp/images/CT Text Logo-2.png";
import { Link } from "react-router-dom";
import * as actions from "../../redux/actions/actions";
import * as msTeams from '@microsoft/teams-js';
import jwt_decode from "jwt-decode";
import Logo from "../logo";
import { Console } from "console";
import { store } from "../../..";
import * as action from '../../redux/actions';
import PhoneInput from 'react-phone-input-2';
import { ReactComponent as ErrorIcon } from "../../images/eroor.svg";
import 'react-phone-input-2/lib/style.css'
import configData from "../../../appsettings.json";
class RegisterUser extends React.Component<any, any> {
  state = {
    PhoneNumber: "",
    fullname: "",
    email: "",
    accessTokenApi: "",
    registerTheme: "",
    disableButton: false,
    showing: false,
    emailErrors: false,
  };
  handelUser = () => {
    const API_KEY = process.env.TKey;
    const API_Sec = process.env.TSec;
    const API_Verify = process.env.TVerify;
    var headers = new Headers();
    const data = new URLSearchParams();
    var Number = this.state.PhoneNumber.includes('+')? this.state.PhoneNumber:"+"+ this.state.PhoneNumber;
    Number=Number?.replace("+", "%2B");
    data.append("To",Number);
    data.append("Channel", "sms");
    fetch(configData.APiUrl+`/v2/Authorize/GetVerifyCode?to=${Number}&channel=${"sms"}`, {
      method: 'GET',
      headers: {
          'Content-type': 'application/json'
      },
  }).then((response: any) => response.json()).then(data => {
    
        if(data.status=="pending")
        {
          this.setState({showing:true});
        }
          var userdetailObj = {
            fullname: this.state.fullname,
            email: this.state.email,
            PhoneNumber: this.state.PhoneNumber,
          };
          this.props.setUserDetails(userdetailObj, this.state.accessTokenApi)
        });
      
  
  }
  validEmail = (e: any) => {
    debugger;
    var emailChecker = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var emailtest = e.target.value;
    var validateEmail = emailChecker.test(emailtest);

    this.setState({ email: e.target.value });

    if (!validateEmail && e.target.value !== "") {
      this.setState({
        emailErrors: true,
      });
    } else {
      this.setState({
        emailErrors: false,
      });
    }
  };
  componentDidMount() {
    let fullName;
    let email;
    let PhoneNumber;
    let msEmail: any;
    if (this.props.userData !== undefined) {
      fullName = this.props.userData.fullname;
      email = this.props.userData.email;
      PhoneNumber = this.props.userData.PhoneNumber;
    }
    this.setState({ PhoneNumber: PhoneNumber, fullname: fullName, email: email })
    
    msTeams.initialize(() => {
      var isInTeams = true;
      msTeams.getContext(async (c) => { 
        msTeams.authentication.getAuthToken({
          successCallback: async function (result) {
            const tokenDecoded: any = jwt_decode(result);
            msEmail = tokenDecoded.upn;
          },
          failureCallback: function (error) {
            console.log("Failure: " + error);
          }
        })
        setTimeout(() => {
          this.setState({ email: msEmail })
        }, 100);

      })
    })
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.accessTokenApi !== prevState.accessTokenApi ||
      nextProps.registerTheme !== prevState.registerTheme)
      return {
        accessTokenApi: nextProps.accessTokenApi,
        registerTheme: nextProps.registerTheme,
      }
    else return null;
  }
  render() {
    //debugger;
    return (
      <div className={this.state.registerTheme === "calltower" ? "userlog_sec calltowerSection_register" : this.props.registerTheme === "commsChoice" ? "userlog_sec commsSection_register" : this.props.registerTheme === "onenet" ? "userlog_sec OneNet_register"
       : this.props.registerTheme === "mobex" ? "userlog_sec OneNet_register Mobex_register" 
       : this.props.registerTheme === "berkshire" ? "userlog_sec OneNet_register Berkshire_register" 
       : this.props.registerTheme === "teligentip" ? "userlog_sec OneNet_register Teligentip_register" 
       :  this.props.registerTheme === "setup4" ? "userlog_sec setupRegister" : 
      this.props.registerTheme === "oneuc" ? "userlog_sec Oneuc_register"  :
       "userlog_sec register_section"}>
        <div className="container">
          <div className="login_box">
            <Logo/>
            <p className="comp_reg text-center">Please complete your details to <br />register for YakChat</p>
            <div className="step_box text-center">
              <a href="#/register" className="step_btn step_active" >Step 1</a>
              <a className="step_btn " >Step 2</a>
              <a className="step_btn " >Step 3</a>
              <a className="step_btn ">Step 4</a>
              <a className="step_btn">Step 5</a>
            </div>
            <div className="user_detail" style={{ backgroundColor: "#fff" }}>
              <div className="register_scroll">
                <h3 className="user_head">User Details</h3>
                <form>
                  <div className="mb-3">
                    <label className="form-label">Full name</label>
                    <input type="text" className="form-control" value={this.state.fullname} onChange={(e) => this.setState({ fullname: e.target.value })}
                      placeholder="Enter your full name" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">E-mail address</label>
                    <input type="email"  className="form-control" value={this.state.email} onChange={this.validEmail}
                      placeholder="Enter your Microsoft 365 E-mail address" required />
                        {this.state.emailErrors ? (
                      <span className="error-div" style={{color:"red"}}>
                        {/* <ErrorIcon className="error-icon" style={{color:"red"}} />{" "} */}
                        <span className="error-span">
                          Please Enter a Valid Email
                        </span>
                      </span>
                    ) : ("")}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Phone number (FOR VERIFICATION)</label>
                    <PhoneInput country={'us'}  onlyCountries={['us', 'ca', 'au', 'gb']} inputClass="form-control" inputStyle={{paddingLeft: "50px",letterSpacing: "0.5px",fontSize: "16px",border:'1px solid #e5e5e5',borderRadius:'11px',height:'41px',width:'100%'}}
                     value={this.state.PhoneNumber} onChange={(e) => this.setState({ PhoneNumber: e})}  placeholder="Enter your mobile number (excl. country code)" />
                    {/* <input type="tel" className="form-control" value={this.state.PhoneNumber} onChange={(e) => this.setState({ PhoneNumber: e.target.value })}
                      placeholder="Enter your mobile/cell phone number" /> */}
                  </div>
                </form>
              </div>
              {
              this.state.fullname ? this.state.email ? this.state.PhoneNumber ? 
                <Link to="/verifyUsers">
                  <button onClick={this.handelUser}
                    className={"nxtBtn"} >Next</button>
                </Link> : <button className={"disable_btn_nxt registerNext"} >Next</button> : <button className={"disable_btn_nxt registerNext"} >Next</button> : <button className={"disable_btn_nxt registerNext"} >Next</button> 
                }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (props: any) => {
  return {
    userData: props.reduxReducer.userDetails_registeration,
    user: props.reduxReducer.user,
    accessTokenApi: props.reduxReducer.accessTokenApi,
    registerTheme: props.reduxReducer.registerTheme,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    setUserDetails: (userdetailObj: any, accessTokenApi: any) => {
      dispatch<any>(actions.setUserDetails(userdetailObj, accessTokenApi));
    },
  
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser);