import React from "react";
export default class closeAfterPermissions extends React.Component<any, any>  {
 componentDidMount(){
   window.close()
 }
  render() {
    return (
      <section >
     closeAfterPermissions
      </section>
    );
  }
}