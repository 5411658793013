import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as action from "../../../redux/actions/actions";
import ChatPanel from "../ChatPanel/ChatPanel";
import NewContact from "../NewContact/NewContact";
import ContactOpen from "../ContactOpen/ContactOpen";
import SideBarComponent from "../SideBarComponent/SideBarComponent";
import NewMessage from "../NewMessage/NewMessage";
import UploadCsv from "../UploadCsv/UploadCsv";
import NewGroup from "../NewGroup/NewGroup";
import EditGroup from "../EditGroup/EditGroup";
import SelectedContacts from "../SelectedContacts/SelectedContacts";
import DefaultScreen from "../DefaultScreen/DefaultScreen";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import ErrorBoundary from "../../errorBoundary/errorBoundary";
import SystemUnavailable from "../../../loginError/SystemUnavailable";
import { store } from "../../../..";

const antIcon = <Loading3QuartersOutlined style={{ fontSize: 60,color:"var(--primary)",backgroundColor:"none" }} spin  />;

class HeaderComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      testName: "",
      WhiteLabelBrand: this.props.whiteLabelBrand,
      width: 1000,
      currentTheme: this.props.currentTheme,
      spinnerValueState: false
    };
    this.props.setCurrentTheme("light");
    this.props.setTabOpened("default");
    //this.props.getUsableCreditForUser();
  }

  screenResizeEventListener: any;

  componentDidMount() {
  if(this.props.WhiteLabelBrand === "calltower"){

    if(this.props.DisplayMode === "dark"){
      this.props.setCurrentTheme("calltowerdark")
    }   }
    this.setState({ width: window.innerWidth });
    this.screenResizeEventListener = window.addEventListener("resize", (e) => {
      this.setState({
        width: window.innerWidth,
      });
    });
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    var theme = "";
    nextProps.currentTheme === "calltower-dark" ? theme = "calltowerdark"
    : nextProps.currentTheme === "commschoicedark" ? theme = "commschoicedark"
      : nextProps.currentTheme === "setupdark" ? theme = "setupdark"
        : theme = nextProps.currentTheme ? prevState.WhiteLabelBrand === "calltower" ? "call-tower"
          : prevState.WhiteLabelBrand === "commschoice" ? "commschoice"
            : prevState.WhiteLabelBrand === "onenet" ? "onenet"
            : prevState.WhiteLabelBrand === "mobex"? "mobex"
              : prevState.WhiteLabelBrand === "teligentip" ? "teligentip"
                : prevState.WhiteLabelBrand === "oneuc" ? "oneuc"
                  : prevState.WhiteLabelBrand === "berkshire" ? "berkshire"
                    : prevState.WhiteLabelBrand === "setup" ? "setup"
                      : nextProps.currentTheme : "light";
    if (nextProps.currentTheme === "dark") {
      if (nextProps.whiteLabelBrand === "calltower") { theme = "calltowerdark" }
      else if (nextProps.whiteLabelBrand === "commschoice") {
        theme = "commschoicedark"
      } else if (nextProps.whiteLabelBrand === "onenet") {
        theme = "onenetdark"
      }
      //#4169

      else if (nextProps.whiteLabelBrand === "mobex") {
        theme = "mobexdark"
      } 
       else if (nextProps.whiteLabelBrand === "berkshire") {        
        theme = "berkshiredark"
      }
      else if (nextProps.whiteLabelBrand === "teligentip") {
        theme = "teligentipdark"
      }
      else if (nextProps.whiteLabelBrand === "oneuc") {
        theme = "oneucdark"
      }
      else if (nextProps.whiteLabelBrand === "setup") {
        theme = "setupdark"
      }

    }
    if (nextProps.currentTheme !== prevState.currentTheme || nextProps.testData !== prevState.testName
      || nextProps.spinnerValue !== prevState.spinnerValueState ||
      nextProps.whiteLabelBrand !== prevState.whiteLabelBrand ||
      nextProps.DisplayMode !== prevState.DisplayMode
    ) {
      document.body.className = `${theme}-theme`;
      return {
        currentTheme: nextProps.currentTheme,
        testName: nextProps.testData,
        spinnerValueState: nextProps.spinnerValue,
        whiteLabelBrand: nextProps.whiteLabelBrand,
        DisplayMode: nextProps.DisplayMode,
      }
    } else {
      return null
    }


  }
  public render() {
    let left: any =
      document.getElementById("left_sec_main") &&
      document.getElementById("left_sec_main")!.clientWidth + 10;
    let widthofRightSec: any =
      document.getElementById("right_Sec_main") &&
      document.getElementById("right_Sec_main")!.clientWidth;
    return (
      <ErrorBoundary>
      <div>
         <Spin spinning={ this.state.spinnerValueState} indicator={antIcon}
              className='spinner' style={{ zIndex: 9999 ,}} />  
        <div
          className="alert-pop_up"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          style={{
            opacity: this.props.alertData ? 1 : 0,
            zIndex: this.props.alertData ? 9999 : -9999,
            transition: "all 1s",
            position: "fixed",
            background:
              this.props.alertData?.className === "danger"
                ? "#f4dbdf"
                : this.props.alertData?.className === "success"
                  ? "var(--primary)"
                  : this.props.alertData?.className === "normal"
                    ? "#f2f2f2"
                    : "#f2f2f2",
            border:
              this.props.alertData?.className === "danger"
                ? "1px solid #b00020"
                : this.props.alertData?.className === "success"
                  ? "1px solid var(--primary)"
                  : this.props.alertData?.className === "normal"
                    ? "1px solid #e5e5e5"
                    : "1px solid #e5e5e5",
            color:
              this.props.alertData?.className === "danger"
                ? "#b00020"
                : this.props.alertData?.className === "success" 
                  ? this.props.whiteLabelBrand === "oneuc" ? "#FFFFFF" :  "#ff" // maksud
                  : this.props.alertData?.className === "normal"
                    ? "#000"
                    : "#000",
            // top: "76px",
            left: left,
            width: this.props.alertData!=null? this.props.alertData.text=="Please Wait Uploading Contacts" ? "73%" : "73%":widthofRightSec ,
           // maxWidth: "63%",
            // height: "40px",
            borderRadius: 0,
            boxShadow: "none",
          }}
        >
          <div
            className="toast-body"
            style={{ padding: "0.25rem", marginTop: 4, marginLeft: 10 }}
          >
            {this.props.alertData?.text ? this.props.alertData?.text : ""}
            <a href={this.props.alertData?.redirectlink ? this.props.alertData?.redirectlink : ""} target='_blank'>{this.props.alertData?.redirectlinkTitle ? this.props.alertData?.redirectlinkTitle : ""}</a>
            <a href={this.props.alertData?.refreshlink ? this.props.alertData?.refreshlink : ""}>{this.props.alertData?.refreshlinkTitle ? this.props.alertData?.refreshlinkTitle : ""}</a>
            {this.props.alertData?.text2 ? this.props.alertData?.text2 : ""}
          </div>
          <a onClick={() => this.props.setAlertData(null)} href="javascript:;void(0);">
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              style={{
                position: "absolute",
                top: 13,
                right: 14,
                zIndex: 99,
                fill:
                  this.props.alertData?.className === "danger"
                    ? "#b00020"
                    : this.props.alertData?.className === "success"
                      ? "#ff"
                      : this.props.alertData?.className === "normal"
                        ? "#000"
                        : "#000",
              }}
            >
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g></g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <path
                        fill=""
                        d="M12.45 10.224L8.227 6l4.225-4.224a.853.853 0 0 0 0-1.207l-.403-.402a.853.853 0 0 0-1.206 0L6.617 4.39 2.393.167a.854.854 0 0 0-1.207 0L.783.569a.853.853 0 0 0 0 1.207L5.009 6 .783 10.225a.853.853 0 0 0 0 1.206l.403.403a.853.853 0 0 0 1.207 0L6.617 7.61l4.225 4.225a.853.853 0 0 0 1.206 0l.403-.402a.854.854 0 0 0 0-1.208z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>

          </a>
        </div>
        <div
          id="wrapper"
          className={this.state.width < 765 ? "mobileViewScreen" : ""}
        >
          {/* {this.state.width > 765 ? ( */}
          <>
            <SideBarComponent />
            {this.props.tabOpened === "newcontact" ? (
              <NewContact></NewContact>
            ) : this.props.tabOpened === "contactopen" ? (
              <ContactOpen editing={false}></ContactOpen>
            ) : this.props.tabOpened === "contactedit" ? (
              <ContactOpen editing={true}></ContactOpen>
            ) : this.props.tabOpened === "newmessage" ? (
              <NewMessage></NewMessage>
            ) : this.props.tabOpened === "UploadCsv" ? (
              <UploadCsv></UploadCsv>
            ) : this.props.tabOpened === "newgroup" ? (
              <NewGroup></NewGroup>
            ) : this.props.tabOpened === "viewgroup" ? (
              <EditGroup editing={false}></EditGroup>
            ) : this.props.tabOpened === "editgroup" ? (
              <EditGroup editing={true}></EditGroup>
            ) : this.props.tabOpened === "selectedContacts" ? (
              <SelectedContacts></SelectedContacts>
            ) :this.props.tabOpened === "systemUnavailable" ? (
              <SystemUnavailable></SystemUnavailable>
            ): this.props.tabOpened === "" ||  
              this.props.tabOpened === "chatpanel" ? (
              <ChatPanel></ChatPanel>
            ) : this.props.tabOpened === "default" ? (
              <DefaultScreen></DefaultScreen>
            ): null}
          </>
        </div>
      </div>
      </ErrorBoundary>
    );
  }
}

// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  // console.log("props.reduxReducer.currentTheme",props.reduxReducer.currentTheme);
  return {
    testData: props.reduxReducer.testName,
    tabOpened: props.reduxReducer.tabOpened,
    alertData: props.reduxReducer.alertData,
    currentTheme: props.reduxReducer.currentTheme,
    spinnerValue: props.reduxReducer.spinnerValue,
    whiteLabelBrand:props.reduxReducer.whiteLabelBrand,
    DisplayMode:props.reduxReducer.DisplayMode
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    testReduxPRops: (value: any) => {
      dispatch<any>(action.testRedux(value));
    },
    setTabOpened: (tab: string) => {
      dispatch<any>(action.setTabOpened(tab));
    },
    getUsableCreditForUser: () => {
      dispatch<any>(action.getUsableCreditForUser());
    },
    setCurrentTheme: (theme: string) => {
      dispatch<any>(action.setCurrentTheme(theme));
    },
    setLoggedInUser: (res: any) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
      {
        dispatch<any>(action.setLoggedInUser_V2(res));
      }
      else
      dispatch<any>(action.setLoggedInUser(res));

     
    },
    setAlertData: (alertData: any) => {
      dispatch<any>(action.setAlertData(alertData));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
