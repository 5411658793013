import _ from "lodash";
import { Reducer } from "redux";
import { ActionTypes } from "../actions/index";
export const reduxReducer: Reducer<any> = (state = { spinnerValue: false, newCount: 0 ,isFromSignalR: false,isFromAddContact:false,isFromUploadContact:0,contactUploadcount:0,isFormNewContact:false,}, action) => {
  switch (action.type) {
    
    case ActionTypes.GET_ALL: {
      return {
        ...state,
        testName: action.testName,
      };
    }
    case ActionTypes.SHOW_HIDE_Info: {
      return {
        ...state,
        showInfo: action.showInfo,
      };
    }
    case ActionTypes.Outlook_Contact_List: {
      return {
        ...state,
        OutlookContactList: action.outlookcontactlist,
      };
    }
    case ActionTypes.SHARED_INBOX_MESSAGE_COUNT: {
      return {
        ...state,
        shareMsgCount: action.sharedInboxMsgCount,
      };
    }
    case ActionTypes.Outlook_Contact_Folders_List: {
      return {
        ...state,
        outLookContactListbyFolders: action.OutLookContactListByFolders,
      };
    }
    case ActionTypes.UpdateOneOutlook_Contact_List: {
      return {
        ...state,
        OutlookContactList: state.OutlookContactList.map((s: any) => {
          return s.id === action.contact.id ? action.contact : s;
        }),
      };
    }
    case ActionTypes.Share_Point_Contact_List: {
      return {
        ...state,
        SharePointContactlist: action.SharePointContactList,
      };
    }
    case ActionTypes.UpdateOne_Share_Point_Contact: {
      const id = action.id;
      var indexInFirstList = state.SharePointContactlist.findIndex((s: any) => s.ContactName === id.listId);
      const indexInSecondList = state.SharePointContactlist[indexInFirstList].ContactList.findIndex((s: any) => s.id === id.itemId);
      const copyOfSharePointContactlist = JSON.parse(JSON.stringify(state.SharePointContactlist));
      copyOfSharePointContactlist[indexInFirstList].ContactList[indexInSecondList] = action.contact;
      return {
        ...state,
        SharePointContactlist: copyOfSharePointContactlist,
      };
    }
    case ActionTypes.Yak_Chat_Contact_List: {
      return {
        ...state,
        YakChatContactList: action.YakchatContactlist,
      };
    }
    case ActionTypes.UpdateOne_Yak_Chat_Contact_List: {

      const newYakChatContactList = state.YakChatContactList.map((c: any) =>
        c.ContactId === action.contact.ContactId ? action.contact : c
      );
      return {
        ...state,
        YakChatContactList: newYakChatContactList,
      };
    }
    case ActionTypes.AddOne_Yak_Chat_Contact_List: {
      return {
        ...state,
        YakChatContactList: [...state.YakChatContactList, action.contact],
      };
    }
    case ActionTypes.USER_LIST: {
      return {
        ...state,
        GetUsers: action.UserList,
      };
    }
    case ActionTypes.UpdateOne_USER_LIST: {
      return {
        ...state,
        GetUsers: state.GetUsers.map((s: any) => {
          return s.id === action.contact.id ? { s, ...action.contact } : s;
        }),
      };
    }
    case ActionTypes.setSelectedContacts_NewMessage: {
      return {
        ...state,
        selectedContactsNewMessage: action.selectedContactsNewMessage,
      };
    }
    case ActionTypes.setTabOpened_Main: {
      return {
        ...state,
        tabOpened: action.tabOpened,
      };
    }
    case ActionTypes.SetPrevious_Tab: {
      return {
        ...state,
        PreviousTab: action.PrevTab,
      };
    }
    case ActionTypes.setContactSelected_OpenContact: {
      return {
        ...state,
        contactSelected_OpenContact: action.contactSelected_OpenContact,
      };
    }
    case ActionTypes.setAlertData: {
      return {
        ...state,
        alertData: action.alertData,
      };
    }
    case ActionTypes.Yak_Chat_GroupEmail_List: {
      return {
        ...state,
        yakChatGroupData: action.groupList
      }
    }
    case ActionTypes.AddOne_Yak_Chat_GroupEmail_List: {
      const i = state.yakChatGroupData.findIndex((x: any) => x.Email === action.groupEmail)
      const newGroupData = state.yakChatGroupData.map((l: any, j: number) => {
        if (i === j)
          l.members.push(action.contact)
        return l;
      })
      return {
        ...state,
        yakChatGroupData: newGroupData
      }
    }
    case ActionTypes.usableCredit: {
      return {
        ...state,
        usableCredit: action.usableCredit
      }
    }
    case ActionTypes.setRooms: {
      return {
        ...state,
        roomData: action.roomData
      }
    }
    case ActionTypes.UPDATE_ROOM_FROM_WEB_SOCKET: {
      return {
        ...state,
        roomData: action.roomData
      }
    }
    case ActionTypes.setOpenMessageRoom: { 
      let newRoomData = JSON.parse(JSON.stringify(state.roomData));
      if (newRoomData?.length !== 0 && action.openMessageRoom !== undefined) {
        var checkConversationAlreadyExists = newRoomData.filter((x: any) => { return x.Id === action.openMessageRoom.Id });
        if (checkConversationAlreadyExists?.length === 0) {
          newRoomData.push(action.openMessageRoom);
        } else {
          newRoomData = newRoomData.map((x: any) => {
            if (x !== null) {
              if (x.Id === action.openMessageRoom.Id) {
                x.unreadMessages = 0;
              }
              return x
            } else {
              return x
            }
          })
        }
      }

      return {
        ...state,
        openMessageRoom: action.openMessageRoom,
        roomData: newRoomData,
      }
    }
    case ActionTypes.setAzureGroups: {
      return {
        ...state,
        azureGroups: action.azureGroups
      }
    }
    case ActionTypes.SHOW_GROUP_CONTACTS: {
      return {
        ...state,
        yakChatGroupContacts: action.yakChatGroupContacts
       
      }
       
    }
    
    case ActionTypes.CURRENT_PAGE_INDEX_FOR_ROOM_MESSAGES: {
      return {
        ...state,
        currentPageIndexForRoomMessages: action.currentPageIndexForRoomMessages
      }
    }
    case ActionTypes.PAGE_COUNT_PAGE_INDEX_FOR_ROOM_MESSAGES: {
      return {
        ...state,
        nextPageIndexForRoomMessages: action.nextPageIndexForRoomMessages
      }
    }
    case ActionTypes.CURRENT_PAGE_INDEX_FOR_INBOX: {
      return {
        ...state,
        currentPageIndexForInbox: action.currentPageIndexForInbox
      }
    }
    case ActionTypes.PAGE_COUNT_PAGE_INDEX_FOR_INBOX: {
      return {
        ...state,
        nextPageIndexForInbox: action.nextPageIndexForInbox
      }
    }
    case ActionTypes.PAGE_COUNT_PAGE_INDEX_FOR_YAKCHAT: {
      return {
        ...state,
        nextPageIndexForYakChat: action.nextPageIndexForYakChat
      }
    }
    case ActionTypes.CURRENT_PAGE_INDEX_FOR_YAKCHAT: {
      return {
        ...state,
        currentPageIndexForYakChat: action.currentPageIndexForYakChat
      }
    }
    case ActionTypes.setCurrentTheme: {
      return {
        ...state,
        currentTheme: action.theme
      }
    }
    case ActionTypes.setMFA: {
      return {
        ...state,
        MFAUSER: action.theme
      }
    }
    case ActionTypes.SET_REGISTER_THEME: {
      return {
           ...state,
        registerTheme: action.theme
      }
    }
    case ActionTypes.CHECK_REGISTER_USER: {
      return {
           ...state,
           checkUser: action.checkUser
      }
    }
    case ActionTypes.CHECK_BANDWIDTH_NUMBER: {
      return {
           ...state,
           isNumber: action.isNumber
      }
    }
    case ActionTypes.setLoggedInUser: {
      return {
        ...state,
        loggedInUser: action.loggedInUser
      }
    }
    case ActionTypes.insertMessageIntoRoom: {
      return {
        ...state,
        roomData: action.data.newRoomData,
        openMessageRoom: action.data.newOpenMessageRoom
      }
    }
    case ActionTypes.setSharedInboxData: {
      return {
        ...state,
        sharedInboxData: action.sharedInboxData,
      }
    }
    case ActionTypes.setSharedEmails: {
      return {
        ...state,
        setSharedEmails: action.setSharedEmails,
      }
    }
    case ActionTypes.setCurrentSelectedSharedEmailInboxData: {
      const sharedInboxData = JSON.parse(JSON.stringify(state.sharedInboxData));
      let archive: any;
      let personalInbox: any;
      sharedInboxData.other.splice(sharedInboxData.other.findIndex((x: any) => x.email === action.obj.email), 1);
      sharedInboxData.other.push(sharedInboxData.currentSelected);
      sharedInboxData.currentSelected = action.obj;
      if (action.obj.email !== "Closed") {
        archive = sharedInboxData.other.splice(sharedInboxData.other.findIndex((x: any) => x.email === "Closed"), 1);
        sharedInboxData.other.push(archive[0]);
      }
      if (action.obj.email !== "") {
        personalInbox = sharedInboxData.other.splice(sharedInboxData.other.findIndex((x: any) => x.email === ""), 1);
        sharedInboxData.other.unshift(personalInbox[0]);
      }
      return {
        ...state,
        sharedInboxData
      }
    }
    case ActionTypes.setCurrentSelectedSharedEmailInboxDataByMessage: {
      const sharedInboxData = JSON.parse(JSON.stringify(state.sharedInboxData));
      var index = sharedInboxData.other.findIndex((x: any) => x.email === action.obj.email);
      if (index !== -1) {
        sharedInboxData.other.splice(index, 1);
        sharedInboxData.other.push(sharedInboxData.currentSelected);
      }
      sharedInboxData.currentSelected = action.obj;
      return {
        ...state,
        sharedInboxData
      }
    }
    case ActionTypes.clearOpenMessageRoom: {
      return {
        ...state,
        openMessageRoom: null
      }
    }
    case "SET_SPINNER": {
      return {
        ...state,
        spinnerValue: action.spin
      }
    }
   
    case "MESSAGE_FROMSIGNALR": {
      return {
        ...state,
        isFromSignalR: action.isFromSignalR
      }
    }
    case "From_AddContact": {
      return {
        ...state,
        isFromAddContact: action.isFromAddContact
      }
    }
    case "From_NewContact": {
      return {
        ...state,
        isFormNewContact: action.isFormNewContact
      }
    }
    case "UPLOAD_CONTACT_INDICATOR": {
      return {
        ...state,
        isFromUploadContact: action.isFromUploadContact
      }
    }
    case "SET_SPINNER_SIDE_BAR": {
      return {
        ...state,
        spinnerSideBarValue: action.spinSideBar
      }
    }
    case "SET_NEW_GROUP": {
      return {
        ...state,
        openNewGroup: action.openNewGroup
      }
    }
    case "setGroupCurrentlyEditing": {
 
      return {
        ...state,
        groupCurrentlyEditing: action.group
      }
    }
    case "ACTUAL_CONTACTS_IN_LIST": {

      return {
        ...state,
        storeActualContactsInListReducer: action.storeActualContactsInListAction
      }
    }
    case ActionTypes.setYakchatLists: {
      return { ...state, yakchatLists: action.lists }
    }
    case "addNewListToLists": {
      return { ...state, yakchatLists: [...state.yakchatLists, action.newList] }
    }
    case "updateListOfContactsInThisList": {
      const newList = _.cloneDeep(state.yakchatLists);
       
      const listToBeChanged = newList.find((x: any) => x?.ListData?.Id === action.Id);
     
      // dispatch<any>({ type: "setGroupCurrentlyEditing", group });
      if( action.newContacts !== undefined && listToBeChanged !== undefined){
        if( listToBeChanged.ListData !== undefined){
          listToBeChanged.ListData.localContacts = action?.newContacts;
        }else{
          listToBeChanged.localContacts = action?.newContacts;
        }
        listToBeChanged.ListData.localContacts = action?.newContacts;
        listToBeChanged.contactCount = action.newContacts?.$values?.length;
      }  
      if (action.groupEmail !== undefined  && listToBeChanged !== undefined) {
      
        if( listToBeChanged.ListData !== undefined){
          listToBeChanged.ListData.GroupEmail = action?.groupEmail;
        }else{
          listToBeChanged.GroupEmail = action?.groupEmail;
        }
        
      }
      return { ...state, yakchatLists: newList }
    }
    case "setSelectedContac.tsWithCtrl": {
      return {
        ...state, selectedContactsWithCtrl: action.payload
      }
    }
    case "setInfoPanelOpened": {
      return {
        ...state, infoPanelOpened: action.payload
      }
    }
    case ActionTypes.setYakChatUsers: {
      return {
        ...state, yakChatUsers: action.payload
      }
    }
    case ActionTypes.SetNewContactData: {
      return {
        ...state,
        NewContactData: action.NewContactData,
      };
    }
    case ActionTypes.NewUnreadMessageCount: {
      return {
        ...state,
        newmessagecount: action.unreadMessagecount,
      };
    }
    case ActionTypes.NewUnreadGroupMessage: {
      return {
        ...state,
        NewGroupUnreadMessage: action.UnreadGroupMessage,
      };
    }
    case ActionTypes.OwnerCircleIndicator: {
      return {
        ...state,
        MessageCircleIndicator: action.CircleIndicator,
      };
    }
    case ActionTypes.SetServicePlanId: {
      return {
        ...state,
        ServicePlanId: action.SetServicePlanId,
      }
    }
    case ActionTypes.SetAplhaBetGroupEmail: {
      return {
        ...state,
        Alphabetgroupemail: action.SetGroupemail,
      }
    }
    case ActionTypes.SetRoomsForAlphabetGroupemail: {
      return {
        ...state,
        AlphabetgroupemailRoomData: action.AplhaBetGrouproomData,
      }
    }
    case ActionTypes.SET_DEFAULT_CONTACT_LIST: {
      return {
        ...state,
        defaultContactList: action.defaultContactList,
      }
    }
    case ActionTypes.SET_CONTACT_SOURCEOUTLOOK: {
      return {
        ...state,
        contactSourceOutlook: action.contactSourceOutlook,
      }
    }
    case ActionTypes.SET_DISPLAY_MODE: {
      return {
        ...state,
        displayMode: action.displayMode,
      }
    }
    case ActionTypes.SET_CONTACT_SOURCESHAREPOINT: {

      return {
        ...state,
        contactSource: action.contactSource,
      }
    }
    case ActionTypes.SET_CONTACT_SOURCEACTIVEDIRECTORY: {
      return {
        ...state,
        contactSourceDirectory: action.contactSourceDirectory,
      }
    }
    case "MOBILE_MESSAGE_SECTION": {
      return {
        ...state,
        classAdded: action.classAdded
      }
    }
    case "MESSAGE_SELECTED_CONTACTS_COUNT": {
      return {
        ...state,
        newCount: action.newCount
      }
    }
    case "SAS_TOKEN": {
      return {
        ...state,
        sasToken: action.sasToken
      }

    }
    case "emptyChatInput": {
      return {
        ...state,
        isChatInput: action.isChatInput
      }

    }

    case "GET_USER_BY_EMAIL": {
      return {
        ...state,
        userDetails: action.userDetails
      }
    }
    case ActionTypes.setWhiteLabelBrandThem: {
      debugger;
      return {
        ...state,
        whiteLabelBrand: action.theme
      }
    }
    case "WHITE_LABLE_BRAND_THEM": {
      debugger;
      return {
        ...state,
        whiteLabelBrand: action.whiteLabelBrand
      }
    }
    case "SET_USER_DETAILS": {
      return {
        ...state,
        user: action.user
      }
    }
    case "SAVE_YAKCHAT_SETTINGS": {
      return {
        ...state,
        checkedvalue: action.checkedvalue
      }
    }
    case "COUNT_CONTACT_MESSAGE": {
      return {
        ...state,
        counts: action.counts
      }
    }
    case "OPEN_COLLAPSE": {
      return {
        ...state,
        opencollapse: action.opencollapse
      }
    }
    case "OPEN_REGISTRATION_PAGE": {
      return {
        ...state,
        openPage: action.openPage
      }
    }
    case "OPEN_TRIAL_PAGE": {
      return {
        ...state,
        trailExpier: action.trailExpier
      }
    }
    case "NEW_BANDWIDTH_USER": {
      return {
        ...state,
        isUsersBandwidth: action.isUsersBandwidth
      }
    }
    
    case "openTrialExpiredMessage": {
      return {
        ...state,
        trailExpier: action.traiMessage
      }
    }
    case "OPEN_SHARED_ERROR": {
      return {
        ...state,
        sharedError: action.sharedError
      }  
    } 
     case "OPEN_SHARED_ERROR": {
      return {
        ...state,
        sharedError: action.sharedError
      }  
    }
    case "OPEN_LOGIN_CONSENT": {
      return {
        ...state,
        loginValue: action.loginValue
      }  
    }
    case "ACCESS_TOKEN_REGISTER": {
      return {
        ...state,
        accessTokenApi: action.accessTokenApi
      }
    }
    case "Expire_On": {
      return {
        ...state,
        expireOn: action.expireOn
      }
    }
    case "Is_Paused": {
      return {
        ...state,
        isPaused: action.isPaused
      }
    }
    case "setTextToEmpty":{
      return{
        ...state,
        setTextToEmpty:action.setTextToEmpty
      }
    }
    case "Retry_Send": {
      return {
        ...state,
        retrysend: action.retrysend
      }
    }
    // case "webexToken": {
    //   return {
    //     ...state,
    //     webexToken: action.webexToken
    //   }
    // }case "webexRToken": {
    //   return {
    //     ...state,
    //     webexRToken: action.webexRToken
    //   }
    // }case "webexC": {
    //   return {
    //     ...state,
    //     webexC: action.webexC
    //   }
    // }
    case "deepLinkRoom":{
      return{
        ...state,
        deepLinkRoom:action.deepLinkRoom
      }
    }
    case "TranslationLanguages":{
      return{
        ...state,
        TranslationLanguages:action.TranslationLanguages
      }
    }
    case "UserTransLang":{
      return{
        ...state,
        UserTransLang:action.UserTransLang
      }
    }
    case "selectedroom":{
      return{
        ...state,
        selectedroom:action.selectedroom
      }
    }
    case "companyVersion": {
      return {
        ...state,
        companyVersion: action.companyVersion
      }
    }
    case "USER_DETAILS": {
      return {
        ...state,
        userDetails_registeration: action.userDetails
      }
    }
    case "COMPANY_DETAILS": {
      return {
        ...state,
        companyDetails_registeration: action.companyDetails
      }
    }
    case "SUBSCRIPTION_DETAILS": {
      return {
        ...state,
        subscriptionDetails_registeration: action.subscriptionDetails
      }
    }
    default:
      return state;
  }
};
