import React from "react";
import { connect, } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Logo1 from "../images/login_logo.png";
import sponser2 from "../images/commsgroup-alt-logo 2.png"
import logo from "../images/CT Text Logo-2.png";
import logoMobex from "../images/mobexlogo.svg";
import logoBerkshire from "../images/berkshirelogo.svg";
import logoTeligentip from "../images/teligentiplogo.svg";
import logoOnet from "../images/OneNetLogo.svg";
import logoSetup from "../images/setupLogo.svg";
import logoOneuc from "../images/OneucDesktopLogo.svg";


class Logo extends React.Component<any, any> {
    render() {
        return (
            <>
                <div className="login_logo text-center" >
                    {this.props.registerTheme === "calltower" ? <img src={logo} /> : this.props.registerTheme === "commsChoice" ? <img src={sponser2} /> 
                    :this.props.registerTheme === "onenet" ? <img src={logoOnet} /> 
                    :this.props.registerTheme === "mobex" ? <img src={logoMobex} /> 
                    :this.props.registerTheme === "berkshire" ? <img src={logoBerkshire} /> 
                    :this.props.registerTheme === "teligentip" ? <img src={logoTeligentip} /> 
                    :this.props.registerTheme === "oneuc" ? <img src={logoOneuc} /> 
                    : this.props.registerTheme === "setup4" ? <img src={logoSetup} />
                    : <img src={Logo1} />}
                </div>
            </>
        );
    }
}
const mapStateToProps = (props: any) => {
    return {
        registerTheme: props.reduxReducer.registerTheme,    
    };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
    return {
        
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Logo);