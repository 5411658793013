import * as React from "react";
import { connect, connectAdvanced } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as actions from "../../../redux/actions/actions";
import OutlookContactComponent from "../OutlookContacts/OutlookFolders";

class OutlookParentComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      calledfrom: "",
      ClickOutlookContactsEvent: this.props.getOutlookContactsfolders,
      OutlookContactFolderWithContactList: [],
      outlookcontactlist: [],
    };
  }
  // componentDidMount() {
  //   // this.props.getOutlookContactFoldersByContactList();
  //   // this.props.getOutLookContactlist(1);
  // }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.outlookContactFoldersByContactList !==
      prevState.OutlookContactFolderWithContactList ||
      nextProps.getOutlookContactsfolders !==
      prevState.ClickOutlookContactsEvent ||
      nextProps.OutlookContactList !== prevState.outlookcontactlist
    ) {
      return {
        OutlookContactFolderWithContactList:
          nextProps.outlookContactFoldersByContactList,
        ClickOutlookContactsEvent: nextProps.getOutlookContactsfolders,
        outlookcontactlist: nextProps.OutlookContactList,
      };
    } else {
      return null;
    }
  }



  public render() {
    {
      if (this.state.outlookcontactlist) {
        this.state.outlookcontactlist?.sort((a: any, b: any) => {
         return (a.displayName || "zzzz").localeCompare(b.displayName || "zzzz");
        })
      }
    }
    return (
      <React.Fragment>
        {this.state.OutlookContactFolderWithContactList !== undefined ||
          this.state.outlookcontactlist !== undefined ? (
          <OutlookContactComponent
            searchFilter={this.props.searchFilter}
            tabOpened={this.props.tabOpened}
            ContactFolders={this.state.OutlookContactFolderWithContactList}
            calledFrom={"Outlook"}
            Status={this.state.ClickOutlookContactsEvent}
            ContactDataList={this.state.outlookcontactlist}
          />
        ) : null}
      </React.Fragment>
    );
  }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {
    outlookContactFoldersByContactList:
      props.reduxReducer.outLookContactListbyFolders,
    OutlookContactList: props.reduxReducer.OutlookContactList,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    getOutlookContactFoldersByContactList: () => {
      dispatch<any>(actions.getOutLookFolderstlist());
    },
    getOutLookContactlist: (pageIndex: number) => {
      dispatch<any>(actions.getOutLookContactlist(pageIndex));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutlookParentComponent);
