import React, { useState, useRef, useEffect, ReactElement } from "react";
import { connect } from "react-redux";
import Bubble from "./Bubble";
import * as action from "../../../redux/actions/actions";
import { ThunkDispatch } from "redux-thunk";
import isSameDay from "date-fns/isSameDay";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import formatISO from "date-fns/formatISO";
import Skeleton from "./Skeleton";
import { store } from "../../../..";
import { addMinutes } from "date-fns";

const Feed = (props: any) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [mostRecent, setMostRecent] = useState<any[] | null>(null);
  const [messageArray, setMessageArray] = useState<any[]>([]);
  const [showSkeleton, setSkeleton] = useState(false);

  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const scrollDown = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function onScroll() {
      //handle scroll positioning
      if (scrollContainer.current!.scrollTop > scrollPosition) {
        return;
      } else {
        //more loading
        if (scrollContainer.current!.scrollTop <= 1000) {
          let { currentPageIndexForRoomMessage, nextPageIndexForRoomMessages } =
            props;

          let hasNext =
            currentPageIndexForRoomMessage !== nextPageIndexForRoomMessages;
          if (hasNext) {
            setMostRecent(props.currentMessageRoom.Messages?.$values);

            //show skeleton
            setSkeleton(true);
            //save scroll position
            setScrollPosition(scrollContainer.current!.scrollTop);
            var pageCount = props.currentPageIndexForRoomMessage + 1;

            //fetch more data

            props.getRoomByRoomId(
              props.currentSelectedDropDownValue.email,
              props.currentMessageRoom,
              pageCount
            );
          }
        }
      }
    }

    //add scroll event to call onScroll function
    scrollContainer.current!.addEventListener("scroll", onScroll, {
      passive: true,
    });
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scrollContainer.current!.removeEventListener("scroll", onScroll);
    };
  }, [setScrollPosition, props, scrollPosition]);

  useEffect(() => {
    // Manage scroll motions
    if (props.currentMessageRoom && props.currentMessageRoom?.Id !== 0) {
      let { currentPageIndexForRoomMessage } = props;
      //check and handle first page
      if (currentPageIndexForRoomMessage === 1) {

        //setState to have the most recent message for first page
        setMostRecent(props?.currentMessageRoom?.Messages?.$values);
        // Move scroll to bottom of page
        scrollDown.current!.scrollIntoView({ block: "end" });
      } else {

        // Add scroll area for other pages
        if (
          props.currentMessageRoom?.Messages?.$values?.length > mostRecent!?.length
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          let newScrollTop = 0;
          for (
            let i = 0;
            i <=
            props.currentMessageRoom?.Messages?.$values?.length -
              mostRecent!.length;
            i++
          ) {
            let el = scrollContainer.current!.children[i] as HTMLElement;
            if (el !== undefined) 

             {newScrollTop += el.offsetHeight;}
          }
          setSkeleton(false);
          scrollContainer.current!.scrollTop = newScrollTop + scrollPosition;
        }
      }
      // debugger;
      
           if ((props.currentMessageRoom && props.currentMessageRoom?.Id !== 0) && (props.roomData && props.roomData.length > 0) )
            {
              
              var unread =  props.roomData.find((ele: any) => {
                return ele.Id === props.currentMessageRoom.Id;
              })
              if (unread && unread?.UnreadMsgCount > 0)
              {
                debugger;
                const newList = props.roomData.map((item: any) => {
                  if (item.Id === props.currentMessageRoom.Id) {
                    const updatedItem = { ...item, UnreadMsgCount: 0 };
                    return updatedItem;
                  }
                  return item;
                });

                props.setRoomData(newList);
                props.getRoomByRoomId(props.currentSelectedDropDownValue.email,props.currentMessageRoom,1);
              }
            }
            
    }
  }, [props, mostRecent, scrollPosition]);

  //process presentation of messages
  // https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
  //1. find unique dates

  const tzOffset = new Date().getTimezoneOffset();

  // added by Maksud
  const getDateSplit = (date: any, del: any) => {
    let dstring = date.replace("-", del).replace("-", del);
    return dstring;
  };
  const getUniqueDays = (messages: any[]) => {
    const mapped = messages.map((msg: any) => {
      // return getDateSplit(msg.TimeStamp.split('T')[0],'/');//formatISO(new Date(msg.TimeStamp), {representation: "date", });
      return format(new Date(msg.TimeStamp), "yyyy/MM/dd");
    });
    return Array.from(new Set(mapped));
  };
  useEffect(() => {
    if (props.currentMessageRoom &&props.currentMessageRoom?.Id !== 0 && props?.currentMessageRoom?.Messages?.$values !== undefined ) {
      const incomingMsg = [...props?.currentMessageRoom?.Messages?.$values];

      const uniqueDays = getUniqueDays(incomingMsg);
      const newMessages: any[] = [];
      incomingMsg.forEach((msg: any) => {
        // https://www.npmjs.com/package/date-fns-tz
        //https://www.heady.io/blog/javascript-handle-date-in-any-timezone-with-daylight-saving-check

        // let dateString = getDateSplit(msg.TimeStamp.split('T')[0],'/');
        let dateString = format(new Date(msg.TimeStamp), "yyyy/MM/dd");
        uniqueDays.forEach((uniqueDay: any) => {
          if (
            isSameDay(new Date(uniqueDay), new Date(msg.TimeStamp)) &&
            newMessages.indexOf(dateString) === -1
          ) {
            newMessages.push(dateString);
          }
        });
        newMessages.push(msg);
      });
      setMessageArray(newMessages);
    }
  }, [props]);

  //2. display chatfeeds
  const displayChatFeed = () => {
    //debugger;
    if (messageArray.length > 0) {
      const returnValue = messageArray!.map((msg: any, index: number) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let element: ReactElement;
        if (typeof msg !== "string") {
          element = (
            <div
              className={`bubble_container ${
                msg.Direction === 10
                  ? "justification_end"
                  : "justification_start"
              }`}
              key={index}
            >
              <div className="text-right">
                {props.ServicePlanId === 6 ||
                props.ServicePlanId === 7 ||
                props.ServicePlanId === 4 ? (
                  msg.Direction === 10 ? (
                    <div className="sendingUser">{msg?.SendingUserName}</div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <Bubble
                  message={msg}
                  token={props.sasToken}
                  currentThem={props.whiteLabelBrand}
                  displayMode={props.displayMode}
                />
              </div>
            </div>
          );
        } else {
          let dateString = format(new Date(msg), "eeee, MMMM dd yyyy");
          let today = isToday(new Date(msg));
          let yesterday = isYesterday(new Date(msg));
          element = (
            <div className="chat_data" key={index}>
              {today ? "Today" : yesterday ? "Yesterday" : dateString}
            </div>
          );
        }
        return element;
      });
      return returnValue;
    }
  };

  return (
    <div ref={scrollContainer} className="chat_scroll">
      {showSkeleton ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "90%",
            marginInline: "auto",
          }}
        >
          <Skeleton type={`avatar`} />
          <Skeleton type={`text`} />
          <Skeleton type={`avatar`} />
          <Skeleton type={`text`} />
          <Skeleton type={`avatar`} />
          <Skeleton type={`text`} />
        </div>
      ) : null}
      {displayChatFeed()}
      <div ref={scrollDown}></div>
    </div>
  );
};

const mapStateToProps = (props: any) => {
  let companyVersion = store.getState().reduxReducer.companyVersion;
  return {
    currentSelectedDropDownValue: props.reduxReducer.sharedInboxData?.currentSelected,
    testData: props.reduxReducer.testName,
    tabOpened: props.reduxReducer.tabOpened,
    yakChatGroupData: props.reduxReducer.yakChatGroupData,
    currentMessageRoom: props.reduxReducer.openMessageRoom,
    loggedInUser: props.reduxReducer.loggedInUser,
    infoPanelOpened: props.reduxReducer.infoPanelOpened,
    contactSelected_OpenContact:props.reduxReducer.contactSelected_OpenContact || [],
    currentPageIndexForRoomMessage:props.reduxReducer.currentPageIndexForRoomMessages, //current page
    nextPageIndexForRoomMessages:props.reduxReducer.nextPageIndexForRoomMessages, //next page
    loggedInUserDefaultCountryCode:companyVersion !== 2 ? props.reduxReducer.loggedInUser.DefaultCountryCode:
    
	store.getState().reduxReducer.loggedInUser.inboxes.$values.filter((item: any) =>item.InboxId ==props.reduxReducer.sharedInboxData?.currentSelected.email)[0] != undefined? 
    store.getState().reduxReducer.loggedInUser.inboxes.$values.filter((item: any) =>item.InboxId ==props.reduxReducer.sharedInboxData?.currentSelected.email)[0].DefaultCountryCode: 1,
    sasToken: props.reduxReducer.sasToken,
    isChatInput: props.reduxReducer.isChatInput,
    whiteLabelBrand: props.reduxReducer.whiteLabelBrand,
    displayMode: props.reduxReducer.displayMode,
    ServicePlanId: props.reduxReducer.ServicePlanId,
    roomData: props.reduxReducer.roomData,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    setRoomData: (roomData: any) => {
      dispatch<any>(action.setRoomsAction(roomData));
    },
    getRoomByRoomId: async (userEmail: any, room: any, pageIndex: any) => {
      if (room?.Participants !== undefined) {
        userEmail = room.Archived === true ? room.CreatorEmail : userEmail;
        let companyVersion = store.getState().reduxReducer.companyVersion;
        if (companyVersion === 2) {
         dispatch<any>(action.getPagingMessagesByRoomId_V2(room.Id, pageIndex, "ChatPanel"));        
		} else {
           dispatch<any>(action.getPagingMessagesByRoomId(userEmail,room.Id,pageIndex,"ChatPanel"));
         }
      }
    },
    SaveTransltion: async (roomId: any, language: any, showalert: any) => {
      debugger;
      dispatch<any>(action.SaveTransltion(roomId, language, showalert));
      dispatch<any>(action.UserTransLang(language));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Feed);
