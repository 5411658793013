import React from "react";
import AuthService from "../../../../Config/msalConfig";
import OutlookParent from "../../ContactsComponent/OutlookContacts/OutlookParentComponent";
import UserParent from "../../UsersComponent/UserParentComponent";
import SharePointParent from "../../ContactsComponent/SharePointContacts/SharePointParentComponent";
import YakChatParent from "../../ContactsComponent/YakChatContacts/YakChatParentComponent";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions/actions";
import * as commonMethod from "../../../commonMethods";
import { Card } from "@material-ui/core";
import { Dropdown } from "antd";
import moment from "moment";
import { ReactComponent as PlusSvg } from "../../../images/plus.svg";
import { ReactComponent as Archive } from "../../../images/archive.svg";
import { ReactComponent as GroupInbox } from "../../../images/usr_grp2.svg";
import { ReactComponent as SettingIcon } from "../../../images/setting.svg";
import { ReactComponent as GroupLogo } from "../../../images/grp_icon.svg";
import { ReactComponent as DownArrow } from "../../../images/down_arrow.svg";
import { ReactComponent as UserIcon } from "../../../images/usr_icn.svg";
import { ReactComponent as GrayAvtar } from "../../../images/gray-avtar.svg";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { ReactComponent as PhoneBook } from "../../../images/contacts.svg";
import { ReactComponent as Search } from "../../../images/search.svg";
import ContactFolders from "./contactFolders"
import { Spin } from "antd";
import { store } from "../../../..";
import * as contactservice from "../../../services/Contacts.Service";
import Select from 'react-select';

const antIcon = (
  <Loading3QuartersOutlined
    style={{ fontSize: 40, color: "var(--primary)", backgroundColor: "none" }}
    spin
  />
);

class SideBarComponent extends React.Component<any, any> {
  private myRef: any;

  private roomRef: any;
  constructor(props: any, state: any) {
    super(props);
    this.myRef = React.createRef();
    this.roomRef = React.createRef();
    this.state = {
      sharedInboxMsg: [],
      roomDataState: [],
      showOutlookcontactfolders: false,
      azureusers: false,
      sharepointcontact: false,
      YakchatContact: true,
      yakChatContactList: [],
      currentActiveDropdown: " ",
      loggedInUserName: this.props.loggedInUser?.account?.name,
      HideContactDiv: false,
      intialsLetter: "",
      pageindex: 1,
      totalPagesYakChatCount: 1,
      searchFilter: "",
      dialogBox: "",
      spinnerValueState: false,
      settings: {
        outlookContacts: false,
        sharepointContacts: false,
        userDir: false,
        sharepointUrl: "",
        WhiteLabelBrand: this.props.whiteLabelBrand,
        userContactDetails: this.props.userDetails,
    
      },
      searchMessageQuery: "",
      displaySharedInboxDropdown: false,
      displayContactsDropdown: false,
      currentPageIndexForInbox: 0,
      nextPageIndexForInbox: 0,
      classAdded: this.props.classAdded,
      newCount: "",
      checkedvalue: "",
      contactSourceOutlook: false,
      contactSourceDirectory: false,
      contactSource: false,
     
    };
  }
 
  ClickYakchatContact() {
    document.getElementById("contacts_list_div")!.scrollTop = 0;
    this.setState({
      YakchatContact: true, currentActiveDropdown: "Contacts", HideContactDiv: true,
      azureusers: false,
      sharepointcontact: false,
      showOutlookcontactfolders: false,
      searchFilter: "",
    });
    if (this.state.yakChatContactList === undefined) {
      //debugger;
      this.props.getcontactlist(1);
      // this.props.getYakChatGroupData(1);
      this.props.getYakChatLists();
    }
  }
  getOutlookContactsFolders() {
    document.getElementById("contacts_list_div")!.scrollTop = 0;
    this.setState({
      showOutlookcontactfolders: true,
      YakchatContact: false,
      azureusers: false,
      sharepointcontact: false,
      currentActiveDropdown: "Outlook",
      HideContactDiv: true,
      searchFilter: "",
    });
  }
  GetUsers() {
    document.getElementById("contacts_list_div")!.scrollTop = 0;
    this.setState({
      azureusers: true,
      YakchatContact: false,
      showOutlookcontactfolders: false,
      sharepointcontact: false,
      currentActiveDropdown: "User Directory",
      HideContactDiv: true,
      searchFilter: "",
    });
  }
  getSharepointContacts() {
    document.getElementById("contacts_list_div")!.scrollTop = 0;
    this.setState({
      sharepointcontact: true,
      YakchatContact: false,
      azureusers: false,
      showOutlookcontactfolders: false,
      currentActiveDropdown: "SharePoint",
      HideContactDiv: true,
      searchFilter: "",
    });
    this.props.getsharepointcontacts(1);
  }
  static getDerivedStateFromProps = (nextProps: any, prevState: any) => {
    if (nextProps.currentPageIndexForInbox !== prevState.currentPageIndexForInbox ||
      nextProps.nextPageIndexForInbox !== prevState.nextPageIndexForInbox ||
      nextProps.sharedInboxMsgCount !== prevState.sharedInboxMsg ||
      nextProps.roomData !== prevState.roomDataState ||
      nextProps.yakChatContactList !== prevState.yakChatContactList ||
      nextProps.spinnerValue !== prevState.spinnerValueState ||
      nextProps.defaultContactList !== prevState.defaultContactList ||
      nextProps.currentPageIndexForYakChat !== prevState.pageindex ||
      nextProps.nextPageIndexForYakChat !== prevState.totalPagesYakChatCount ||
      nextProps.contactSource !== prevState.contactSource ||
      nextProps.contactSourceOutlook !== prevState.contactSourceOutlook ||
      nextProps.contactSourceDirectory !== prevState.contactSourceDirectory ||
      nextProps.classAdded !== prevState.classAdded ||
      nextProps.newCount !== prevState.newCount ||
      nextProps.whiteLabelBrand !== prevState.whiteLabelBrand ||
      nextProps.checkedvalue !== prevState.checkedvalue


    ) {
      return {
        yakChatContactList: nextProps.yakChatContactList,
        currentPageIndexForInbox: nextProps.currentPageIndexForInbox,
        nextPageIndexForInbox: nextProps.nextPageIndexForInbox,
        sharedInboxMsg: nextProps.sharedInboxMsgCount,
        roomDataState: nextProps.roomData,
        spinnerValueState: nextProps.spinnerValue,
        defaultContactList: nextProps.defaultContactList,
        pageindex: nextProps.currentPageIndexForYakChat,
        totalPagesYakChatCount: nextProps.nextPageIndexForYakChat,
        contactSource: nextProps.contactSource,
        contactSourceOutlook: nextProps.contactSourceOutlook,
        contactSourceDirectory: nextProps.contactSourceDirectory,
        newCount: nextProps.newCount,
        whiteLabelBrand: nextProps.whiteLabelBrand,
        classAdded: nextProps.classAdded,
        checkedvalue: nextProps.checkedvalue
      };
    } else return null;
  };
  clickEventListener: any;
  onClick = (e: any) => {
    if (!e.composedPath().includes(document.getElementById("messagesGroupInboxDropdownDiv")) && !e.composedPath().includes(document.getElementById("contactsGroupInboxDropdownDiv"))
    ) {
      this.setState({ displaySharedInboxDropdown: false, displayContactsDropdown: false, });
    } else if (e.composedPath().includes(document.getElementById("messagesGroupInboxDropdownDiv"))) {
      this.setState({ displaySharedInboxDropdown: !this.state.displaySharedInboxDropdown });
    } else if (e.composedPath().includes(document.getElementById("contactsGroupInboxDropdownDiv"))) {
      this.setState({ displayContactsDropdown: !this.state.displayContactsDropdown, });
    }
    const elem = document.getElementById("dropdownMenuList-Setting");
    if (e.composedPath().includes(elem)) {
      if (!elem?.classList.contains("show")) elem!.classList.add("show");
    } else {
      if (elem?.classList.contains("show")) elem!.classList.remove("show");
    }
  };
  componentDidMount() {
    this.clickEventListener = window.addEventListener("click", this.onClick);
    this.myRef.current.scrollTo({ top: 10, behaviour: "smooth" });
    this.setState({
      intialsLetter: commonMethod.generateFirstLastNameIntials(this.props.loggedInUser?.account?.name),
    });
  }
  componentWillUnmount() {
    window.removeEventListener("click", this.onClick);
    window.addEventListener('scroll', function () {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // Show loading spinner and make fetch request to api
      }
    });
  }
  handelSharepointSearch = () => {
  }
  searchTimeout: any;
  doSearch = (PageIndex: number) => {
    if (this.state.searchFilter === "") {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      if (this.state.showOutlookcontactfolders) {
        this.props.getOutlookContactFoldersByContactList();
        this.props.getOutLookContactlist(1);
      } else if (this.state.YakchatContact) {
        this.NextPageList(1);
      } else if (this.state.azureusers) {
        this.props.GetUsers();
        this.props.listAzureGroups();
      }
      return;
    }
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    if (this.state.showOutlookcontactfolders) {
      this.searchTimeout = setTimeout(() => {
        this.props.searchOutLookFolderstlist(this.state.searchFilter);
        this.props.searchoutlookContactList(this.state.searchFilter, PageIndex);
      }, 300);
    } else if (this.state.YakchatContact) {
      this.searchTimeout = setTimeout(() => {
        this.props.searchContactList(PageIndex, this.state.searchFilter?.startsWith("+") ? this.state.searchFilter.substr(1, this.state.searchFilter?.length) : this.state.searchFilter);
      }, 300);
    } else if (this.state.azureusers) {
      this.searchTimeout = setTimeout(() => { this.props.searchAzureUsers(this.state.searchFilter); }, 300);
    } else if (this.state.sharepointcontact) {
      this.props.searchSharepointContactsItems(PageIndex, this.state.searchFilter?.startsWith("+") ? this.state.searchFilter.substr(1, this.state.searchFilter?.length) : this.state.searchFilter);

    }
  };
  NextPageList(PageIndex: any) {
    if (this.state.currentActiveDropdown === "Contacts") {
      this.props.getcontactlist(PageIndex);
    }
    else if (this.state.currentActiveDropdown === "Outlook") {
      this.props.getOutLookContactlist(PageIndex);
    }
  }
  
  handleChange = (e: any) => {
    let target = e.target
    let name = target.name
    let value = Array.from(target.selectedOptions, (option: any) => option.value);
    this.props.setDefaultContactList(value);
  }
  handleSearchConversation = (e: any) => {
    var groupEmail;
    this.setState({ searchMessageQuery: e.target.value });
    if (this.props.ServicePlanId === 3) {
      groupEmail = "";
    } else {
      groupEmail = this.props.sharedInboxData.currentSelected.email;
    }
    var archived = this.props.sharedInboxData.currentSelected.email === "Closed" ? true : false;
    let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
          var inboxId= this.props.sharedInboxData.currentSelected.email ;
          this.props.searchConversation_V2(archived, e.target.value, archived === true ? "''" : groupEmail, this.props.loggedInUser.Email,inboxId)
        }
        else
        {
    this.props.searchConversation(archived, e.target.value, archived === true ? "''" : groupEmail, this.props.loggedInUser.Email)
        }
  }

  handleContactScroll = (element: any) => {
    if (this.state.currentActiveDropdown === "Contacts") {
      const bottom = element.target.scrollHeight <= (element.target.scrollTop + element.target.clientHeight + 5);
      var Scroll = element.target.scrollTop;
      var ScrollType = Scroll % 10;
      var CountPageindex = this.state.pageindex;
      if (bottom && this.state.spinnerValueState === false) {
        if (this.state.searchFilter && this.state.searchFilter?.length > 0) {
          let totalPage = localStorage.getItem('YakchaTContactPageCount') || "";
          let PageCount = parseInt(totalPage);
          if (!PageCount) PageCount = 1;
          if (PageCount >= CountPageindex) {
            this.doSearch(CountPageindex);
          }
        }
        else {
          let PageCount = this.state.totalPagesYakChatCount;
          if (!PageCount) PageCount = 1;
          if (PageCount >= CountPageindex) {
            this.NextPageList(CountPageindex);
          }
        }
      }
    }
    else if (this.state.currentActiveDropdown === "SharePoint") {
      const bottom = element.target.scrollHeight <= (element.target.scrollTop + element.target.clientHeight + 5);
      if (bottom) {
        var Scroll = element.target.scrollTop;
        var ScrollType = Scroll % 10;
        var CountPageindexs = this.state.scroolCount;
        CountPageindexs = (this.props.sharepointcontactsdata[0].ContactList?.length / 20) + 1;
        let PageCounts = 1;
        if (!PageCounts) PageCounts = 1;
        this.props.getSharePointContactsPageIndex(CountPageindexs);
        this.props.setSpinnerInSideBar(true);
        this.setState({ scroolCount: this.state.scroolCount + 1 });
        this.setState({ scroolCount: CountPageindexs });
      }
    }
  };
  handleRoomScroll = async (element: any) => {
    const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
    const bottom = ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight);
    const bottom1 = element.target.scrollHeight <= (element.target.scrollTop + element.target.clientHeight + 5);
    const bottom2 = element.target.scrollHeight <= (element.target.scrollTop + element.target.clientHeight + 2);
    var currentPageIndex = this.state.currentPageIndexForInbox + 1;
    this.setState({ currentPageIndexForInbox: currentPageIndex });
    if (bottom1) {
      //debugger;
      if (currentPageIndex <= this.state.nextPageIndexForInbox) {
        let companyVersion=store.getState().reduxReducer.companyVersion;
        let email= this.props.loggedInUser.Email;
        var groupEmail = this.props.sharedInboxData.currentSelected.email === this.props.loggedInUser.Email ? "''" : this.props.sharedInboxData.currentSelected.email;
       
        if(companyVersion===2){
          //debugger;
          email=this.props.sharedInboxData.currentSelected.email=== "Closed"?this.props.currentMessageRoom.InboxId :this.props.sharedInboxData.currentSelected.email ;
           groupEmail = this.props.sharedInboxData.currentSelected.email === this.props.loggedInUser.user.Email ? "''" : this.props.sharedInboxData.currentSelected.email;
            }
        this.props.getPersonalInbox(currentPageIndex, email, false, groupEmail, "SideBarComponent", "");
        }
      
    }
    var Scroll = element.target.scrollTop;
    var ScrollType = Scroll % 10;
  };
  saveSettings = () => {
    let checkedvalue: string = "";
    let isSharePoint: string = "";
    let TranslationLanguage:string="";
    if (!this.state.contactSourceOutlook && !this.state.contactSourceDirectory)
      checkedvalue = "localcontacts";
    else if (this.state.contactSourceOutlook && !this.state.contactSourceDirectory)
      checkedvalue = "contacts";
    else if (!this.state.contactSourceOutlook && this.state.contactSourceDirectory)
      checkedvalue = "users";
    else if (this.state.contactSourceOutlook && this.state.contactSourceDirectory)
      checkedvalue = "contacts%26users";
    if (this.state.contactSource) {
      isSharePoint = "isSharePoint"
    }
    //debugger;
   
    this.props.saveYakChatSettings(checkedvalue, isSharePoint);
    this.setState({ dialogBox: "" });
  };
  handleConversationClick = (message: any, e: any) => {
    e.persist();
    e.preventDefault();
    let elem = e.target as any;
    if (elem.tagName === "LI" && elem.className === "") return;
    while (elem.nodeName && elem.nodeName.toLowerCase() !== "li") {
      if (elem.id === "dropdownMenu-InboxList" ||
        elem.classList.contains("ant-dropdown")
      )
        return;
      elem = elem.parentNode;
    }
    e.stopPropagation();
    setTimeout(() => {
      commonMethod.openLeftSection();
    }, 200);
    const newList = this.state.roomDataState.map((item: any) => {
      if (item.Id === message.Id) {
        const updatedItem = { ...item, UnreadMsgCount: 0 };
        return updatedItem;
      }
      return item;
    });
    this.props.setRoomData(newList);
    this.props.getRoomByRoomId(this.props.sharedInboxData.currentSelected.email, message, 1);
    this.props.emptyChatInput(true);
    (document.getElementById('clearChat') as HTMLElement)?.click();
    this.getMessageCountForSelectedEmail(this.props.sharedInboxData.currentSelected.email, message.UnreadMsgCount);
  };
 
  renderRoomDataMessages = (x: any, i: number) => {
    let companyVersion=store.getState().reduxReducer.companyVersion;
    if (x !== undefined && x !== null) {
      x.Participants = x.Participants !== undefined ? x.Participants.$values !== undefined ? x.Participants.$values : x.Participants : x.Participants;
      let participants = x.Participants !== undefined ? x.Participants[0].Name : x.Participants;
      if (x.Participants !== undefined) {
        for (let p = 1; p < x.Participants?.length; p++) {
          participants += `, ${x.Participants[p]?.Name}`;
        }
        let lastMessageText = "";
        let lastMessageTimeDate: any = "";
        let lastMessageTimestamp = "";
        // This check is added to prevent an error thrown by rooms that have 0 messages.
        lastMessageText = x.LastMsgText;
        let currentDate = new Date();
        lastMessageTimeDate = moment.utc(x.LastMsgTime).local();
        if (commonMethod.isToday(lastMessageTimeDate)) {
          // format: HH:MM am
          lastMessageTimestamp = moment(lastMessageTimeDate).format("hh:mma");
        } else if (commonMethod.isYesterday(lastMessageTimeDate)) {
          // format: Yesterday HH:DDpm
          lastMessageTimestamp = `Yesterday ${moment(
            lastMessageTimeDate
          ).format("hh:mma")}`;
        } else if (commonMethod.isWithinAWeek(lastMessageTimeDate)) {
          // same week
          // Format: Mon HH:DDam
          lastMessageTimestamp = moment(lastMessageTimeDate).format(
            "ddd hh:mma"
          );
        } else {
          lastMessageTimestamp = moment(lastMessageTimeDate).format("DD MMM");
        }
        return (
          <li
            className={this.props.currentMessageRoom && this.props.currentMessageRoom.Id === x.Id ? "active" : ""}
            onClick={(e) => (this.handleConversationClick(x, e))}
            style={{ cursor: "pointer" }}
            key={"roomdata" + i}
          >
            {x.Participants?.length > 1 ? (
              <>
                <div className="usr_imgbx grp test">
                  <div className="usr_img2" style={{ backgroundColor: x.Participants[0].avatarColor }}>
                    {commonMethod.generateFirstLastNameIntials(x.Participants[0].Name)}
                  </div>
                  <div className="usr_img2 usr_img22" style={{ backgroundColor: x.Participants[1].avatarColor }}>
                    {commonMethod.generateFirstLastNameIntials(x.Participants[1].Name)}
                  </div>
                </div>
              </>
            ) : (
              <div className="usr_imgbx">
                {commonMethod.generateFirstLastNameIntials(x.Participants[0].Name) !== "+" ? (
                  <div className="usr_img2" style={{ backgroundColor: x.avatarColor }}>
                    {" "}
                    {commonMethod.generateFirstLastNameIntials(x.Participants[0].Name)}{" "}
                  </div>
                ) : (
                  <GrayAvtar fill="var(--surface)"></GrayAvtar>
                )}
              </div>
            )}
            <div className="usr_txt">
              <h3>
                {" "}
                <span className="grp_txt" title={participants}>
                  {x.GroupMemberPrimary === (companyVersion===2?this.props.loggedInUser.user.Email:this.props.loggedInUser.Email) && (
                    <GroupInbox
                      style={{
                        marginRight: 5,
                        fill: "var(--primary)",
                      }}
                    ></GroupInbox>
                  )}
                  {participants}
                </span>{" "}
                <span className="tym">{lastMessageTimestamp}</span>{" "}
              </h3>
              <div
                className="txt_div"
                style={{ marginTop: "5px", display: "flex" }}
              >
                {" "}
                <span
                  className="sub_txt"
                  style={{
                    overflowWrap: "break-word",
                    flex: 1,
                    color: this.props.currentMessageRoom && this.props.currentMessageRoom.Id === x.Id ? "var(--onsurfacehighest)" : "inherit",
                    fontFamily: "'roboto','Noto Color Emoji','Segoe UI Emoji', Times, Symbola, Aegyptus, Code1997, Code2001, Code2002, Musica, serif, LastResort",
                  }}
                  title={lastMessageText}
                >
                  {lastMessageText}
                </span>{" "}
                <span
                  className="icon"
                  style={{ marginRight: 15 }}
                  onClick={(e) => e.preventDefault()}
                  id="dropdownMenu-InboxList"
                >
                  {x.UnreadMsgCount > 0 && (
                    <div className="rit_bx">
                      {" "}
                      <span className="badge"> {x.UnreadMsgCount !== 0 ? x.UnreadMsgCount : ""} </span>
                    </div>
                  )}
                  <span className="no_arw">
                    <a className="btn dropdown-toggle dots" onClick={(e) => { e.preventDefault(); }}>
                      <Dropdown
                        overlay={
                          <Card
                            style={{ width: 250, backgroundColor: "rgb(242, 242, 242)" }}
                          >
                            <ul
                              style={{ listStyle: "none", paddingLeft: 10 }}
                              className="InboxDropdown"
                            >
                              {x.IsGroup ? (
                                <>
                                  {
                                    x.Participants?.length === 1 &&
                                    x.Participants[0].Name === x.Participants[0].SfBPhoneNumber?.PhoneNumber && (
                                      <li
                                        onClick={(e) => {
                                          e.preventDefault();
                                          document.getElementById("contacts_tab")?.click();
                                          var obj = {
                                            ForeName: "",
                                            Participants: x.Participants,
                                            UserEmail: "",
                                            CreatorEmail: x.CreatorEmail
                                          }
                                          this.props.setTabOpened("newcontact");
                                          this.props.SetNewContactData(obj);
                                        }}
                                      >
                                        Add contact
                                      </li>
                                    )
                                  }
                                  {
                                    this.props.sharedInboxData && this.props.sharedInboxData.currentSelected.email === "Closed"
                                      ?
                                      <li onClick={() => { this.props.currentPageIndexForRoomMessages(1); this.props.CloseConversation(x, "false") }}>
                                        Re-Open
                                      </li>
                                      : null}
                                  {
                                    this.props.sharedInboxData &&
                                      this.props.sharedInboxData.currentSelected.email !== "Closed" ? (
                                      <li onClick={() => { this.props.updateGroupPrimaryOwner(x.Id, x.GroupMemberPrimary ===  (companyVersion===2?this.props.loggedInUser.user.Email:this.props.loggedInUser.Email)? "" :  (companyVersion===2?this.props.loggedInUser.user.Email:this.props.loggedInUser.Email)); }}>
                                        {x.GroupMemberPrimary ===  (companyVersion===2?this.props.loggedInUser.user.Email:this.props.loggedInUser.Email)  ? "Remove" : "Add"}{" "}
                                        yourself as owner
                                      </li>
                                    ) : null
                                  }
                                  {/* {this.props.sharedInboxData && this.props.sharedInboxData.currentSelected.email !== "Closed" ? (
                                    <li>Mark as{" "}{x.UnreadMsgCount > 0 ? "read" : "unread"}</li>) : null
                                  } */}

                                  {this.props.sharedInboxData && this.props.sharedInboxData.currentSelected.email !== "Closed" ? (
                                    <li onClick={() => { this.props.CloseConversation(x, "true"); }}>
                                      Close
                                    </li>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  {
                                    x.Participants?.length === 1 &&
                                    x.Participants[0]?.Name === x.Participants[0].SfBPhoneNumber?.PhoneNumber && (
                                      <li
                                        onClick={(e) => {
                                          e.preventDefault();
                                          document.getElementById("contacts_tab")?.click();
                                          var obj = {
                                            ForeName: "",
                                            Participants: x.Participants,
                                            UserEmail: "",
                                            CreatorEmail: x.CreatorEmail
                                          }
                                          this.props.setTabOpened("newcontact");
                                          this.props.SetNewContactData(obj);
                                        }}
                                      >
                                        Add contact
                                      </li>
                                    )
                                  }
                                  {
                                    this.props.sharedInboxData && this.props.sharedInboxData.currentSelected.email === "Closed" ?
                                      <li onClick={() => { this.props.currentPageIndexForRoomMessages(1); this.props.CloseConversation(x, "false") }}>
                                        Re-Open
                                      </li>
                                      : null
                                  }
                                  {
                                    this.props.sharedInboxData && this.props.sharedInboxData.currentSelected.email !== "Closed" ? (
                                      <li onClick={() => { this.props.CloseConversation(x, "true"); }}>
                                        Close
                                      </li>) :
                                      null
                                  }
                                </>
                              )}
                            </ul>
                          </Card>
                        }
                        trigger={["click"]}
                        placement="bottomLeft"
                        arrow
                      >
                        <img
                          className="btn dropdown-toggle dots"
                          src={require("../../../images/dots.svg")}
                        />
                      </Dropdown>
                    </a>
                  </span>
                </span>
              </div>
            </div>
          </li>
        );
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  };
  getTotalMessageCount = () => {
    let companyVersion=store.getState().reduxReducer.companyVersion;
    // if(companyVersion===2)
    // {
    //   if (this.state.sharedInboxMsg !== undefined) {
    //     let countUpdate = this.state.sharedInboxMsg.$values !== undefined ? this.state.sharedInboxMsg.$values : this.state.sharedInboxMsg
    //     var totalsharemessage = this.props.sharedInboxMsgCount.$values.reduce((prev: any, cur: any) => { return prev + cur.msgCount }, 0);
    //     var array = this.props.sharedInboxMsgCount.$values.find((x: any) => x.sharedInbox === this.props.sharedInboxData.currentSelected.email)
    //     var CountValue = this.props.roomData?.reduce((prev: any, cur: any) => { return prev + cur.UnreadMsgCount }, 0)
    //     var totalCount = totalsharemessage
    //     if (array?.msgCount !== CountValue) {
    //       if (CountValue > 0) {
    //         totalCount = totalsharemessage - CountValue
    //       } else { totalCount = totalsharemessage - array?.msgCount }
    //     }
    //     if (totalCount > 0) {
    //       return <span className="badge"> {totalCount} </span>;
    //     }
    //   }
    // }
    // else
    // {
    if (this.state.sharedInboxMsg !== undefined) {
      let countUpdate = this.state.sharedInboxMsg.$values !== undefined ? this.state.sharedInboxMsg.$values : this.state.sharedInboxMsg
      var totalsharemessage = this.props.sharedInboxMsgCount.$values.reduce((prev: any, cur: any) => { return prev + cur.msgCount }, 0);
       //var array = this.props.sharedInboxMsgCount.$values.find((x: any) => x.sharedEmail === this.props.sharedInboxData.currentSelected.email)
      var array = this.props.sharedInboxMsgCount.$values.find((x: any) => x.sharedInbox === this.props.sharedInboxData.currentSelected.email);      
      var CountValue = this.props.roomData?.reduce((prev: any, cur: any) => { return prev + cur.UnreadMsgCount }, 0);
      var totalCount = totalsharemessage
      if (array?.msgCount !== CountValue) {
        if (CountValue > 0) {
          totalCount = totalsharemessage - CountValue
        } else { totalCount = totalsharemessage - array?.msgCount }
      }
      if (totalCount > 0) {
        return <span className="badge"> {totalCount} </span>;
      }
    }
  //}
  };
  getMessageCountForSelectedEmail = (email: any, unreadMessageCount: any) => {
    
    //////////////////////////////////////////////////
    let companyVersion=store.getState().reduxReducer.companyVersion;
    if(companyVersion===2)
    {
      if (email === this.props.sharedInboxData.currentSelected.email) {
        //debugger;
        var CountValue = this.props.roomData?.reduce((prev: any, cur: any) => { return prev + cur.UnreadMsgCount }, 0)
        if (unreadMessageCount !== 0) {
          if (this.props.OwnerMessageIn !== undefined && this.props.OwnerMessageIn > 0) {
            var currentCount = this.props.OwnerMessageIn;
            var updatedOne = currentCount - CountValue;
            this.props.getCircleIndicator(updatedOne);
          }
        }
        //debugger;
        return CountValue !== undefined && CountValue > 0 ? (CountValue === 0 ? null : this.state.displaySharedInboxDropdown ? (<span className="badge">{CountValue}</span>
        ) : (<span className="ellipse_circle"></span>)) : null;
      } else {
        //debugger;
        // added by Maksud
        //var sharedInboxCount = this.state.sharedInboxMsg !== undefined ? this.state.sharedInboxMsg !== undefined ? this.state.sharedInboxMsg : this.state.sharedInboxMsg.$values.find((x: any) => x.sharedEmail === email) : [];
        var sharedInboxCount = this.state.sharedInboxMsg !== undefined ? this.state.sharedInboxMsg.$values.find((x: any) => x.sharedInbox === email) : [];
        if (unreadMessageCount !== 0) {
          if (sharedInboxCount?.msgCount !== undefined && sharedInboxCount?.msgCount > 0) {
            var currentCount = sharedInboxCount.$values !== undefined ? sharedInboxCount.$values[0].msgCount : sharedInboxCount.msgCount;
            var updatedOne = currentCount - unreadMessageCount;
            sharedInboxCount.msgCount = updatedOne;
            this.props.getCircleIndicatorForSharedEmail(this.state.sharedInboxMsg);
          }
        }
        if (sharedInboxCount !== undefined) {
          // var count
          // if (this.props.roomData) {
          //   count = this.props.roomData.reduce(function (prev: any, curr: any) {
          //     return prev + curr.UnreadMsgCount;
          //   }, 0);
          // }  
  
          // added by Maksud
          debugger;
          var count = sharedInboxCount.msgCount !== undefined ? sharedInboxCount.msgCount : 0;
          return count <= 0 ? null : this.state.displaySharedInboxDropdown ? (<span className="badge">{isNaN(count) ? null : count}</span>) : (<span className="ellipse_circle"></span>);
        }
      }
    }
else{
    if (email === this.props.loggedInUser.Email) {
      var CountValue = this.props.roomData?.reduce((prev: any, cur: any) => { return prev + cur.UnreadMsgCount }, 0)
      if (unreadMessageCount !== 0) {
        if (this.props.OwnerMessageIn !== undefined && this.props.OwnerMessageIn > 0) {
          var currentCount = this.props.OwnerMessageIn;
          var updatedOne = currentCount - CountValue;
          this.props.getCircleIndicator(updatedOne);
        }
      }
      return CountValue !== undefined && CountValue > 0 ? (CountValue === 0 ? null : this.state.displaySharedInboxDropdown ? (<span className="badge">{CountValue}</span>
      ) : (<span className="ellipse_circle"></span>)) : null;
    } else {
      //debugger;
      // added by Maksud
      //var sharedInboxCount = this.state.sharedInboxMsg !== undefined ? this.state.sharedInboxMsg !== undefined ? this.state.sharedInboxMsg : this.state.sharedInboxMsg.$values.find((x: any) => x.sharedEmail === email) : [];
      var sharedInboxCount = this.state.sharedInboxMsg !== undefined ? this.state.sharedInboxMsg.$values.find((x: any) => x.sharedEmail === email) : [];
      if (unreadMessageCount !== 0) {
        if (sharedInboxCount?.msgCount !== undefined && sharedInboxCount?.msgCount > 0) {
          var currentCount = sharedInboxCount.$values !== undefined ? sharedInboxCount.$values[0].msgCount : sharedInboxCount.msgCount;
          var updatedOne = currentCount - unreadMessageCount;
          sharedInboxCount.msgCount = updatedOne;
          this.props.getCircleIndicatorForSharedEmail(this.state.sharedInboxMsg);
        }
      }
      if (sharedInboxCount !== undefined) {
        // var count
        // if (this.props.roomData) {
        //   count = this.props.roomData.reduce(function (prev: any, curr: any) {
        //     return prev + curr.UnreadMsgCount;
        //   }, 0);
        // }  

        // added by Maksud
        var count = sharedInboxCount.msgCount !== undefined ? sharedInboxCount.msgCount : 0;
        debugger;
        return count <= 0 ? null : this.state.displaySharedInboxDropdown ? (<span className="badge">{isNaN(count) ? null : count}</span>) : (<span className="ellipse_circle"></span>);
      }
    }
  }
  };
   render() {
    let companyVersion=store.getState().reduxReducer.companyVersion;
    let EnableList:any=false;
    if(companyVersion===2)
    {
      EnableList= true;
    }
    else
    {
      EnableList= this.props.loggedInUser?.Subscription?.EnableLists !== undefined ? this.props.loggedInUser?.Subscription?.EnableLists:false;
    }
    
    let toReturn = [];
    toReturn.push(
      <div className="left_sec" key={1} id="left_sec_main">
        <div className="User_pro" style={this.props.classAdded === true ? { display: "none" } : { display: "flex" }}>
          <div className="usr_info">
            <div className="usr_img">{this.state.intialsLetter}</div>
            <div className="usrinfo">
              <h3>{this.state.loggedInUserName} </h3>
              {
                this.props.FreeCredit !== -1 ? <span className="credit_span">
                  <span
                    style={{
                      color: this.props.FreeCredit < 1 ? "#b00020" : "#999999",
                    }}
                  >
                    {" "}
                    Free Credit: {this.props.FreeCredit}{" "}
                  </span>
                  <span
                    style={{
                      //
                      //color: this.props.usableCredit < 1 ? "#b00020" : "#999999",
                      color: this.props.PurchasedCredit < 1 ? "#b00020" : "#999999",
                    }}
                  >
                    {" "}
                    Purchased Credit: ${
                    //this.props.usableCredit
                    this.props.PurchasedCredit                    
                    }{" "}
                  </span>
                </span>
                  : null

              }
            </div>
          </div>
          <div className="stng_icon">
            <div className="dropdown">
              <button
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <SettingIcon className="blk_icon"></SettingIcon>
              </button>
              <ul id="dropdownMenuList-Setting" className="dropdown-menu pd_15">
                <li>
                  <a
                    className="dropdown-item"
                    href="javascript:;void(0);"
                    onClick={() => { this.setState({ dialogBox: "settings" }); }}
                  >
                    Settings
                  </a>
                </li>
                <li>

                  <a
                    className="dropdown-item"
                    href="javascript:;void(0);"
                    onClick={() => {
                      this.props.setCurrentTheme(this.props.currentTheme === "light" ? this.props.whiteLabelBrand === "calltower" ? "calltower-dark" : this.props.whiteLabelBrand === "commschoice" ? "commschoicedark" : "dark" : "light");
                      this.props.saveProfileMode("", this.props.currentTheme === "light" ? "dark" : "light");
                    }}
                  >
                    {this.props.currentTheme === "light" ? "Dark" : "Light"}{" "}
                    Mode
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="javascript:;void(0);">
                    View help
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </a>
                </li>

                <li>
                  <a
                    onClick={() => { AuthService.signOut(); }}
                    className="dropdown-item red"
                  >
                    Logout
                  </a>
                  {/* {this.props.whiteLabelBrand === "calltower" ?  
                  <Link to=  "#calltower">
                  <a
                    onClick={() =>
                       {AuthService.signOut(); }}
                    className="dropdown-item red"
                  >
                    Logout
                  </a>
                   </Link>  :
                 <a 
                    onClick={() => { AuthService.signOut(); }}
                className="dropdown-item red"
                  >
                 Logout
               </a> }  */}
                </li>
              </ul>
            </div>
          </div>
        </div>
        {this.props.tabOpened === "newmessage" ||
          this.props.tabOpened === "newgroup" ||
          this.props.tabOpened === "UploadCsv" ? (
          <div className="nw_msg">
            <div className="inr_dv" style={this.props.classAdded === true ? { display: "none" } : { display: "flex" }}>
              <h1>
                {this.props.tabOpened === "newmessage" ? "New Message" : this.props.tabOpened === "newgroup" ? "New Contact Group" : this.props.tabOpened === "UploadCsv" ? "Import Contacts" : ""}
              </h1>
              <button
                className="btn cstm_btn gry_btn"
                onClick={() => { document.getElementById("msg_tab")?.click(); }}
              >
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>
        ) : this.props.tabOpened === "newcontact" ? (
          <div className="nw_msg">
            <div className="inr_dv">
              <h1>New Contact</h1>
              <button
                className="btn cstm_btn gry_btn"
                onClick={() => { document.getElementById("msg_tab")?.click(); }}
              >
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>
        ) : null}

        <ul className="nav nav-tabs" id="myTab" role="tablist" style={this.props.classAdded === true ? { display: "none" } : { display: "flex" }}>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="msg_tab"
              data-toggle="tab"
              href="#msg_sec"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              onClick={() => { this.props.setTabOpened(""); }}
            >
              Messages
              {this.state.sharedInboxMsg ? this.getTotalMessageCount() : ""}
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="contacts_tab"
              data-toggle="tab"
              href="#contacts"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              onClick={() => {
                this.ClickYakchatContact();
                this.props.getcontactlist(1);
                this.props.setTabOpened("contactopen");
                //  this.props.setContactSelected_OpenContact(0, this.props.calledFrom);
              }}
            >
              Contacts
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="msg_sec"
            ref={this.roomRef}
            onScroll={(event: any) =>
              this.handleRoomScroll(event)
            }
            role="tabpanel"
            aria-labelledby="msg_tab"
          >
            <div className="usr_bx2">
              <div
                className={`User_pro User_pro2 ${this.state.displaySharedInboxDropdown ? "User_pro_hover" : ""}`}
                id="messagesGroupInboxDropdownDiv"
                style={{ cursor: "pointer" }}

              >
                <div className="usr_info" style={{ width: "80%" }}>
                  <div className="usr_img">
                    {this.props.sharedInboxData &&
                      this.props.sharedInboxData.currentSelected.email === "Closed" ? (
                      <Archive fill="var(--surface)"></Archive>
                    ) : this.props.sharedInboxData &&
                      this.props.sharedInboxData.currentSelected.email === "" ? (
                      <UserIcon fill="var(--surface)"></UserIcon>
                    ) : (
                      <GroupLogo fill="var(--surface)" />
                    )}
                  </div>
                  <div
                    className="usrinfo"
                    style={{ width: "75%" }}
                    title={this.props.sharedInboxData ? this.props.sharedInboxData.currentSelected.email + " " : ""}
                  >
                    <h2
                      className="slct"
                      style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    >
                      {this.props.sharedInboxData ? this.props.sharedInboxData.currentSelected.name : ""}
                    </h2>
                    <div className="txt_div">
                      <span
                        className="sub_txt"
                        style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                      >
                        {this.props.sharedInboxData ? this.props.sharedInboxData.currentSelected.UniqueNumber : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="rit_bx ritbox_tr">
                  {this.props.sharedInboxData ? this.getMessageCountForSelectedEmail(this.props.sharedInboxData.currentSelected.email, 0) : null}
                  {this.state.displaySharedInboxDropdown ? null : (<a href="javascript:;void(0);" className="dwn_arw"><DownArrow /></a>)}
                </div>
                {this.props.sharedInboxData && (                 
                 
               <div className="cntcts_optns"
                 style={{ top: "60px", display: this.state?.displaySharedInboxDropdown ? "block" : "none" ,
                 minHeight:"4vh",maxHeight:"calc(80vh - 120px)",overflow:"auto"}}
               >

                    <div className="optns pd_0">
                      <ul className="usr_list ">
                        {this.props.sharedInboxData?.other?.length > 0 ? (
                          this.props.sharedInboxData.other.map(
                            (x: any, i: number) => {
                              if (x.email === "Closed") return null;
                              return (
                                <li
                                  key={i}
                                  onClick={() => { this.props.setCurrentSelectedSharedEmail(x); commonMethod.openBootstrapCollapseWithId("collapse-allMessages"); }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="usr_imgbx">
                                    <div className="usr_img2 blk_bg">
                                      {x.email === "" ? (
                                        <UserIcon fill="var(--surface)"></UserIcon>
                                      ) : (
                                        <GroupLogo
                                          fill="var(--surface)"
                                          className="wht_icon"
                                          width="32"
                                          height="24"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="usr_txt"
                                    title={x.UniqueNumber}
                                  >

                                    <h3 style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                      {x.name !== null ? x.name?.replace(/["]/g, "") : ""}
                                    </h3>
                                    <div className="txt_div">
                                      <span
                                        className="sub_txt"
                                        style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                      >
                                        {x.UniqueNumber}
                                      </span>
                                    </div>
                                  </div>
                                  {this.state.sharedInboxMsg ? this.getMessageCountForSelectedEmail(x.email, 0) : ""}
                                </li>
                              );
                            }
                          )
                        ) : (
                          <li key={"emptyli"}></li>
                        )}
                      </ul>
                    </div>

                    {this.props.sharedInboxData?.other?.length > 0
                      ? this.props.sharedInboxData?.other.map(
                        (x: any, i: number) => {
                          if (x.email === "Closed")
                            return (
                              <div
                                className="optns"
                                onClick={() => { this.props.setCurrentSelectedSharedEmail(x); commonMethod.openBootstrapCollapseWithId("collapse-allMessages"); }}
                              >
                                <div
                                  className="usr_info"
                                  style={{ width: "80%" }}
                                >
                                  <div className="usr_img blk_bg">
                                    <Archive className="wht_icon"></Archive>
                                  </div>
                                  <div
                                    className="usrinfo"
                                    style={{ width: "75%" }}
                                  >
                                    {x.name}
                                  </div>
                                </div>
                              </div>
                            );
                        }
                      )
                      : null}
                  </div>
                )}
              </div>
              <div className="srch_bx">
                <input
                  type="search"
                  onChange={this.handleSearchConversation}
                  value={this.state.searchMessageQuery}
                  className="form-control search_form"
                  placeholder=""
                />
              </div>
            </div>
            <div>
              <div
                ref={this.myRef}
                className="accordion"
                id="accordionExample"
                style={{ height: "calc(100vh - 299px)" }}
              >
                <div className="card" id="scroll_convo">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-block text-left "
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse-allMessages"
                        aria-expanded="true"
                        aria-controls="collapse-allMessages"
                        id="collapse-allMessagesButton"
                      >
                        <span className="cl_lft">All Messages</span>
                        <span className="cl_rit">
                          {" "}
                          <img
                            src={require("../../../images/down_arrow.svg")}
                            className="arrw_dwn_img"
                            alt="usr_icn"
                          />{" "}
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapse-allMessages"
                    className="collapse show"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <ul className="usr_list test1">
                        {this.state.roomDataState !== undefined &&
                          (this.state.searchMessageQuery !== "" ?
                            this.state.roomDataState.filter((x: any) => {
                              const s = this.state.searchMessageQuery.toLowerCase();
                              let checkval=x.Participants.$values;
                              if(store.getState().reduxReducer.companyVersion===2)
                              {
                              if(checkval!==undefined)
                              {
                                return (x.Participants.$values !== undefined ?
                                  (x.Participants.$values.some((y: any) => y.Name?.toLowerCase().includes(s) ? y.Name?.toLowerCase().includes(s) : y.SfBPhoneNumber?.PhoneNumber?.includes(s)) //||
                                    //x.Messages.some((y: any) => y.Text?.toLowerCase().includes(s))
                                    )
                                  :
                                  (x.Participants.$values.some((y: any) => y.Name?.toLowerCase().includes(s) ? y.Name?.toLowerCase().includes(s) : y.SfBPhoneNumber?.PhoneNumber?.toLowerCase().includes(s))// ||
                                   // x.Messages.some((y: any) => y.Text?.toLowerCase().includes(s))
                                   )
                                )
                              }
                              else
                              {
                              return (x.Participants !== undefined ?
                                (x.Participants.some((y: any) => y.Name?.toLowerCase().includes(s) ? y.Name?.toLowerCase().includes(s) : y.SfBPhoneNumber?.PhoneNumber?.toLowerCase().includes(s)) //||
                                  //x.Messages.some((y: any) => y.Text?.toLowerCase().includes(s))
                                  )
                                :
                                (x.Participants.some((y: any) => y.Name?.toLowerCase().includes(s) ? y.Name?.toLowerCase().includes(s) : y.SfBPhoneNumber?.PhoneNumber?.toLowerCase().includes(s))// ||
                                 // x.Messages.some((y: any) => y.Text?.toLowerCase().includes(s))
                                 )
                              )
                              }
                            }
                            else{
                              return (x.Participants.$values !== undefined ?
                                (x.Participants.$values.some((y: any) => y.Name?.toLowerCase().includes(s) ? y.Name?.toLowerCase().includes(s) : y.SfBPhoneNumber?.PhoneNumber.toLowerCase().includes(s)) ||
                                  x.Messages.$values.some((y: any) => y.Text?.toLowerCase().includes(s)))
                                :
                                (x.Participants.some((y: any) => y.Name?.toLowerCase().includes(s) ? y.Name?.toLowerCase().includes(s) : y.SfBPhoneNumber?.PhoneNumber.toLowerCase().includes(s)) ||
                                  x.Messages.$values.some((y: any) => y.Text?.toLowerCase().includes(s)))
                              )}
                            }).map(this.renderRoomDataMessages)
                            :
                            this.state.roomDataState.map(this.renderRoomDataMessages))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="plus_icon" style={{ zIndex: 0 }}>
              <a
                href="javascript:;void(0);"
                className="circle"
                onClick={() => {
                  document.getElementById("contacts_tab")?.click();
                  this.props.setTabOpened("newmessage");
                  this.props.setSelectedContacts([]);
                  setTimeout(() => { commonMethod.openLeftSection(); }, 200);
                  if (this.state.yakChatContactList === undefined || this.state.yakChatContactList?.length === 0) {
                    this.props.getcontactlist(1);
                    // this.props.getYakChatGroupData(1);
                    // this.props.getYakChatLists();
                  }
                }}
              >
                <PlusSvg fill="var(--onPrimary)"></PlusSvg>
              </a>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="contacts"
            onScroll={(event: any) => this.handleContactScroll(event)}
            role="tabpanel"
            aria-labelledby="contacts_tab"
          >
            <div className="usr_bx2">
              <Spin spinning={this.state.spinnerValueState} indicator={antIcon}
                style={{ zIndex: 9999, right: "44%", top: "55%", position: 'absolute', fontSize: 40 }} />
              <div
                className={`User_pro User_pro2 ${this.state.displayContactsDropdown ? "User_pro_hover" : ""}`}
                id="contactsGroupInboxDropdownDiv"
                style={{ cursor: "pointer" }}
              >
                <div className="usr_info">
                  <div className="usr_img">

                    <PhoneBook style={{ fill: "var(--surface)" }} />
                    {/* <UserIcon/> */}
                  </div>
                  <div className="usrinfo">
                    <h2 className="YakchatContactsList">{this.state.currentActiveDropdown === " " ? this.props.defaultContactList : this.state.currentActiveDropdown}</h2>
                  </div>
                </div>

                <div className="rit_bx">
                  <a href="javascript:;void(0);" className="dwn_arw">
                    {this.state.displayContactsDropdown ? null : <DownArrow />}
                  </a>
                </div>
                <div
                  className="cntcts_optns"
                  style={{ display: this.state.displayContactsDropdown ? "block" : "none" }}
                >
                  <div
                    className="optns"
                    onClick={() => { this.ClickYakchatContact(); }}
                    style={{ display: this.state.HideContactDiv ? "block" : "none" }}
                  >
                    <div className="usr_info">
                      <ContactFolders />
                      <div className="usrinfo" id="YakChat_contacts_div"> Contacts</div>
                    </div>
                  </div>

                  {(this.state.contactSourceOutlook) ? (
                    <div
                      className="optns"
                      onClick={() => {
                        this.getOutlookContactsFolders();
                        this.props.getOutlookContactFoldersByContactList();
                        this.props.getOutLookContactlist(1);
                      }}
                    >
                      <div className="usr_info">
                        <ContactFolders />
                        <div className="usrinfo">Outlook</div>
                      </div>
                    </div>) : ""}

                  {this.state.contactSourceDirectory ? (
                    <div
                      className="optns"
                      onClick={() => {
                        this.GetUsers();
                        this.props.GetUsers();
                        this.props.listAzureGroups();
                      }}
                    >
                      <div className="usr_info">
                        <ContactFolders />
                        <div className="usrinfo">User Directory</div>
                      </div>
                    </div>) : ""}
                  {this.state.contactSource ? (
                    <div
                      className="optns"
                      onClick={() => { this.getSharepointContacts(); }}
                    >
                      <div className="usr_info">
                        <ContactFolders />
                        <div className="usrinfo">SharePoint</div>
                      </div>
                    </div>) : ""}
                </div>
              </div>
              <div className="srch_bx contact_search">
                <Search onClick={() => this.doSearch(1)}></Search>
                <input
                  type="search"
                  value={this.state.searchFilter}
                  onChange={(e) => {
                    this.setState({ searchFilter: e.target.value, pageindex: 1 });
                    if (e.target.value?.length === 0) {
                      this.props.getcontactlist(1);
                    }
                  }}
                  onKeyDown={(event) => {

                    var keyTrack = event.key
                    if (keyTrack.toUpperCase() === "ENTER") {
                      this.doSearch(1);
                    }
                    if (keyTrack.toUpperCase() === "BACKSPACE" && keyTrack?.length === 0) {
                      this.props.getcontactlist(1);

                    }
                  }}
                  className="form-control search_form"
                  placeholder={this.props.tabOpened === "newmessage" ? this.state.YakchatContact ? "Start typing a name or number" : "Search Contacts" : "Search Contacts"}
                />
              </div>
            </div>
            <div
              className="contacts_listN"
              id="contacts_list_div"
              style={{ height: this.props.classAdded ? "calc(100vh - 150px)" : this.props.tabOpened === "newmessage" || this.props.tabOpened === "newcontact" || this.props.tabOpened === "newgroup" || this.props.tabOpened === "UploadCsv" ? "calc(100vh - 367px)" : "calc(100vh - 297px)" }}
            >
              <SharePointParent
                searchFilter={this.state.searchFilter}
                SharePointSelected={this.state.sharepointcontact}
              />
              <OutlookParent
                searchFilter={this.state.searchFilter}
                getOutlookContactsfolders={this.state.showOutlookcontactfolders}
              />
              <UserParent
                searchFilter={this.state.searchFilter}
                Userselected={this.state.azureusers}
              />
              <YakChatParent
                yakchatSelected={this.state.YakchatContact}
                PageIndex={this.state.pageindex}
                searchFilter={this.state.searchFilter}
              />
            </div>
            {/* start here */}
            {this.props.tabOpened !== "newmessage" &&
              this.props.tabOpened !== "newgroup" && (
                <div className="plus_icon" id="contactsPlusDiv">
                  <ul
                    className="optn_lst"
                    style={{ display: "none" }}
                    id="contactsPlusOptions"
                  >
                    {this.props.selectedContactsWithCtrl &&
                      this.props.selectedContactsWithCtrl?.length > 0 && (
                        <li className="plus_icon_menus"
                          onClick={(e) => {
                            this.props.setSelectedContacts(this.props.selectedContactsWithCtrl);
                            this.props.setTabOpened("newgroup");
                            this.props.setSelectedContactsWithCtrl([]);
                          }}
                        >
                          {" "}
                          <a
                            href="javascript:;void(0);"
                            onClick={(e) => {
                              e.preventDefault();
                              const contactsPlusDiv = document.getElementById(
                                "contactsPlusDiv"
                              );
                              if (contactsPlusDiv?.classList.contains("opened"))
                                contactsPlusDiv?.classList.add("opened");
                              else contactsPlusDiv?.classList.remove("opened");

                              const contactsPlusOptions = document.getElementById(
                                "contactsPlusOptions"
                              );
                              if (contactsPlusOptions?.style.display === "none")
                                contactsPlusOptions!.style.display = "inline";
                              else contactsPlusOptions!.style.display = "none";

                              const contactsPlusDivIcon = document.getElementById(
                                "contactsPlusDiv-Icon-a"
                              );
                              if (
                                contactsPlusDivIcon?.classList.contains("show")
                              )
                                contactsPlusDivIcon!.classList.remove("show");
                              else contactsPlusDivIcon!.classList.add("show");

                              this.props.setTabOpened("newgroup");
                              this.props.setSelectedContacts([]);
                              this.ClickYakchatContact();
                            }}
                          >
                            {" "}
                            <svg
                              className="wht_icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="18"
                              viewBox="0 0 25 18"
                            >
                              <g>
                                <g>
                                  <g>
                                    <path d="M8.717 3.652a3.65 3.65 0 1 1 7.3 0 3.65 3.65 0 0 1-7.3 0z" />
                                  </g>
                                  <g>
                                    <g>
                                      <path d="M8.394 6.519a4.868 4.868 0 0 1-.928-2.867c0-.664.133-1.298.373-1.876a3.129 3.129 0 1 0 .555 4.743z" />
                                    </g>
                                  </g>
                                  <g>
                                    <path d="M24.257 9.916V8.82c0-.605-.49-1.095-1.095-1.095h-1.643V6.083c0-.605-.49-1.095-1.095-1.095h-1.095c-.605 0-1.095.49-1.095 1.095v1.643H16.59c-.605 0-1.095.49-1.095 1.095v1.095c0 .605.49 1.095 1.095 1.095h1.643v1.642c0 .606.49 1.096 1.095 1.096h1.095c.605 0 1.095-.49 1.095-1.096v-1.642h1.643c.604 0 1.095-.49 1.095-1.095z" />
                                  </g>
                                  <g>
                                    <g>
                                      <path d="M16.983 12.653v-.39h-.392a2.35 2.35 0 0 1-2.347-2.347V8.82l.003-.079h-4.07c-2.42 0-4.58 1.974-4.38 4.39.055.672.31 2.139.504 3.192a1.462 1.462 0 0 0 1.437 1.199h9.258c.705 0 1.31-.504 1.438-1.2.078-.429.168-.928.25-1.414a2.35 2.35 0 0 1-1.702-2.256z" />
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <path d="M4.55 13.236c-.118-1.417.368-2.796 1.368-3.881.204-.223.427-.427.662-.613H4.232c-2.073 0-3.925 1.693-3.754 3.765.047.575.266 1.833.432 2.737.109.596.628 1.027 1.231 1.027H5.02c-.267-1.473-.425-2.494-.47-3.035z" />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>{" "}
                            New group with selection (
                            {this.props.selectedContactsWithCtrl?.length})
                          </a>
                        </li>
                      )}
                    
                    {EnableList !== undefined ? EnableList === true ?
                      <li className="plus_icon_menus">
                        {" "}
                        <a
                          href="javascript:;void(0);"
                          onClick={(e) => {
                            e.preventDefault();
                            const contactsPlusDiv = document.getElementById(
                              "contactsPlusDiv"
                            );
                            if (contactsPlusDiv?.classList.contains("opened"))
                              contactsPlusDiv?.classList.add("opened");
                            else contactsPlusDiv?.classList.remove("opened");

                            const contactsPlusOptions = document.getElementById(
                              "contactsPlusOptions"
                            );
                            if (contactsPlusOptions?.style.display === "none")
                              contactsPlusOptions!.style.display = "inline";
                            else contactsPlusOptions!.style.display = "none";

                            const contactsPlusDivIcon = document.getElementById(
                              "contactsPlusDiv-Icon-a"
                            );
                            if (contactsPlusDivIcon?.classList.contains("show"))
                              contactsPlusDivIcon!.classList.remove("show");
                            else contactsPlusDivIcon!.classList.add("show");

                            this.props.setTabOpened("newgroup");
                            this.props.setSelectedContacts([]);
                            this.ClickYakchatContact();
                          }}
                        >
                          {" "}
                          <svg
                            className="wht_icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="18"
                            viewBox="0 0 25 18"
                          >
                            <g>
                              <g>
                                <g>
                                  <path d="M8.717 3.652a3.65 3.65 0 1 1 7.3 0 3.65 3.65 0 0 1-7.3 0z" />
                                </g>
                                <g>
                                  <g>
                                    <path d="M8.394 6.519a4.868 4.868 0 0 1-.928-2.867c0-.664.133-1.298.373-1.876a3.129 3.129 0 1 0 .555 4.743z" />
                                  </g>
                                </g>
                                <g>
                                  <path d="M24.257 9.916V8.82c0-.605-.49-1.095-1.095-1.095h-1.643V6.083c0-.605-.49-1.095-1.095-1.095h-1.095c-.605 0-1.095.49-1.095 1.095v1.643H16.59c-.605 0-1.095.49-1.095 1.095v1.095c0 .605.49 1.095 1.095 1.095h1.643v1.642c0 .606.49 1.096 1.095 1.096h1.095c.605 0 1.095-.49 1.095-1.096v-1.642h1.643c.604 0 1.095-.49 1.095-1.095z" />
                                </g>
                                <g>
                                  <g>
                                    <path d="M16.983 12.653v-.39h-.392a2.35 2.35 0 0 1-2.347-2.347V8.82l.003-.079h-4.07c-2.42 0-4.58 1.974-4.38 4.39.055.672.31 2.139.504 3.192a1.462 1.462 0 0 0 1.437 1.199h9.258c.705 0 1.31-.504 1.438-1.2.078-.429.168-.928.25-1.414a2.35 2.35 0 0 1-1.702-2.256z" />
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <path d="M4.55 13.236c-.118-1.417.368-2.796 1.368-3.881.204-.223.427-.427.662-.613H4.232c-2.073 0-3.925 1.693-3.754 3.765.047.575.266 1.833.432 2.737.109.596.628 1.027 1.231 1.027H5.02c-.267-1.473-.425-2.494-.47-3.035z" />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>{" "}
                          New Group{" "}
                        </a>
                      </li> : "" : ""}
                    <li>
                      {" "}
                      <a
                        href="javascript:;void(0);"
                        onClick={(e) => {
                          e.preventDefault();
                          const contactsPlusDiv = document.getElementById(
                            "contactsPlusDiv"
                          );
                          if (contactsPlusDiv?.classList.contains("opened"))
                            contactsPlusDiv?.classList.add("opened");
                          else contactsPlusDiv?.classList.remove("opened");

                          const contactsPlusOptions = document.getElementById(
                            "contactsPlusOptions"
                          );
                          if (contactsPlusOptions?.style.display === "none")
                            contactsPlusOptions!.style.display = "inline";
                          else contactsPlusOptions!.style.display = "none";
                          this.props.setTabOpened("UploadCsv");
                          this.ClickYakchatContact();

                          const contactsPlusDivIcon = document.getElementById(
                            "contactsPlusDiv-Icon-a"
                          );
                          if (contactsPlusDivIcon?.classList.contains("show"))
                            contactsPlusDivIcon!.classList.remove("show");
                          else contactsPlusDivIcon!.classList.add("show");
                        }}
                      >
                        {" "}
                        <svg
                          className="wht_icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="23"
                          viewBox="0 0 21 23"
                        >
                          <g>
                            <g>
                              <path d="M20.992 16.039v-1.094c0-.604-.49-1.093-1.094-1.093h-1.64V12.21c0-.604-.49-1.094-1.094-1.094H16.07c-.604 0-1.093.49-1.093 1.094v1.64h-1.64c-.605 0-1.095.49-1.095 1.094v1.094c0 .604.49 1.094 1.094 1.094h1.64v1.64c0 .604.49 1.094 1.094 1.094h1.094c.604 0 1.094-.49 1.094-1.094v-1.64h1.64c.604 0 1.094-.49 1.094-1.094z" />
                            </g>
                            <g>
                              <path d="M16.617 6.743l-6.25-6.25V5.82c0 .508.414.922.922.922z" />
                            </g>
                            <g>
                              <path d="M11.29 6.743h5.327l-6.25-6.25V5.82c0 .508.414.922.922.922z" />
                            </g>
                            <g>
                              <g>
                                <path d="M16.07 19.867h.547v-8.75h-.547c-.604 0-1.093.49-1.093 1.094v1.64h-1.64c-.605 0-1.095.49-1.095 1.094v1.094c0 .604.49 1.094 1.094 1.094h1.64v1.64c0 .604.49 1.094 1.094 1.094z" />
                              </g>
                              <g>
                                <path d="M16.07 21.117a2.347 2.347 0 0 1-2.344-2.344v-.39h-.39a2.346 2.346 0 0 1-2.344-2.344v-1.094a2.347 2.347 0 0 1 2.344-2.344h.39v-.39a2.347 2.347 0 0 1 2.344-2.344h.547V7.993H11.29A2.174 2.174 0 0 1 9.118 5.82V.493h-7.5c-.69 0-1.25.56-1.25 1.25V21.74c0 .69.56 1.25 1.25 1.25h13.75c.69 0 1.25-.56 1.25-1.25v-.624z" />
                              </g>
                            </g>
                          </g>
                        </svg>{" "}
                        Import Contacts
                      </a>{" "}
                    </li>
                    <li >
                      {" "}
                      <a className="plus_icon_menus"
                        href="javascript:;void(0);"
                        onClick={(e) => {
                          e.preventDefault();
                          commonMethod.openLeftSection();
                          this.props.SetNewContactData({});
                          const contactsPlusDiv = document.getElementById("contactsPlusDiv");
                          if (contactsPlusDiv?.classList.contains("opened"))
                            contactsPlusDiv?.classList.add("opened");
                          else contactsPlusDiv?.classList.remove("opened");
                          const contactsPlusOptions = document.getElementById("contactsPlusOptions");
                          if (contactsPlusOptions?.style.display === "none")
                            contactsPlusOptions!.style.display = "inline";
                          else contactsPlusOptions!.style.display = "none";
                          this.props.setTabOpened("newcontact");
                          setTimeout(() => { commonMethod.openLeftSection(); }, 50);
                          const contactsPlusDivIcon = document.getElementById("contactsPlusDiv-Icon-a");
                          if (contactsPlusDivIcon?.classList.contains("show"))
                            contactsPlusDivIcon!.classList.remove("show");
                          else contactsPlusDivIcon!.classList.add("show");

                        }}
                      >
                        {" "}
                        <svg
                          className="wht_icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="24"
                          viewBox="0 0 21 24"
                        >
                          <g>
                            <g>
                              <g>
                                <path d="M4.233 5.336a5.128 5.128 0 1 1 10.255 0 5.128 5.128 0 0 1-10.255 0z" />
                              </g>
                              <g>
                                <path d="M15.129 20.719a2.567 2.567 0 0 1-2.564-2.564v-.64h-.64A2.567 2.567 0 0 1 9.36 14.95v-1.282c0-.22.028-.436.081-.64H5.529c-2.865.287-5.503 2.771-5.1 5.696.105.774.626 2.51.969 3.602.179.57.704.956 1.299.956h13.328c.594 0 1.12-.387 1.298-.956.189-.602.432-1.4.63-2.124a2.55 2.55 0 0 1-1.542.517z" />
                              </g>
                              <g>
                                <path d="M19.616 12.386h-1.923v-1.922c0-.708-.574-1.282-1.282-1.282H15.13c-.708 0-1.282.574-1.282 1.282v1.922h-1.923a1.28 1.28 0 0 0-1.144.708l-.01.02c-.016.034-.032.07-.045.107l-.015.04a1.053 1.053 0 0 0-.035.123l-.009.042a1.344 1.344 0 0 0-.015.095l-.003.032a1.176 1.176 0 0 0-.005.115v1.282c0 .708.573 1.282 1.281 1.282h1.923v1.923c0 .708.574 1.282 1.282 1.282h1.282c.708 0 1.282-.574 1.282-1.282v-1.923h1.923c.708 0 1.282-.574 1.282-1.282v-1.282c0-.708-.574-1.282-1.282-1.282z" />
                              </g>
                            </g>
                          </g>
                        </svg>{" "}
                        New Contact
                      </a>{" "}
                    </li>
                  </ul>
                  <a

                    href="javascript:;void(0);"
                    id="contactsPlusDiv-Icon-a"
                    className="circle blk_bg"
                    onClick={() => {
                      const contactsPlusDiv = document.getElementById(
                        "contactsPlusDiv"
                      );
                      if (contactsPlusDiv?.classList.contains("opened"))
                        contactsPlusDiv?.classList.add("opened");
                      else contactsPlusDiv?.classList.remove("opened");
                      const contactsPlusOptions = document.getElementById(
                        "contactsPlusOptions"
                      );
                      if (contactsPlusOptions?.style.display === "none")
                        contactsPlusOptions!.style.display = "inline";
                      else contactsPlusOptions!.style.display = "none";

                      const contactsPlusDivIcon = document.getElementById(
                        "contactsPlusDiv-Icon-a"
                      );
                      if (contactsPlusDivIcon?.classList.contains("show"))
                        contactsPlusDivIcon!.classList.remove("show");
                      else contactsPlusDivIcon!.classList.add("show");
                    }}
                  >
                    {" "}
                    <svg
                      className="wht_icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                    >
                      <g>
                        <g>
                          <path d="M21.596 9.779H13.04V1.222C13.04.546 12.492 0 11.816 0h-.815C10.327 0 9.78.546 9.78 1.222v8.557H1.223C.547 9.779 0 10.326 0 11.002v.815c0 .675.547 1.221 1.223 1.221H9.78v8.558c0 .675.547 1.222 1.221 1.222h.815c.676 0 1.223-.547 1.223-1.222v-8.558h8.557c.676 0 1.223-.546 1.223-1.221v-.815c0-.676-.547-1.223-1.223-1.223z" />
                        </g>
                      </g>
                    </svg>
                  </a>{" "}
                </div>
              )}
          </div>
        </div>
      </div>
    );

    if (this.state.dialogBox === "settings")
      toReturn.push(
        <div className="dialogBox">
          <div
            key={2}
            className="modal fade show"
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-modal="true"
            role="dialog"
            style={{
              display: "block",
              paddingLeft: "8px",
              textAlign: "left",
              zIndex: 999999,
              background: "#0000002",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog  modal-dialog-scrollable cmn_modl">
              <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                  <button
                    type="button"
                    className="btn cstm_btn gry_btn"
                    data-dismiss="modal"
                    onClick={() => this.setState({ dialogBox: "" })}
                  >
                    Cancel
                  </button>
                  <h5 className="modal-title">Settings</h5>
                  <button
                    type="button"
                    className="btn cstm_btn ylo_btn"
                    onClick={this.saveSettings}
                  >
                    Save
                  </button>
                </div>
                <div className="modal-body ">
                  <div className="stng_dtls">
                    <div className="yr_dtls">
                      <h2> Your Details </h2>
                      <div className="label1">
                        <label className="grry_txt"> LOGIN EMAIL </label>
                        <div className="phone_txt vlu_txt">
                          {this.props.loggedInUser?.account?.userName}
                        </div>
                      </div>
                      <div className="label1">
                        <label className="grry_txt"> CREDIT BALANCE </label>
                        <div className="phone_txt vlu_txt">
                        {" "}
                          ${
                         // this.props.usableCredit
                         this.props.PurchasedCredit     
                          }{" "}
                        </div>
                      </div>
                      {/* {
                       
                      store.getState().reduxReducer.companyVersion===2?
                      ( store.getState().reduxReducer.loggedInUser.user.EnableTranslation)
                      :( store.getState().reduxReducer.loggedInUser.Subscription.EnableTranslation)
                      ?? */}
             
                      {/* <div className="label1 default_contact">
                        <label className="grry_txt"> Default contacts </label>
                        <select className="form-control" onChange={this.handleChange} 
                          value={this.props.defaultContactList}>
                          <option value=" YakChat Contacts " className="setting_select_option"> YakChat Contacts </option>
                          <option value="  Outlook Contacts "> Outlook Contacts</option>
                          <option value=" Active Directory Contacts ">Active Directory Contacts</option>
                          <option value="Sharepoint Contacts">Sharepoint Contacts</option>
                        </select>
                      </div> */}
                    </div>

                    <div className="switch_btns">
                      <h2> Office 365 Integration </h2>
                      <ul className="btn_list no_dots">
                        <li>
                          {" "}
                          Outlook Contacts
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={this.state.contactSourceOutlook ? true : false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.props.setContactSourceOutlook(true);
                                } else {
                                  this.props.setContactSourceOutlook(false);

                                }
                              }

                                // this.setState({
                                //   settings: {
                                //     ...this.state.settings,
                                //     outlookContacts: e.target.checked,
                                //   },
                                // })
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </li>
                        <li>
                          {" "}
                          User Directory
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={this.state.contactSourceDirectory ? true : false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.props.setContactSourceActiveDirectory(true);
                                } else {
                                  this.props.setContactSourceActiveDirectory(false);
                                }
                              }
                                // this.setState({
                                //   settings: { ...this.state.settings, userDir: e.target.checked },
                                // })
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </li>
                        <li>
                          {" "}
                          Sharepoint Contacts
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={this.state.contactSource ? true : false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.props.setContactSourceSharePoint(true);
                                } else {
                                  this.props.setContactSourceSharePoint(false);
                                }
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    if (this.state.dialogBox === "room_more")
      toReturn.push(
        <div className="dialogBox">
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <a className="dropdown-item" href="javascript:;void(0);">
                Pin to top
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="javascript:;void(0);">
                Mute notifications
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="javascript:;void(0);">
                Mark as completed
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="javascript:;void(0);">
                Archive room
              </a>
            </li>
          </ul>
        </div>
      );
    return toReturn;
  }
}

// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  let companyVersion=store.getState().reduxReducer.companyVersion;
  
  return {
    yakChatContactList: props.reduxReducer.YakChatContactList,
    yakchatcontactsNewData: props.reduxReducer.YakChatContactNewList,
    tabOpened: props.reduxReducer.tabOpened,
    //usableCredit: props.reduxReducer.usableCredit,
    roomData: props.reduxReducer.roomData,
    currentTheme: props.reduxReducer.currentTheme,
    sharedInboxData: props.reduxReducer.sharedInboxData,
    loggedInUser: props.reduxReducer.loggedInUser,
    currentMessageRoom: props.reduxReducer.openMessageRoom,
    selectedContactsWithCtrl: props.reduxReducer.selectedContactsWithCtrl,
    unreadMessagecount: props.reduxReducer.newmessagecount,
    OwnerMessageIn: props.reduxReducer.MessageCircleIndicator,
    currentPageIndexForInbox: props.reduxReducer.currentPageIndexForInbox,
    nextPageIndexForInbox: props.reduxReducer.nextPageIndexForInbox,
    sharedInboxMsgCount: props.reduxReducer.shareMsgCount,
    spinnerValue: props.reduxReducer.spinnerSideBarValue,
    defaultContactList: props.reduxReducer.defaultContactList,
    currentPageIndexForYakChat: props.reduxReducer.currentPageIndexForYakChat,
    nextPageIndexForYakChat: props.reduxReducer.nextPageIndexForYakChat,
    contactSource: props.reduxReducer.contactSource,
    contactSourceOutlook: props.reduxReducer.contactSourceOutlook,
    contactSourceDirectory: props.reduxReducer.contactSourceDirectory,
    classAdded: props.reduxReducer.classAdded,
    newCount: props.reduxReducer.newCount,
    whiteLabelBrand: props.reduxReducer.whiteLabelBrand,
    checkedvalue: props.reduxReducer.checkedvalue,
    displayMode: props.reduxReducer.displayMode,
    sharepointcontactsdata: props.reduxReducer.SharePointContactlist,
    spinnerSideBarValue: props.reduxReducer.spinnerSideBarValue,
    sharedError: props.reduxReducer.sharedError,
    ServicePlanId: props.reduxReducer.ServicePlanId,
    FreeCredit:companyVersion!==2? props.reduxReducer.loggedInUser?.Subscription?.FreeCreditBalance:
    (store.getState().reduxReducer.sharedInboxData==undefined||store.getState().reduxReducer.sharedInboxData?.currentSelected.email==="Closed"?props.reduxReducer.loggedInUser.inboxes.$values[0].FreeCreditBalance:
    props.reduxReducer.loggedInUser.inboxes.$values.filter(
      (item: any) => item.InboxId == store.getState().reduxReducer.sharedInboxData?.currentSelected.email )!=undefined?
      props.reduxReducer.loggedInUser.inboxes.$values.filter(
      (item: any) => item.InboxId == store.getState().reduxReducer.sharedInboxData?.currentSelected.email )[0].FreeCreditBalance:"0"),
    PurchasedCredit:companyVersion!==2?props.reduxReducer.loggedInUser?.Subscription?.Credit:
    (store.getState().reduxReducer.sharedInboxData==undefined||store.getState().reduxReducer.sharedInboxData?.currentSelected.email==="Closed"?
    props.reduxReducer.loggedInUser.inboxes.$values[0].Credit:props.reduxReducer.loggedInUser.inboxes.$values.filter(
      (item: any) => item.InboxId == store.getState().reduxReducer.sharedInboxData?.currentSelected.email )!=undefined? props.reduxReducer.loggedInUser.inboxes.$values.filter(
      (item: any) => item.InboxId == store.getState().reduxReducer.sharedInboxData?.currentSelected.email )[0].Credit:"0"),
    selectedContactsNewMessage: props.reduxReducer.selectedContactsNewMessage || [],
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    setRoomData: (roomData: any) => {
      dispatch<any>(actions.setRoomsAction(roomData));
    },
    emptyChatInput: (isChatInput: any) => {
      dispatch<any>(actions.emptyChatInput(isChatInput));
    },
    getPersonalInbox: async (pageIndex: any, loggedInUser: any, archivedFlag: any, groupEmail: any, calledFrom: any, data: any) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2){
      dispatch<any>(actions.getPersonalInbox_V2(pageIndex, loggedInUser, archivedFlag, groupEmail, calledFrom, data));
      }
      else
      dispatch<any>(actions.getPersonalInbox(pageIndex, loggedInUser, archivedFlag, groupEmail, calledFrom, data));
    },
    getcontactlist: async (PageIndex: any) => {
      dispatch<any>(actions.setSpinnerInSideBar(true));
      let companyVersion=store.getState().reduxReducer.companyVersion;
        if(companyVersion===2)
          {
      dispatch<any>(actions.getyakchatContacts_V2(PageIndex));
          }
          else{
            dispatch<any>(actions.getyakchatContacts(PageIndex));
          }
    },
    searchContactList: (PageIndex: number, searchFilter: string) => {
      dispatch<any>(actions.setSpinnerInSideBar(true));
      let companyVersion=store.getState().reduxReducer.companyVersion;
        if(companyVersion===2)
          {
      dispatch<any>(actions.searchYakChatContacts_V2(PageIndex, searchFilter));
          }
          else{
          dispatch<any>(actions.searchYakChatContacts(PageIndex, searchFilter));
        }
    },
    searchoutlookContactList: (searchQuery: string, PageIndex: number) => {
      dispatch(actions.searchoutlookContactList(searchQuery, PageIndex));
    },
    searchOutLookFolderstlist: (searchFilter: string) => {
      dispatch<any>(actions.searchOutLookFolderstlist(searchFilter));
    },
    searchSharepointContactsItems: (PageIndex: number, searchFilter: string) => {
      dispatch<any>(actions.searchSharePointContacts(PageIndex, searchFilter));
    },
    getOutlookContactFoldersByContactList: () => {
      dispatch<any>(actions.getOutLookFolderstlist());
    },
    getOutLookContactlist: (pageIndex: number) => {
      dispatch<any>(actions.getOutLookContactlist(pageIndex));
    },
    getSharePointContacts: (pageIndex: number) => {
      dispatch<any>(actions.setSpinnerInSideBar(true));
      dispatch<any>(actions.getSharePointContacts(pageIndex));
    },

    GetUsers: () => {
      dispatch<any>(actions.getazurereusers());
      //dispatch<any>(actions.listAzureGroups());
    },
    searchAzureUsers: (searchFilter: string) => {
      dispatch<any>(actions.searchAzureUsers(searchFilter));
    },
    setTabOpened: (tab: string) => {
      dispatch<any>(actions.setTabOpened(tab));
    },
    saveYakChatSettings: async (checkedvalue: string, isSharePoint: string) => {
      //debugger;
      let companyVersion=store.getState().reduxReducer.companyVersion;
        if(companyVersion===2){
      dispatch<any>(actions.saveYakChatSettings_V2(checkedvalue, isSharePoint));
        }
        else{
          dispatch<any>(actions.saveYakChatSettings(checkedvalue, isSharePoint));
        }
    },
    setOpenMessageRoom: (room: any) => {
      dispatch<any>(actions.setOpenMessageRoom(room));
    },
    currentPageIndexForRoomMessages: (pageIndex: any) => {
      dispatch<any>(actions.currentPageIndexForRoomMessages(pageIndex));

    },
    getRoomByRoomId: async (userEmail: any, room: any, pageIndex: any) => {
      
      var userEmail = room.Archived === true ? room.CreatorEmail !== undefined ? room.CreatorEmail : room.Participants[0].Room.CreatorEmail : userEmail;
      let companyVersion=store.getState().reduxReducer.companyVersion;
        if(companyVersion===2)
          {
      dispatch<any>(actions.getPagingMessagesByRoomId_V2( room.Id, pageIndex, "SideBar"));
          }
          else{
            dispatch<any>(actions.getPagingMessagesByRoomId(userEmail, room.Id, pageIndex, "SideBar"));
          }
          dispatch<any>(actions.selectedroom(room.Id));
          dispatch<any>(actions.UserTransLang(room.TranslationLanguage));
    },
    setCurrentTheme: (theme: string) => {
      // dispatch<any>(actions.saveProfileMode(userEmail,theme));
      dispatch<any>(actions.setCurrentTheme(theme));
    },

    saveProfileMode: (userEmail: string, theme: string) => {
      dispatch<any>(actions.saveProfileMode(userEmail, theme));
    },
    setCurrentSelectedSharedEmail: (email: string) => {
      dispatch<any>(actions.setCurrentSelectedSharedEmail(email, "", ""));
    },
    setSelectedContacts: (contact: any) => {
      dispatch<any>(actions.setSelectedContacts(contact));
    },
    selectContactWithCtrl: (contact: any) => {
      dispatch<any>(actions.selectContactWithCtrl(contact));
    },
    setSelectedContactsWithCtrl: (contacts: any) => {
      dispatch<any>({ type: "setSelectedContactsWithCtrl", payload: contacts });
    },
    updateGroupPrimaryOwner: (id: number, email: string) => {
      dispatch<any>(actions.updateGroupPrimaryOwner(id, email));
    },
    SetNewContactData: (contact: any) => {
      dispatch<any>(actions.setnewcontactData(contact));
    },
    CloseConversation: (data: any, value: any) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2){
      dispatch<any>(actions.closeConversation_V2(data, value));
      }
      else
      {
        dispatch<any>(actions.closeConversation(data, value));
      }
    },
    getCircleIndicator: (messageCounter: any) => {
      dispatch<any>(actions.getCircleIndicator(messageCounter));
    },
    getCircleIndicatorForSharedEmail: (messageCounter: any) => {
      dispatch<any>(actions.getSharedInboxMsgCount(messageCounter));
    },
    getYakChatGroupData: (pageIndex: any) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2){
      dispatch<any>(actions.getYakChatGroupData_V2(pageIndex));
      }
      else{dispatch<any>(actions.getYakChatGroupData(pageIndex));

      }

    },
    getYakChatLists: () => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
      {
      dispatch<any>(actions.getYakChatLists_V2());
      }
      else{
        dispatch<any>(actions.getYakChatLists());
      }
    },
    setDefaultContactList: (value: any) => {
      dispatch<any>(actions.setDefaultContactList(value));
    },
    searchConversation: (archiveFlag: any, searchText: any, groupEmail: any, userEmail: any) => {
      
        dispatch<any>(actions.searchConversation(archiveFlag, searchText, groupEmail, userEmail));
      
    },
    searchConversation_V2: (archiveFlag: any, searchText: any, groupEmail: any, userEmail: any,inboxId:any) => {
      dispatch<any>(actions.searchConversation_V2(archiveFlag, searchText, groupEmail, userEmail,inboxId));
    },
    setContactSourceSharePoint: (isSharePoint: any,) => {
      dispatch<any>(actions.setContactSourceSharePoint(isSharePoint));
    },
    setContactSourceOutlook: (isOutlook: any,) => {
      dispatch<any>(actions.setSpinnerInSideBar(true));
      dispatch<any>(actions.setContactSourceOutlook(isOutlook));
    },
    setContactSourceActiveDirectory: (isActiveDirectory: any) => {
      dispatch<any>(actions.setContactSourceActiveDirectory(isActiveDirectory));
    },
    mobileMessageSection: (data: any) => {
      dispatch<any>(actions.mobileMessageSection(data))
    },
    setSpinnerInSideBar: (value: any) => {
      dispatch<any>(actions.setSpinnerInSideBar(true))
    },
    getsharepointcontacts: (pageIndex: number) => {
      dispatch<any>(actions.setSpinnerInSideBar(true));
      dispatch<any>(actions.getSharePointContacts(pageIndex));
    },
    getSharePointContactsPageIndex: (pageIndex: any) => {
      dispatch<any>(actions.getSharePointContactsPageIndex(pageIndex))
    },
    listAzureGroups: () => {
      dispatch<any>(actions.setSpinnerInSideBar(true));

      dispatch<any>(actions.listAzureGroups());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideBarComponent);
