import configData from "../../appsettings.json";
import { store } from "../../index";

export const getazureusers = () => {
  const url = `${configData.graphApiUrl}/v1.0/users?$top=${999}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser?.accessToken,
    },
  });
};

export const searchAzureUsers = (searchQuery: string) => {
  const url = `${configData.graphApiUrl}/v1.0/users?$filter=startswith(displayName,'${searchQuery}') or startswith(givenName,'${searchQuery}') or startswith(surname,'${searchQuery}')`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser?.accessToken,
    },
  });
};

export const editUser = (id: any, data: any) => {
  const url = `${configData.graphApiUrl}/v1.0/users/` + id;
  return fetch(url, {
    method: "PATCH",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser?.accessToken,
    },
    body: JSON.stringify(data),
  });
};

// export const getDirectoryRoles = () => {
//   const url = `${configData.graphApiUrl}/v1.0/directoryRoles/`;

//   return fetch(url, {
//     method: "GET",
//     headers: {
//       Accept: "*/*",
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + store.getState().reduxReducer.loggedInUser?.accessToken,
//     },
//   });
// };

export const getDirectoryRolesOfUser = (id: any = 0) => {
  const url = `${configData.graphApiUrl}/v1.0/users/${id}/memberOf`;

  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser?.accessToken,
    },
  });
};

export const listAzureGroups = () => {
  const url = `${configData.graphApiUrl}/v1.0/groups?$orderby=displayName`;

  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser?.accessToken,
    },
  });
};

export const listGroupMembers = (id: any) => {
  const url = `${configData.graphApiUrl}/v1.0/groups/${id}/members`;

  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser?.accessToken,
    },
  });
};
export const listAzureGroupsMembers = () => {
  const url = `${configData.graphApiUrl}/v1.0/groups?$expand=members`;

  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser?.accessToken,
    },
  });
};