import React, { Component } from 'react'
import { connect, } from "react-redux";
import {
    BrowserRouter as Router,
    Link,
} from "react-router-dom";
import sponser from "../../TeamsApp/images/commsgroup-alt-logo 2.svg"
import logo from "../../TeamsApp/images/CT Text Logo-2.png";
import { ReactComponent as EroorSvg } from "../../TeamsApp/images/error_msg.svg";

class Authentication extends React.Component<any, any>  {
    render() {
        return (
            <section className="userlog_sec error_sec" style={{backgroundColor:this.props.whiteLabelBrand === "calltower" || window.location.href.toLowerCase().includes("calltower")|| window.location.href.toLowerCase().includes("cttext-webex")? "#6bbc15" :
            this.props.whiteLabelBrand ===  "commschoice" || window.location.href.toLowerCase().includes("commschoice") ? "#000045" :
            this.props.whiteLabelBrand ===  "onenet" || window.location.href.toLowerCase().includes("onenet") ? "#E9E9ED":
            this.props.whiteLabelBrand ===  "mobex" || window.location.href.toLowerCase().includes("mobex") ? "#E5E5E5":
            this.props.whiteLabelBrand ===  "berkshire" || window.location.href.toLowerCase().includes("berkshire") ? "#0082CA":
            this.props.whiteLabelBrand ===  "teligentip" || window.location.href.toLowerCase().includes("teligentip") ? "#F37430":
            window.location.href.toLowerCase().includes("webex")?"#E5E5E5":
            this.props.whiteLabelBrand ===  "oneuc" || window.location.href.toLowerCase().includes("oneuc") ? "#492575":"#fcbf00"}}>
                <div className="container" style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <div className="login_box" style={{ textAlign: "center" }}>
                        <div className="error_logo text-right">
                            {this.props.whiteLabelBrand === "calltower" ? <img src={logo} alt="logo" className="app_logo call-tower-logo" style={{ height: "auto", width: "300px", }} /> : this.props.whiteLabelBrand === "commschoice" ?
                                <img src={sponser} alt="#" style={{
                                    height: "auto"
                                }} /> : <><EroorSvg style={{}} /> <img src={"images/splash_logo.svg"} alt="logo" /> </>}
                        </div>
                        <div className="timeOutBox" style={{ backgroundColor: "#fff", }}>
                            <div className="inner_box">
                            <h2>Authentication error</h2>
                                <p className="subheading" style={{ marginTop: "14px" }}>
                                    Your account is not authorised.
                                    Please contact your Administrator or register for a 14-day free trial.
                                    To Register you need to use the new Version of application please click.
                                    <h4> <a style={{color:"#000000"}} href='https://teams.microsoft.com/l/app/95ae1adb-b4ec-4858-908b-1b7f855901f0?source=app-details-dialog'>YakChat 2.1</a></h4>
                                </p>
                                <div style={{ justifyContent: "center", display: "flex", alignItems: "center" }}></div>
                                <Link to="/register">
                                    {/* <button className="btn" style={{ backgroundColor: this.props.whiteLabelBrand === "calltower" || window.location.href.toLowerCase().includes("cttext-webex")? "#6bbc15" : this.props.whiteLabelBrand ===  "commschoice" || window.location.href.toLowerCase().includes("commschoice") ? "#000045" : this.props.whiteLabelBrand ===  "onenet" || window.location.href.toLowerCase().includes("Onenet") ? "#BECE31" 
                                    :this.props.whiteLabelBrand ===  "mobex" || window.location.href.toLowerCase().includes("mobex") ? "#4555AC" 
                                    :this.props.whiteLabelBrand ===  "berkshire" || window.location.href.toLowerCase().includes("berkshire") ? "#0082CA" 
                                    :this.props.whiteLabelBrand ===  "teligentip" || window.location.href.toLowerCase().includes("teligentip") ? "#F37430" 
                                    :window.location.href.toLowerCase().includes("webex")?"#E5E5E5"
                                    :this.props.whiteLabelBrand ===  "oneuc" || window.location.href.toLowerCase().includes("oneuc") ? "#482474" :"#fcbf00", borderRadius: "11px", color: "#fff", marginTop: "10px" }}> Register</button> */}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (props: any) => {
    return {
        loggedInUser: props.reduxReducer.loggedInUser,
        openPage: props.reduxReducer.openPage,
        trailExpier: props.reduxReducer.trailExpier,
        sharedError: props.reduxReducer.sharedError
    };

};
export default connect(mapStateToProps, null)(Authentication);

