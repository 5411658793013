import React from "react";
import "../TeamsApp/globalTheme.css";
import AuthHelper from "../Config/msalConfig";
import { setTimeout } from "timers";
import { connect, } from "react-redux";
import CommsChoice from "../TeamsApp/Login/commsChoice/commsChoice";
import configData from "../appsettings.json"
 class CommsConsent extends React.Component<any, any>  {
  state={
    showLink:false,
    url:"",
    showCommsLogin:false,
  }
  componentDidMount() {
    // window.location.origin
  this.setState({showCommsLogin: this.props.loginValue?false:true});
  }
  checkLoginHandel =()=>{
    window.open("https://login.microsoftonline.com/common/adminConsent?client_id="+configData.client_id+"&redirect_uri="+configData.BaseUrlTeams+"/thankyou.html?brand=commsChoice");
      localStorage.clear();
      sessionStorage.clear();
      this.setState({showCommsLogin:true})
      setTimeout(() => {
        window.location.assign(configData.publishurl+"/#/commsChoiceConsent") 
      }, 1000);  
  }
  handelRefresh =()=>{
    window.location.assign(configData.publishurl+"/#/commschoice")
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => {
      AuthHelper.initSignin();
    }, 500);
   
  }
  render() {
    return (
      <>
      { this.state.showCommsLogin ?  <CommsChoice loginStatus={this.props.loggedInUser}></CommsChoice> :
      <section className="consent_container">
           <div className="consent_popup ">
          <div className="addcontact_box"   style={{backgroundColor:"#000045" }}>
            <div className="sure_detail" style={{backgroundColor:"#fff"}}>
              <div className="addcont_txt">
                <h3>
                Connect to Microsoft Teams
                </h3>
                <p className="save_press">
                <span style={{color:"#999999"}}>Yakchat requires administrator consent to connect with your Microsoft 365 system.</span>
                </p>
              </div>
              <div className="addgr_btnbox" >
                <button
                onClick={this.handelRefresh}
                  className="btn cstm_btn blk_btn comms_color_black" style={{backgroundColor:"#000", color:"#fff"}}
                >
                  {" "}
                  Refresh{" "}
                </button>
                <button
                  className="btn  cstm_btn comms_color"
                  style={{backgroundColor:"#000045", color:"#fff"}}
                  onClick={this.checkLoginHandel}
                >
            Continue
                </button>
              {this.state.showLink ? <a target="_blank" href={this.state.url}></a> :""}
              </div>
            </div>
          </div>
        </div>
      </section>}
      </>
    );
  }
}
const mapStateToProps = (props: any) => {
  return {
    whiteLabelBrand:props.reduxReducer.whiteLabelBrand,
    loginValue:props.reduxReducer.loginValue
  };
};
export default  connect(mapStateToProps, null)(CommsConsent);