import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Link } from "react-router-dom";
import * as actions from "../../redux/actions/actions";
import Logo from "../logo";
class RegisterCompany extends React.Component<any, any> {
  state = {
    Company: {
      Companyname: "",
      BillingAddress: {
        street: "",
        city: "",
        State: "",
        Country: "",      
      },
    },
    accessToken: "",
    registerTheme:"",
  };
  handelCompanyDetails = () => {
    var companyDetailsObj = {
      company: this.state.Company
    }
    this.props.setCompanyDetails(companyDetailsObj, this.state.accessToken);
  }
  componentDidMount() {
    let Companyname;
    let street;
    let city;
    let state;
    let country;
    if (this.props.companyDetails !== undefined) {
      Companyname = this.props.companyDetails.company.Companyname;
      street = this.props.companyDetails.company.BillingAddress.street;
      country = this.props.companyDetails.company.BillingAddress.Country;
      city = this.props.companyDetails.company.BillingAddress.city
      state = this.props.companyDetails.company.BillingAddress.State;
    }
    this.setState({
      Company: {
        ...this.state.Company,
        Companyname: Companyname,
        BillingAddress: {
          ...this.state.Company.BillingAddress,
          street: street,
          city: city,
          State: state,
          Country: country,
        }
      },
    })
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.accessTokenApi !== prevState.accessToken||
			nextProps.registerTheme !== prevState.registerTheme)
			return {
				accessToken: nextProps.accessToken,
				registerTheme: nextProps.registerTheme,
			}
    else return null;
  }

  render() {

    return (
      <div className={this.state.registerTheme === "calltower" ? "userlog_sec calltowerSection_register" : this.props.registerTheme === "commsChoice" ? "userlog_sec commsSection_register" : this.props.registerTheme === "onenet" ? "userlog_sec OneNet_register" 
      : this.props.registerTheme === "mobex" ? "userlog_sec Mobex_register"
      : this.props.registerTheme === "berkshire" ? "userlog_sec Berkshire_register"
      : this.props.registerTheme === "oneuc" ? "userlog_sec Oneuc_register" : this.props.registerTheme === "setup4" ? "userlog_sec setupRegister" : "userlog_sec register_section"}>
        <div className="container">
          <div className="login_box">
          <Logo/>
            <p className="comp_reg text-center">Please complete your details to <br />register for YakChat</p>
            <div className="step_box text-center">
            <a href="javascript:;void(0);" className="step_btn ">Step 1</a>
              <a href="javascript:;void(0);" className="step_btn ">Step 2</a>
              <a href="javascript:;void(0);" className="step_btn step_active">Step 3</a>
              <a href="javascript:;void(0);" className="step_btn">Step 4</a>
              <a href="javascript:;void(0);" className="step_btn">Step 5</a>
            </div>
            <div className="user_detail">
              <div className="register_scroll">
                <h3 className="user_head">Company Details</h3>
                <form>
                  <div className="mb-3">
                    <label className="form-label">Company name</label>
                    <input type="text"
                      value={this.state.Company.Companyname}
                      onChange={(e) => this.setState({
                        Company: {
                          ...this.state.Company,
                          Companyname: e.target.value
                        }
                      })}
                      className="form-control" placeholder="Enter your company name" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">street</label>
                    <input type="text" className="form-control"
                      value={this.state.Company.BillingAddress.street}
                      onChange={(e) => this.setState({
                        Company: {
                          ...this.state.Company,
                          BillingAddress: {
                            ...this.state.Company.BillingAddress,
                            street: e.target.value
                          }
                        }
                      })}
                      placeholder="Street address" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">City</label>
                    <input type="text" className="form-control"
                      value={this.state.Company.BillingAddress.city}
                      onChange={(e) => this.setState({
                        Company: {
                          ...this.state.Company,
                          BillingAddress: {
                            ...this.state.Company.BillingAddress,
                            city: e.target.value
                          }
                        }
                      })}
                      placeholder="City" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Zip/Postal</label>
                    <input type="text" className="form-control"
                      value={this.state.Company.BillingAddress.State}
                      onChange={(e) => this.setState({
                        Company: {
                          ...this.state.Company,
                          BillingAddress: {
                            ...this.state.Company.BillingAddress,
                            State: e.target.value
                          }
                        }
                      })}
                      placeholder="Zip/Postal" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Country</label>
                    <div className="country_select">
                      <select className="form-control "
                        value={this.state.Company.BillingAddress.Country}
                        onChange={(e) => this.setState({
                          Company: {
                            ...this.state.Company,
                            BillingAddress: {
                              ...this.state.Company.BillingAddress,
                              Country: e.target.value
                            }
                          }
                        })}>
                        <option value="" >Country where texts will be sent to</option>
                        <option value="USA">USA</option>
                        <option value="Canada">Canada</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="Australia">Australia</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div className="next_backbtn">
              <Link to="/verifyUsers">
                  <button className="nxtBtn back_btn">Back</button> </Link>
                {this.state.Company.Companyname ? this.state.Company.BillingAddress.street ? this.state.Company.BillingAddress.city ? this.state.Company.BillingAddress.State ? this.state.Company.BillingAddress.Country ?
                  <Link to="/registerDetails">
                    <button className="nxtBtn" onClick={this.handelCompanyDetails}>Next</button>
                  </Link> : <button className={"disable_btn_nxt registerNext"} >Next</button> : <button className={"disable_btn_nxt registerNext"} >Next</button> : <button className={"disable_btn_nxt registerNext"} >Next</button> : <button className={"disable_btn_nxt registerNext"} >Next</button> : <button className={"disable_btn_nxt registerNext"} >Next</button>}
                {/* <Link to="/registerDetails"> </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (props: any) => {

  return {
    accessTokenApi: props.reduxReducer.accessTokenApi,
    userDetails_registeration: props.reduxReducer.userDetails_registeration,
    registerTheme: props.reduxReducer.registerTheme,
    companyDetails: props.reduxReducer.companyDetails_registeration
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    setCompanyDetails: (companyDetailsObj: any, accessToken: any) => {
      dispatch<any>(actions.setCompanyDetails(companyDetailsObj, accessToken));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterCompany);