import * as React from "react";
import ContactComponent from "../../sharedComponent/contactsComponent";

export default class OutlookContactComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      Contactlistdata: [],
      Calledfrom:
        this.props.calledFrom !== undefined ? this.props.calledFrom : "",
      status: this.props.Status,
      ContactFoldersWithContactlists:
        this.props.ContactFolders !== undefined
          ? this.props.ContactFolders
          : [],
      OutlookContacts:
        this.props.ContactDataList !== undefined
          ? this.props.ContactDataList
          : [],
    };
  }
  componentDidMount() {}
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.ContactFolders !== prevState.ContactFoldersWithContactlists ||
      nextProps.calledFrom !== prevState.Calledfrom ||
      nextProps.Status !== prevState.status ||
      nextProps.ContactDataList !== prevState.OutlookContacts
    ) {
      return {
        ContactFoldersWithContactlists: nextProps.ContactFolders,
        status: nextProps.Status,
        Calledfrom: nextProps.calledFrom,
        OutlookContacts: nextProps.ContactDataList,
      };
    } else {
      return null;
    }
  }

  public render() {
    return (
      <React.Fragment>
        <ContactComponent
          searchFilter={this.props.searchFilter}
          tabOpened={this.props.tabOpened}
          calledFrom={this.state.Calledfrom}
          Status={this.state.status}
          ContactFoldersWithConatactList={
            this.state.ContactFoldersWithContactlists
          }
          ContactList={this.state.OutlookContacts}
        />  
      </React.Fragment>
    );
  }
}
