import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";
import React from "react";
import { connect, } from "react-redux";
import "../../../TeamsApp/antd-minified.css";
import "../../../TeamsApp/antd-minified.css";
import AuthService from "../../../Config/msalConfig";
import HeaderComponent from "../../components/layout/headerComponent/headerComponent";
import Setup4Login from "./setup4Login";

import Authentication from "../../loginError/authentication";
import SystemUnavailable from "../../loginError/SystemUnavailable";

class Setup4MainApp extends React.Component<any, any> {
  state = {
    openPage: ""
  };
  componentDidMount() {
    AuthService.initSignin()
  }
  render() {
    return this.props.openPage ? (
      this.props.checkUser ? <Authentication /> :
        <Setup4Login />
    ) : this.props.trailExpier ? (<SystemUnavailable />) : this.props.sharedError ? (<SystemUnavailable />) :
      this.props.loggedInUser &&
        typeof this.props.loggedInUser !== "string" ? (<HeaderComponent></HeaderComponent>) : (
        <Router>
          <div>
            <Link to="/#/setup4">
              <Setup4Login loginStatus={this.props.loggedInUser}></Setup4Login>
            </Link>
          </div>
        </Router>
      );
  }
}

const mapStateToProps = (props: any) => {
  return {
    loggedInUser: props.reduxReducer.loggedInUser,
    openPage: props.reduxReducer.openPage,
    trailExpier: props.reduxReducer.trailExpier,
    sharedError: props.reduxReducer.sharedError,
    checkUser: props.reduxReducer.checkUser,
  };

};
  export default  connect(mapStateToProps, null)(Setup4MainApp);