import React from "react";
import AuthHelper from "../../Config/msalConfig";
import logo1 from "../../TeamsApp/images/webexLOADER.svg";
import logo from "../../TeamsApp/images/YakChat Icon.png";
import { connect } from "react-redux";
import * as actions from "../redux/actions/actions";
import { ThunkDispatch } from "redux-thunk";
import { Link, useHistory } from "react-router-dom";

 function LoginWebex(props: any) {
  const history = useHistory();

   const setLoginUser =()=>{
     debugger;
     let webexC:any =localStorage.getItem("webexC");
     localStorage.clear();
     sessionStorage.clear();
     
     localStorage.setItem(
         "webexC",
         webexC
       );
    AuthHelper.signIn()
    props.checkRegisterUser(true);
   }
   const openRegister =()=>{
    debugger;
    setTimeout(() => {
      window.location.reload()
    }, 200);
   // history.push('/register')  
  // window.location.reload();
    //  setTimeout(() => {
    //    history.push('/register')  
    //   //  window.location.reload();
    //   debugger
    // } , 200);
  }
  return (
    <section className="userlog_sec error_sec "style={{backgroundColor:"#E5E5E5"}}>
      <div className="container">
        <div className="login_box" style={{ textAlign: "center" }}>  
          <div className="error_logo logoLogin_div text-center">
            {props.loginStatus === "loggingIn" ? (
              <img src={logo}  alt="logo"
                
              /> 
            ) : (
              <>
              <img src={logo} className="errorSignInLogo" alt="logo" style={{margin:"40px"}} />
              <div style ={{color:" rgb(0 0 0 / 60%)"}} className ="errorSignIntext">Sign in using your registered credentials</div>
              {/* <div style ={{color:"#000"}} className="welcome_Line">Send and receive SMS text messages in Microsoft Teams</div> */}
              </> )}
          </div>
          {props.loginStatus === "loggingIn" ? (
            // <img src={logo1}  alt="logo"  style={{marginTop:"30px"}}
            //     /> 
           <p></p>
          ) : (
            <>
            <button id="loginButtonMainInTeams" onClick={setLoginUser} className="btn cstm_btn gry_btn"
              style={{marginTop:"20px",  backgroundColor:"#000", color:"#fff"}}>
              Sign in
            </button>
            <Link to="/register">
            <button   className="btn cstm_btn gry_btn register_btn" style={{color:"#000",borderColor: "#000",background: "#E5E5E5"}}>
             Register
            </button>
            </Link>
            {/* <span className="signin_spinner">
                  <Spin spinning={this.state.SpinnerValueState} indicator={antIcon}  className="login_spinner " style={{ zIndex: 9999, }} />
                </span> */}
           </> 
          )}
        </div>
      </div>
    </section>
  );
}
const mapStateToProps = (props: any) => {
  return {
    whiteLabelBrand:props.reduxReducer.whiteLabelBrand
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    checkRegisterUser:(checkUser:boolean) => {   
      debugger;  
      dispatch<any>(actions.checkRegisterUser(checkUser));
    }, 
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(LoginWebex);