import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as actions from "../../redux/actions/actions";
import UserListComponent from "../UsersComponent/UserListComponent";

class UserParentComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      calledfrom: "",
      GetUsers: [],
      userselected: this.props.Userselected,
    };
  }
  componentDidMount() {
    // this.props.GetUsers();
    // this.props.listAzureGroups();  
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.GetUserData !== prevState.GetUsers ||
      nextProps.Userselected !== prevState.userselected
    ) {
      return {
        GetUsers: nextProps.GetUserData,
        userselected: nextProps.Userselected,
      };
    } else {
      return null;
    }
  }

  public render() {
    
    return (
      <React.Fragment>
        {this.state.GetUsers !== undefined ? (
          <UserListComponent
            searchFilter={this.props.searchFilter}
            tabOpened={this.props.tabOpened}
            UsersList={this.state.GetUsers}
            calledFrom={"Users"}
            UserSelected={this.state.userselected}
            azureGroupData={this.props.azureGroups}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {
    GetUserData: props.reduxReducer.GetUsers,
    azureGroups: props.reduxReducer.azureGroups,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any
) => {
  return {
    GetUsers: () => {
      dispatch<any>(actions.getazurereusers());
    },
    listAzureGroups: () => {
      dispatch<any>(actions.listAzureGroups());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserParentComponent);
