import React from "react";
import { connect } from "react-redux";
// import "./style.css";
import { ThunkDispatch } from "redux-thunk";
import * as action from "../../../redux/actions/actions";
import * as commonMethod from "../../../commonMethods";
import { ReactComponent as OutlineSvg } from "../../../images/_Outlined.svg";
import parsePhoneNumber, { parseNumber } from 'libphonenumber-js';
import { ReactComponent as PlusSvg } from "../../../images/plus.svg";
import { ReactComponent as MinusOutlineSvg } from "../../../images/minus-icon-ouline-orange.svg";
// import { convertListsFromRefsIntoObjects} from "../../../commonMethods";
import InputMask from "react-input-mask";
import { store } from "../../../..";

class UploadCsv extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      filesPresent: [],
      errorsFoundIn: [],
      contactsToBeSaved: [],
      contactsUploads:[],
      openTab: "uploadFiles",
      searchQuery: "",
      filteredContactsBySearch: [],
      shouldDisplayAllErrors: false,
      maxIndexOfContactsYet: 0,
      dialogBox: "",
      groupEmail: "",
      mandatoryFiledError: false,
      yakchatLists: "",
      list_Id: "",
      groupSelected: false,
      phoneNumberError: "",
      previousContacts: [],
      contactData:[],
    };
  }
  validateContact: any = (contact: any, sequence: any) => {
    if (contact.length > 1) {
      let phoneNumber = contact[sequence.findIndex((i: any) => i === "phonenumber")];
      if (phoneNumber?.startsWith("0")){
        phoneNumber = phoneNumber.replace(/^0+/, '')
        }
      if( this.props.loggedInUserDefaultCountryCode === 1){
      }else if( this.props.loggedInUserDefaultCountryCode === 61){
      }else if( this.props.loggedInUserDefaultCountryCode === 44){ }
      if (phoneNumber?.startsWith('+')) {
        var validPhoneNumber = parsePhoneNumber(phoneNumber);
   
        if (validPhoneNumber !== undefined) {
          this.setState({ phoneNumberError: false })
        }  else{
          this.setState({ phoneNumberError: true })
        }
      }
      else {
  
        var newPhoneNumber = "+" + this.props.loggedInUserDefaultCountryCode + phoneNumber
        var newValidPhoneNumber = parsePhoneNumber(newPhoneNumber);
        if (newValidPhoneNumber === undefined) {
          this.setState({ phoneNumberError: true })
        } else {
          this.setState({ phoneNumberError: false })
        }
      }
      if (contact[sequence.findIndex((i: any) => i === "firstname")] === "")
        return false;

      if (contact[sequence.findIndex((i: any) => i === "lastname")] === "")
        return false;
      // var phoneno = /^\+?[1-9]\d{1,14}$/;
      if (contact[sequence.findIndex((i: any) => i === "phonenumber")] === "")
        return false;
      // if (contact[sequence.findIndex((i: any) => i === "email")] === "")
      //   return false;

      return true;
    }
    return true;

  };
  saveNewContacts =()=>{}

  changeUploadProgress = async (uploadProgress: any, fileIndex: number) => {
  
    uploadProgress = JSON.stringify(uploadProgress).substr(0, 4);
    if (document.getElementById("prog_value_UploadCsv")) {
      document.getElementById(
        "prog_value_UploadCsv"
      )!.innerHTML = `${uploadProgress}%`;
      document.getElementById(
        "prog_bar_UploadCsv"
      )!.style.width = `${uploadProgress}%`;
    } else return false;

    await this.sleep(0);
    if (uploadProgress === "100") {
      const updatedFilesPresent = JSON.parse(
        JSON.stringify(this.state.filesPresent)
      );
      updatedFilesPresent[fileIndex] = {
        ...updatedFilesPresent[fileIndex],
        uploadProgress: "uploaded",
      };
      this.setState({ filesPresent: updatedFilesPresent });
    }
    return true;
  };
  sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  uploadThisFile = (file: any) => {
    var reader: any = new FileReader();
    reader.onload = (e: any) => {
      var data = e.currentTarget.result;
      const searchRegExp = /\r/g;
      const replaceWith = '';
      // e.currentTarget.result.replaceAll(`\r`, "");
      data = data.replace(searchRegExp, replaceWith);
      let rows = data.split("\n");
      const index = this.state.filesPresent.length;
      const newFileObjectinState = {
        name: file.name,
        uploadProgress: "uploading",
      };
      // cancelled flag to check if the file was cancelled while uploading:
      let isCancelled = false;
      
      this.setState(
        {
          filesPresent: [...this.state.filesPresent, newFileObjectinState],
        },
        async () => {
          await this.sleep(10);
          const contactsToBeSaved = [];
          let maxIndexOfContactsYet = 0;
         
          rows = rows.map((c: string) => c.split(","));
          for (let i = 0; i < 4; i++) {
            if(rows[0][0].toLowerCase() !== "firstname" ){
              let isCancelled = true;
              
      
      this.setState(
        {
          filesPresent:[ "failed"],
        });
            
              this.props.setAlertData({
                text: "Please add mandatory Fields",
                className: "danger",
              });
              setTimeout(() => this.props.setAlertData(null), 2000);
              return;
            }else if(rows[0][1].toLowerCase() !== "lastname"){
              this.setState(
                {
                  filesPresent:[ "failed"],
                });
              this.props.setAlertData({
                text: "Please add mandatory Fields",
                className: "danger",
              });
              setTimeout(() => this.props.setAlertData(null), 2000);
              return;
            }else if(rows[0][2].toLowerCase() !== "phonenumber"){
              isCancelled = true;
              this.setState(
                {
                  filesPresent:[ "failed"],
                });
              this.props.setAlertData({
                text: "Please add mandatory Fields",
                className: "danger",
              });
              setTimeout(() => this.props.setAlertData(null), 2000);
              return;
            }


            rows[0][i] = rows[0][i].toLowerCase();

          }
          const sequence = rows[0].map((r: any) =>
            r === "firstname"
              ? "firstname" :
              r === "lastname"
                ? "lastname"
                : r === "phonenumber"
                  ? "phonenumber"
                  : r
          );
          const errorsFoundIn: any[] = [];
          for (let i = 1; i < rows.length; i++) {
            let row = rows[i]
            // row.replace( /[\r\n]+/gm, "" );
            if (this.state.filesPresent[index].uploadProgress === "cancelled") {
              isCancelled = true;
              const copyOfFilesPresent = this.state.filesPresent.filter(
                (x: any, i: number) => i !== index
              );
              this.setState({ filesPresent: copyOfFilesPresent });
              break;
            }

            if (this.props.tabOpened !== "UploadCsv") {
              isCancelled = true;
              return;
            }
            // removing preceding and succeeding white space
            row = row.map((r: string) => r.trim());
            if (!this.validateContact(row, sequence)) {
              errorsFoundIn.push({ rowNumber: i, fileIndex: index });
              if (
                !(await this.changeUploadProgress(
                  ((i + 1) * 100) / rows.length,
                  index
                ))
              ) {
                isCancelled = true;
                return;
              }
              continue;
            } else {
              
              let obj: any = `{"${sequence[3]}": "${row[3]}", "${sequence[1]}": "${row[1]}", "${sequence[2]}": "${row[2]}",`;
              obj += ` "${sequence[0]}": "${row[0]}"}`;
              try {
                if(row[1] !== undefined ){
                  obj = JSON.parse(obj);
                  contactsToBeSaved.push({
                    ...obj,
                    id: maxIndexOfContactsYet,
                    fileIndexInWhichItExists: index,
                    isSelected: true,
                  });
                  maxIndexOfContactsYet++;
                }
                
                if (!(await this.changeUploadProgress(
                  ((i + 1) * 100) / rows.length,
                  index
                ))
                ) {
                  isCancelled = true;
                  return;
                }
              } catch (err) {
                console.log(err);
                if (!(await this.changeUploadProgress(
                  ((i + 1) * 100) / rows.length,
                  index
                ))
                ) {
                  isCancelled = true;
                  return;
                }
                errorsFoundIn.push({ rowNumber: i, fileIndex: index });
                continue;
              }
            }  
          }
          if (!isCancelled)
            this.setState({
              errorsFoundIn,
              contactsToBeSaved,
              maxIndexOfContactsYet,
            });
          return;
        }
      );
    };

    reader.readAsText(file);
  };
  fileDrop = (e: any) => {
    e.preventDefault();
    if (
      this.state.filesPresent.filter(
        (x: any) => x.uploadProgress === "uploading"
      ).length > 0
    ) {
      this.props.setAlertData({
        text: "Please wait until the current file is uploaded",
        className: "danger",
      });
      setTimeout(() => this.props.setAlertData(null), 2000);
      return;
    }
    const files = e.dataTransfer.files;

    if (!files[0] ||( files[0].type !== "application/vnd.ms-excel"&& files[0].type!=="text/csv")) {
      // display error here saying that the type of file is not correct.
      const newFileObjectinState = {

        name: files[0] ? files[0].name : "error",
        uploadProgress: "failed",
      };
      this.setState({
        filesPresent: [...this.state.filesPresent, newFileObjectinState],
      });
      return;
    }

    this.uploadThisFile(files[0]);
  };
  checkMandatoryField = (e: any) => {
    if (this.state.phoneNumberError === true) {
      this.props.setAlertData({
        className: "danger",
        text: "Invalid Phone Number",
      });
      setTimeout(() => {
        this.props.setAlertData(null);
      }, 2000);
    } else {
      if (this.state.errorsFoundIn.rowNumber !== 1) {
        
        if (this.state.errorsFoundIn.length) { 
          this.props.setAlertData({
            className: "danger",
            text: "Invalid Format",
          });
          setTimeout(() => {
            this.props.setAlertData(null);
          }, 2000);

        } else {
          this.onNext(e)
        }
      }
      else {
        this.onNext(e)
      }
    }
  }
  onNext = (e: any) => {
    e.preventDefault();
    // if (
    //   this.state.filesPresent.filter(
    //     (x: any) => x.uploadProgress !== "uploaded"
    //   ).length === 0
    // ) {
    //   this.props.setAlertData({
    //     className: "danger",
    //     text: "Please remove the files with incorrect format!",
    //   });
    //   setTimeout(() => {
    //     this.props.setAlertData(null);
    //   }, 2000);

    //   return;
    // }
    // console.log("this.state.filesPersent",this.state.filesPresent.length)
    if ( this.state.filesPresent.length === 0 ) {
      this.props.setAlertData({
        className: "danger",
        text: "Please add at least 1 file.",
      });
      setTimeout(() => {
        this.props.setAlertData(null);
      }, 2000);
      return;
    }


    if (this.state.contactsToBeSaved.length > 1000) {
      this.props.setAlertData({
        className: "danger",
        text: "Retry with a file less than 1000 contacts.",
      });
      setTimeout(() => {
        this.props.setAlertData(null);
      }, 2000);
      return;
    }
   

    // for (let contact of this.state.contactsToBeSaved) { 
    //   
    //   var regex = /[a-zA-Z]/g
    //    let isNum =regex.test(contact.phonenumber)
    //   // if( isNum === false) {
    //   //   
    //   //   const newContactsToBeSaved = this.state.contactsToBeSaved.map((c: any, j: number) => {
    //   //     {
    //   //       c.isSelected = false;
    //   //     }
    //   //     return c;
    //   // });
    //   //   console.log("regex.test contact.phonenumber)",contact.phonenumber)
    //   // } 
    // }
    this.setState({ openTab: "uploadContacts" });
  };

  saveContacts = () => {
    this.props.setSpinner(true)
    
    var count = 0;
    for (let contact of this.state.contactsToBeSaved) {
      contact.phonenumber=   contact.phonenumber.replace(/\D/g,'');
      contact.phonenumber=  contact.phonenumber.replace("(", "") ;
      contact.phonenumber= contact.phonenumber.replace(")", "");
      contact.phonenumber= contact.phonenumber.replace("-", "");
      contact.phonenumber.trim();
         if (contact.phonenumber.startsWith('61')) {
          contact.phonenumber = "+" + contact.phonenumber
         }else if (contact.phonenumber.startsWith('1')) {
          contact.phonenumber = "+" + contact.phonenumber
         }else if (contact.phonenumber.startsWith('44')) {
          contact.phonenumber = "+" + contact.phonenumber
         }else if (contact.phonenumber.startsWith('0')){
           if(this.props.loggedInUserDefaultCountryCode === 1){
            contact.phonenumber =  contact.phonenumber.replace("0","");
            contact.phonenumber = "+" + this.props.loggedInUserDefaultCountryCode + contact.phonenumber
           } 
         }else{
          contact.phonenumber ="+" + this.props.loggedInUserDefaultCountryCode + contact.phonenumber
         }
      if (contact.isSelected) {
        let companyVersion=store.getState().reduxReducer.companyVersion;
        contact = {
          "ContactId":count,
          "PhoneNumber": contact.phonenumber,
          "UserEmail":companyVersion!==2?this.props.loggedInUser.Email:this.props.loggedInUser.user.Email ,
          "ForeName": contact.firstname,
          "SureName": contact.lastname,
          "ContactEmail": contact.email,
          "GroupEmail": this.state.groupEmail,
          "saved":0
        };
        count++;
        this.state.contactData.unshift(contact);  
      }
    }  
    this.props.saveYakChatContact(this.state.contactData, count);   
    
  };
  render() {
    let companyVersion=store.getState().reduxReducer.companyVersion;
    const toReturn = [];
    debugger;
    if (this.state.openTab === "uploadFiles")
      toReturn.push(
        <div className="right_Sec" key="mainUploadCsv" id="right_Sec_main">
          <div className="top_row">
            <div className="top_left">
              <div className="usr_imgcon">
                <div className="usr_txt">
                  <h3>Import Contacts </h3>
                </div>
              </div>
            </div>
            {/* <a href="javascript:;void(0);" className="bck_arw" id="back_arrow"><i className="fas fa-arrow-left"></i> </a> */}
          </div>
          <div className="chat_or_info">
            <div className="chat_sec">
              <div className="chat_bx">
                <div className="drag_main">
                  <div
                    className="drag_contsec"
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={(e) => e.preventDefault()}
                    onDragLeave={(e) => e.preventDefault()}
                    onDrop={this.fileDrop}
                  >
                    <div className="drag_content">
                      <svg
                        style={{ fill: "var(--primary)" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="46"
                        height="50"
                        viewBox="0 0 46 50"
                      >
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g />
                                </g>
                              </g>
                            </g>
                            <g>
                              <path d="M45.606 34.48v-2.388a2.39 2.39 0 0 0-2.389-2.39h-3.584v-3.585a2.39 2.39 0 0 0-2.391-2.39h-2.389a2.39 2.39 0 0 0-2.39 2.39v3.586H28.88a2.39 2.39 0 0 0-2.391 2.389v2.389a2.39 2.39 0 0 0 2.39 2.39h3.585v3.585a2.39 2.39 0 0 0 2.389 2.389h2.389a2.39 2.39 0 0 0 2.39-2.39v-3.584h3.585a2.39 2.39 0 0 0 2.39-2.39z" />
                            </g>
                            <g>
                              <path d="M36.048 14.17L22.393.514v11.642c0 1.11.902 2.014 2.014 2.014z" />
                            </g>
                            <g>
                              <path d="M24.406 14.17h11.642L22.393.514v11.642c0 1.11.902 2.014 2.013 2.014z" />
                            </g>
                            <g>
                              <g>
                                <path d="M34.853 42.845h1.195V23.728h-1.195a2.39 2.39 0 0 0-2.39 2.389v3.586H28.88a2.39 2.39 0 0 0-2.391 2.389v2.389a2.39 2.39 0 0 0 2.39 2.39h3.585v3.585a2.39 2.39 0 0 0 2.389 2.389z" />
                              </g>
                              <g>
                                <path d="M34.853 45.576a5.127 5.127 0 0 1-5.122-5.12v-.854h-.852a5.126 5.126 0 0 1-5.121-5.121v-2.39a5.127 5.127 0 0 1 5.121-5.12h.852v-.854a5.127 5.127 0 0 1 5.122-5.12h1.195V16.9H24.406a4.75 4.75 0 0 1-4.744-4.745V.514H3.275a2.73 2.73 0 0 0-2.73 2.73v43.697a2.73 2.73 0 0 0 2.73 2.73h30.042a2.731 2.731 0 0 0 2.731-2.73v-1.365z" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <h3>
                        Drop your contacts files here, or{" "}
                        <a
                          href="javascript:;void(0);"
                          onClick={() => {  
                       
                            document.getElementById("fileUploadCsvImport")
                              ?.click();
                          }}
                        >
                          browse
                        </a>
                      </h3>
                      <form
                        id="uploadCsvInputForm"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <input
                          type="file"
                          onChange={(
                            e: any
                          ) => {
                            
                            if (
                              this.state.filesPresent.filter(
                                (x: any) => x.uploadProgress === "uploading"
                              ).length > 0
                            ) {
                              this.props.setAlertData({
                                text:
                                  "Please wait until the current file is uploaded",
                                className: "danger",
                              });
                              setTimeout(
                                () => this.props.setAlertData(null),
                                2000
                              );
                              return;
                            }
                            if (
                              e.currentTarget?.files
                                ? e.currentTarget?.files[0] &&
                                (e.currentTarget?.files[0].type !==
                                "application/vnd.ms-excel"&& e.currentTarget?.files[0].type!=="text/csv")
                                : true

                            ) {
                             
                              const newFileObjectinState = {
                                name: e.currentTarget?.files
                                  ? e.currentTarget?.files[0].name
                                  : null,
                                uploadProgress: "failed",
                              };
                              console.log(newFileObjectinState);
                              this.setState({
                                filesPresent: [
                                  ...this.state.filesPresent,
                                  newFileObjectinState,
                                ],
                              });

                              this.props.setAlertData({
                                className: "danger",
                                text: "Acceptable file formats include .csv files",
                              });
                              setTimeout(() => {
                                this.props.setAlertData(null);
                              }, 2000);
                              return;
                            }
                            console.log(e.currentTarget?.files?[0]:"emptyfile2");
                            e.currentTarget?.files &&
                              e.currentTarget?.files[0] &&
                              this.uploadThisFile(e.currentTarget?.files[0]);
                            e.currentTarget.value = "";
                            e.currentTarget.type = "";
                            e.currentTarget.type = "file";
                          }}
                     
                          id="fileUploadCsvImport"
                          style={{ display: "none" }}
                        ></input>
                      </form>
                      <p>
                        Please upload the file containing your contacts.
                        Acceptable file formats include .csv files.
                        Please{" "}
                        <a
                          href="javascript:;void(0);"
                          onClick={() => {
                            //creating an invisible element
                            var element = document.createElement("a");
                            element.setAttribute(
                              "href",
                              "data:text/plain;charset=utf-8," +
                              encodeURIComponent(
                                `FirstName,LastName,PhoneNumber,Email\n`
                              )
                            );
                            element.setAttribute("download", "Contacts.csv");
                            document.body.appendChild(element);
                            element.click();
                            document.body.removeChild(element);
                          }}
                        >
                          click here
                        </a>{" "}
                        to download a template for adding contacts
                      </p>
                    </div>
                  </div>
                  <div className="upload_sec">
                    {this.state.filesPresent?.map((file: any, i: number) =>
                      file.uploadProgress === "uploaded" ? (
                        <div className="cont_uploadbx" key={i}>
                          <div className="file_nm">
                            <h4>{file.name}</h4>
                          </div>
                          <div className="file_icon">
                            <div className="uplo_done">
                              <img src="images/yellow_correct.svg" />
                            </div>
                            <div
                              className="uplo_canc ml-2"
                              onClick={(e:any) => {
                                if (
                                  this.state.filesPresent.filter(
                                    (x: any) => x.uploadProgress === "uploading"
                                  ).length > 0
                                ) {
                                  this.props.setAlertData({
                                    text:
                                      "Please wait until the current file is uploaded",
                                    className: "danger",
                                  });
                                  setTimeout(
                                    () => this.props.setAlertData(null),
                                    2000
                                  );
                                  return;
                                }
                                let copyOfFilesPresent = this.state.filesPresent.filter(
                                  (x: any, j: number) => i !== j
                                );
                                let newContactsToBeSaved = this.state.contactsToBeSaved.filter(
                                  (y: any) => y.fileIndexInWhichItExists !== i
                                );
                                newContactsToBeSaved.map((c: any) => {
                                  if (c.fileIndexInWhichItExists > i)
                                    c.fileIndexInWhichItExists--;
                                  return c;
                                });
                                let newErrorsFoundIn = this.state.errorsFoundIn.filter(
                                  (y: any) => y.fileIndex !== i
                                );
                                newErrorsFoundIn.map((c: any) => {
                                  if (c.fileIndex > i) c.fileIndex--;
                                  return c;
                                });
                                this.setState({
                                  filesPresent: copyOfFilesPresent,
                                  contactsToBeSaved: newContactsToBeSaved,
                                  errorsFoundIn: newErrorsFoundIn,
                                });
                              }}
                            >
                              <a href="javascript:;void(0);">
                                <OutlineSvg  />
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : file.uploadProgress === "uploading" ? (
                        <div className="cont_uploadbx" key={i}>
                          <div className="file_nm w-100">
                            <div className="progr_cont">
                              <div className="file_nm">
                                <h4>{file.name}</h4>
                              </div>
                              <div
                                className="prog_value"
                                id="prog_value_UploadCsv"
                              >
                                0%
                              </div>
                            </div>
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-success"
                                role="progressbar"
                                id="prog_bar_UploadCsv"
                                style={{ width: `0%` }}
                              ></div>
                            </div>
                          </div>
                          <div className="file_icon">
                            <div
                              className="uplo_canc ml-2"
                              onClick={() => {
                                let copyOfFilesPresent = this.state.filesPresent.slice();
                                copyOfFilesPresent[i].uploadProgress =
                                  "cancelled";
                                this.setState({
                                  filesPresent: copyOfFilesPresent,
                                });
                              }}
                            >
                              <a href="javascript:;void(0);">
                                <OutlineSvg onClick={() =>{
                                  (document.getElementById('fileUploadCsvImport')as HTMLInputElement).value=""
                                } } />
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="cont_uploadbx" key={i}>
                          <div className="file_nm">
                            <h4>{file.name}</h4>
                          </div>
                          <div className="file_icon">
                            <p className="error_msg">Incorrect file format</p>
                            <div className="uplo_done">
                              <img src="images/error_msg.svg" />
                            </div>
                            <div
                              className="uplo_canc ml-2"
                              onClick={(e:any) => {
                                  (document.getElementById('fileUploadCsvImport')as HTMLInputElement).value=""
                                
                                let copyOfFilesPresent = this.state.filesPresent.filter(
                                  (x: any, j: number) => i !== j
                                );
                                let newContactsToBeSaved = this.state.contactsToBeSaved.filter(
                                  (y: any) => y.fileIndexInWhichItExists !== i
                                );
                                let newErrorsFoundIn = this.state.errorsFoundIn.filter(
                                  (y: any) => y.fileIndex !== i
                                );
                                this.setState({
                                  filesPresent: copyOfFilesPresent,
                                  contactsToBeSaved: newContactsToBeSaved,
                                  errorsFoundIn: newErrorsFoundIn,
                                });
                              }}
                            >
                              <a href="javascript:;void(0);">
                                <OutlineSvg  />
                              </a>
                            </div>
                          </div>
                        </div>
                      )
                    )}

                  </div>
                </div>
              </div>

              <div className="drag_btnbox">
                <a
                  href="javascript:;void(0);"
                  className="drag_btn cancel_btn"
                  onClick={() => {
                    this.setState({ dialogBox: "cancel" });
                  }}
                >
                  Cancel
                </a>
                <a
                  href="javascript:;void(0);"
                  className={`drag_btn next_btn ${this.state.filesPresent.filter(
                    (x: any) =>{return x.uploadProgress !== "uploaded"}
                  ).length > 0 || this.state.filesPresent.length === 0 
                    ? null
                    : "next_yellow"
                    }`}
                  onClick={this.checkMandatoryField}
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    else if (this.state.openTab === "uploadContacts") {
      const errorsArr = [];
      if (
        this.state.shouldDisplayAllErrors ||
        this.state.errorsFoundIn.length < 4
      ) {
        for (let i = 0; i < this.state.errorsFoundIn.length; i++) {
          errorsArr.push(
            <React.Fragment key={"errorNum-" + i}>
              Row {this.state.errorsFoundIn[i].rowNumber + 1} in file{" "}
              {this.state.errorsFoundIn[i].fileIndex + 1}
              <br />
            </React.Fragment>
          );
        }
      } else {
        for (let i = 0; i < 4; i++) {
          errorsArr.push(
            <React.Fragment key={"errorNum-" + i}>
              Row {this.state.errorsFoundIn[i].rowNumber + 1} in file{" "}
              {this.state.errorsFoundIn[i].fileIndex + 1}
              <br />
            </React.Fragment>
          );
        }
        errorsArr.push(
          <React.Fragment key="errorLast">
            {" "}
            And{" "}
            {this.state.errorsFoundIn.length -
              (this.state.errorsFoundIn.length < 4
                ? this.state.errorsFoundIn.length
                : 4)}{" "}
            others.
          </React.Fragment>
        );
      }

      toReturn.push(
        <div className="right_Sec" key="mainUploadCsv">
          <div className="top_row">
            <div className="top_left">
              <div className="usr_imgcon">
                <div className="usr_txt">
                  <h3>Import Contacts </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="chat_or_info">
            <div className="chat_sec">
              <div className="chat_bx">
                <div className="cntct_frm cntct_view contct_upl">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="cntcts_grp">
                        <div className="cntct_hedr">
                          <h2>Imported Contacts</h2>
                          <span className="sub_txt">
                            {" "}
                            {this.state.contactsToBeSaved.length} contacts
                          </span>
                        </div>
                        <div className="srch_bx">
                          <input
                            value={this.state.searchQuery}
                            onChange={(e: any) => {
                              this.setState({
                                searchQuery: e.target.value,
                                filteredContactsBySearch: this.state.contactsToBeSaved.filter(
                                  (c: any) =>
                                    c.firstname
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase()) ||
                                    c.lastname
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase()) ||
                                    c.email
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase()) ||
                                    c.phonenumber
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase()) ||
                                    (c.firstname + " " + c.lastname)
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase())
                                ),
                              });
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Search Contacts"
                          />
                        </div>
                        <ul className="usr_list import_contactbox">
                          {
                            this.state.contactsToBeSaved.map(
                              (c: any, i: number) => {
                                return (
                                  <li key={i}>
                                    <div className="usr_txt">
                                      <h3
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          textAlign: "left",
                                          paddingRight: "46px",
                                        }}
                                      >
                                        {" "}
                                        {c.firstname} {c.lastname}{" "}
                                      </h3>
                                      <div className="txt_div">
                                      <InputMask className={"sub_txt no_border"}
                                        disabled={true}
                                          mask={ 
                                              this.props.loggedInUserDefaultCountryCode === 1 ?
                                               c.phonenumber.startsWith("+1") ? ("+9 (999) 999-9999") :("(999) 999-9999")  : this.props.loggedInUserDefaultCountryCode === 44 ? c.phonenumber.startsWith("+44") ? ("+99 09999 999999") :  ("09999 999999")
                                                :this.props.loggedInUserDefaultCountryCode === 61 ?   c.phonenumber.startsWith("+61") ?
                                                ("+99 09 9999 9999"):("09 9999 9999"):""
                                              }    
                                          value={c.phonenumber }
                                        />
                                      </div>
                               
                                      <a
                                          href="#"
                                          className="add_btn "
                                        
                                        >
                                          {" "}
                                          { c.isSelected === true?
                                        <MinusOutlineSvg className="ctThem"
                                        onClick={() => {
                                            const newContactsToBeSaved = this.state.contactsToBeSaved.map((c: any, j: number) => {
                                                if(i === j) {
                                                  c.isSelected = false;
                                                }
                                                return c;
                                            });
                                           this.setState({
                                              contactsToBeSaved: newContactsToBeSaved,
                                            });
                                          }}
                                      ></MinusOutlineSvg>:
                                         ( <PlusSvg fill="var(--secondary)" style={{backgroundColor:"var(----surface)",width:"16px",height:"16px"}}
                                         onClick={() => {
                                            const newContactsToBeSaved = this.state.contactsToBeSaved.map((c: any, x: number) => {
                                                if(i === x) {
                                                  c.isSelected = true;
                                                }
                                                return c;
                                            });
                                            this.setState({
                                              contactsToBeSaved: newContactsToBeSaved,
                                            });
                                          }}
                                         ></PlusSvg>) }
                                        </a>
                                    </div>
                                  </li>
                                );
                              }
                          
                            )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="grp_info">
                        <div className="cntct_hedr">
                          <h2> Import actions</h2>
                        </div>
                        {/* <div className="phone_txt vlu_txt start_over">
                          <a
                            href="#"
                            onClick={() => {
                              this.setState({
                                filesPresent: [],
                                errorsFoundIn: [],
                                contactsToBeSaved: [],
                                openTab: "uploadFiles",
                                searchQuery: "",
                                filteredContactsBySearch: [],
                                shouldDisplayAllErrors: false,
                                maxIndexOfContactsYet: 0,
                              });
                            }}
                          >
                            <img src="images/upload_file_black.svg" /> Start
                            over
                          </a>
                        </div> */}

                        {/* <div
                          className="phone_txt vlu_txt start_over no_marg"
                          style={{ textAlign: "left" }}
                        >
                          <a
                            href="javascript:;void(0);"
                            onClick={(e: any) => {
                              e.preventDefault();
                              this.setState({
                                dialogBox: "ShareContactsToSharedInbox",
                                temporaryGroupEmail: this.state.groupEmail,
                              });
                            }}
                          >

                            <img src="images/_group-add.svg" /> Add contacts to
                            a group
                          </a>
                        </div> */}
                        {this.props.ServicePlanId === 1 || this.props.ServicePlanId === 3 ? "" :
                          <div
                            className="phone_txt vlu_txt start_over no_marg"
                            style={{ textAlign: "left" }}
                          >
                            <a
                              href="javascript:;void(0);"
                              onClick={(e: any) => {
                                e.preventDefault();
                                // this.setState({
                                //   dialogBox: "ShareContactsToSharedInbox",
                                // });
                                this.setState({
                                  dialogBox: "AddContactsToAGroup",
                                  temporaryGroupEmail: this.state.groupEmail,
                                });
                              }}
                            >
                              <img src="images/_group-add.svg" /> Share contacts
                            to shared inbox
                          </a>
                          </div>}
                        {this.state.errorsFoundIn.length > 0 ? (
                          <div className="error_box">
                            <p>
                              {this.state.errorsFoundIn.length} fields were
                              formatted incorrectly. We removed all fields with
                              incorrect formatting. The following fields were
                              removed:
                            </p>
                            <p className="m-0">
                              {errorsArr}
                              <a
                                href="javascript:;void(0);"
                                onClick={() =>
                                  this.setState({
                                    shouldDisplayAllErrors: !this.state
                                      .shouldDisplayAllErrors,
                                  })
                                }
                              >
                                {this.state.errorsFoundIn.length < 4
                                  ? ""
                                  : this.state.shouldDisplayAllErrors
                                    ? "View less"
                                    : "View all"}
                              </a>
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="drag_btnbox">
                <a
                  href="javascript:;void(0);"
                  className="drag_btn cancel_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ openTab: "uploadFiles" });
                  }}
                >
                  Back
                </a>
                <a
                  href="javascript:;void(0);"
                  className="drag_btn next_btn next_yellow"
                  onClick={(e) => {
                    e.preventDefault();
                    
                    this.saveContacts();
                  }}
                >
                  Import Contacts
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.dialogBox !== "")
    
    debugger;
      toReturn.push(
        this.state.dialogBox === "AddContactsToAGroup" ? (
          <div className="dialogBox">
            <div className="addcontact_box" key="AddContactsToGroup">
              <div className="addcontact_detail">
                <div className="addcont_txt">
                  <h3>Add contacts to group</h3>
                  <p>

                    Select the group or groups you want your contacts to be
                    placed in
                  </p>
                </div>
                <button
                  type="button"
                  className="pop-closebtn"
                  onClick={() => this.setState({ dialogBox: "" })}
                >
                  <img src="images/cross.svg" />
                </button>

                <ul className="usr_list import_contactbox add_groupbox">
                  {this.props.setSharedEmails &&
                    this.props.setSharedEmails.map((c: any) => {
                      return (
                        <li>
                          <div className="usr_imgbx">
                            <div className="grp_bx">
                              {commonMethod.generateFirstLastNameIntials(
                               companyVersion===2?c.Name:c.UserName
                              )}
                            </div>
                          </div>
                          <div className="usr_txt">
                            <h3>{ companyVersion===2?c.Name:c.UserName}</h3>
                            <div className="txt_div">
                              <span className="sub_txt">
                                {c.members?.length} Contacts
                              </span>
                            </div>
                          </div>
                          <label className="group_check">
                            <input
                              type="checkbox"
                              checked={
                                 companyVersion===2?(c.InboxId === this.state.temporaryGroupEmail ) :(c.Email === this.state.temporaryGroupEmail)
                              }
                              id="groupEmailSelected"
                              name="groupEmailSelected"
                              onChange={(e: any) => {
                               
                                this.setState({
                                  temporaryGroupEmail:
                                    (c.Email === this.state.temporaryGroupEmail)
                                      ? "":(c.Email!=undefined?c.Email:c.InboxId)
                                });
                                
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      );
                    })}
                </ul>
                <div className="addgr_btnbox">
                  <a
                    href="javascript:;void(0);"
                    className="drag_btn next_btn next_yellow add_grbtn"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        groupEmail: this.state.temporaryGroupEmail,
                        dialogBox: "",
                      });
                      // var count=0;
                      // for (let contact of this.state.contactsToBeSaved) {
                      //   contact.phonenumber=   contact.phonenumber.replace(/\D/g,'');
                      //   contact.phonenumber=  contact.phonenumber.replace("(", "") ;
                      //   contact.phonenumber= contact.phonenumber.replace(")", "");
                      //   contact.phonenumber= contact.phonenumber.replace("-", "");
                      //   contact.phonenumber.trim();
                      //      if (contact.phonenumber.startsWith('61')) {
                      //       contact.phonenumber = "+" + contact.phonenumber
                      //      }else if (contact.phonenumber.startsWith('1')) {
                      //       contact.phonenumber = "+" + contact.phonenumber
                      //      }else if (contact.phonenumber.startsWith('44')) {
                      //       contact.phonenumber = "+" + contact.phonenumber
                      //      }else if (contact.phonenumber.startsWith('0')){
                      //        if(this.props.loggedInUserDefaultCountryCode === 1){
                      //         contact.phonenumber =  contact.phonenumber.replace("0","");
                      //         contact.phonenumber = "+" + this.props.loggedInUserDefaultCountryCode + contact.phonenumber
                      //        } 
                      //      }else{
                      //       contact.phonenumber ="+" + this.props.loggedInUserDefaultCountryCode + contact.phonenumber
                      //      }
                      //   if (contact.isSelected) {
                      //     contact = {
                      //       ContactId: count,
                      //       PhoneNumber:contact.phonenumber,
                      //       UserEmail: contact.email,
                      //       ForeName: contact.firstname,
                      //       SureName: contact.lastname,
                      //       ContactEmail: contact.email,
                      //       GroupEmail: this.state.temporaryGroupEmail,
                      //       saved: 0
                      //     };
                      //     count++;
                      //     this.props.savedContactWithSharedInbox(contact,count);   
                      //     
                      //     // this.state.contactsUploads.push(contact);
                      //   }
                      // }
                      // console.log("this.state.contactsUploads",this.state.contactsUploads)
                      // this.props.savedContactWithSharedInbox(this.state.contactsUploads,count);    
                    }}
                  >
                    Add to group
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.dialogBox === "ShareContactsToSharedInbox" ? (

          <div className="dialogBox">
            <div
              className="addcontact_box share_contact"
              key="ShareContactsToSharedInbox"
            >
              <div className="addcontact_detail" style={{ top: "15%" }}>
                <div className="addcont_txt">
                  <h3>Add contacts to Group</h3>
                  <p>
                    Select the group or groups you want your contacts to be placed
                  </p>
                </div>
                <button
                  type="button"
                  className="pop-closebtn"
                  onClick={(e) => {
                    //  this.setState({ dialogBox: "" });
                    e.preventDefault();
                    this.setState({
                      groupEmail: this.state.temporaryGroupEmail,
                      dialogBox: "",
                    });
                  }}
                >
                  <img src="images/cross.svg" />
                </button>
                <div className="choose_inbox">
                  <h5>CHOOSE GROUPS</h5>
                  <ul className="usr_list import_contactbox add_groupbox">
                    {this.props.yakchatLists &&
                      this.props.yakchatLists.map((c: any) => {
                        return ((c.ListData.Id || c.ListData.Id === 0) && (
                          <li>
                            <div className="usr_imgbx">
                              <div className="grp_bx">
                                {commonMethod.generateFirstLastNameIntials(c.ListName !== undefined ? c.ListName : c.ListData.ListName)}
                              </div>
                            </div>
                            <div className="usr_txt">
                              <h3 >{c.ListData.ListName}</h3>
                              <div className="txt_div">
                                <span className="sub_txt">
                                  {c.localContacts &&
                                    c.ListData.localContacts.$values.length}{" "}
                                Contacts
                              </span>
                              </div>
                            </div>
                            <label className="group_check">
                              <input
                                type="checkbox"
                                checked={this.state.list_Id === c.ListData.Id}
                                id="groupEmailSelected"
                                name="groupEmailSelected"
                                onChange={(e: any) => {
                                  if (this.state.list_Id === c.ListData.Id) {
                                    this.setState({ list_Id: "" });
                                  } else {
                                    this.setState({ list_Id: c.ListData.Id });
                                  }

                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        )
                        );
                      })}
                  </ul>
                  <div className="addgr_btnbox">
                    <a
                      href="javascript:;void(0);"
                      className="drag_btn next_btn next_yellow add_grbtn"
                      onClick={(e) => {
                        e.preventDefault();
                        var newLocalContacts = this.props.yakchatLists.map((x: any) => {
                          if (x.ListData.Id === this.state.list_Id)
                            return x.ListData.localContacts
                        })
                        
                        this.props.EditYakChatontact(this.state.contactsToBeSaved.map((x:any)=>x.id)
                          ,this.state.list_Id);
                        this.setState({ dialogBox: "" });
                      }}
                    >
                      Add to group
                </a>
                  </div>
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Start typing inbox name..."
                  /> */}
                  {/* <ul className="usr_list import_contactbox add_groupbox">
                  
                  {this.props.yakChatGroupData &&
                    this.props.yakChatGroupData.map((c: any) => {
                      return (
                        <li>
                          <div className="usr_imgbx">
                            <div className="grp_bx">
                              {commonMethod.generateFirstLastNameIntials(
                                c.UserName
                              )}
                            </div>
                          </div>
                          <div className="usr_txt">
                            <h3>{c.UserName}</h3>
                            <div className="txt_div">
                              <span className="sub_txt">
                                {c.members.length} Contacts
                              </span>
                            </div>
                          </div>
                          <label className="group_check">
                            <input
                              type="checkbox"
                              checked={
                                c.Email === this.state.temporaryGroupEmail
                              }
                              id="groupEmailSelected"
                              name="groupEmailSelected"
                              onChange={(e: any) => {
                                this.setState({
                                  temporaryGroupEmail:
                                    c.Email === this.state.temporaryGroupEmail
                                      ? ""
                                      : c.Email,
                                });
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      );
                    })}
                </ul> */}
                </div>
                <div className="addgr_btnbox">
                  <a
                    href="javascript:;void(0);"
                    className="drag_btn next_btn next_yellow add_grbtn"
                  >
                    Save
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.dialogBox === "cancel" ? (
          <div className="dialogBox">
            <div className="addcontact_box" key="cancel">
              <div className="sure_detail">
                <div className="addcont_txt">
                  <h3>Are you sure you want to cancel?</h3>
                  <p className="save_press">
                    If you don’t press save you will lose all the changes you
                    have made
                  </p>
                </div>
                <div className="addgr_btnbox">
                  <button
                    className="btn cstm_btn blk_btn cancelbtn"
                    onClick={() => {
                      this.setState({ dialogBox: "" });
                    }}
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                  <button
                    className="btn  cstm_btn ylo_btn2"
                    onClick={() => {
                      if (
                        this.state.filesPresent.filter(
                          (x: any) => x.uploadProgress === "uploading"
                        ).length > 0
                      ) {
                        const copy = this.state.filesPresent.map((x: any) => {
                          if (x.uploadProgress === "uploading")
                            x.uploadProgress = "cancelled";
                          return x;
                        });
                        this.setState({ filesPresent: copy });
                        setTimeout(() => {
                          document.getElementById("contacts_tab")?.click();
                          this.props.setTabOpened("contactopen");
                        }, 100);
                        return;
                      }
                      document.getElementById("contacts_tab")?.click();
                      this.props.setTabOpened("contactopen");
                    }}
                  >
                    {" "}
                    Okay{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null
      );

    return toReturn;
  }
}

const mapStateToProps = (props: any) => {
  let companyVersion=store.getState().reduxReducer.companyVersion;
  return {
    testData: props.reduxReducer.testName,
    tabOpened: props.reduxReducer.tabOpened,
    yakChatGroupData: props.reduxReducer.yakChatGroupData,
    setSharedEmails:props.reduxReducer.setSharedEmails,
    spinnerValue: props.reduxReducer.spinnerValue,
    ServicePlanId: props.reduxReducer.ServicePlanId,
    yakchatLists: props.reduxReducer.yakchatLists,
    loggedInUserDefaultCountryCode: companyVersion!==2?props.reduxReducer.loggedInUser.DefaultCountryCode:store.getState().reduxReducer.loggedInUser.inboxes.$values.filter((item:any) => item.InboxId == props.reduxReducer.sharedInboxData?.currentSelected.email)[0].DefaultCountryCode,
    storeActualContactsInList: props.reduxReducer.storeActualContactsInListReducer,
    loggedInUser: props.reduxReducer.loggedInUser,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    saveYakChatContact: async (data: any, savedFrom: any) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
      dispatch<any>(action.uploadYakChatContact_V2(data, savedFrom));
        }
        else{
          dispatch<any>(action.uploadYakChatContact(data, savedFrom));
        }

    },
    setAlertData: (data: any) => {
      dispatch<any>(action.setAlertData(data));
    },
    setTabOpened: (tab: string) => {
      dispatch<any>(action.setTabOpened(tab));
    },
    setSpinner: (value: any) => {
      dispatch<any>(action.setSpinner(true))
    },
    getContactsByListId: async (group: any, lists: any, calledFrom: any, previousContacts: any) => {
      let companyVersion=store.getState().reduxReducer.companyVersion;
      if(companyVersion===2)
        {
      dispatch<any>(action.getContactsByListId_V2(group, lists, "", previousContacts))
        }
        else{
          dispatch<any>(action.getContactsByListId(group, lists, "", previousContacts))
        }
    },
    EditYakChatontact: (id: any, ListId: any) => {
      return dispatch<any>(action.EditYakChatontact(id, ListId));
    },
    shiftContactsInContactList: (previousContacts: any, newContacts: any, id: number) => {
      dispatch<any>(action.shiftContactsInContactList(previousContacts, newContacts, id));
    },
    // uploadContactIndicator: (value:any) => {
    //   dispatch<any>(action.uploadContactIndicator(value));
    // },
    
    savedContactWithSharedInbox:(lastContact: any,count:any)=>{
      
      dispatch<any>(action.savedContactWithSharedInbox(lastContact,count));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadCsv);
