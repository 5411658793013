import React from "react";
import _ from "lodash";
import Lightbox from 'react-image-lightbox';


class ImagePreview extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      downloadUrl: this.props.imageurl,
      showMenu: false,
    };
  }

  copyImage = ( callback: any) =>{
    console.log("this.state.downloadUrl",this.state.downloadUrl);
    console.log("this.state.downloadUrl",this.props.imageurl);
    
    let newVariable: any;
    newVariable = window.navigator;
    var xhr = new XMLHttpRequest();
    xhr.open("GET",this.state.downloadUrl);
    xhr.responseType = 'text';
    xhr.send();
    xhr.onload = function (data:any) {
        // Note: .response instead of .responseText
        var blob = new Blob([xhr.response], {type: 'image/jpeg'});
        var img = document.createElement('img');
        img.onload = function(e) {
        window.URL.revokeObjectURL(img.src);
      }
      img.src = window.URL.createObjectURL(blob);
      document.body.appendChild(img);
       console.log(img);
      // navigator.clipboard.writeText(url);
    // navigator.clipboard.writeText(this.state.downloadUrl)
  }
}
  downloadImage = () => {
    let newVariable: any;
    newVariable = window.navigator;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", this.state.downloadUrl);
    xhr.responseType = 'blob';
    xhr.send();
      xhr.onload = function (data:any) {
        if(data){
            // Handle response
            if (window.navigator && newVariable.msSaveOrOpenBlob)  {
                  //IE11 and the legacy version Edge support
                  var blob = data.currentTarget.response;
                  newVariable.msSaveOrOpenBlob(blob, 'image.jpg');
            } else {
                  const url = window.URL.createObjectURL(new Blob([data.currentTarget.response]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', 'image.jpg');
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
            }
          }
      };
    // console.log("this.state.downloadUrl", this.state.downloadUrl)

  }
  render() {
    const { showMenu } = this.state;
    return (
      <div onMouseDown={(e: any) => e.Which}>
            <a onClick={ this.downloadImage}  style={{ position: "absolute", zIndex: 9999, top: 22, left: 70, right: "auto" }}>Download</a>
        {/* <a href={this.state.downloadUrl}  target="_blank" className="mobile_send_btn"  style={{ position: "absolute", zIndex: 9999, top: 22, left: 70, right: "auto" }}
         download="this.state.downloadUrl.jpg" >Download</a> */}
        <button style={{ position: "absolute", zIndex: 9999, top: 22, left: 23, right: "auto", backgroundColor:"white"}}
          onClick={this.copyImage}>Copy</button>
        <Lightbox mainSrc={this.props.imageurl || ''} onCloseRequest={() => this.props.closeImage()} />
    
      </div>)
  }
}

export default ImagePreview;
