import React from "react";
import AuthHelper from "../../Config/msalConfig";
import logo from "../../TeamsApp/images/yakchat-loader.gif";
import logo1 from "../../TeamsApp/images/logoLogin.png";
import { connect } from "react-redux";
import * as actions from "../redux/actions/actions";
import { ThunkDispatch } from "redux-thunk";
import { Link, useHistory } from "react-router-dom";

 function Login(props: any) {
  const history = useHistory();

   const setLoginUser =()=>{
     debugger;
    // let webexC:any =localStorage.getItem("webexC");
     localStorage.clear();
     sessionStorage.clear();
     
    //  localStorage.setItem(
    //      "webexC",
    //      webexC
    //    );
    AuthHelper.signIn()
    props.checkRegisterUser(true);
   }
   const openRegister =()=>{
    debugger;
    setTimeout(() => {
      window.location.reload()
    }, 200);
   // history.push('/register')  
  // window.location.reload();
    //  setTimeout(() => {
    //    history.push('/register')  
    //   //  window.location.reload();
    //   debugger
    // } , 200);
  }
  return (
    <section className="userlog_sec error_sec "style={{backgroundColor:"#fcbf00"}}>
      <div className="container">
        <div className="login_box" style={{ textAlign: "center" }}>  
          <div className="error_logo logoLogin_div text-center">
            {props.loginStatus === "loggingIn" ? (
              <img src={logo}  alt="logo"
                style={{ width: "calC(50vw + 15vh)", position: "absolute", top: "50%",
                  left: "50%", transform: "translate(-50%, -50%)", maxWidth: "400px", }}
              /> 
            ) : (
              <>
              <img src={logo1} className="errorSignInLogo" alt="logo" />
              <h1 style ={{color:"#fff"}} className ="errorSignIntext">Welcome to YakChat!</h1>
              <div style ={{color:"#fff"}} className="welcome_Line">Send and receive SMS text messages in Microsoft Teams</div>
              </> )}
          </div>
          {props.loginStatus === "loggingIn" ? (
            <div className="authen_txt" style={{ backgroundColor:props.whiteLabelBrand !== "calltower" ?"#ffff" :"none" }}>
              <img src="images/lock.svg" alt="yakchat logo"/> Microsoft Secure Authentication
            </div>
          ) : (
            <>
            <button id="loginButtonMainInTeams" onClick={setLoginUser} className="btn cstm_btn gry_btn"
              style={{marginTop:"20px",  backgroundColor:"#ffffff", color:"#000"}}>
              Sign in
            </button>
            {/* <Link to="/register">
            <button   className="btn cstm_btn gry_btn register_btn">
             Register
            </button>
            </Link> */}
            {/* <span className="signin_spinner">
                  <Spin spinning={this.state.SpinnerValueState} indicator={antIcon}  className="login_spinner " style={{ zIndex: 9999, }} />
                </span> */}
           </> 
          )}
        </div>
      </div>
    </section>
  );
}
const mapStateToProps = (props: any) => {
  return {
    whiteLabelBrand:props.reduxReducer.whiteLabelBrand
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    checkRegisterUser:(checkUser:boolean) => {   
      debugger;  
      dispatch<any>(actions.checkRegisterUser(checkUser));
    }, 
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Login);