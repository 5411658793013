import { debug } from "console";
import { store } from "../../index";
import configData from "../../appsettings.json";
import { stat } from "fs";
export const getOutlookcontactfolders = () => {
  const url = `${configData.graphApiUrl}/v1.0/me/contactFolders/`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const getoutlookContactList = (pageIndex: number) => {
  const url = `${configData.graphApiUrl}/v1.0/me/contacts?$top=${999}&$skip=${
    (pageIndex - 1) * 999
  }&$orderby=displayName`;

  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const getOutlookcontactlistByContactFolders = (id: any,pageIndex:any,searchQuery:any) => {
  //  const url =  `${configData.graphApiUrl}/v1.0/me/contactFolders/` + id + "/contacts";
  const url = `${configData.graphApiUrl}/v1.0/me/contactFolders/${id}/contacts?$top=${10}&$skip=${(pageIndex - 1) * 999}
  &$filter=startswith(displayName,'${searchQuery}') or startswith(givenName,'${searchQuery}') or startswith(surname,'${searchQuery}')`;
    return fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
      },
    });
  };
export const editOutlookContact = (id: any, data: any) => {
  const url = `${configData.graphApiUrl}v1.0/me/contacts/` + id;
  return fetch(url, {
    method: "PATCH",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
    body: JSON.stringify(data),
  });
};
export const searchoutlookContactList = (
  searchQuery: string,
  pageIndex: number
) => {
  const url = `${configData.graphApiUrl}/v1.0/me/contacts?$top=${10}&$skip=${
    (pageIndex - 1) * 999
  }&$filter=startswith(displayName,'${searchQuery}') or startswith(givenName,'${searchQuery}') or startswith(surname,'${searchQuery}')&$orderby=displayName`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const getsharepointContactsLists = (pageIndex: number) => {
  // console.log(pageIndex)
  //debugger;
  //const url = `${configData.graphApiUrl}/v1.0/sites/root/lists/?$top=${10000}&$skiptoken=${(10) *pageIndex}&$paged=${true}&$orderby=displayName`;
  const url = `${
    configData.graphApiUrl
  }/v1.0/sites/root/lists/?$top=${10000}&$orderby=displayName`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const getsharepointContactsListItems = (name: any, pageIndex: any) => {
  const url =
    `${configData.graphApiUrl}/v1.0/sites/root/lists/` +
    name +
    `/items?$top=${20 * pageIndex}&$offset=${
      (pageIndex - 1) * 10
    }&$expand=fields`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const searchSharepointContactsItems = (
  name: any,
  searchQuery: string,
  pageIndex: number
) => {
  const url =
    `${configData.graphApiUrl}/v1.0/sites/root/lists/` +
    name +
    `/items?$top=${10000}&$expand=fields`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const editSharePointContact = (itemId: any, listId: any, data: any) => {
  const url = `${configData.graphApiUrl}/v1.0/sites/root/lists/${listId}/items/${itemId}`;
  return fetch(url, {
    method: "PATCH",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
    body: JSON.stringify(data),
  });
};
export const getsharepointContactsListItemsItem = (id: any, name: any) => {
  const url = `${configData.graphApiUrl}/v1.0/sites/root/lists/${name}/items/${id}/?expand=fields`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export const getyakchatcontactlist = (pageindex: any, userEmail: any) => {
  if (userEmail.charAt(userEmail?.length - 1) === ",") {
    userEmail = userEmail.replace(/,/gi, "");
  }
  const url =
    `${configData.APiUrl}/LocalContacts/GetPagingContactsByUserEmail?userEmail=` +
    userEmail +
    `&pageIndex=` +
    pageindex;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getContactsByListId = (listId: any) => {
  const url =
    `${configData.APiUrl}/Lists/GetLocalContactsByListId?id=` + listId;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const searchyakchatcontactlist = (pageindex: any, searchFilter: any) => {
  searchFilter = encodeURIComponent(searchFilter);
  var sharedEmails =
    store.getState().reduxReducer.shareMsgCount?.$values !== undefined
      ? store
          .getState()
          .reduxReducer.shareMsgCount?.$values.map((a: any) => a.sharedEmail)
          .toString()
      : store
          .getState()
          .reduxReducer.shareMsgCount?.map((a: any) => a.sharedEmail)
          .toString();
  var userEmail = encodeURIComponent(
    store.getState().reduxReducer.loggedInUser.account.userName
  );
  userEmail = userEmail + "," + sharedEmails;
  const url =
    `${configData.APiUrl}/LocalContacts/SearchContacts?userEmail=` +
    userEmail +
    `&pageIndex=` +
    pageindex +
    `&searchText=` +
    searchFilter;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      // "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
      // mode: "no-cors",
    },
  });
};
export const getYakChatGroupData = () => {
  const url = `${configData.APiUrl}/Users/GetGroupsEmails`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const saveYakChatContact = async (data: any, savedFrom: any) => {
  let url = "";
  var phoneNumber = encodeURIComponent(data.phoneNumber);
  var userEmail = encodeURIComponent(
    store.getState().reduxReducer.loggedInUser.account.userName
  );
  var groupEmail =
    data.groupEmail === ""
      ? encodeURIComponent(data.groupEmail)
      : data.groupEmail;
      debugger;
  if (data.email === "" && groupEmail === "" && data.lastname === "") {
    url =
      await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=""&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=""&userEmail=${userEmail}`;
  } else if (data.email === "" && groupEmail === "") {
    url =
      await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=""&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}`;
  } else if (data.email === "" && groupEmail !== "") {
    url =
      await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=""&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  } else if (groupEmail === "" && data.email !== "") {
    url =
      await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}`;
  } else if (data.lastName === "") {
    url =
      await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=""&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  } else {
    url =
      await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  }
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const uploadYakChatContact = async (data: any, savedFrom: any) => {
  // var phoneNumber= encodeURIComponent(data.phoneNumber);
  debugger;
  const url =
    await `${configData.APiUrl}/LocalContacts/AddImportedContacts?Email`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(data),
  });
};
export const UpdateRoomParticipantTeams = (data: any, participantId: any) => {
  var sharedEmails =
    store.getState().reduxReducer.shareMsgCount?.$values !== undefined
      ? store
          .getState()
          .reduxReducer.shareMsgCount?.$values.map((a: any) => a.sharedEmail)
          .toString()
      : store
          .getState()
          .reduxReducer.shareMsgCount?.map((a: any) => a.sharedEmail)
          .toString();
  var userEmail = encodeURIComponent(
    store.getState().reduxReducer.loggedInUser.account.userName
  );
  data.groupEmail = userEmail + "," + sharedEmails;
  var url = "";
  if (data.groupEmail === "" || data.groupEmail === null) {
    url = `${
      configData.APiUrl
    }/Rooms/UpdateRoomParticipant?userEmail=""&roomParticipantId=${participantId}&contactUri=${
      data.phoneNumber
    }&name=${data.firstName + " " + data.lastName}`;
  }
  url = `${configData.APiUrl}/Rooms/UpdateRoomParticipant?userEmail=${
    data.groupEmail
  }&roomParticipantId=${participantId}&contactUri=${data.phoneNumber}&name=${
    data.firstName + " " + data.lastName
  }`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const UpdateFirstReceiveConvName = (roomId: any) => {
  const url = `${configData.APiUrl}/Rooms/UpdateFirstReceiveConvName?roomId=${roomId}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const unreadCounterUpdate = (
  roomId: any,
  lastIncomingMessageId: any,
  groupEmail: any
) => {
  if(lastIncomingMessageId==undefined)
  {lastIncomingMessageId=0;}
  const url = `${configData.APiUrl}/Rooms/UpdateLastSeen?roomId=${roomId}&lastSeen=${lastIncomingMessageId}&userEmail=${groupEmail}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const GetGroupRoomPrimaryMember = (roomId: any) => {
  const url = `${configData.APiUrl}/Rooms/GetPrimaryMember?roomid=${roomId}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const GetContactsByUserEmailPhoneNumber = (
  userEmail: any,
  phoneNumber: any
) => {
  phoneNumber = phoneNumber?.replace("+", "%2B");
  const url = `${configData.APiUrl}/LocalContacts/GetContactsByUserEmailPhoneNumber?userEmail=${userEmail}&phoneNumber=${phoneNumber}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const editYakChatContact = (id: any, data: any) => {
  var url = "";
  data.Phone = data.Phone.replace("+", "%2B");
  data.groupEmail = data?.groupEmail === "undefined" ? "" : data.groupEmail;
  if (data.Email === "" && data.groupEmail === "" && data.surename === "") {
    url = `${configData.APiUrl}/LocalContacts/UpdateContacts?Phone=${data.Phone}&foreName=${data.foreName}&contactId=${id}`;
  } else if (data.Email === "" && data.groupEmail === "") {
    url = `${configData.APiUrl}/LocalContacts/UpdateContacts?Phone=${data.Phone}&foreName=${data.foreName}&sureName=${data.surename}&contactId=${id}`;
  } else if (data.groupEmail === "") {
    url = `${configData.APiUrl}/LocalContacts/UpdateContacts?Email=${data.Email}&Phone=${data.Phone}&foreName=${data.foreName}&sureName=${data.surename}&contactId=${id}`;
  } else if (data.Email === "") {
    url = `${configData.APiUrl}/LocalContacts/UpdateContacts?Phone=${data.Phone}&foreName=${data.foreName}&sureName=${data.surename}&contactId=${id}&groupEmail=${data.groupEmail}`;
  } else {
    url = `${configData.APiUrl}/LocalContacts/UpdateContacts?Email=${data.Email}&Phone=${data.Phone}&foreName=${data.foreName}&sureName=${data.surename}&contactId=${id}&groupEmail=${data.groupEmail}`;
  }
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getGroupsEmails = () => {
  const url = `${configData.APiUrl}/GetGroupsEmails`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const updateRoomParticipants = (
  roomParticipantId: any,
  contactUri: any,
  name: any,
  userEmail: any
) => {
  const url = `${configData.APiUrl}/Rooms/UpdateRoomParticipant?userEmail=${userEmail}&roomParticipantId=${roomParticipantId}&contactUri=${contactUri}&name=${name}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getContactsByUserEmail = (userEmail: string, pageIndex: any) => {
  const url = `${configData.APiUrl}/GetContactsByUserEmail?Email=${userEmail}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getUsableCreditForUser = () => {
  const url = `${configData.APiUrl}/Users/getUsableCreditForUser?userEmail=${
    store.getState().reduxReducer.loggedInUser.account.userName
  }`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const saveYakChatSettings = (
  checkedvalue: string,
  isSharePoint: string
) => {
  const url = `${configData.APiUrl}/Setting/ChangeContactType?userEmail=${
    store.getState().reduxReducer.loggedInUser.account.userName
  }&flag=${checkedvalue}&isSharePoint=${isSharePoint}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getRooms = (pageIndex: number, email: string) => {
  const url = `${configData.APiUrl}/getRoomsGroup?email=${email}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export async function queueMessageChannel(
  roomId: any,
  text: any,
  userEmail: any,
  fileBlob: any,
  serviceproviderid: any,
  channelName: any
) {
  debugger;

  // if(text.includes("http"))
  // {
  // text=text.replace(/%2520/g,"£_Yak");
  // }
  let dataReturned:any;
  text = decodeURIComponent(text);
  // if(text.includes("http"))
  // {
  // text=text.replace(/£_Yak/g,"%2520");
  // text=text.replace(/&/g,"%26");
  // }
  // text= text.replace(/\n/g,"%0A");

  const version = "React";
  if (text?.length === 0) {
    text = "%00";
  }
  var SendRequest: any = {
    roomId: roomId,
    text: text,
    userEmail: userEmail,
    uploadedFiles: fileBlob,
    serviceProviderId: serviceproviderid,
    channelName: channelName,
    yakchatVersion: version,
  };

  const url = `${configData.APiUrl}/Sending/QueueMessage_Channel_V3`;
  await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(SendRequest),
  })
    .then(async function (response) {
      // first then()
      if (response.ok) {
        dataReturned = response.json();
        return dataReturned;
      } else {
        return response.text().then((text) => {
          if (response.status == 401) {
            dataReturned = "Unable to send text: "+"Authentication error";
            return dataReturned;
          }
          dataReturned = text;
          return dataReturned;
        });
      }
    })
    .then(function (msgtext) {
      if (isNaN(msgtext)) {
        if (
          msgtext.includes("EntityNotFoundException") &&
          msgtext.includes("@")
        ) {
          msgtext = msgtext.toString().split(":")[2];
          msgtext = msgtext
            .toString()
            .substring(
              0,
              msgtext
                .toString()
                .indexOf(".", msgtext.toString().indexOf(".") + 1)
            );
          dataReturned = "Unable to send text: " + msgtext;
            }
                else if(msgtext.includes(")") && msgtext.includes("(")){
          msgtext = msgtext
            .toString()
            .substring(
              msgtext.toString().indexOf("(") + 1,
              msgtext.toString().indexOf(")")
            );
          dataReturned = "Unable to send text: " + msgtext;
        }
        return dataReturned;
      }
       
      else {
        return msgtext;
      }
    })
    .catch(function (error) {
      error = error.message;
      if (error.includes("EntityNotFoundException") && error.includes("@")) {
        error = error.toString().split(":")[2];
        error = error
          .toString()
          .substring(
            0,
            error.toString().indexOf(".", error.toString().indexOf(".") + 1)
          );
      } else if (error.includes(")") && error.includes("(")) {
        error = error
          .toString()
          .substring(
            error.toString().indexOf("(") + 1,
            error.toString().indexOf(")")
          );
      }
      dataReturned = "Unable to send text: "+ error;
      return dataReturned;
    });
  return dataReturned;
}
// export const queueMessage = (roomId: any, text: any,userEmail:any) => {
//   text= text. replace(/\n/g,"%0A");
//   const url = `${configData.APiUrl}/Sending/QueueMessage?roomId=${roomId}&text=${text}&userEmail=${userEmail}`;
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       accept: "application/json;",
//       Authorization:"Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
//     }
//   });
// };
// export const queueMessageMMS = (roomId: any, text: any,fileBlob:any,fileName:any,userEmail:any,) => {
//   text=decodeURI(text);
//   text= text. replace(/\n/g,"%0A");
//   var messageMMS ={roomId:roomId, text:text, uploadedFileUrl :fileBlob,fileName:fileName, email:userEmail}
//   const url = `${configData.APiUrl}/Sending/QueueMessageMMS`;
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       'Content-Type': 'application/json',
//       accept: "application/json;",
//       Authorization:
//         "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
//     },
//     body: JSON.stringify(messageMMS)
//   });
// };
// export const queueMessageMedia = (roomId: any, text: any,userEmail:any) => {
//   const url = `${configData.APiUrl}/Sending/QueueMessageMedia?roomId=${roomId}&text=${text}&userEmail=${userEmail}`;
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       accept: "application/json;",
//       Authorization:
//         "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
//     }
//   });
// };
// export const queueMessageSMPP = (roomId: any, text: any,userEmail:any) => {
//   const url = `${configData.APiUrl}/Sending/QueueMessageSMPP?roomId=${roomId}&text=${text}&userEmail=${userEmail}`;
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       accept: "application/json;",
//       Authorization:
//         "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
//     }
//   });
// };
// export const queueMessageVonageApi = (roomId: any, text: any,userEmail:any) => {
//   const url = `${configData.APiUrl}/Sending/QueueMessageVonageApi?roomId=${roomId}&text=${text}&userEmail=${userEmail}`;
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       accept: "application/json;",
//       Authorization:
//         "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
//     }
//   });
// };
export const getUser = (token: string, userName: any) => {
  const url = `${configData.APiUrl}/Users/GetUsers_Trial?userEmail=` + userName;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + token,
    },
  });
};
export async function getUserCompany(token: string, companyId: any) {
  const url =
    `${configData.APiUrl}/Users/GetCompanyById?companyId=` + companyId;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + token,
    },
  });
  return response;
}
export const getMFAuser = (userEmail: string, groupEmail: any) => {
  let email = store.getState().reduxReducer.loggedInUser.Email;
  if (groupEmail !== undefined && groupEmail !== null && groupEmail !== "") {
    email = groupEmail;
  }
  const url = `${configData.APiUrl}/Sending/checkIfMFANumber?userEmail=${email}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const updateRoomsLatestMsgs = (token: string) => {
  const url = `${configData.APiUrl}/Rooms/UpdateRoomsLatestMsgs`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + token,
    },
  });
};
export const GetServicePhoneNumbers = (phoneNumber: any, token: string) => {
  phoneNumber = phoneNumber.replace("+", "%2B");
  const url = `${configData.APiUrl}/Sending/GetServicePhoneNumbers?phoneNumbers=${phoneNumber}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + token,
    },
  });
};
export const GetGroupsEmails = () => {
  const url = `${configData.APiUrl}/GetGroupsEmails?userEmail=${
    store.getState().reduxReducer.loggedInUser.account.userName
  }`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const GetPersonalInbox = (
  pageIndex: number,
  userEmail: any,
  archiveFlag: any,
  groupemail: any
) => {
  const url = `${configData.APiUrl}/Rooms/GetPagingUnArchivedRooms_New?userEmail=${userEmail}&archiveFlag=${archiveFlag}&PageIndex=${pageIndex}&groupEmail=${groupemail}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const AddContactList = (participants: any) => {
  const url = `${configData.APiUrl}/Lists/AddContactList`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(participants),
  });
};
export const AddContactListsLocalContact = (
  contactId: number,
  listId: number
) => {
  const url = `${
    configData.APiUrl
  }/Lists/AddContactListsLocalContact?contactIds=${contactId}&Id=${listId}&userEmail=${
    store.getState().reduxReducer.loggedInUser.account.userName
  }`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const AddContactListsLocalContact_V2 = (
  contactId: any,
  listId: number
) => {
  const url = `${
    configData.APiUrl
  }/V2/ContactList/AddContact?listId=${listId}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(contactId),
  });
};
export async function removeContactListsLocalContact  (
  contactId: number,
  listId: any,
  email: string
)  {
  let dataReturned;
  const url =
    `${configData.APiUrl}/Lists/RemoveContactListsLocalContact?contactIds=` +
    contactId +
    `&Id=` +
    listId +
    `&userEmail=` +
    email;
    await fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  })
  .then(async function (response) {
    // first then()
    if (response.ok) {
      dataReturned = response.text();
      return dataReturned;
    }

    throw new Error(await response.text());
  })
  .then(function (text) {
    // second then()
    console.log("Request successful", text);
    dataReturned = text;
    return dataReturned;
  })
  .catch(function (error) {
    debugger;
    dataReturned = error
      .toString()
      .substring(
        error.toString().indexOf("(") + 1,
        error.toString().indexOf(")")
      );
    console.log("Request failed", dataReturned);
    return dataReturned;
  });
return dataReturned;
};
export const GetContactLists = (email: any) => {
  const url = `${configData.APiUrl}/Lists/GetLists?userEmail=${email}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const DeleteThisList = (id: string) => {
  const url = `${configData.APiUrl}/Lists/DeleteContactList?listId=${id}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const DeleteThisContact = (id: string, phoneNumber: any) => {
  const url = `${configData.APiUrl}/LocalContacts/DeleteContacts?contactId=${id}&phoneNumber=${phoneNumber}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const GetUsersBySubscriptionId = (
  userEmail: string,
  id: number,
  token: string = store.getState().reduxReducer.loggedInUser.accessTokenApi,
  shouldCheckGroup: boolean = false
) => {
  const url = `${configData.APiUrl}/Users/GetUsers_Trial?userEmail=${userEmail}&CheckGroup=${shouldCheckGroup}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};
export const UpdateGroupPrimaryOwner = (id: number, email: string) => {
  const url = `${configData.APiUrl}/Rooms/UpdateGroupPrimary?roomId=${id}&memberEmail=${email}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export async function CreateRoom(data: any, userEmail: any) {
  let dataReturned;
  const url = `${configData.APiUrl}/Rooms/CreateRoom?userEmail=${userEmail}&dedicated=false`;
  await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(data),
  })
    .then(async function (response) {
      // first then()
      if (response.ok) {
        dataReturned = response.text();
        return dataReturned;
      }

      throw new Error(await response.text());
    })
    .then(function (text) {
      // second then()
      console.log("Request successful", text);
      dataReturned = text;
      return dataReturned;
    })
    .catch(function (error) {
      dataReturned = error
        .toString()
        .substring(
          error.toString().indexOf("(") + 1,
          error.toString().indexOf(")")
        );
      console.log("Request failed", dataReturned);
      return dataReturned;
    });
  return dataReturned;
}
export async function createRoomGroup(data: any, email: any) {
  debugger;
  if(email=="Closed")
  {email=store.getState().reduxReducer.sharedInboxData?.currentSelected
    ?.email;}
  let dataReturned;
  const url = `${
    configData.APiUrl
  }/Rooms/CreateRoomGroup?groupEmail=${email}&dedicated=${false}`;
  await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(data),
  })
    .then(async function (response) {
      // first then()
      if (response.ok) {
        dataReturned = response.text();
        return dataReturned;
      }

      throw new Error(await response.text());
    })
    .then(function (text) {
      // second then()
      console.log("Request successful", text);
      dataReturned = text;
      return dataReturned;
    })
    .catch(function (error) {
      dataReturned = error
        .toString()
        .substring(
          error.toString().indexOf("(") + 1,
          error.toString().indexOf(")")
        );
      console.log("Request failed", dataReturned);
      return dataReturned;
    });
  return dataReturned;
}
export const CloseConversation = (roomId: any, flag: any) => {
  const url = `${configData.APiUrl}/Rooms/ArchiveRooms?roomId=${roomId}&archiveFlag=${flag}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getPagingMessagesByRoomId = (
  userEmail: any,
  roomId: any,
  pageIndex: any
) => {
  const url = `${configData.APiUrl}/Rooms/GetPagingMessagesByRoomId?userEmail=${userEmail}&roomId=${roomId}&pageIndex=${pageIndex}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getOrderedUnArchivedRoom = (userEmail: any) => {
  const url = `${configData.APiUrl}/Rooms/GetOrderedUnArchivedRooms?userEmail=${userEmail}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const uploadImageToBlob = async (fileBlobDetails: any) => {
  const url = await `${configData.APiUrl}/V3/Send/AddMessageMedia`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(fileBlobDetails),
  });
};
export const searchConversation = async (
  archiveFlag: any,
  searchText: any,
  groupEmail: any,
  userEmail: any
) => {
  const url =
    await `${configData.APiUrl}/Rooms/SearchRooms?archiveFlag=${archiveFlag}&searchText=${searchText}&groupEmail=${groupEmail}&userEmail=${userEmail}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getSasTokenBlobs = async () => {
  const url = await `${configData.APiUrl}/Sending/GetSasTokenBlobs`;
  return await fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};

export const registerUserTrail = async (
  user: any,
  stradditionalEmails: any,
  groupEmail: any,
  dedicatedNumber: any,
  accessToken: any,
  userData: any
) => {
  const url =
    await `${configData.APiUrl}/Users/AddUser_Trial?stradditionalEmails=${user}&groupEmail=${stradditionalEmails}&groupName=${groupEmail}&dedicatedNumber=${dedicatedNumber}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(userData),
  });
};

export const registerCompanyTrail = async (company: any, accessToken: any) => {
  const url = await `${configData.APiUrl}/Users/AddCompany_Trial`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(company),
  });
};

export const registerSubscriptionTrail = async (
  subscription: any,
  accessToken: any
) => {
  const url = await `${configData.APiUrl}/Users/AddSubscription_Trial`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(subscription),
  });
};
export const servicePhoneNumberUpdateTrail = async (
  PhoneNumber: any,
  trail: any,
  accessToken: any
) => {
  const url =
    await `${configData.APiUrl}/ServicePhoneNumber/updateTrialServicePhonenumber?phoneNumber=${PhoneNumber}&trial=${trail}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  });
};
export async function servicePhoneNumberGetTrail(accessToken: any) {
  const url = `${
    configData.APiUrl
  }/ServicePhoneNumber/GetBandWidthPhoneNumbers?trial=${true}`;
  const data = await fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  });
  return data;
}
export const saveProfileMode = (userEmail: any, displayMode: any) => {
  const url = `${configData.APiUrl}/Setting/saveProfileMode?userEmail=${userEmail}&displayMode=${displayMode}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};

export const addSharedContactApi = (lstContact: any) => {
  const url = `${configData.APiUrl}/LocalContacts/AddImportedContacts`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(lstContact),
  });
};

export const getUserByEmail = async (userEmail: any) => {
  const url =
    await `${configData.APiUrl}/Users/GetUserByUserEmail?userEmail=${userEmail}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const registerUserToMailChimp = async (
  userEmail: any,
  userRegistrationDate: any,
  servicePlanId: any,
  firstname: any,
  lastname: any,
  street: any,
  city: any,
  phone: any,
  state: any,
  country: any,
  dedecatedNumber: any,
  accessToken: any
) => {
  const url =
    await `${configData.APiUrl}/MailChimp/RegisterUsertoMailChimp?userEmail=${userEmail}&userRegistrationDate=${userRegistrationDate}&servicePlanId=${servicePlanId}&firstname=${firstname}&lastname=${lastname}&street=${street}&city=${city}&phone=${phone}&state=${state}&country=${country}&dedecatedNumber=${dedecatedNumber}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  });
};
export const cancelUserToMailChimp = async (
  userEmail: any,
  userRegistrationDate: any,
  servicePlanId: any,
  firstname: any,
  lastname: any,
  street: any,
  city: any,
  phone: any,
  state: any,
  country: any,
  dedecatedNumber: any,
  accessToken: any
) => {
  const url =
    await `${configData.APiUrl}/MailChimp/CancelUsertoMailChimp?userEmail=${userEmail}&userRegistrationDate=${userRegistrationDate}&servicePlanId=${servicePlanId}&firstname=${firstname}&lastname=${lastname}&street=${street}&city=${city}&phone=${phone}&state=${state}&country=${country}&dedecatedNumber=${dedecatedNumber}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  });
};

export const sendNewMsgMailChimp = async (userEmail: any) => {
  const url =
    await `${configData.APiUrl}/MailChimp/SendNewMsgMailChimp?userEmail=${userEmail}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};

export const registerUserToZoho = async (
  userEmail: any,
  company: any,
  userRegistrationDate: any,
  servicePlanId: any,
  firstname: any,
  lastname: any,
  street: any,
  city: any,
  phone: any,
  state: any,
  country: any,
  zip: any,
  dedecatedNumber: any,
  accessToken: any
) => {
  const url = `${configData.APiUrl}/Zoho/RegisterUsertoZoho?userEmail=${userEmail}&company=${company}&userRegistrationDate=${userRegistrationDate}&servicePlanId=${servicePlanId}&firstname=${firstname}&lastname=${lastname}&street=${street}&city=${city}&phone=${phone}&state=${state}&country=${country}&zip=${zip}&dedecatedNumber=${dedecatedNumber}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  });
};
export const cancelUserToZoho = async (
  userEmail: any,
  company: any,
  userRegistrationDate: any,
  servicePlanId: any,
  firstname: any,
  lastname: any,
  street: any,
  city: any,
  phone: any,
  state: any,
  country: any,
  zip: any,
  dedecatedNumber: any,
  accessToken: any
) => {
  const url = `${configData.APiUrl}/Zoho/CancelUsertoZoho?userEmail=${userEmail}&company=${company}&userRegistrationDate=${userRegistrationDate}&servicePlanId=${servicePlanId}&firstname=${firstname}&lastname=${lastname}&street=${street}&city=${city}&phone=${phone}&state=${state}&country=${country}&zip=${zip}&dedecatedNumber=${dedecatedNumber}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  });
};

export const sendNewMsgZoho = async (userEmail: any) => {
  const url = `${configData.APiUrl}/Zoho/SendNewMsgZoho?userEmail=${userEmail}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const checkTrailUser = async (userEmail: any) => {
  const url = `${configData.APiUrl}/Users/CheckTrialUsed?userEmail=${userEmail}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};

//api Version2
export const getCompanyVersion_V2 = (token: string) => {
  debugger;
  const url = `${configData.APiUrl}/v2/Settings/GetCompanyVersion`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + token,
    },
  });
};
export const getUser_V2 = (token: string) => {
  const url = `${configData.APiUrl}/v2/Users/GetUser`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization: "Bearer " + token,
    },
  });
};
export const GetPersonalInbox_V2 = (
  pageIndex: number,
  inboxId: any,
  archiveFlag: any
) => {
  debugger;
  const url = `${configData.APiUrl}/v2/Rooms/GetRooms?inboxId=${inboxId}&archiveFlag=${archiveFlag}&PageIndex=${pageIndex}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const CreateRoom_V2 = async (data: any, inboxId: Number) => {
  debugger;
  // let dataReturned;
  let isGroup = store
    .getState()
    .reduxReducer.loggedInUser.inboxes.$values.filter(
      (item: any) => item.InboxId === inboxId
    )[0].InboxType;
  isGroup = isGroup == 1 ? true : false;
  const url = `${configData.APiUrl}/V2/Rooms/CreateRoom?inboxId=${inboxId}&isGroup=${isGroup}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(data),
  });
};
export const uploadImageToBlob_V2 = async (fileBlobDetails: any) => {
  const url = await `${configData.APiUrl}/V3/Send/AddMessageMedia`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(fileBlobDetails),
  });
};
export async function queueMessageChannel_V2(
  data: any,
  roomId: any,
  text: any,
  inboxId: any,
  uploadedFileUrl: any,
  serviceproviderid: any,
  channelName: any
) {
  // if(text.includes("http"))
  // {
  // text=text.replace(/%2520/g,"£_Yak");
  // }
  let dataReturned;
  text = decodeURIComponent(text);
  // if(text.includes("http"))
  // {
  // text=text.replace(/£_Yak/g,"%2520");
  // text=text.replace(/&/g,"%26");
  // }
  // text= text.replace(/\n/g,"%0A");

  const version = "React";
  if (text?.length === 0) {
    text = "%00";
  }
  debugger;
  let isGroup = store
    .getState()
    .reduxReducer.loggedInUser.inboxes.$values.filter(
      (item: any) => item.InboxId === inboxId
    )[0].InboxType;
  isGroup = isGroup == 1 ? true : false;
  var SendRequest: any = {
    participants: data,
    roomId: roomId,
    isGroup: isGroup,
    text: text,
    inboxId: inboxId,
    uploadedFiles: uploadedFileUrl,
    serviceProviderId: serviceproviderid,
    channelName: channelName,
    yakchatVersion: version,
  };

  const url = `${configData.APiUrl}/V3/Send/QueueMessage`;
  await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(SendRequest),
  })
    .then(async function (response) {
      debugger;
      // first then()
      if (response.ok) {
        dataReturned = response.json();
        return dataReturned;
      }
      else if(response.status!=undefined&&response.status!=401)
      {
        dataReturned = response.json();
        return dataReturned;
      }
       else {
        return response.text().then((text) => {
          if (response.status == 401) {
            dataReturned = "Unable to send text: "+"Authentication error";
            return dataReturned;
          }
          dataReturned = text;
          return dataReturned;
        });
      }
    })
    .then(function (msgtext) {
      if (msgtext.Code != undefined && msgtext.Code != 200) {
        dataReturned =msgtext;
      } else if (msgtext.Code == undefined) {
       
        dataReturned ="Unable to send text: " + msgtext;
      } else {
        return msgtext;
      }
    })
    .catch(function (error) {
      if(error.message!=undefined)
      {
        dataReturned = "Unable to send text: " + error.message;
      } else if(error.Code!=undefined){
        error = error.Message;
        dataReturned ="Unable to send text: " + error;
      }
     
    else
    {
      return error;
    }
    });
  return dataReturned;
}
export const searchConversation_V2 = async (
  archiveFlag: any,
  searchText: any,
  inboxId: any
) => {
  debugger;

  const url =
    await `${configData.APiUrl}/V2/Rooms/SearchRooms?inboxId=${inboxId}&archiveFlag=${archiveFlag}&searchText=${searchText}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getPagingMessagesByRoomId_V2 = (roomId: any, pageIndex: any) => {
  const url = `${configData.APiUrl}/V2/Rooms/GetMessages?roomId=${roomId}&pageIndex=${pageIndex}`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const unreadCounterUpdate_V2 = (
  roomId: any,
  lastIncomingMessageId: any
) => {
  const url = `${configData.APiUrl}/V2/Rooms/UpdateMessageStatus?roomId=${roomId}&lastSeen=${lastIncomingMessageId}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getyakchatcontactlist_V2 = (pageindex: any) => {
  const url =
    `${configData.APiUrl}/V3/Contacts?pageIndex=` + pageindex;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getContactsByListId_V2 = (listId: any) => {
  const url =
    `${configData.APiUrl}/V2/ContactList/GetLocalContactsByListId?id=` + listId;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const saveYakChatContact_V2 = async (data: any, savedFrom: any) => {
  let url = "";
  debugger;
  var userEmail = store.getState().reduxReducer.loggedInUser.account.userName;
  data.userEmail = userEmail;
  data.SurName = data.lastName;
  data.ForeName = data.firstName;
  data.ContactEmail = data.email;
  var value: any = [];
  var object: any = {};
  if (data.groupEmail !== "" && data.groupEmail !== null) {
   
    data.InboxId= store.getState().reduxReducer.loggedInUser.inboxes.$values.filter(
        (item: any) => item.InboxType !== 0 && item.Name == data.groupEmail
      )[0].InboxId;
      data.ContactAssignment= 1;
      data.EditPermissions= 0;
  }
  if (data.groupEmail == "" || data.groupEmail == null) {
  
    data.InboxId=0;//store
    // .getState()
    // .reduxReducer.loggedInUser.inboxes.$values.filter(
    //   (item: any) => item.InboxType ==0)!=undefined&&store
    //   .getState()
    //   .reduxReducer.loggedInUser.inboxes.$values.filter(
    //     (item: any) => item.InboxType ==0).length>0?store
    //   .getState()
    //   .reduxReducer.loggedInUser.inboxes.$values.filter(
    //     (item: any) => item.InboxType == 0)[0].InboxId:(store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 6 ||
    //     store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 7)?0:store.getState().reduxReducer.loggedInUser.inboxes.$values[0].InboxId;
        data.ContactAssignment= 0;
        data.EditPermissions= 0;
   
  }

  url = await `${configData.APiUrl}/V3/Contacts`;
  // }else  if(data.email === "" && groupEmail === ""){
  //   url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=""&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=""`;
  // }else if(data.email === "" && groupEmail !== ""){
  //   url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=""&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  // }
  // else if(groupEmail === "" && data.email !== "") {
  //   url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=""`;
  // } else if(data.lastName === "") {
  //   url =await `${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=""&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  // }
  // else{
  //   url  = await`${configData.APiUrl}/LocalContacts/InsertContacts?Email=${data.email}&Phone=${phoneNumber}&foreName=${data.firstName}&sureName=${data.lastName}&userEmail=${userEmail}&groupEmail=${groupEmail}`;
  // }
  return await fetch(url, {
    method: "PUT",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(data),
  });
};
export const uploadYakChatContact_V2 = async (data: any, savedFrom: any) => {
  // var phoneNumber= encodeURIComponent(data.phoneNumber);
  debugger;
  var value: any = [];
  var object: any = {};
  data.forEach((datacontact: any) => {
    if (datacontact.GroupEmail !== "" && datacontact.GroupEmail !== null) {
      datacontact.InboxId= datacontact.GroupEmail;
      datacontact.ContactAssignment= 1;
      datacontact.EditPermissions= 0;
      datacontact.SurName=datacontact.SureName;
 
    }
    if (datacontact.GroupEmail == "" || datacontact.GroupEmail == null) {
   
        datacontact.InboxId=store
        .getState()
        .reduxReducer.loggedInUser.inboxes.$values.filter(
          (item: any) => item.InboxType ==0)!=undefined&&store
          .getState()
          .reduxReducer.loggedInUser.inboxes.$values.filter(
            (item: any) => item.InboxType ==0).length>0?store
          .getState()
          .reduxReducer.loggedInUser.inboxes.$values.filter(
            (item: any) => item.InboxType == 0)[0].InboxId:(store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 6 ||
            store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 7)?0:store.getState().reduxReducer.loggedInUser.inboxes.$values[0].InboxId;
      datacontact.ContactAssignment= 1;
      datacontact.EditPermissions= 0;
      datacontact.SurName=datacontact.SureName;
    }
  });
  const url = await `${configData.APiUrl}/V3/Contacts/Bulk`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(data),
  });
};

export const editYakChatContact_V2 = (id: any, data: any) => {
  debugger;
  data.ContactId = id;
  let url = "";
  data.ContactEmail = data.userEmail;
  var userEmail = store.getState().reduxReducer.loggedInUser.account.userName;
  data.userEmail = userEmail;
  data.PhoneNumber = data.Phone;
  data.SurName = data.surename;
  data.ForeName = data.foreName;
  data.ContactEmail = data.email==undefined?data.Email:data.email;
  var value: any = [];
  var object: any = {};
  data.InboxId =
    store.getState().reduxReducer.sharedInboxData.currentSelected.email;
    
  if (data.groupEmail !== "" && data.groupEmail !== null) {
    let inboxId=isNaN(data.groupEmail)?store
    .getState()
    .reduxReducer.loggedInUser.inboxes.$values.filter(
      (item: any) =>item.Name == data.groupEmail
    )[0].InboxId:data.groupEmail;
    data.InboxId=inboxId;
    data.ContactAssignment=1;
    data.EditPermissions= 0;
    
  }
  if (data.groupEmail == "" || data.groupEmail == null) {
    // let inboxId=store
    // .getState()
    // .reduxReducer.loggedInUser.inboxes.$values.filter(
    //   (item: any) => item.InboxType ==0)!=undefined&&store
    //   .getState()
    //   .reduxReducer.loggedInUser.inboxes.$values.filter(
    //     (item: any) => item.InboxType ==0).length>0?store
    //   .getState()
    //   .reduxReducer.loggedInUser.inboxes.$values.filter(
    //     (item: any) => item.InboxType == 0)[0].InboxId:(store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 6 ||
    //     store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 7)?0:store.getState().reduxReducer.loggedInUser.inboxes.$values[0].InboxId;
    
      data.InboxId=0;//inboxId;
      data.ContactAssignment=0;
      data.EditPermissions= 0;
    
  }
  url = `${configData.APiUrl}/V3/Contacts/`+id;

  return fetch(url, {
    method: "PATCH",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(data),
  });
};
export const saveYakChatSettings_V2 = (
  checkedvalue: string,
  isSharePoint: string
) => {
  const url = `${configData.APiUrl}/V2/Settings/UpdateSetting?userEmail=${
    store.getState().reduxReducer.loggedInUser.account.userName
  }&flag=${checkedvalue}&isSharePoint=${isSharePoint}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const searchyakchatcontactlist_V2 = (
  pageindex: any,
  searchFilter: any
) => {
  searchFilter = encodeURIComponent(searchFilter);
  // var sharedEmails =  store.getState().reduxReducer.shareMsgCount?.$values !== undefined ? store.getState().reduxReducer.shareMsgCount?.$values.map((a: any) => a.sharedEmail).toString() : store.getState().reduxReducer.shareMsgCount?.map((a: any) => a.sharedEmail).toString();
  // var userEmail = encodeURIComponent( store.getState().reduxReducer.loggedInUser.account.userName);
  // userEmail= userEmail + "," + sharedEmails;
  const url =
    `${configData.APiUrl}/V2/Contacts/SearchContacts?pageIndex=` +
    pageindex +
    `&searchText=` +
    searchFilter;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      // "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
      // mode: "no-cors",
    },
  });
};
export const GetContactLists_V2 = (email: any) => {
  const url = `${configData.APiUrl}/V3/ContactList`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const AddContactList_V2 = (participants: any) => {
  debugger;
  var object: any = {};
  if (participants.GroupEmail !== "" && participants.GroupEmail !== null&& participants.GroupEmail !== undefined) 
  {
    participants.InboxId= participants.GroupEmail;
    participants.ContactAssignment=1;
    participants.EditPermissions=  0;
  }

  if (participants.GroupEmail == "" || participants.GroupEmail == null) {
    // let inboxId=store
    // .getState()
    // .reduxReducer.loggedInUser.inboxes.$values.filter(
    //   (item: any) => item.InboxType ==0)!=undefined&&store
    //   .getState()
    //   .reduxReducer.loggedInUser.inboxes.$values.filter(
    //     (item: any) => item.InboxType ==0).length>0?store
    //   .getState()
    //   .reduxReducer.loggedInUser.inboxes.$values.filter(
    //     (item: any) => item.InboxType == 0)[0].InboxId:(store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 6 ||
    //     store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 7)?0:store.getState().reduxReducer.loggedInUser.inboxes.$values[0].InboxId;
    
        participants.InboxId= 0;
        participants.ContactAssignment=0;
        participants.EditPermissions=  0;
  }
  // if (participants.GroupEmail == "" || participants.GroupEmail == null) {
  //   participants.InboxId = store
  //     .getState()
  //     .reduxReducer.loggedInUser.inboxes.$values.filter(
  //       (item: any) => item.InboxType == 0
  //     )[0].InboxId;
  // }
  const url = `${configData.APiUrl}/V2/ContactList/AddList`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(participants),
  });
};
export const UpdateContactList_V2 = (contactLists: any) => {
  debugger;
  var object: any = {};
  if (contactLists.GroupEmail !== ""&&contactLists.GroupEmail !== 0 && contactLists.GroupEmail !== null&& contactLists.GroupEmail !== undefined) 
  {
    contactLists.InboxId= contactLists.GroupEmail;
    contactLists.ContactAssignment= 1;
    contactLists.EditPermissions=0;
  
}
  if (contactLists.GroupEmail == "" || contactLists.GroupEmail == null||contactLists.GroupEmail == 0) {
    let inboxId=0;
    // store
    // .getState()
    // .reduxReducer.loggedInUser.inboxes.$values.filter(
    //   (item: any) => item.InboxType ==0)!=undefined&&store
    //   .getState()
    //   .reduxReducer.loggedInUser.inboxes.$values.filter(
    //     (item: any) => item.InboxType ==0).length>0?store
    //   .getState()
    //   .reduxReducer.loggedInUser.inboxes.$values.filter(
    //     (item: any) => item.InboxType == 0)[0].InboxId:(store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 6 ||
    //     store.getState().reduxReducer.loggedInUser.user.ServicePlanId === 7)?0:store.getState().reduxReducer.loggedInUser.inboxes.$values[0].InboxId;
    
        contactLists.InboxId= inboxId;
        contactLists.ContactAssignment= 0;
        contactLists.EditPermissions=0;
  }

  const url = `${configData.APiUrl}/V2/ContactList/UpdateList`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(contactLists),
  });
};
// export const removeContactListsLocalContact_V2 = ( contactId: number,listId: any,email: string) => {
//   const url = `${configData.APiUrl}/V2/ContactList/RemoveContact?ContactIds=` + contactId + `&listId=` +listId;
//   return fetch(url, {
//     method: "Delete",
//     headers: {
//       accept: "application/json;",
//       "Content-Type": "application/json",
//       Authorization:
//         "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
//     }
//   });
// };
export const DeleteThisList_V2 = (id: string) => {
  const url = `${configData.APiUrl}/V2/ContactList/DeleteList?listId=${id}`;
  return fetch(url, {
    method: "Delete",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const DeleteThisContact_V2 = (id: string, phoneNumber: any) => {
  const url = `${configData.APiUrl}/V2/Contacts/DeleteContact?contactId=${id}`;
  return fetch(url, {
    method: "Delete",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const getSasTokenBlobs_V2 = async () => {
  const url = await `${configData.APiUrl}/V2/Send/GetMediaToken`;
  return await fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const CloseConversation_V2 = (roomId: any, flag: any) => {
  const url = `${configData.APiUrl}/V2/Rooms/CloseRoom?roomId=${roomId}&archiveFlag=${flag}`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  });
};
export const servicePhoneNumberUpdateTrail_V2 = async (
  PhoneNumber: any,
  trail: any,
  accessToken: any
) => {
  const url =
    await `${configData.APiUrl}/V2/SenderId/UpdateTrialPhoneNumber?phoneNumber=${PhoneNumber}&trial=${trail}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  });
};
export const addSharedContactApi_V2 = (lstContact: any) => {
  const url = `${configData.APiUrl}/V2/Contacts/AddMultipleContacts`;
  return fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
    body: JSON.stringify(lstContact),
  });
  // const url = `${configData.APiUrl}/LocalContacts/AddImportedContacts`;
  // return fetch(url, {
  //   method: "POST",
  //   headers: {
  //     accept: "application/json;",
  //     "Content-Type": "application/json",
  //     Authorization:
  //       "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
  //   },body:JSON.stringify(lstContact)
  // });
};
export const registerUserTrail_V2 = async (
  accessToken: any,
  userTrial: any
) => {
  const url = await `${configData.APiUrl}/V2/Users/AddTrial`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(userTrial),
  });
};
export const getOutlookcontactfoldersWithContacts = () => {
  const url = `${configData.graphApiUrl}/v1.0/me/contactFolders?$expand=contacts($top=${999})`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessToken,
    },
  });
};
export async function TranslateText(text: any, from: any, to: any) {
  debugger;
  let dataReturned;
  let key = "6bf5158a8d4e4a03bd9544c43dcd1633";
  let endpoint = "";
  let location = "centralus";
  const url = `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=${from}&to=${to}`;
  let textToTranslate = [{ text: text }];
  await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": key,
      "Ocp-Apim-Subscription-Region": location,
    },
    body: JSON.stringify(textToTranslate),
  })
    .then(async function (response) {
      // first then()
      if (response.ok) {
        dataReturned = response.json();
        return dataReturned;
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    })
    .then(function (text) {
      // second then()
      console.log("Request successful", text);
      dataReturned = text;
      return dataReturned;
    })
    .catch(function (error) {
      dataReturned = error;
      return dataReturned;
    });
  return dataReturned;
}
export async function DetectTranslateText(text: any) {
  debugger;
  let dataReturned;
  let key = "6bf5158a8d4e4a03bd9544c43dcd1633";
  let endpoint = "";
  let location = "centralus";
  const url = `https://api.cognitive.microsofttranslator.com/detect?api-version=3.0`;
  let textToTranslate = [{ text: text }];
  await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": key,
      "Ocp-Apim-Subscription-Region": location,
    },
    body: JSON.stringify(textToTranslate),
  })
    .then(async function (response) {
      // first then()
      if (response.ok) {
        dataReturned = response.json();
        return dataReturned;
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    })
    .then(function (text) {
      // second then()
      console.log("Request successful", text);
      dataReturned = text;
      return dataReturned;
    })
    .catch(function (error) {
      dataReturned = error;
      return dataReturned;
    });
  return dataReturned;
}
export async function GetLanguages() {
  let dataReturned;
  let key = "6bf5158a8d4e4a03bd9544c43dcd1633";
  let endpoint = "";
  let location = "centralus";
  const url = `https://api.cognitive.microsofttranslator.com/languages?api-version=3.0&scope=translation`;

  await fetch(url, {
    method: "Get",
    headers: {
      accept: "application/json;",
    },
  })
    .then(async function (response) {
      // first then()
      if (response.ok) {
        dataReturned = response.json();
        return dataReturned;
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    })
    .then(function (text) {
      // second then()
      console.log("Request successful", text);
      dataReturned = text;
      return dataReturned;
    })
    .catch(function (error) {
      dataReturned = error;
      return dataReturned;
    });
  return dataReturned;
}
export async function SaveTransltion(roomId: any, language: any) {
  debugger;
  let dataReturned;
  const url = `${configData.APiUrl}/V2/Rooms/UpdateRoomLanguage?roomId=${roomId}&language=${language}`;
  await fetch(url, {
    method: "PATCH",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + store.getState().reduxReducer.loggedInUser.accessTokenApi,
    },
  })
    .then(async function (response) {
      // first then()
      if (response.ok) {
        dataReturned = response.json();
        return dataReturned;
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    })
    .then(function (text) {
      // second then()
      console.log("Request successful", text);
      dataReturned = text;
      return dataReturned;
    })
    .catch(function (error) {
      dataReturned = error;
      return dataReturned;
    });
  return dataReturned;
}
// export const GetWebexRegistrations = (token: string) => {
//   const url = `${configData.APiUrl}/V2/Webex`;
//   return fetch(url, {
//     method: "GET",
//     headers: {
//       accept: "application/json;",
//       "Content-Type": "application/json",
//       Authorization:
//         "Bearer " + token,
//     },
//   });
// };
// export const SaveWebexRegistrations = async (webexConfig: any,token: string) => {
//   const url = await `${configData.APiUrl}/V2/Webex`;
//   return await fetch(url, {
//     method: "POST",
//     headers: {
//       accept: "application/json;",
//       "Content-Type": "application/json",
//       Authorization:
//         "Bearer " + token,
//     },
//     body: JSON.stringify(webexConfig),
//   });
// };
// export const getTokenWebex = async (code:any,brand:any) => {
//   var object: any = {};
//   var body = 'grant_type=authorization_code&'+  // This is an OAuth2 Authorization Code request
//  'redirect_uri='+encodeURIComponent(configData.publishurl)+'&'+ // Same custom app Redirect URI
//  'code='+encodeURIComponent(code)+'&'+ // User auth code retrieved previously
//  'client_id='+encodeURIComponent(brand=="cttext"?configData.webexClientId:configData.yakchatwebexClientId)+'&'+ // The custom app Client ID
//  'client_secret='+encodeURIComponent(brand=="cttext"?configData.webexSecret:configData.yakchatwebexSecret); 
//  let dataReturned;
//   const url = await `https://webexapis.com/v1/access_token?`+body;
//    await fetch(url, {
//     method: "POST",
//     headers: {
//       // accept: "application/json;",
//       "Content-Type": "application/x-www-form-urlencoded"
//     }
//   }) .then(async function (response) {
//     // first then()
//     if (response.ok) {
//       dataReturned = response.json();
//       return dataReturned;
//     } else {
//       return response.text().then((text) => {
//         throw new Error(text);
//       });
//     }
//   })
//   .then(function (text) {
//     // second then()
//     console.log("Request successful", text);
//     dataReturned = text;
//     return dataReturned;
//   })
//   .catch(function (error) {
//     dataReturned = error;
//     return dataReturned;
//   });
// return dataReturned;
// };
// export const getTokenRefreshWebex = async (refresh:any,brand:any) => {
//   var object: any = {};
//   var body = 'grant_type=refresh_token&'+  // This is an OAuth2 Authorization Code request
//  'refresh_token='+encodeURIComponent(refresh)+'&'+ // User auth code retrieved previously
//  'client_id='+encodeURIComponent(brand=="cttext"?configData.webexClientId:configData.yakchatwebexClientId)+'&'+ // The custom app Client ID
//  'client_secret='+encodeURIComponent(brand=="cttext"?configData.webexSecret:configData.yakchatwebexSecret); 
//  let dataReturned;
//   const url = await `https://webexapis.com/v1/access_token?`+body;
//    await fetch(url, {
//     method: "POST",
//     headers: {
//       // accept: "application/json;",
//       "Content-Type": "application/x-www-form-urlencoded"
//     }
//   }) .then(async function (response) {
//     // first then()
//     if (response.ok) {
//       dataReturned = response.json();
//       return dataReturned;
//     } else {
//       return response.text().then((text) => {
//         throw new Error(text);
//       });
//     }
//   })
//   .then(function (text) {
//     // second then()
//     console.log("Request successful", text);
//     dataReturned = text;
//     return dataReturned;
//   })
//   .catch(function (error) {
//     dataReturned = error;
//     return dataReturned;
//   });
// return dataReturned;
// };
// export const GetWebexRooms = async (token: string) => {
//   let dataReturned;
//   const url = await `https://webexapis.com/v1/rooms`;
//    await fetch(url, {
//     method: "Get",
//     headers: {
//       accept: "application/json;",
//       "Content-Type": "application/json",
//       Authorization:
//         "Bearer " + token,
//     },
//   }) .then(async function (response) {
//     // first then()
//     if (response.ok) {
//       dataReturned = response.json();
//       return dataReturned;
//     } else {
//       return response.text().then((text) => {
//         throw new Error(text);
//       });
//     }
//   })
//   .then(function (text) {
//     // second then()
//     console.log("Request successful", text);
//     dataReturned = text;
//     return dataReturned;
//   })
//   .catch(function (error) {
//     dataReturned = error;
//     return dataReturned;
//   });
// return dataReturned;
// };
// export const GetWebexUser = async (token: string) => {
//   let dataReturned;
//   const url = await `https://webexapis.com/v1/userinfo`;
//    await fetch(url, {
//     method: "Get",
//     headers: {
//       accept: "application/json;",
//       "Content-Type": "application/json",
//       Authorization:
//         "Bearer " + token,
//     },
//   }) .then(async function (response) {
//     // first then()
//     if (response.ok) {
//       dataReturned = response.json();
//       return dataReturned;
//     } else {
//       return response.text().then((text) => {
//         throw new Error(text);
//       });
//     }
//   })
//   .then(function (text) {
//     // second then()
//     console.log("Request successful", text);
//     dataReturned = text;
//     return dataReturned;
//   })
//   .catch(function (error) {
//     dataReturned = error;
//     return dataReturned;
//   });
// return dataReturned;
// };
// export const CreateWebexRoom = async (webexConfig: any,token: string) => {
//   let dataReturned;
//   const url = await `https://webexapis.com/v1/rooms`;
//   return await fetch(url, {
//     method: "POST",
//     headers: {
//       accept: "application/json;",
//       "Content-Type": "application/json",
//       Authorization:
//         "Bearer " + token,
//     },
//     body: JSON.stringify(webexConfig),
//   }) .then(async function (response) {
//     // first then()
//     if (response.ok) {
//       dataReturned = response.json();
//       return dataReturned;
//     } else {
//       return response.text().then((text) => {
//         throw new Error(text);
//       });
//     }
//   })
//   .then(function (text) {
//     // second then()
//     console.log("Request successful", text);
//     dataReturned = text;
//     return dataReturned;
//   })
//   .catch(function (error) {
//     dataReturned = error;
//     return dataReturned;
//   });
// return dataReturned;
// };
// export const CreateWebexTab = async (webexConfig: any,token: string) => {
//   let dataReturned;
//   const url = await `https://webexapis.com/v1/room/tabs`;
//   return await fetch(url, {
//     method: "POST",
//     headers: {
//       accept: "application/json;",
//       "Content-Type": "application/json",
//       Authorization:
//         "Bearer " + token,
//     },
//     body: JSON.stringify(webexConfig),
//   }) .then(async function (response) {
//     // first then()
//     if (response.ok) {
//       dataReturned = response.json();
//       return dataReturned;
//     } else {
//       return response.text().then((text) => {
//         throw new Error(text);
//       });
//     }
//   })
//   .then(function (text) {
//     // second then()
//     console.log("Request successful", text);
//     dataReturned = text;
//     return dataReturned;
//   })
//   .catch(function (error) {
//     dataReturned = error;
//     return dataReturned;
//   });
// return dataReturned;
// };
export const getInstlationAppId =(userId: any,graphToken:any) => {
  const url = `${configData.graphApiUrl}/v1.0/users/${userId}/teamwork/installedApps?$expand=teamsApp&$filter=teamsApp/externalId eq '${configData.ManifestAppId}'`;
  
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + graphToken,
    }})
};
export const getChatId = (userId: any,teamsAppInstallationId:any,graphToken:any) => {
  const url = `${configData.graphApiUrl}/v1.0/users/${userId}/teamwork/installedApps/${teamsAppInstallationId}/chat`;
  let dataReturned;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + graphToken,
    }
  })
};
export const GetUserTeamsActivityRegistrations = (token: string) => {
  const url = `${configData.APiUrl}/V2/TeamsActivity/User`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + token,
    },
  });
};
export const GetgroupTeamsActivityRegistrations = (token: string) => {
  const url = `${configData.APiUrl}/V2/TeamsActivity/Group`;
  return fetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + token,
    },
  });
};
export const SaveTeamsActivityUser = async (ActivityUserConfig: any,token: string) => {
  const url = await `${configData.APiUrl}/V2/TeamsActivity/User`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + token,
    },
    body: JSON.stringify(ActivityUserConfig),
  });
};
export const SaveTeamsActivityGroup = async (ActivityGroupConfig: any,token: string) => {
  const url = await `${configData.APiUrl}/V2/TeamsActivity/Group`;
  return await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + token,
    },
    body: JSON.stringify(ActivityGroupConfig),
  });
};